module Easypass {
    @Component('easypass', 'buttonsCommande', {
        controllerAs: 'vm',
        bindings: {
            bonplan: '<',
            clickAndCollect: '<',
            restaurantIdBonplan: '<',
            restaurantIdCc: '<',
            clickAndServe: '<'
        },
        templateUrl: './buttonsCommande.html'
    })
    class ButtonsCommandeComponent {
        static $inject: string[] = ['$state'];

        bonplan: boolean;
        clickAndCollect: boolean;
        clickAndServe: boolean;
        restaurantIdBonplan: number;
        restaurantIdCc: number;
        bonplanType: Core.RestaurantType = Core.RestaurantType["bonplan click&collect"];
        ccType: Core.RestaurantType = Core.RestaurantType["Click & Collect"];

        constructor(private $state: ng.ui.IStateService) {
        }

        goToRestaurantBonPlan() {
            this.$state.go('dt-home', {
                restaurantId: this.restaurantIdBonplan
            });
        }

        goToRestaurantCC() {
            this.$state.go('clickAndCollect', {
                id: this.restaurantIdCc
            });
        }

        goToRestaurants(restaurantType: Core.RestaurantType) {
            this.$state.go('restaurants', { restaurantType: restaurantType });
        }
    }
}