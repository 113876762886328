module Easypass {
    angular.module('easypass', ['configuration', 'localization', 'ui.router', 'ngMessages', 'ngAnimate', 'ngTouch', 'nvd3', 'rzModule', 'as.sortable', 'ui.select', 'ngSanitize', 'core', 'ngCookies'])
        .config([
            '$compileProvider', '$httpProvider', '$stateProvider', '$urlRouterProvider', 'localizeServiceProvider', 'userServiceProvider', 'cookieServiceProvider', 'routeServiceProvider', 'localStorageProvider', 'appCoreConfiguratorProvider',
            ($compileProvider: ng.ICompileProvider,
                $httpProvider: ng.IHttpProvider,
                $stateProvider: ng.ui.IStateProvider,
                $urlRouterProvider: ng.ui.IUrlRouterProvider,
                localizeServiceProvider: IS.Localization.LocalizeServiceProvider,
                userServiceProvider: Core.UserServiceProvider,
                cookieServiceProvider: Core.CookieServiceProvider,
                routeServiceProvider: Core.RouteServiceProvider,
                localStorageProvider: Core.ILocalStorageProvider,
                appCoreConfiguratorProvider: Core.AppCoreConfiguratorProvider) => {

                appCoreConfiguratorProvider.config($compileProvider, $httpProvider, $stateProvider, $urlRouterProvider, localizeServiceProvider, userServiceProvider, cookieServiceProvider, routeServiceProvider, localStorageProvider);

                Easypass.Registration.registerAllRoutes($stateProvider);

                $urlRouterProvider.otherwise('/restaurants');
            }])

        .run(['$state', '$rootScope', '$exceptionHandler', '$http', 'userService', 'cookieService', 'routeService', 'localizeService', 'pushManagerService', 'configService', 'uuidService', 'notationService', 'appCoreConfigurator',
            ($state: ng.ui.IStateService,
                $rootScope: Core.IRootScopeWithHeaderConfiguration,
                $exceptionHandler: ng.IExceptionHandlerService,
                $http: ng.IHttpService,
                userService: Core.UserService,
                cookieService: Core.CookieService,
                routeService: Core.RouteService,
                localizeService: IS.Localization.LocalizeService,
                pushManager: Core.IPushManagerService,
                configService: IS.Configuration.ConfigService,
                uuidService: Core.UuidService,
                notationService: Core.INotationService,
                appCoreConfigurator: Core.AppCoreConfigurator) => {

                appCoreConfigurator.run($state, $rootScope, $exceptionHandler, $http, userService, cookieService, routeService, localizeService, pushManager, configService, uuidService);

                if (userService.isAuthenticated()) {
                    notationService.init();
                }
            }]);
}

module Easypass.Registration {
    export function registerAllRoutes($stateProvider: ng.ui.IStateProvider) {
        registerDinnerTakeaway($stateProvider);
        registerCommandes($stateProvider);
        registerClickAndCollect($stateProvider);
        registerAuthentication($stateProvider);
    }

    var registerDinnerTakeaway: ($stateProvider: ng.ui.IStateProvider) => void;
    export function setDTRegister(register: ($stateProvider: ng.ui.IStateProvider) => void) {
        registerDinnerTakeaway = register;
    }

    var registerCommandes: ($stateProvider: ng.ui.IStateProvider) => void;
    export function setCommandesRegister(register: ($stateProvider: ng.ui.IStateProvider) => void) {
        registerCommandes = register;
    }

    var registerClickAndCollect: ($stateProvider: ng.ui.IStateProvider) => void;
    export function setCCRegister(register: ($stateProvider: ng.ui.IStateProvider) => void) {
        registerClickAndCollect = register;
    }

    var registerAuthentication: ($stateProvider: ng.ui.IStateProvider) => void;
    export function setAuthRegister(register: ($stateProvider: ng.ui.IStateProvider) => void) {
        registerAuthentication = register;
    }
}