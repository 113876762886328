module Core {
    export class RestaurantsService {
        protected myRestaurants: IRestaurant[] = [];
        protected getsFromServerOrchestrator: IS.SingleRequestOrchestrator;
        protected userEmail: string;

        constructor(
            protected $rootScope: ng.IRootScopeService,
            protected $http: ng.IHttpService,
            protected $q: ng.IQService,
            protected storageService: StorageService,
            protected configService: IS.Configuration.ConfigService,
            protected userService: UserService,
            protected $state: ng.ui.IStateService) {

            var deferred = this.$q.defer<IUserInformations>();

            this.myRestaurants = this.storageService.getRestaurants() || [];

            if (userService.isAuthenticated()) {
                this.gets(true);
            }

            userService.getUserInformations().then(user => {
                this.userEmail = user.email;
            }).catch(error => {
                deferred.reject(error);
            });

            this.$rootScope.$on('clearUserInMemoryData', () => {
                this.myRestaurants = [];
                this.getsFromServerOrchestrator = null;
            });
        }

        gets(force: boolean = false, excludeCcRs: boolean = false, excludeNoFluidite: boolean = false, excludeHidden: boolean = true): ng.IPromise<IRestaurant[]> {
            var deferred = this.$q.defer<IRestaurant[]>();

            if (force || this.myRestaurants.length === 0 || this.getsFromServerOrchestrator != null) {
                this.getsFromServer().then(restaurants => {

                    this.myRestaurants = restaurants;
                    this.storageService.saveRestaurants(restaurants);
                    deferred.resolve(this.filter(this.sort(restaurants), excludeCcRs, excludeNoFluidite, excludeHidden));

                }).catch(error => {
                    deferred.reject(error);
                }).finally(() => {
                    this.getsFromServerOrchestrator = null;
                });
            }
            else {
                deferred.resolve(this.filter(this.sort(this.myRestaurants), excludeCcRs, excludeNoFluidite, excludeHidden));
            }

            return deferred.promise;
        }

        get(id: number): ng.IPromise<IRestaurant> {
            var deferred = this.$q.defer<IRestaurant>();

            this.gets().then(restaurants => {
                var restaurant = _.find(restaurants, restaurant => restaurant.id === id);

                if (restaurant == null) {
                    deferred.reject(`Restaurant ${id} not found`);
                    return;
                }

                deferred.resolve(restaurant);
            }).catch(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        getNext(currentRestaurantId: number, excludeCcRs: boolean = false, excludeNoFluidite: boolean = false, excludeHidden: boolean = true): ng.IPromise<IRestaurant> {
            return this.getByDirection(currentRestaurantId, 1, excludeCcRs, excludeNoFluidite, excludeHidden);
        }

        getPrevious(currentRestaurantId: number, excludeCcRs: boolean = false, excludeNoFluidite: boolean = false, excludeHidden: boolean = true): ng.IPromise<IRestaurant> {
            return this.getByDirection(currentRestaurantId, -1, excludeCcRs, excludeNoFluidite, excludeHidden);
        }


        //isValidOpenDate(dateString: string, restaurant: IRestaurant) {

        //    var date = moment(dateString);
        //    if (date != null && restaurant != null) {

        //        //On commence par regarder les fermetures exceptionnelles
        //        var fermetures = restaurant.joursFermetures;
        //        if (fermetures.length > 0) {
        //            for (var fermeture of fermetures) {
        //                if (date.isSameOrAfter(moment(fermeture.dateDebut.toDateString(), 'YYYY-MM-DD'))
        //                    && date.isSameOrBefore(moment(fermeture.dateFin.toDateString(), 'YYYY-MM-DD'))) {
        //                    return true;
        //                }
        //            }
        //        }

        //        // Si pas de fermetures de prévues on regarde les jours d'ouverture

        //        // un -1 pour le faire matcher avec l'enum JourOuvertureFlag
        //        var day = 1 << (date.day() - 1);
        //        // le 6 correspond au dimanche 
        //        if (day == -1) day = 1 << 6;

        //        return !((restaurant.jourOuverture & day) == day);
        //    }
        //    return false;

        //}


        protected getsFromServer(requestConfig: ng.IRequestConfig = null): ng.IPromise<IRestaurant[]> {

            return this.configService.getConfigValueAsync('url').then((url) => {
                if (this.getsFromServerOrchestrator == null) {
                    if (requestConfig === null) {
                        requestConfig = {
                            method: 'GET',
                            url: `${url}/${this.userService.userInformations.currentSite.name}/restaurant`
                        };
                    }
                    this.getsFromServerOrchestrator = new IS.SingleRequestOrchestrator(this.$http, this.$q, requestConfig, result => {
    
                        var restaurants: IRestaurant[] = result.map((rep: any) => <IRestaurant>rep);
    
                        for (var restaurant of restaurants) {
                            if (!restaurant.hasImage) {
    
                                if (restaurant.isPrestationSurMesure) {
                                    restaurant.imageUrl = './styles/assets/prestation-sur-mesure-restaurant.jpg';
                                } else
                                    if (restaurant.isDinnerTakeaway) {
                                        restaurant.imageUrl = './styles/assets/dta-restaurant.jpg';
                                    }
                                    else if (restaurant.isFoodles) {
                                        restaurant.imageUrl = './styles/assets/foodles-restaurant.jpg';
                                    } else if (restaurant.isRoomService) {
                                        restaurant.imageUrl = './styles/assets/default-restaurant-roomservice.jpg';
                                    }
                                    else {
                                        restaurant.imageUrl = './styles/assets/default-restaurant.jpg';
                                    }
    
                                restaurant.hasImage = true;
                            } else {
                                restaurant.imageUrl = encodeURI(`${this.configService.getConfigValue('url')}/${this.userService.userInformations.currentSite.name}/restaurant/${restaurant.id}/picture`);
                            }
    
                            restaurant.restaurantTypeLabel = restaurant.restaurantType;


                            for (var jourFermeture of restaurant.joursFermetures || []) {
                                jourFermeture.dateDebut = this.convertToJsDate(jourFermeture.dateDebut);
                                jourFermeture.dateFin = this.convertToJsDate(jourFermeture.dateFin);
                            }
                        }
    
                        return restaurants;
                    });
                }
    
                return this.$q.resolve(this.getsFromServerOrchestrator.execute());
            });
            
        }

        private filter(restaurants: IRestaurant[], excludeCcRs: boolean, excludeNoFluidite: boolean, excludeHidden: boolean): IRestaurant[] {
            var hiddenRestaurants = this.storageService.getHiddenRestaurants(this.userEmail) || [];

            restaurants = _.filter(restaurants, restaurant => {
                var keep = (!excludeCcRs || (!restaurant.isClickAndCollect && !restaurant.isRoomService));
                keep = keep && (!excludeNoFluidite || restaurant.hasFluidite);

                if (excludeHidden) {
                    keep = keep && (_.findIndex(hiddenRestaurants, hidden => hidden === restaurant.id) === -1);
                }
                return keep;
            });
            return restaurants;
        }

        private sort(restaurants: IRestaurant[]): IRestaurant[] {
            var order = this.storageService.getRestaurantOrder();
            if (!order) {
                return restaurants;
            }

            var ordered = order.map(x => _.find(restaurants, r => r.id === x));
            ordered = ordered.filter(x => x !== undefined);
            ordered = ordered.concat(restaurants.filter(x => order.indexOf(x.id) === -1));
            return ordered;
        }

        private getByDirection(currentRestaurantId: number, direction: number, excludeCcRs: boolean, excludeNoFluidite: boolean, excludeHidden: boolean): ng.IPromise<IRestaurant> {
            var deferred = this.$q.defer<IRestaurant>();

            this.gets(false, excludeCcRs, excludeNoFluidite, excludeHidden).then(restaurants => {
                if (restaurants == null || restaurants.length === 0) {
                    deferred.reject('No suitable restaurant found');
                    return;
                }
                restaurants = restaurants.filter(r => !r.isDinnerTakeaway && !r.isFoodles);
                var index = _.findIndex(restaurants, restaurant => restaurant.id === currentRestaurantId);
                if (index === -1) {
                    index = 0;
                }

                var restaurant = restaurants[(index + direction + restaurants.length) % restaurants.length];
                deferred.resolve(restaurant);
            }).catch(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        private getJoursFermeturesPromises(restaurants: IRestaurant[]): ng.IPromise<void>[] {
            let promises: ng.IPromise<void>[] = [];

            // récupérer les jours de fermetures de chacun des restaurants
            for (var restaurant of restaurants) {
                promises.push(this.getJoursFermeturesFromServer(restaurant));
            }

            return promises;
        }

        private getJoursFermeturesFromServer(restaurant: IRestaurant): ng.IPromise<void> {
            var defered = this.$q.defer<void>();

            this.$http.get<any>(`${this.configService.getConfigValue('url')}/${this.userService.userInformations.currentSite.name}/restaurant/${restaurant.id}/jours-fermetures`).success((results: IDateInterval[]) => {
                restaurant.joursFermetures = [];

                for (var result of results) {
                    result.dateDebut = this.convertToJsDate(result.dateDebut);
                    result.dateFin = this.convertToJsDate(result.dateFin);
                    restaurant.joursFermetures.push(result);
                }

                defered.resolve();
            }).catch(error => {
                defered.reject(error);
            });

            return defered.promise;
        }

        private convertToJsDate(str: Date): Date {
            let date: Date = moment(str).toDate();
            date.setHours(0, 0, 0, 0);
            return date;
        }

        getIsOpenSmart(restaurant: IRestaurant): boolean {
            if (restaurant.isClickAndCollect) {
                return this.getIsOrderOpen(restaurant);
            }
            return this.getIsOpen(restaurant);
        }

        getIsOpen(restaurant: IRestaurant): boolean {
            return this.checkIsOpen(restaurant.openAt, restaurant.closeAt);
        }

        getIsOrderOpen(restaurant: IRestaurant): boolean {
            return this.checkIsOpen(restaurant.orderOpenAt, restaurant.orderCloseAt);
        }

        private checkIsOpen(openAt: string, closeAt: string): boolean {
            var open: Date = this.stringHourToDate(openAt, null, true);
            var close: Date = this.stringHourToDate(closeAt, null, false);

            var now: Date = new Date();
            now.setSeconds(0, 0);

            return open <= now && now <= close;
        }

        stringHourToDate(hour: string, date: Date = null, isLowerBound: boolean = false): Date {
            date = !!date ? date : new Date();
            date.setHours(0, 0, 0, 0);

            if (hour != null && /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(hour)) {
                var splittedHour = hour.split(':');
                date.setHours(+splittedHour[0], +splittedHour[1]);
            }
            else {
                date.setFullYear(date.getFullYear() + (isLowerBound ? -1 : 1));
            }

            return date;
        }

        getRouteById(restaurantId: number): ng.IPromise<string> {
            var deferred = this.$q.defer<string>();

            this.get(restaurantId)
                .then(restaurant => {
                    var route = this.getRoute(restaurant);
                    deferred.resolve(route);
                })
                .catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        private getRoute(restaurant: IRestaurant): string {
            if (restaurant.isClickAndCollect) {
                return 'clickAndCollect';
            }
            return 'restaurant';
        }

        getHasCaisse(restaurantId: number): ng.IPromise<boolean> {
            var deferred = this.$q.defer<boolean>();

            // plusieurs façons d'être sans caisse :
            // 1.	Site.HasCaisse = False
            // 		=> Sans Caisse, peu importe le mode du restaurant
            // 2.	Site.HasCaisse = True
            // 		a.	Restaurant.EstSansCaisse = False
            // 			=> Avec Caisse
            // 		b.	Restaurant.EstSansCaisse = True
            // 			=> Sans Caisse

            this.userService.getUserInformations()
                .then(userInformations => {
                    if (!userInformations.currentSite.hasCaisse) {
                        deferred.resolve(false);
                    }
                    else {
                        this.get(restaurantId)
                            .then(restaurant => {
                                deferred.resolve(!restaurant.estSansCaisse);
                            })
                            .catch(error => {
                                deferred.reject(error);
                            });
                    }
                })
                .catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        setOrder(restaurantIds: number[]) {
            this.storageService.saveRestaurantOrder(restaurantIds);
        }

        resetHiddenRestaurants() {
            this.storageService.setHiddenRestaurants([], this.userEmail);
        }

        getJourOuvertureFlagByMomentDate(date: moment.Moment): Core.JourOuvertureFlag {
            let flag: Core.JourOuvertureFlag = this.getJourOuvertureFlag(date.day());
            if (!!flag) {
                return flag;
            }
            throw `${date.day()} of date ${date.toDate()} is not a valid day number`;
        }

        getJourOuvertureFlagByJSDate(date: Date): Core.JourOuvertureFlag {
            let flag: Core.JourOuvertureFlag = this.getJourOuvertureFlag(date.getDay());
            if (!!flag) {
                return flag;
            }
            throw `${date.getDay()} of date ${date} is not a valid day number`;
        }

        private getJourOuvertureFlag(day: number): Core.JourOuvertureFlag {
            switch (day) {
                case 0:
                    return Core.JourOuvertureFlag.Dimanche;
                case 1:
                    return Core.JourOuvertureFlag.Lundi;
                case 2:
                    return Core.JourOuvertureFlag.Mardi;
                case 3:
                    return Core.JourOuvertureFlag.Mercredi;
                case 4:
                    return Core.JourOuvertureFlag.Jeudi;
                case 5:
                    return Core.JourOuvertureFlag.Vendredi;
                case 6:
                    return Core.JourOuvertureFlag.Samedi;
            }
            return null;
        }

        getIsAnOpenningDay(restaurant: Core.IRestaurant, date: Date): boolean {
            // pour ne pas modifier la date passée en paramètre, on la clone avant de supprimer l'heure
            date = new Date(date.getTime());
            date.setHours(0, 0, 0, 0);

            console.log("estUnJourDeFermetureExceptionnelle:" + this.estUnJourDeFermetureExceptionnelle(restaurant, date));
            // tslint:disable-next-line:no-bitwise
            return !!(this.getJourOuvertureFlagByJSDate(date) & restaurant.jourOuverture)
                && !this.estUnJourDeFermetureExceptionnelle(restaurant, date);
        }

        getJoursFermetures(restaurant: Core.IRestaurant) {
            if (!!restaurant.joursFermetures && restaurant.joursFermetures.length) {
                return restaurant.joursFermetures[0];
            }
            return null;
        }

        estUnJourDeFermetureExceptionnelle(restaurant: Core.IRestaurant, date: Date): IDateInterval {
            // pour ne pas modifier la date passée en paramètre, on la clone avant de supprimer l'heure
            date = new Date(date.getTime());
            date.setHours(0, 0, 0, 0);

            for (let jourFermeture of restaurant.joursFermetures) {
                if (jourFermeture.dateDebut <= date && date <= jourFermeture.dateFin) {
                    return jourFermeture;
                }
            }
            return null;
        }
    }
}