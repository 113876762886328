module Easypass {
    @Component('easypass', 'termsAndConditions', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<'
        },
        templateUrl: './termsAndConditions.html'
    })
    class TermsAndConditionsComponent {
        static $inject: string[] = ['userService', 'restaurantsService'];
        isLoading: boolean;
        hasError: boolean;
        restaurantId: number;
        contactEmail: string;

        constructor(private userService: Core.UserService, private restaurantService: RestaurantsService) {
            userService.getUserInformations().then(infos => {
                this.contactEmail = infos.currentSite.siteContactMail;
            });
        }

        $onInit() {
            //this.isLoading = true;

            //this.dinnerTakeawayConfigService.getConfig(this.restaurantId)
            //    .then(c => {
                   
            //        this.config = c;
            //        this.isLoading = false;
            //    }).catch(r => { this.hasError = true; this.isLoading = false; });
        }
    }
} 