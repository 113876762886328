module Easypass.DinerTakeAway {
    @Component('easypass', 'dtBasketIcon', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<',
            currentDate: '<'
        },
        templateUrl: './dtBasketIcon.html',
        transclude: true
    })
    class BasketIconComponent {
        static $inject: string[] = ['dtBasketService', '$state'];

        articlesNumber: number = 0;
        restaurantId: number;
        currentDate: string;
        internalDate: Date;
        existsBasket: boolean;
        isBasketCanNOTBeReachedVisible: boolean;

        private observableCanceller: Rx.Disposable;

        constructor(
            private basketService: DtBasketService,
            private $state: ng.ui.IStateService) {
        }

        $onInit = () => {

            this.internalDate = this.currentDate ? new Date(this.currentDate) : undefined;

            this.observableCanceller = this.basketService.getBasketObserver(this.restaurantId, this.internalDate)
                .subscribe(basket => {
                    if (basket) {
                        this.articlesNumber = basket.itemsNumber;
                        this.internalDate = basket.date;
                    }

                    this.existsBasket = !!basket;
                });
        }

        dismissPopup = () => {
            this.isBasketCanNOTBeReachedVisible = false;
        }

        goToBasket = () => {
            if (this.articlesNumber > 0) {
                if (this.internalDate) {
                    this.$state.go('dt-basket', {
                        restaurantId: this.restaurantId,
                        currentDate: this.internalDate
                    });
                }
                else {
                    this.$state.go('dt-home', {
                        restaurantId: this.restaurantId
                    });
                }
            } else {
                this.isBasketCanNOTBeReachedVisible = true;
            }
        }

        $onDestroy = () => {
            if (this.observableCanceller) {
                this.observableCanceller.dispose();
            }
        }
    }
}
