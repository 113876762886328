module Core {
	declare var cordova: any; // Workaround typings
    class AnimationsController {
        isLoading: boolean;
        animation: IAnimation;
        error: boolean;
        hasMoreThanOneAnimation: boolean;
        restaurantId: number;
        restaurantAnimationId: number;
		displayAnimationButton: boolean;
		networkError: boolean;

		private previousNextRouteName: string;
		private fromRestaurant: boolean;
		private fromNotification: boolean;

        constructor(private animationService: AnimationService,
            private $stateParams: ng.ui.IStateParamsService,
            private configService: IS.Configuration.ConfigService,
            private $state: ng.ui.IStateService,
            private $rootScope: IRootScopeWithHeaderConfiguration,
            private $window: ng.IWindowService,
            private routeService: RouteService,
			private restaurantsService: RestaurantsService) {

            this.isLoading = true;

            this.restaurantId = this.getStateParamAsNumber('restaurantId');
            this.restaurantAnimationId = this.getStateParamAsNumber('restaurantAnimationId');

			this.checkRouteName();
			if (!this.fromRestaurant) {
				this.restaurantId = null;
			}
			
			// get animation to display
			var getAnimation: ng.IPromise<IAnimation> = !!this.restaurantAnimationId
				? this.animationService.get(this.restaurantAnimationId)
				: this.animationService.getFirst();
			getAnimation.then(animation => {
				this.setAnimation(animation);
			}).catch(error => {
				checkNetworkError(error, () => this.error = true, () => this.networkError = true);
			}).finally(() => {
				this.isLoading = false;
			});

			if (!this.fromNotification) {
				this.animationService.getsCount(this.restaurantId).then(count => {
					this.hasMoreThanOneAnimation = count > 1;
				});
			}
        }

        goToPrevious() {
			this.goToByDirection(false);
        }
        goToNext() {
			this.goToByDirection(true);
        }
		private goToByDirection(mustGoToNext: boolean) {
			var getByDirection: (restaurantAnimationId: number, restaurantId: number) => ng.IPromise<IAnimation>;
			if (mustGoToNext) {
				getByDirection = this.animationService.getNext;
			} else {
				getByDirection = this.animationService.getPrevious;
			}

			getByDirection(this.restaurantAnimationId, this.restaurantId).then(animation => {
				this.routeService.forceNavigationDirection(mustGoToNext ? NavigationDirection.left : NavigationDirection.right);
				this.$state.go(this.previousNextRouteName, {
					restaurantAnimationId: animation.restaurantAnimationId,
					restaurantId: this.restaurantId
				});
			});
		}

        goBack() {
			if (this.fromNotification) {
				this.$state.go('notifications');
				return;
			}

			this.restaurantsService.getRouteById(this.restaurantId).then(route => {
				this.$state.go(route, { id: this.restaurantId });
			});
        }
        goToRestaurant() {
            if (this.restaurantId != null) {
                this.restaurantsService.getRouteById(this.restaurantId).then(route => {
                    this.$state.go(route, { id: this.animation.restaurantId });
                });
            }
            else {
                this.restaurantsService.getRouteById(this.animation.restaurantId).then(route => {
                    this.$state.go(route, { id: this.animation.restaurantId });
                });
            }

        }

        goToLink() {
			if (!window.cordova) {
				this.$window.open(this.animation.lien, '_system');
			}
			else {
				cordova.InAppBrowser.open(this.animation.lien, '_system');
			}
		}

		private setAnimation(animation: IAnimation): void {
            this.animation = animation;
			this.restaurantAnimationId = animation.restaurantAnimationId;

			var today: Date = new Date();
			today.setHours(0, 0, 0, 0);
			this.displayAnimationButton = !!this.animation.libelleLien && (!this.animation.startAt || this.animation.startAt <= today) && (!this.animation.endAt || today <= this.animation.endAt);
		}

		private getStateParamAsNumber(paramName: string): number {
			var value = this.$stateParams[paramName];
			if (value) value = +value;
			return value;
		}
		private checkRouteName() {
			this.previousNextRouteName = this.$state.current.name;

			if (this.$state.current.name == 'animations') {
				this.previousNextRouteName = 'animationWithId';
			}

			switch (this.$state.current.name) {
				case 'animationsOfRestaurantWithBack':
					this.fromRestaurant = true;
					break;

				case 'animationOfNotificationWithBack':
					this.fromNotification = true;
					break;
			}
		}
    }

    angular.module('core').controller('animationsController', ['animationService', '$stateParams', 'configService', '$state', '$rootScope', '$window', 'routeService', 'restaurantsService', AnimationsController]);
}