module Core {
    class parameterSoldeDirective implements ng.IDirective {
        restrict: string = 'E';
        templateUrl = "./parameterSoldeDirectiveTemplate.html";
       

        link(scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery) {
        }

        static factory() {
            return () => {
                return new parameterSoldeDirective();
            };
        }
    }

    angular.module('core').directive('parameterSolde', [parameterSoldeDirective.factory()]);
}