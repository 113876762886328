module Core {
    export abstract class RegistrationController {
        constructor(protected $scope: ng.IScope,
            protected $state: ng.ui.IStateService,
            protected userService: UserService,
            protected siteService: SiteService,
            protected $stateParams: ng.ui.IStateParamsService,
            protected $rootScope: IRootScopeWithHeaderConfiguration,
            protected storageService: StorageService,
            protected $timeout: ng.ITimeoutService
        ) {
            var registrationInfo = storageService.getRegistration();

            if (registrationInfo) {
                this.registration = registrationInfo;
                storageService.clearRegistration();
            }
            else {
                this.registration.isUserRegistered = !!$stateParams['email'];
                this.registration.email = $stateParams['email'];
                this.registration.nom = $stateParams['nom'];
                this.registration.prenom = $stateParams['prenom'];
                this.registration.accepteCgu = false;
                this.registration.accepteRecevoirOffresElior = false;
                this.registration.accepteRecevoirOffresPartenaires = false;
                this.registration.hasNoBadge = false;
                this.registration.dateNaissance = null;
                this.registration.isAssociation = !!$stateParams['association'];
                if (!!$stateParams['site']) {
                    this.getSites($stateParams['site'], null, null, false)
                        .then((sites) => {
                            // Find a site with the exact same name (case insensitive)
                            this.registration.site = _.find(sites, (site: IRegistrationSite) => site.siteName.toLowerCase() === $stateParams['site'].toLowerCase());
                        })
                        .catch(error => {
                            // TODO: StatusCode=0
                        });
                }
            }

            this.isUserRegistered = this.registration.isUserRegistered;
            this.hideCivBirthdate = this.registration.isAssociation;

            // Don't load sites next to me if account association or browser is not able to do it
            if (this.registration.isAssociation == false && !!navigator.geolocation) {
                this.loadSitesNextToMe();
            }

            this.$scope.$watch(() => this.siteSearchPattern, (newValue: string) => {
                if (newValue) {
                    this.updateScrollIos();
                    this.getSites(newValue, null, null, true)
                        .then((sites) => {
                            this.registrationSites = sites;
                            this.updateScrollIos();
                        })
                        .catch(error => {
                            // TODO: StatusCode=0
                        });
                } else {
                    this.registrationSites = null;
                    this.updateScrollIos();
                }
            });

            window.addEventListener("message", (event) => {
                if (event.data['height'] === true) {
                    (<any>document.querySelector("iframe")).height = '600px';
                }
            });
        }

        hideCivBirthdate: boolean = false;
        siteSearchPattern: string;
        registrationSites: IRegistrationSite[];
        sitesNextToMe: IRegistrationSite[];
        loadingSitesNextToMe: boolean = false;
        registration: IRegistrationForm = <IRegistrationForm>{};
        isRegistering: boolean = false;
        isUserRegistered: boolean = false;
        isErrorOnLocation: boolean = false;
        isRegistrationFailed: boolean;
        errorMessage: string;
        errorMessageDetails: string[];
        updateScrollIosNum: number = 0;
        updateScrolllIosTimer: any;
        updateScrollIos = () => {
            if (this.updateScrolllIosTimer && this.updateScrolllIosTimer.$$state.status == 0) {
                this.$timeout.cancel(this.updateScrolllIosTimer);
            }
            this.updateScrolllIosTimer = this.$timeout(200).then(() => this.updateScrollIosNum++);
        };
        civilityOptions = [{ key: 0, value: "Registration_Civility_M" }, { key: 1, value: "Registration_Civility_Mme" }];
        tmpDateNaissance: string;
        searchSites() {
            this.updateScrollIos();
            this.getSites(this.siteSearchPattern, null, null, true)
                .then((sites) => {
                    this.registrationSites = sites;
                    this.updateScrollIos();
                })
                .catch(error => {
                    // TODO: StatusCode=0
                });
        }

        siteDisplayName = () => {
            if (!!this.registration.site)
                if (this.registration.isAssociation)
                    return `${this.registration.site.siteNameLong}`;
                else
                    return `${this.registration.site.siteNameLong} (${this.registration.site.codePostal})`;
            else
                return '';
        }

        selectSite(site: IRegistrationSite): void {
            this.registration.site = site;
            this.registrationSites = null;
            this.updateScrollIos();
        }

        resetSite(): void {
            this.registration.site = null;
            this.updateScrollIos();
        }

        goToCgu(): void {
            this.storageService.saveRegistration(this.registration);
            this.$state.go('cgu');
        }

        showBirthDate(): boolean {
            return this.registration.site && this.registration.site.birthDateRequested;
        }

        goToHelp(origin: string): void {
            if (origin === 'HelpButton')
                (<any>window).DataAnalyticsSender.sendCustomEvent('SignUp_BTN_HelpButton', '');
            else if (origin === 'HelpLink')
                (<any>window).DataAnalyticsSender.sendCustomEvent('SignUp_BTN_HelpLink', '');

            this.storageService.saveRegistration(this.registration);
            var registrationType = "";
            if (!this.registration.site) {
                registrationType = "unknown";
            }
            else if (this.registration.site.hasSoldeRegistration) {
                registrationType = "solde";
            }
            else if (this.registration.site.hasBadgeRegistration) {
                registrationType = "badge";
            }
            this.$state.go('help', { registrationType: registrationType });
        }

        cancel(): void {
            this.updateScrollIos();
            this.registration.matchingAccounts = null;
            this.registration.isReadOnly = false;
        }

        showPasswordRulesPopin: boolean = false;
        showPasswordRules(): void {
            this.updateScrollIos();
            this.showPasswordRulesPopin = true;
        }

        getSites(searchPattern: string, latitude: number, longitude: number, allowCancel: boolean, hasEntOnly: boolean = false): ng.IPromise<IRegistrationSite[]> {
            return this.siteService.getSites(searchPattern, latitude, longitude, allowCancel, hasEntOnly);
        }

        forceAccepteROE = (element: boolean) => {
            this.updateScrollIos();
            this.registration.accepteRecevoirOffresElior = !element;
        };
        forceAccepteROP = (element: boolean) => {
            this.updateScrollIos();
            this.registration.accepteRecevoirOffresPartenaires = !element;
        };
        forceAccepteCGU = (element: boolean) => {
            this.updateScrollIos();
            this.registration.accepteCgu = !element;
        };

        private loadSitesNextToMe(): void {
            this.updateScrollIos();
            if (!this.sitesNextToMe || this.sitesNextToMe.length === 0) {
                this.loadingSitesNextToMe = true;
                navigator.geolocation.getCurrentPosition((position) =>
                    this.getSites('', position.coords.latitude, position.coords.longitude, false)
                        .then((sites) => this.sitesNextToMe = sites)
                        .catch(error => {
                            // TODO: StatusCode=0
                            this.isErrorOnLocation = true;
                        })
                        .finally(() => {
                            this.loadingSitesNextToMe = false;
                            this.updateScrollIos();
                        }),
                    () => { this.isErrorOnLocation = true; this.loadingSitesNextToMe = false; this.updateScrollIos(); },
                    {
                        timeout: 5000
                    });
            }
        }
    }
}