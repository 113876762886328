module Core {
    class MenuController {
        hasCaisse: boolean;
        isRegisteredInCaisse: boolean;
        displayName: string;
        hasQrCode: boolean;
        menuClass: string;
        initials: string;
        unreadNotifications: number;
        showRestaurants: boolean = true;
        showAnimation: boolean;
        showEnquete: boolean;
        showNotifications: boolean;
        showCommandes: boolean;
        showRoomService: boolean;
        showPrestationSurMesure: boolean;
        showAffluence: boolean;
        showPresenceConvive: boolean = false;
        restaurantCount: number;
        commandeCount: number;
        selectedMenuItem: MenuItem;
        MenuItem = MenuItem;
        marqueImageUrl: string;
        hasMarqueImage: boolean;
        singleClickAndCollectId: number;
        singleRoomServiceId: number;
        multipleRoomService: boolean = false;
        multipleprestaSurMesure: boolean = false;
        singleprestaSurMesureId: number;
        forceMarqueLogoRefresh: boolean;
        currentSite: ISiteInformation;
        showChangeSiteConfirmation: boolean = false;

        constructor(
            private $rootScope: ng.IRootScopeService,
            private userService: UserService,
            private animationService: AnimationService,
            private enqueteService: EnqueteService,
            private restaurantService: RestaurantsService,
            configService: IS.Configuration.ConfigService,
            $scope: ng.IScope,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService
        ) {
            var refreshUserData = (forceUser: boolean = false, forceRestaurants: boolean = false) => {
                if (userService.isAuthenticated()) {
                    userService
                        .getUserInformations(forceUser)
                        .then((userInformations) => {
                            this.displayName = userInformations.displayName;
                            this.currentSite = userInformations.currentSite;
                            this.hasQrCode = userInformations.currentSite.hasQrCode;
                            this.hasCaisse = userInformations.currentSite.hasCaisse;
                            this.isRegisteredInCaisse =
                                userInformations.currentSite.isRegisteredInCaisse;
                            this.forceMarqueLogoRefresh = true;

                            // Ma presence
                            this.showPresenceConvive =
                                userInformations.currentSite
                                    .askPresenceOnSiteHeureArrivee ||
                                userInformations.currentSite.askPresenceForLunch ||
                                userInformations.currentSite.askPresenceOnSite;

                            if (userInformations.currentSite.marqueImageId) {
                                this.hasMarqueImage = true;
                                this.marqueImageUrl = `${configService.getConfigValue(
                                    "url"
                                )}/documents/${userInformations.currentSite.marqueImageId}`;
                            }

                            this.initials = userInformations.initials;

                            if (!userInformations.currentSite.canHideRestaurants) {
                                this.restaurantService.resetHiddenRestaurants();
                            }

                            if (userInformations.currentSite.restaurantsDisplayed) {
                                this.showRestaurants = true;
                                restaurantService
                                    .gets(forceRestaurants)
                                    .then((restaurants) => {
                                        this.restaurantCount = restaurants.length;

                                        this.refreshAnimations(forceRestaurants);
                                        this.refreshCampagnes(forceRestaurants);

                                        // Commandes
                                        var restaurantsWithCommandes = restaurants.filter((r) => r.hasCommandes);
                                        this.showCommandes = restaurantsWithCommandes.length != 0;

                                        // RoomService
                                        if (userInformations.role.indexOf("CONVIVEPRO") != -1) {
                                            if (
                                                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                                                    navigator.userAgent
                                                ) ||
                                                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                                                    navigator.userAgent.substr(0, 4)
                                                )
                                            ) {
                                                this.showRoomService = false;
                                            } else {
                                                var roomServices = this.filterRestaurantsByType(
                                                    restaurants,
                                                    (r) => r.isRoomService
                                                );
                                                this.showRoomService = roomServices.length != 0;
                                                if (roomServices.length > 1) {
                                                    this.multipleRoomService = true;
                                                }
                                                if (this.showRoomService) {
                                                    this.singleRoomServiceId = roomServices[0].id;
                                                }

                                                var prestaSurMesures = this.filterRestaurantsByType(
                                                    restaurants,
                                                    (r) => r.isPrestationSurMesure
                                                );
                                                this.showPrestationSurMesure =
                                                    prestaSurMesures.length != 0;
                                                if (prestaSurMesures.length > 1) {
                                                    this.multipleprestaSurMesure = true;
                                                }
                                                if (this.showPrestationSurMesure) {
                                                    this.singleprestaSurMesureId = prestaSurMesures[0].id;
                                                }
                                            }
                                        }

                                        // Fluidité (affluence)
                                        this.showAffluence =
                                            this.getFirstRestaurantWithFluidite(restaurants) != null;


                                    })
                                    .catch((error) => {
                                        // TODO: StatusCode=0
                                    });
                            } else {
                                this.manageTimechefSansRestaurants();
                            }
                        })
                        .catch((error) => {
                            // TODO: StatusCode=0
                        });
                }
            };

            refreshUserData();
            this.userService.refreshSolde();

            $scope.$on("connectionStateChanged", () => {
                refreshUserData();
                this.userService.refreshSolde();
            });
            $scope.$on("soldeChanged", () => {
                this.userService.refreshSolde();
            });
            $scope.$on("onResumeCordova", () => {
                refreshUserData(true, true);
                this.userService.refreshSolde();
            });

            this.refreshNotificationparameters();

            $scope.$on("notificationsUpdated", () => {
                this.refreshNotificationparameters();
            });

            $scope.$on(
                "$stateChangeSuccess",
                (
                    event: any,
                    toState: IStateWithConfiguration,
                    toParams: any,
                    fromState: IStateWithConfiguration,
                    fromParams: any,
                    options: any
                ) => {
                    if (toState.menuItem && typeof toState.menuItem === "function") {
                        this.selectedMenuItem = toState.menuItem(toParams.activeTab);
                    } else {
                        this.selectedMenuItem = toState.menuItem;
                    }

                    //on récupère le menuClass depuis la config 'headerConfiguration.menuCssClass' ou à défaut celle du parent
                    this.menuClass = toState.headerConfiguration.menuCssClass;
                    if (!this.menuClass) {
                        var parentState =
                            toState.parent && <any>this.$state.get(<string>toState.parent);
                        this.menuClass =
                            (parentState.headerConfiguration &&
                                parentState.headerConfiguration.menuCssClass) ||
                            toState.parent + "-menu";
                    }
                }
            );

            $scope.$watch(
                () => {
                    return $state.params["activeTab"];
                },
                (newVal) => {
                    if (newVal) {
                        if (newVal == "recharger") {
                            this.selectedMenuItem = MenuItem.Recharger;
                        } else {
                            this.selectedMenuItem = MenuItem.MesTickets;
                        }
                    }
                }
            );

            $scope.$watch(
                () => this.currentSite,
                (selectedSite: ISiteInformation) => {
                    this.showChangeSiteConfirmation =
                        selectedSite &&
                        selectedSite.name !==
                        this.userService.userInformations.currentSite.name;
                }
            );

            $rootScope.$on("refreshRestaurants", () => {
                refreshUserData(true, true);
            });

            $rootScope.$on("refreshPresenceConvive", () => {
                refreshUserData();
            });

        }

        userSites(): ISiteInformation[] {
            return this.userService.userInformations.sites;
        }

        setCurrentSite(): void {
            if (this.currentSite) {
                this.userService.setCurrentSite(this.currentSite.name);
            }
        }

        cancelChangeSite(): void {
            this.currentSite = this.userService.userInformations.currentSite;
            this.showChangeSiteConfirmation = false;
        }

        isAuthenticated(): boolean {
            return this.userService.isAuthenticated();
        }

        goToCompteTickets(): void {
            this.$state.go("tickets", {}, { location: "replace" });
        }

        goToMaPresence(): void {
            this.$state.go("presenceConvive", {}, { location: "replace" });
        }

        goToCompteRecharger(): void {
            this.$state.go("credit", {}, { location: "replace" });
        }
        goToFluidite(): void {
            var self = this;
            this.restaurantService.gets().then((restaurants) => {
                var restaurantWithFluidite = self.getFirstRestaurantWithFluidite(
                    restaurants
                );
                if (restaurantWithFluidite) {
                    var restaurantId = restaurantWithFluidite.id;
                    self.$state.go("fluidite", { restaurantId: restaurantId });
                }
            });
        }
        goToPsm(): void {
            if (!this.multipleprestaSurMesure && this.singleprestaSurMesureId) {
                this.$state.go("psm-home", {
                    restaurantId: this.singleprestaSurMesureId
                });
            } else {
                this.$state.go("restaurants", {
                    restaurantType: Core.RestaurantType["Event / Cocktail"]
                });
            }
        }
        goToRoomService(): void {
            if (!this.multipleRoomService && this.singleRoomServiceId) {
                this.$state.go("roomService.articles", {
                    id: this.singleRoomServiceId
                });
            } else {
                this.$state.go("restaurants", {
                    restaurantType: Core.RestaurantType["Room Service"]
                });
            }
        }

        goToHistorique(): void {
            this.$state.go("historique");
        }

        goToHome(): void {
            this.$state.go("restaurants", { restaurantType: null });
        }

        private refreshAnimations(force: boolean): void {
            this.animationService.gets(force).then(
                (animations) => {
                    this.showAnimation = animations.length != 0;
                },
                (error) => {
                    // TODO: StatusCode=0
                }
            );
        }
        private refreshCampagnes(force: boolean): void {
            this.enqueteService.gets(force).then(
                (campagnes) => {
                    this.showEnquete = campagnes.length != 0;
                },
                (error) => {
                    // TODO: StatusCode=0
                }
            );
        }

        private refreshNotificationparameters(): void {
            this.unreadNotifications = _.size(
                _.filter(this.userService.notifications, (n) => !n.lue)
            );
            this.showNotifications =
                !!this.userService.notifications &&
                this.userService.notifications.length > 0;
        }

        private filterRestaurantsByType(
            restaurants: IRestaurant[],
            filter: (
                value: IRestaurant,
                index: number,
                array: IRestaurant[]
            ) => boolean
        ): IRestaurant[] {
            var filtered = restaurants.filter(filter);
            return filtered;
        }

        private getFirstRestaurantWithFluidite(
            restaurants: IRestaurant[]
        ): IRestaurant {
            var restaurant: IRestaurant = null;

            var restaurantsWithFluidite = restaurants.filter((r) => r.hasFluidite);
            if (restaurantsWithFluidite && restaurantsWithFluidite.length > 0) {
                restaurant = restaurantsWithFluidite.shift();
            }

            return restaurant;
        }

        private refresh() {
            this.$rootScope.$broadcast("onResumeCordova");
        }

        private manageTimechefSansRestaurants(): void {
            this.showRestaurants = false;
            this.showCommandes = false;
            this.showAffluence = false;
            this.showEnquete = false;
            this.showAnimation = false;
        }
    }

    angular
        .module("core")
        .controller("menuController", [
            "$rootScope",
            "userService",
            "animationService",
            "enqueteService",
            "restaurantsService",
            "configService",
            "$scope",
            "$state",
            "$timeout",
            MenuController
        ]);
}
