module IS.Localization {
    export class LocalizeService {
        private currentLanguage = '';
        private resources: any = null;

        constructor(private $rootScope: ng.IRootScopeService,
            private $http: ng.IHttpService,
            private $window: ng.IWindowService,
            private resourceBaseUrl: string,
            private resourceFilePattern: string,
            private resourceDefaultFile: string,
            private resourceKeyNotFound: string,
            private cultures: ICulture[],
            private defaultCulture: string) {

            this.currentLanguage = defaultCulture;

            if (!this.currentLanguage) {
                this.setLanguage((<any>$window.navigator).userLanguage || $window.navigator.language);
            }

            moment.locale(this.currentLanguage);
        }

        getAvailablesCultures(): ICulture[] {
            return this.cultures;
        }

        getCurrentLanguage(): string {
            return this.currentLanguage;
        }

        getCurrentCulture(): ICulture {
            return this.getCulture(this.currentLanguage);
        }

        getCulture(code: string): ICulture {
            return _.find(this.cultures, culture => culture.code === code);
        }

        setLanguage(code: string) {
            if (!code) {
                return;
            }

            if (code.indexOf('-') !== -1) {
                code = code.substring(0, 2);
            }

            if (_.some(this.cultures, culture => culture.code === code) && this.currentLanguage !== code) {
                this.currentLanguage = code;
                moment.locale(this.currentLanguage);
                this.initializeLocalizedResources();
            }
        }

        private buildUrl(): string {
            return this.resourceBaseUrl + (this.currentLanguage ? this.resourceFilePattern.replace('{lang}', this.currentLanguage) : this.resourceDefaultFile);
        }

        private loadedResourcesSuccessCallback(data: any) {
            this.resources = data;
            this.$rootScope.$broadcast('resourcesUpdated');
        }

        private initializeDefaultResources() {
            this.$http.get(this.resourceBaseUrl + this.resourceDefaultFile, { cache: false })
                .success((data) => {
                    this.loadedResourcesSuccessCallback(data);
                })
                .error(error => {
                    console.log("unable to load resources");
                });
        }

        initializeLocalizedResources() {
            const url = this.buildUrl();

            this.$http.get(url, { cache: false })
                .success((data) => {
                    this.loadedResourcesSuccessCallback(data);
                })
                .error(error => {
                    this.initializeDefaultResources();
                });
        }

        getLocalizedResource(key: string): string {
            if (!this.resources) {
                return '';
            }

            const resource = this.resources[key];
            return resource || this.resourceKeyNotFound.replace("{key}", key);
        }
    }
}