module Core {
    @Component('core', 'restaurantFerme', {
        controllerAs: 'vm',
        bindings: {
            restaurant: '<',
        },
        templateUrl: './restaurantFerme.html'
    })
    class RestaurantFermeComponent {
        static $inject: string[] = ['restaurantsService'];

        constructor(private restaurantsService: Core.RestaurantsService) {
        }

        restaurant: Core.IRestaurant;

        isClosed: boolean;
        openDate: Date;
        openTime: string;
        estLeMatin: boolean;
        estLeSoir: boolean;

        $onInit = () => {
            if (!!this.restaurant && !!this.restaurant.jourOuverture && this.restaurant.jourOuverture !== 0) {
                this.isClosed = !this.getIsOpen(this.restaurant);

                this.openTime = this.getOpenHour(this.restaurant);
                this.openDate = this.getOpenDate(this.restaurant, this.openTime);

                this.gererMatinOuSoir(this.openDate);
            }
            else {
                this.isClosed = false;
            }
        }

        private gererMatinOuSoir(openDate: Date) {
            let now: Date = new Date();
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let openDateDay = new Date(openDate.getTime());
            openDateDay.setHours(0, 0, 0, 0);

            let diffEnJours = (moment.duration(moment(openDateDay).diff(today))).asDays();
            let estMemeJour = diffEnJours === 0;
            let estLendemain = diffEnJours === 1;

            if (estMemeJour && now < this.openDate) {
                this.estLeMatin = true;
            } else {
                this.estLeSoir = estMemeJour || estLendemain;
            }
        }

        private getIsOpen(restaurant: Core.IRestaurant): boolean {
            // dans un premier temps, on ne vérifie que les horaires d'ouverture
            let open: boolean = this.restaurantsService.getIsOpenSmart(restaurant);

            let today: Date = new Date();
            today.setHours(0, 0, 0, 0);

            // si on est dans les horaires d'ouverture, on vérifie les jours d'ouvertures habituelles
            open = open && !!(this.restaurantsService.getJourOuvertureFlagByJSDate(today) & restaurant.jourOuverture);

            // si on est dans un jour d'ouverture habituelle, on vérifie les jours de fermetures exceptionnelles
            open = open && !this.restaurantsService.estUnJourDeFermetureExceptionnelle(restaurant, today);

            return open;
        }

        private getOpenHour(restaurant: Core.IRestaurant): string {
            if (restaurant.isClickAndCollect) {
                return restaurant.orderOpenAt;
            }
            return restaurant.openAt;
        }

        private getOpenDate(restaurant: Core.IRestaurant, openTime: string): Date {
            let heureOuvertureDuJour: Date = this.restaurantsService.stringHourToDate(openTime, null, false);

            // si le restaurant n'est pas encore ouvert aujourd'hui
            // on retourne son heure d'ouverture du jour
            let today: Date = new Date();
            if (today <= heureOuvertureDuJour && this.restaurantsService.getIsAnOpenningDay(restaurant, today)) {
                return heureOuvertureDuJour;
            }

            // sinon, on se place directement 'demain' et on cherche le prochain jour d'ouverture
            today.setHours(0, 0, 0, 0);
            let openDate: Date = this.getTomorrow(today);

            let found: boolean;
            do {
                found = true;

                // on cherche le prochain jour d'ouverture
                while ((this.restaurantsService.getJourOuvertureFlagByJSDate(openDate) & restaurant.jourOuverture) === 0) {
                    openDate = this.getTomorrow(openDate);
                }

                // on vérifie qu'il ne tombe pas un jour de fermeture exceptionnelle
                let jourFermeture: IDateInterval = this.restaurantsService.estUnJourDeFermetureExceptionnelle(restaurant, openDate);
                if (jourFermeture) {
                    // pas de chance, on se positionne le lendemain de la fermeture exceptionnelle
                    openDate = this.getTomorrow(jourFermeture.dateFin);
                    found = false;
                }
            }
            while (!found);

            return openDate;
        }

        private getTomorrow(date: Date): Date {
            let tomorrow: Date = new Date(date.getTime());
            tomorrow.setDate(date.getDate() + 1);
            return tomorrow;
        }
    }
}