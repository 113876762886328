module Core {

    interface IBrandSpecificLogoScope extends ng.IScope {
        isTcpro: boolean;
        forceCheckTheme: boolean;
    };

    class BrandSpecificLogo implements ng.IDirective {

        constructor(private configService: IS.Configuration.ConfigService,
            private userService: Core.UserService) {
        }

        scope = {
            isTcpro: "=",
            forceCheckTheme: "="
        };
        replace: boolean = true;
        restrict: string = 'A';

        isThemeBl: boolean = false;
        logoIds: ILogoIdsModel = null;

        checkTheme = (scope: IBrandSpecificLogoScope, element: ng.IAugmentedJQuery) => {
            this.isThemeBl = this.userService.getCurrentTheme().id === "dark";
            this.setLogo(scope, element);
        };

        link = (scope: IBrandSpecificLogoScope, element: ng.IAugmentedJQuery): void => {
            if (scope.forceCheckTheme) {
                scope.$on("changeTheme", () => {
                    this.checkTheme(scope, element);
                });
            }
            this.checkTheme(scope, element);
        };

        setLogo(scope: IBrandSpecificLogoScope, element: ng.IAugmentedJQuery) {
            var currentLogoId = null;
            var isDesktop = (window.innerWidth >= 995 && window.outerWidth >= 995);

            this.userService.getUserInformations().then((result) => {
                this.logoIds = {
                    marqueLogoTimechefBlId: result.currentSite.marqueLogoTimechefBlId,
                    marqueLogoTimechefId: result.currentSite.marqueLogoTimechefId,
                    marqueLogoTimechefProBlId: result.currentSite.marqueLogoTimechefProBlId,
                    marqueLogoTimechefProId: result.currentSite.marqueLogoTimechefProId
                }

                if (scope.isTcpro) {
                    if (this.isThemeBl) {
                        currentLogoId = this.logoIds.marqueLogoTimechefProBlId || null;
                    }
                    else {
                        currentLogoId = this.logoIds.marqueLogoTimechefProId || null;
                    }
                } else if (this.isThemeBl || isDesktop) {
                    currentLogoId = this.logoIds.marqueLogoTimechefBlId || null;
                } else {
                    currentLogoId = this.logoIds.marqueLogoTimechefId || null;
                }

                if (currentLogoId !== null) {
                    var logo = `${this.configService.getConfigValue("url")}/documents/${currentLogoId}`;
                    element.css("background-image", `url(${logo})`);
                    element.css("background-size", "contain");
                    element.css("background-repeat", "no-repeat");
                    element.css("background-position", "50%");
                }
            });
        }

        static factory() {
            var directive = (configService, userService) => {
                return new BrandSpecificLogo(configService, userService);
            };
            return directive;
        }
    }

    angular.module('core').directive('brandSpecificLogo', ['configService', 'userService', BrandSpecificLogo.factory()]);
}