module Core {
  export class AllergenesService {
    localizeService: IS.Localization.LocalizeService;
    allergenes: IAllergene[];
    private mesAllergenes: string[];

    constructor(
      private $rootScope: ng.IRootScopeService,
      private storageService: StorageService,
      private userService: UserService,
      localizeService: IS.Localization.LocalizeService
    ) {
      this.userService.getUserInformations().then((userInformations) => {
        this.mesAllergenes = this.storageService.getAllergenes(
          userInformations.email
        );
        this.localizeService = localizeService;
      });

      this.allergenes = [
        {
          key: "A",
          ressourceNameKey: "Allergene_Milk",
          asset: "./styles/assets/allergene/lait.svg",
        },
        {
          key: "F",
          ressourceNameKey: "Allergene_Corn",
          asset: "./styles/assets/allergene/ble.svg",
        },
        {
          key: "C",
          ressourceNameKey: "Allergene_Egg",
          asset: "./styles/assets/allergene/oeuf.svg",
        },
        {
          key: "D",
          ressourceNameKey: "Allergene_Poisson",
          asset: "./styles/assets/allergene/poisson.svg",
        },
        {
          key: "I",
          ressourceNameKey: "Allergene_Sulfites",
          asset: "./styles/assets/allergene/sulfites.svg",
        },
        {
          key: "G",
          ressourceNameKey: "Allergene_Nuts",
          asset: "./styles/assets/allergene/fruits_coques.svg",
        },
        {
          key: "E",
          ressourceNameKey: "Allergene_Crustaceans",
          asset: "./styles/assets/allergene/crustaces.svg",
        },
        {
          key: "O",
          ressourceNameKey: "Allergene_Molluscs",
          asset: "./styles/assets/allergene/mollusques.svg",
        },
        {
          key: "L",
          ressourceNameKey: "Allergene_Celery",
          asset: "./styles/assets/allergene/celeri.svg",
        },
        {
          key: "B",
          ressourceNameKey: "Allergene_Soy",
          asset: "./styles/assets/allergene/soja.svg",
        },
        {
          key: "K",
          ressourceNameKey: "Allergene_Peanuts",
          asset: "./styles/assets/allergene/arachide.svg",
        },
        {
          key: "M",
          ressourceNameKey: "Allergene_Mustard",
          asset: "./styles/assets/allergene/moutarde.svg",
        },
        {
          key: "H",
          ressourceNameKey: "Allergene_Sesame",
          asset: "./styles/assets/allergene/sesame.svg",
        },
        {
          key: "N",
          ressourceNameKey: "Allergene_Lupine",
          asset: "./styles/assets/allergene/lupin.svg",
        },
      ];

      this.$rootScope.$on("connectionStateChanged", () => {
        if (userService.isAuthenticated()) {
          this.mesAllergenes = this.storageService.getAllergenes(
            this.userService.userInformations.email
          );
        } else {
          this.mesAllergenes = [];
        }
      });
    }

    isAllergic(allergene: IAllergene): boolean {
      return _.includes(this.mesAllergenes, allergene.key);
    }

    isAllergicToPlat(platKeys: string[]): boolean {
      return _.intersection(platKeys, this.mesAllergenes).length > 0;
    }

    subListForPlat(platKeys: string[]): IAllergene[] {
      return _.filter(this.allergenes, (allergene: any) =>
        _.includes(platKeys, allergene.key)
      );
    }

    toggleAllergene(allergene: IAllergene) {
      if (this.isAllergic(allergene)) {
        _.remove(
          this.mesAllergenes,
          (allergeneKey: any) => allergeneKey == allergene.key
        );
      } else {
        this.mesAllergenes.push(allergene.key);
      }
      (<any>window).DataAnalyticsSender.sendCustomEvent(
        "Settings_Allergens_BTN_Toggle",
        ""
      );

      this.userService.getUserInformations().then((userInformations) => {
        this.storageService.saveAllergenes(
          userInformations.email,
          this.mesAllergenes
        );
      });
    }

    getAllergeneByKey(key: string) {
      return _.find(this.allergenes, (allergene: any) => allergene.key === key);
    }
  }

  angular
    .module("core")
    .service("allergenesService", [
      "$rootScope",
      "storageService",
      "userService",
      "localizeService",
      AllergenesService,
    ]);
}
