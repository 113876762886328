module Easypass {
    @Component('easypass', 'tcCommandes', {
        controllerAs: 'vm',
        bindings: {},
        templateUrl: './commandes.html'
    })
    class CommandesComponent {
        static $inject: string[] = ['commandeService', '$state', '$timeout', '$scope', 'restaurantsService', '$sce'];

        commandes: Core.ICommandeListItem[];
        isLoading: boolean = true;
        isOnError: boolean = false;
        clickAndCollect: boolean = false;
        bonplan: boolean = false;
        restaurantIdBonplan: number = null;
        restaurantIdCc: number = null;
        bonplanType: Core.RestaurantType = Core.RestaurantType["bonplan click&collect"];
        clickAndServe: boolean = false;
        commandeListUrl: string = null;

        constructor(
            private commandeService: CommandeService,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $rootScope: ng.IRootScopeService,
            private restaurantsService: RestaurantsService,
            private $sce: ng.ISCEService) {
            this.isLoading = true;
            this.isOnError = false;
        }

        $onInit = () => {
            this.isLoading = true;
            this.restaurantsService.gets().then(restaurants => {
                // Restaurant Externe
                var restaurantExterneWithCommandes = restaurants.filter(r => r.isRestaurantExterne && r.hasCommandes);
                if (restaurantExterneWithCommandes && restaurantExterneWithCommandes.length > 0) {
                    var restaurantWithCommande = restaurantExterneWithCommandes.shift()
                    this.restaurantsService.getCommandesRedirectionInformation(restaurantWithCommande.id).success(redirectionInfo => {
                            if (redirectionInfo.isEmbedded) {
                                this.commandeListUrl = this.$sce.trustAsResourceUrl(redirectionInfo.url);
                            } else {
                                window.open(redirectionInfo.url, '_self');
                            }
                        }).catch(error => {
                            console.log(error);
                        }).finally(() => {
                            this.isLoading = false;
                        });;
                }
                else {
                    // Commande Innovorder --> DEPRECATED
                    var hasClickAndCollectInnovorder = (restaurants.filter(r => r.isClickAndCollectInnovorder).length > 0);
                    if (hasClickAndCollectInnovorder) {
                        this.commandeService.getInnovorderCommandeListUrl().success(innovorder => {
                            this.commandeListUrl = this.$sce.trustAsResourceUrl(innovorder.url);
                        }).catch(error => {
                            checkNetworkError(error, () => { /* do nothing */ }, () => this.isOnError = true);
                        }).finally(() => {
                            this.isLoading = false;
                        });
                    }
                    // Commandes Timechef
                    else {
                        this.commandeService.getMesCommandes(true).then((commandes) => {
                            this.commandes = commandes;
                            var dtaRestaurants: Core.IRestaurant[];
                            dtaRestaurants = restaurants.filter(r => r.isDinnerTakeaway == true);
                            if (dtaRestaurants.length == 1) {
                                this.bonplan = true;
                                this.restaurantIdBonplan = dtaRestaurants.pop().id;
                            } else if (dtaRestaurants.length > 1) {
                                this.bonplan = true;
                                this.restaurantIdBonplan = null;
                            }

                            var ccRestaurants: Core.IRestaurant[];
                            ccRestaurants = restaurants.filter(r => r.isClickAndCollect == true);

                            if (ccRestaurants.filter(x => x.isClickAndServe).length > 0) {
                                this.clickAndServe = true;
                            }

                            if (ccRestaurants.length == 1) {
                                this.clickAndCollect = true;
                                this.restaurantIdCc = ccRestaurants.pop().id;
                            } else if (ccRestaurants.length > 1) {
                                this.clickAndCollect = true;
                                this.restaurantIdCc = null;
                            }

                        }
                            , error => {
                                this.isOnError = true;
                            }).finally(() => {
                                this.isLoading = false;
                            });
                    }
                }
            }).catch(error => {
                this.isLoading = false;
                this.isOnError = true;
            });
        }

        goToCommandeDetails = (index: number, event: Event) => {
            this.$state.go("tc-commande-detail", { index: index });
        }
    }
}