module Easypass {
    class ConfirmEmailController {

        email: string;
        callFrom: string;
        isEmailConfirmed: boolean = false;
        isLoading: boolean = false;
        isOnError: boolean = false;

        constructor(
            private authenticationService: Core.AuthenticationService,
            private $stateParams: ng.ui.IStateParamsService
        ) {
            (<any>window).DataAnalyticsSender.sendCustomEvent('ConfirmEmail_Screen', '');

            this.email = $stateParams['email'];
            this.callFrom = $stateParams['callFrom'];

            if (!!$stateParams['userid']) {
                if (!!$stateParams['token']) {
                    this.confirmEmail($stateParams['userid'], $stateParams['token']);
                }
            }
        }

        confirmEmail(userid: string, token: string): void {
            this.isLoading = true;
            this.isOnError = false;

            this.authenticationService.confirmEmail(<Core.IConfirmEmail>{ userid: userid, token: token })
                .then(() => {
                    this.isEmailConfirmed = true;
                }, error => {
                    this.isOnError = true;
                }).finally(() => {
                    this.isLoading = false;
                });
        }

        sendConfirmEmail(): void {
            this.isLoading = true;
            this.isOnError = false;
            (<any>window).DataAnalyticsSender.sendCustomEvent('ConfirmEmail_BTN_Send', '');

            this.authenticationService.sendConfirmEmail(<Core.ISendConfirmEmail>{ email: this.email })
                .then(() => {
                    // Confirm email send with success
                }, error => {
                    this.isOnError = true;
                }).finally(() => {
                    this.isLoading = false;
                });
        }
    }

    angular.module('easypass').controller('confirmEmailController', ['authenticationService', '$stateParams', ConfirmEmailController]);
}
