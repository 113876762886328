module Easypass {
    class RestaurantExterneController {
        restaurant: Core.IRestaurant;
        isLoading: boolean = true;
        networkError: boolean;
        restaurantUrl: string;

        constructor(private $rootScope: Core.IRootScopeWithHeaderConfiguration,
            private restaurantsService: RestaurantsService,
            private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private $sce: ng.ISCEService
        ) {
            var url = $stateParams['restaurantUrl'];
            this.restaurantUrl = $sce.trustAsResourceUrl(url);
        }
    }

    angular.module('easypass').controller('restaurantExterneController',
        ['$rootScope', 'restaurantsService', '$stateParams', '$state', '$sce', RestaurantExterneController]);
}
