module Core {
    @Component('core', 'toggleButton', {
        controllerAs: 'vm',
        bindings: {
            value: '=',
            disabled: '<?'
        },
        template: `
            <div class="toggle-button" data-ng-click="vm.toggle()" data-ng-class="{ active : vm.value }" data-ng-disabled='vm.disabled'>
            </div>`
    })
    class ToggleButtonComponent {
        value: boolean;
        disabled: boolean;

        toggle = () => {
            if (!this.disabled) {
                this.value = !this.value;
            }
        }
    }
}