module Easypass {
    class LongTouchDirective implements ng.IDirective {
        restrict: string = 'AE';

        longTouchTimer: any;

        constructor(private $timeout: ng.ITimeoutService) { }

        link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attr: ng.IAttributes): void => {
            var func = attr['longTouch'];
            var eventSent = false;

            element.on('touchstart mousedown', event => {
                eventSent = false;

                let cancelEvent = (endEvent: JQueryEventObject) => {
                    endEvent.preventDefault();
                    endEvent.stopImmediatePropagation();
                    endEvent.stopPropagation();
                    element.unbind('touchend mouseup', cancelEvent);

                };

                if (this.longTouchTimer && this.longTouchTimer.$$state.status == 0) return;
                this.longTouchTimer = this.$timeout(() => {
                    eventSent = true;
                    this.$timeout(500).then(() => eventSent = false);
                    element.on('touchend mouseup', cancelEvent);
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    event.stopPropagation();
                    scope.$applyAsync(func)
                }, 500);
            });
            element.bind('click', (clickEvent) => {
                if (eventSent) {
                    clickEvent.preventDefault();
                    clickEvent.stopPropagation();
                    clickEvent.stopImmediatePropagation();
                }
            });
            element.on('touchcancel touchend touchmove mouseup mousemove mousecancel', event => {
                if (this.longTouchTimer) {
                    this.$timeout.cancel(this.longTouchTimer);
                }
            });
        }

        static factory() {
            return ($timeout: ng.ITimeoutService) => {
                return new LongTouchDirective($timeout);
            };
        }
    }

    angular.module('easypass').directive('longTouch', ['$timeout', LongTouchDirective.factory()]);
}