module Easypass {

    interface DropdownContainerScope extends ng.IScope {
        libelle: string,
        maxItem: number,
        minItem: number,
        showProgress: boolean,
        items: IProductGroupItems[],
        toggle: () => void;
        active: boolean,
        getSelectedCount: () => number,
        getProgressBar: () => number;
    }

    class DropdownContainerDirective implements ng.IDirective {
        restrict: string = 'AE';
        templateUrl = "./dropdownContainerTemplate.html";

        scope = {
            libelle: '=',
            maxItem: '=',
            minItem: '=',
            showProgress: '=',
            items: '='
        };

        public selectedCount: number = 1;

        link($scope: DropdownContainerScope, elem: ng.IAugmentedJQuery) {
            $scope.toggle = () => {
                if (!$scope.active) {
                    $scope.active = true;
                } else {
                    $scope.active = false;
                }
            }
            $scope.getSelectedCount = (): number => _.filter($scope.items, { 'isChecked': true }).length;
            $scope.getProgressBar = (): number => !$scope.maxItem ? 0 : $scope.getSelectedCount() / $scope.maxItem;
        }

        static factory() {
            return () => {
                return new DropdownContainerDirective();
            };
        }
    }

    angular.module('easypass').directive('dropdownContainer', [DropdownContainerDirective.factory()]);
}