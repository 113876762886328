module Easypass.DinerTakeAway {
    @Component('easypass', 'dtPortion', {
        controllerAs: 'vm',
        bindings: {
            portion: '<',
            disableQuantity: '<',
            isLocker: '<',
            onQuantityChanged: '&quantityChanged',
            quantity: '<'
        },
        templateUrl: './dtPortion.html'
    })
    class PortionComponent {
        portion: Core.IPortion;
        currentQuantity: number;
        disableQuantity: boolean;
        isLocker: boolean;
        portionSolo: Core.PortionType = Core.PortionType.Simple;
        portionFamille: Core.PortionType = Core.PortionType.Famille;
        portionDuo: Core.PortionType = Core.PortionType.Duo;

        set quantity(quantity: number) {
            this.currentQuantity = quantity | 0;
        }

        onQuantityChanged: (data: { quantity: number }) => void;
        quantityChanged = (value: number) => {
            this.currentQuantity = value;
            this.onQuantityChanged({ quantity: value });
        };
    }
}