module Core {
    interface SurveyDirectionScope extends ng.IScope {
        currentStep: number;
        goBack: () => void;
        goNext: () => void;
    }

    class SurveyDirective implements ng.IDirective {
        restrict = 'EA';

        scope = {
            currentStep: '=',
            goBack: '@',
            goNext: '@'
        }
        transclude = true;
        template = '<div data-ng-transclude></div>';

        link = (scope: SurveyDirectionScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes): void => {


            setTimeout(() => {

                scope.$watch('currentStep', (newValue: number) => {
                    recalculateStepStates(newValue);
                });

            }, 0);

            scope.goBack = () => scope.currentStep--;
            

            scope.goNext = () => scope.currentStep++;

            function recalculateStepStates(currentStep: number = -1) {
                if (currentStep == -1)
                    return;
                setTimeout(() => {
                    var stepElements = element[0].querySelectorAll('.survey-item');
                    var totalSteps = stepElements.length;
                    stepElements[currentStep].classList.add("current");
                    stepElements[currentStep].classList.remove("previous");
                    stepElements[currentStep].classList.remove("next");
                    stepElements[currentStep].classList.remove("futur");

                    if (stepElements[currentStep - 1]) {
                        stepElements[currentStep - 1].classList.add("previous");
                        stepElements[currentStep - 1].classList.remove("current");

                        if (stepElements[currentStep - 2]) {
                            stepElements[currentStep - 2].classList.remove("current");
                            stepElements[currentStep - 2].classList.remove("previous");
                        }
                    }

                    if (stepElements[currentStep + 1]) {
                        stepElements[currentStep + 1].classList.add("next");
                        stepElements[currentStep + 1].classList.remove("current");

                        if (stepElements[currentStep + 2]) {
                            stepElements[currentStep + 2].classList.remove("next");
                        }

                        for (var i = currentStep + 1; i < stepElements.length; i++) {
                            stepElements[i].classList.add("futur");
                        }
                    }
                });
            }
        }

        static factory() {
            return () => {
                return new SurveyDirective();
            };
        }
    }

    angular.module('core').directive('survey', [SurveyDirective.factory()]);
}