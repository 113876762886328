module Core {
    export enum RestaurantType{
        "Self" = 1,
        "Cafeteria" = 2,
        "Click & Collect" = 3,
        "Room Service" = 4,
        "Restauration rapide" = 5,
        "Brasserie" = 6,
        //restaurant bon plans
        "bonplan click&collect" = 7,
        "bonplan impulsion" = 8,
        "Event / Cocktail" = 9
    }
}