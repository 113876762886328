module Core {
    @Component('core', 'scrollTopButton', {
        controllerAs: 'vm',
        bindings: {},
        templateUrl: './scrollTopButton.html'
    })
    class ScrollTopButtonComponent {
        scrollTop = () => {
            window.scrollTo(0, 0);
        }
    }
}