module Core {
    class NotificationsController {
        days: { date: string, notifications: Core.INotification[] }[];
        notificationCount: number;
        isLoading: boolean;

        constructor(private userService: Core.UserService, private $rootScope: ng.IRootScopeService) {
            this.isLoading = true;
            this.days = this.groupNotifications(this.userService.notifications);
            this.updateNotif();

            (<any>window).DataAnalyticsSender.sendCustomEvent('Notifications_Screen', '');
        }

        private updateNotif() {
            this.userService.getNotifications()
                .then(notifications => {
                    this.days = this.groupNotifications(notifications);
                })
                .catch(error => {
                    // TODO: StatusCode=0
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        private groupNotifications(notifications: Core.INotification[]): { date: string, notifications: Core.INotification[] }[] {
            var orderedNotification: { date: string, notifications: Core.INotification[] }[] = new Array();

            _.forEach(notifications, notification => {
                var dateOnly = new Date(notification.date.getTime());
                dateOnly.setHours(0, 0, 0, 0);
                var dateOnlyKey = dateOnly.toISOString();

                var elem = _.find(orderedNotification, { date: dateOnlyKey });
                if (!!elem) {
                    elem.notifications.push(notification);
                } else {
                    orderedNotification.push({
                        date: dateOnlyKey,
                        notifications: [notification]
                    });
                }
            });

            this.notificationCount = notifications.length;
            return _.sortBy(orderedNotification, notif => notif.date).reverse();
        }

        onRead(notification: Core.INotification) {
            this.userService.setNotificationState(notification.id, true);
            (<any>window).DataAnalyticsSender.sendCustomEvent('Notifications_BTN_Detail', '');
        }

        onDelete(notification: Core.INotification) {
            this.userService.deleteNotification(notification.id).then(() => this.updateNotif());
            (<any>window).DataAnalyticsSender.sendCustomEvent('Notifications_BTN_Delete', '');
        }
    }

    angular.module('core').controller('notificationsController', ['userService', '$rootScope', NotificationsController]);
}