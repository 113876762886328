module Easypass {
    export class CheckBoxScopeDeclaration {
        isChecked: boolean;
    }

    export interface ICheckBoxScope extends ng.IScope {
        isChecked: boolean;
        updateState: () => void;
    }

    class CheckBoxDirective implements ng.IDirective {
        restrict: string = "EA";
        template: any = "<button class=\"custom-checkbox\" data-ng-click='updateState()'><img ng-if=\"isChecked\" src=\"styles/assets/pictos/green-arrow.png\" /></button>";

        scope = {
            isChecked: '='
        };

        link($scope: ICheckBoxScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) {
            var checkBoxInputElement = <HTMLInputElement>element[0].childNodes[0];
            checkBoxInputElement.checked = $scope.isChecked;

            $scope.updateState = () => {
                if (!$scope.isChecked) {
                    $scope.isChecked = true;
                } else {
                    $scope.isChecked = false;
                }
            }

            $scope.$watch("isChecked",
                (newValue: boolean, oldValue: boolean) => {
                    if (oldValue != newValue) {
                        checkBoxInputElement.checked = newValue;
                    }
                },
                true);
        };

        static factory() {
            return () => {
                return new CheckBoxDirective();
            };
        }
    }

    angular.module('easypass').directive('checkBoxDirective', [CheckBoxDirective.factory()]);
}