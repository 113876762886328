module Core {
    export class AuthenticationService {
        constructor(private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService) {
        }

        getToken(signInRequest: IAuthRequest,scope: string): ng.IPromise<IToken> {
            var deferred = this.$q.defer<IToken>();
            var url = `${this.configService.getConfigValue('tokenUrl')}/${signInRequest.authType}?scope=${scope}`;

            this.$http.post(url, signInRequest)
                .success((authToken: IToken) => {
                    deferred.resolve(authToken);
                }).catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        renewToken(refreshToken: string): ng.IPromise<IToken> {
            var deferred = this.$q.defer<IToken>();

            this.$http.post(`${this.configService.getConfigValue('url')}/oauth/refresh`, { refreshToken }, { headers: { Authorization: null } })
                .success((authToken: IToken) => {
                    deferred.resolve(authToken);
                })
                .catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        forgotPassword(forgotPassword: IForgotPassword): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();

            var uri = `${this.configService.getConfigValue('url')}/oauth/forgotpassword`;
            this.$http.post(uri, forgotPassword)
                .success(() => deferred.resolve())
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        resetPassword(resetPassword: IResetPassword): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();

            var uri = `${this.configService.getConfigValue('url')}/oauth/resetpassword`;
            this.$http.post(uri, resetPassword)
                .success(() => deferred.resolve())
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        confirmEmail(confirmEmail: IConfirmEmail): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();

            var uri = `${this.configService.getConfigValue('url')}/oauth/confirmemail`;
            this.$http.post(uri, confirmEmail)
                .success(() => deferred.resolve())
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        sendConfirmEmail(sendConfirmEmail: ISendConfirmEmail): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();

            var uri = `${this.configService.getConfigValue('url')}/oauth/sendconfirmemail`;
            this.$http.post(uri, sendConfirmEmail)
                .success(() => deferred.resolve())
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
    angular.module('core').service('authenticationService',
        ['$http', '$q', 'configService', AuthenticationService]);
}