module Easypass {
    class LoadingButtonDirective implements ng.IDirective {
        restrict: string = 'A';

        link(scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) {
            var loadingResource = attrs["loadingButtonResource"];
            var staticResource = element.val();

            scope.$watch(attrs["loadingButton"], (value) => {
                if (value === true) {
                    element.val(loadingResource);
                    element.attr("disabled", "disabled");
                    element.addClass("loading");
                } else {
                    element.val(staticResource);
                    element.removeAttr("disabled");
                    element.removeClass("loading");
                }
            });
        }

        static factory() {
            var directive = () => {
                return new LoadingButtonDirective();
            };
            return directive;
        }
    }

    angular.module('easypass').directive('loadingButton', [LoadingButtonDirective.factory()]);
}