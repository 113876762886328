module Core {
  @Component("core", "restaurantFermeture", {
    controllerAs: "vm",
    bindings: {
      restaurant: "<",
    },
    templateUrl: "./restaurantFermeture.html",
  })
  class RestaurantFermetureComponent {
    static $inject: string[] = ["restaurantsService"];

    constructor(private restaurantsService: Core.RestaurantsService) {}

    restaurant: Core.IRestaurant;

    dateInterval: Core.IDateInterval = null;
    isUnique: boolean;
    isServiceInactif: boolean;

    $onInit = () => {
      this.isServiceInactif = false;
      if (
        this.restaurant.isClickAndCollect &&
        !this.restaurant.clickAndCollectActif
      ) {
        this.isServiceInactif = true;
      }

      if (
        !!this.restaurant &&
        !!this.restaurant.joursFermetures &&
        this.restaurant.joursFermetures.length
      ) {
        for (let joursFermetures of this.restaurant.joursFermetures) {
          let dateDebut: moment.Moment = moment(joursFermetures.dateDebut);
          let dateFin: moment.Moment = moment(joursFermetures.dateFin);

          while (
            dateDebut.isSameOrBefore(dateFin) &&
            (this.restaurantsService.getJourOuvertureFlagByMomentDate(
              dateDebut
            ) &
              this.restaurant.jourOuverture) ===
              0
          ) {
            dateDebut.add(1, "days");
          }

          while (
            dateFin.isSameOrAfter(dateDebut) &&
            (this.restaurantsService.getJourOuvertureFlagByMomentDate(dateFin) &
              this.restaurant.jourOuverture) ===
              0
          ) {
            dateFin.subtract(1, "days");
          }

          if (dateDebut.isSameOrBefore(dateFin)) {
            this.dateInterval = <IDateInterval>{
              dateDebut: dateDebut.toDate(),
              dateFin: dateFin.toDate(),
            };
            break;
          }
        }

        if (!!this.dateInterval) {
          this.isUnique =
            this.dateInterval.dateDebut.getTime() ==
            this.dateInterval.dateFin.getTime();
        }
      }
    };
  }
}
