module Easypass {
    @Component('easypass', 'tcCommandeDetail', {
        controllerAs: 'vm',
        bindings: {
            index: '<'
        },
        templateUrl: './commandeDetail.html'
    })
    class CommandeDetail {
        static $inject: string[] = ['commandeService', 'commandeStatusService', '$state', 'routeService', 'restaurantsService'];

        index: number;
        internalIndex: number;
        commande: Core.ICommandeDetail;
        commandes: Core.ICommandeListItem[];
        commandeStatus: string;
        hasMoreCommandes: boolean = false;
        isLoading: boolean = true;
        isOnError: boolean = false;
        startTime: string;
        endTime: string;
        dateRetrait: string;
        heureRetrait: string;
        heureFinRetrait : string;
        hasCreneauRetraits : boolean = false;
        lieuRetrait: string;
        collected: boolean = false;
        isClickAndServe: boolean;
        isCommandeDTA: boolean = false;
        isCommandeCC: boolean = false;
		
        constructor(
            private commandeService: CommandeService,
            private commandeStatusService: CommandeStatusService,
            private $state: ng.ui.IStateService,
            private routeService: Core.RouteService,
            private restaurantService: Core.RestaurantsService) {

            this.isLoading = true;

            this.internalIndex = Number(this.index);

            this.commandeService.getMesCommandes().then(commandes => {
                this.commandes = commandes;
                this.getCommandeDetail();
            }).catch(error => {
                this.isOnError = true;
                this.$state.go("tc-commandes");
            });
        }

        getCommandeDetail = () => {
            this.commandeService.getCommandeDetail(this.internalIndex, true).then(commande => {
                this.commande = commande;

                this.hasMoreCommandes = !!this.commandes[this.internalIndex + 1];

                this.isCommandeDTA = this.commande.restaurantTypeId == Core.RestaurantType["bonplan click&collect"];
                this.isCommandeCC = this.commande.restaurantTypeId == Core.RestaurantType["Click & Collect"];

                this.commandeStatus = this.commandeStatusService.getStatusText(this.commande.statut);
                this.dateRetrait = moment(commande.dateRetrait).format('DD/MM/YYYY');
                this.heureRetrait = moment(commande.dateRetrait).format('HH:mm');

                if(commande.dateFinRetrait){
                    this.heureFinRetrait = moment(commande.dateFinRetrait).format('HH:mm');
                }

                this.lieuRetrait = this.commande.lieuRetraitLibelle;

                if (this.commande.statut == CommandeState.DTA_PAYEE_RETIREE) {
                    this.collected = true;
                }

                this.restaurantService.get(commande.restaurantId).then(restaurant => {
                    this.hasCreneauRetraits = restaurant.hasCreneauRetraits;
                    this.startTime = restaurant.orderOpenAt || restaurant.openAt;
                    this.endTime = restaurant.orderCloseAt || restaurant.closeAt;
                    if (this.commande.statut == CommandeState.DTA_PAYEE_ERREUR_RESA_CASIER_PHYSIQUE && (moment(restaurant.closeAt, "hh:mm") < moment())) {
                        this.commandeStatus = this.commandeStatusService.getStatusText(CommandeState.DTA_PAYEE_NON_RETIREE)
                    }
                    this.isClickAndServe = restaurant.isClickAndServe;
                }).finally(() => {
                    this.isLoading = false;
                });
            }).catch(error => {
                this.isOnError = true;
            });
        }

        getNextCommande = () => {
            if (!this.hasMoreCommandes) return;

            this.$state.go("tc-commande-detail", { index: this.internalIndex + 1 }, {location: 'replace'});
        }

        getPrevCommande = () => {
            if (this.internalIndex == 0) return;

            this.routeService.forceNavigationDirection(Core.NavigationDirection.right);
            this.$state.go("tc-commande-detail", { index: this.internalIndex - 1 }, { location: 'replace' });
        }

		getCommandeTotalArticlesPrix(): number {
			let total: number = +(_.sum(this.commande.articles, article => article.prix * article.quantite)).toFixed(2);
			return total;
		}
    }
}
