module Easypass.DinerTakeAway {
    @Component('easypass', 'dtExpressyourself', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<'
        },
        templateUrl: './dtExpressyourself.html'
    })
    class ExpressyourselfComponent {
        static $inject: string[] = ['enqueteService', 'restaurantsService', '$state', '$timeout'];

        restaurantId: number;
        isLoading: boolean = false;
        campagne: Core.ICampagne;
        enquete: Core.IEnquete;
        private currentQuestionIndex = -1;
        restaurant: Core.IRestaurant;
        isPosting: boolean = false;
        hasError: boolean = false;
        hasNetworkError: boolean = false;

        constructor(private enqueteService: EnqueteService,
            private restaurantService: RestaurantsService,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService) {
        }

        $onInit() {
            this.isLoading = true;
            this.restaurantService.get(this.restaurantId).then(restaurant => this.restaurant = restaurant).catch(error => {
                this.hasError = true;
            });
            this.enqueteService.gets(true, this.restaurantId).then(campagnes => {
                this.campagne = _.find(campagnes, (camp) => !camp.dejaRepondueAujourdhui);

                if (this.campagne) {
                    this.enqueteService.getContent(this.campagne.enqueteId).then(enquete => {
                        this.enquete = enquete;
                        this.isLoading = false;
                        this.currentQuestionIndex = 0;
                    }).catch(error => {
                        this.hasError = true;
                    });
                } else {
                    this.currentQuestionIndex = 0;
                }
            }).catch(error => {
                this.hasError = true;
            }).finally(() => {
                this.isLoading = false;
            });
        }

        goToNext = (question: number) => {
            if (this.enquete.questions.length > 1) {
                //TODO: cette gestion du timeout devrait être dans la vue plutôt
                this.$timeout(2000).then(() => {
                    if (this.currentQuestionIndex == question)
                        this.currentQuestionIndex++;
                });
            }
        }

        goToRestaurant() {
            this.restaurantService.getRouteById(this.restaurantId).then(route => {
                this.$state.go(route, { id: this.restaurantId });
            });
        }

        validate = () => {
            this.isPosting = true;
            this.hasError = false;
            this.hasNetworkError = false;

            this.enqueteService.sendResponses(this.enquete.questions, this.enquete.commentaire, this.campagne.id, this.campagne.restaurantId)
                .then(() => this.currentQuestionIndex++)
                .catch(error => {
                    checkNetworkError(error, () => this.hasError = true, () => this.hasNetworkError = true);
                })
                .finally(() => this.isPosting = false);
        }
    }
}