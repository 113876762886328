module Core {
	export class AnimationService {
		private myAnimations: { [id: number]: IAnimation[] } = {};
		private getsFromServerOrchestrator: IS.SingleCallbackOrchestrator;

		constructor(private $rootScope: ng.IRootScopeService,
			private $http: ng.IHttpService,
			private $q: ng.IQService,
			private storageService: StorageService,
			private configService: IS.Configuration.ConfigService,
			private userService: UserService,
			private restaurantsService: RestaurantsService) {

			this.$rootScope.$on('clearUserInMemoryData', () => {
				this.myAnimations = {};
			});
		}

		gets(force: boolean = false, restaurantId: number = null): ng.IPromise<IAnimation[]> {
			var deferred = this.$q.defer<IAnimation[]>();

			var isEmpty = Object.getOwnPropertyNames(this.myAnimations).length === 0;
			if (force || isEmpty || this.getsFromServerOrchestrator != null) {
				this.getsFromServer().then(animations => {
					this.myAnimations = animations;
					deferred.resolve(this.filter(this.myAnimations, restaurantId));
				}).catch(error => {
					deferred.reject(error);
				}).finally(() => {
					this.getsFromServerOrchestrator = null;
				});
			} else {
				deferred.resolve(this.filter(this.myAnimations, restaurantId));
			}

			return deferred.promise;
        }

		get(restaurantAnimationId: number): ng.IPromise<IAnimation> {
			var deferred = this.$q.defer<IAnimation>();

			this.gets().then(animations => {
				var animation = _.find(animations, animation => animation.restaurantAnimationId === restaurantAnimationId);

				if (animation == null) {
					deferred.reject(`Animation ${restaurantAnimationId} not found`);
					return;
				}

				deferred.resolve(animation);
			}, error => {
				deferred.reject(error);
			});

			return deferred.promise;
        }

		private getsFromServer(): ng.IPromise<{ [id: number]: IAnimation[] }> {
			if (this.getsFromServerOrchestrator == null) {
				this.getsFromServerOrchestrator = new IS.SingleCallbackOrchestrator(this.$q, () => {
					var deferred = this.$q.defer<{ [id: number]: IAnimation[] }>();

					var results: { [id: number]: IAnimation[] } = {};

					var promises: ng.IPromise<void>[] = [];

					this.restaurantsService.gets().then(restaurants => {
						for (var restaurant of restaurants) {
							//promises.push(this.getsByRestaurantFromServer(restaurant).then(result => {
							//	results[result.id] = result.animations;
                            //}));
                            var animations: IAnimation[] = restaurant.animations.map((rep: any) => this.convertToAnimation(rep, restaurant));
                            results[restaurant.id] = animations;
						}

						this.$q.all(promises).then(_ => {
							deferred.resolve(results);
						});
					});

					return deferred.promise;
				});
			}

			return this.$q.resolve(this.getsFromServerOrchestrator.execute());
        }

		private getsByRestaurantFromServer(restaurant: IRestaurant): ng.IPromise<{ id: number, animations: IAnimation[] }> {
			var deferred = this.$q.defer<{ id: number, animations: IAnimation[] }>();

			this.$http.get<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurant.id}/animations`)
				.success(result => {
					var animations: IAnimation[] = result.map((rep: any) => this.convertToAnimation(rep, restaurant));

					deferred.resolve({ id: restaurant.id, animations: animations });
				})
				.error(error => {
					deferred.reject(error);
				});

			return deferred.promise;
        }

		private filter(animations: { [id: number]: IAnimation[] }, restaurantId: number = null): IAnimation[] {
			var today = new Date();
			if (!!restaurantId) {
				return animations[restaurantId];
			} else {
				var flattened: IAnimation[] = [];

				for (var key in animations) {
					var id: number = +key;

                    if (animations[id][0] && moment(animations[id][0].endAt).isSameOrAfter(today, 'day')) {
						flattened = flattened.concat(animations[id]);
					}
				}

				return flattened;
			}
        }

		private convertToAnimation(animation: any, restaurant: IRestaurant): IAnimation {
			return <IAnimation>{
				description: animation.animation.description,
				endAt: new Date(animation.dateFin),
				startAt: new Date(animation.dateDebut),
				fontColor: animation.animation.couleurPolice,
				backgroundColor: animation.animation.couleurFond,
				id: animation.animation.id,
				restaurantAnimationId: animation.animation.restaurantAnimationId,
				libelle: animation.animation.libelle,
				libelleLien: animation.animation.lienLibelle,
				lien: animation.animation.lien,
				restaurantName: restaurant.libelle,
				restaurantId: restaurant.id,
				pictoUrl: `${this.configService.getConfigValue('url')}/animation/${animation.animation.id}/pictogram`,
				pictureUrl: `${this.configService.getConfigValue('url')}/animation/${animation.animation.id}/picture`
			};
		}

		getNext = (currentRestaurantAnimationId: number, restaurantId: number = null): ng.IPromise<IAnimation> => {
			return this.getByDirection(currentRestaurantAnimationId, 1, restaurantId);
        }

		getPrevious = (currentRestaurantAnimationId: number, restaurantId: number = null): ng.IPromise<IAnimation> => {
			return this.getByDirection(currentRestaurantAnimationId, -1, restaurantId);
        }

		private getByDirection = (currentRestaurantAnimationId: number, direction: number, restaurantId: number = null): ng.IPromise<IAnimation> => {
			var deferred = this.$q.defer<IAnimation>();

			this.gets(false, restaurantId)
				.then(animations => {
					if (animations == null || animations.length === 0) {
						deferred.reject('No suitable animation found');
						return;
					}

					var index = _.findIndex(animations, animation => animation.restaurantAnimationId === currentRestaurantAnimationId);
					if (index === -1) {
						index = 0;
					}

					var animation = animations[(index + direction + animations.length) % animations.length];
					deferred.resolve(animation);
				})
				.catch(error => {
					deferred.reject(error);
				});

			return deferred.promise;
		}

		getsCount(restaurantId: number = null): ng.IPromise<number> {
			var deferred = this.$q.defer<number>();

			this.gets(false, restaurantId).then(animations => {
				deferred.resolve(!!animations ? animations.length : 0);
			}, error => {
				deferred.reject(error);
			});

			return deferred.promise;
		}

        getCurrent(restaurantId: number = null): ng.IPromise<IAnimation> {
			var deferred = this.$q.defer<IAnimation>();

			this.gets(false, restaurantId).then(animations => {
				var now = new Date();
				now.setHours(0, 0, 0, 0);

				var animation = _.find(animations, animation => {
					var startAt = animation.startAt;
					startAt.setHours(0, 0, 0, 0);

					var endAt = animation.endAt;
					endAt.setHours(0, 0, 0, 0);

					return startAt <= now && now <= endAt;
				});

				if (animation) {
					deferred.resolve(animation);
				} else {
					deferred.reject('No running animation');
				}
			}, error => {
				deferred.reject(error);
			});

			return deferred.promise;
		}

        getFirst(): ng.IPromise<IAnimation> {
			var deferred = this.$q.defer<IAnimation>();

			this.gets().then(animations => {
				if (animations.length > 0) {
					deferred.resolve(animations[0]);
				}
				else {
					deferred.reject('No animation found');
				}
			});

			return deferred.promise;
		}

		clearAnimations() {
			if (this.myAnimations) {
				for (var restaurantAnimation in this.myAnimations) {
					this.storageService.removeAnimations(+restaurantAnimation);
				}
			}
			this.myAnimations = {};
		}
	}

	angular.module('core').service('animationService',
		['$rootScope', '$http', '$q', 'storageService', 'configService', 'userService', 'restaurantsService', AnimationService]);
}