module Easypass {
    class TicketsController {
        hasCaisse: boolean = false;
        isRegisteredInCaisse: boolean = false;
        displayName: string;
        initials: string;
        theme: Core.ITheme;
        hasQrCode: boolean;
        ticketsForListing: IFormattedTicket[];
        skip: number = 0;
        take: number = 10;
        showTickets = false;
        isOnErrorTicket: boolean = false;
        onErrorTicketMessage: string;
        refreshScrollIos: number = 0;
        restaurantId: number;
        isLoadingTickets: boolean = true;
        isOnErrorTickets: boolean = false;
        showTicketButton: boolean = true;
        isLoadingMoreTicket: boolean = false;
        hasMoreTickets: boolean = true;
        isSoldeLoading: boolean = false;
        buttonLabel: string;

        timeoutHandler: number;
        canNavigateToTicketDetail: boolean = true;

        refreshScrollToBottomIos: number = 0;

        constructor(
            private localizeService: IS.Localization.LocalizeService,
            private redirectionService: Core.IRedirectionService,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private routeService: Core.RouteService,
            private userService: Core.UserService,
            private ticketsService: TicketsService,
            private $timeout: ng.ITimeoutService,
            private $rootScope: ng.IRootScopeService,
            private $scope: ng.IScope,
            private $location: ng.ILocationService
        ) {
            (<any>window).DataAnalyticsSender.sendCustomEvent('Tickets_Screen', '');

            this.isOnErrorTicket = false;
            this.isLoadingTickets = true;
            this.isOnErrorTickets = false;
            this.showTicketButton = true;
            this.restaurantId = +$stateParams["restaurantId"];
            //maj du solde quand on arrive ici
            this.$rootScope.$broadcast('soldeChanged');

            this.displayName = userService.userInformations.displayName;
            this.hasCaisse = userService.userInformations.currentSite.hasCaisse;
            this.isRegisteredInCaisse = userService.userInformations.currentSite.isRegisteredInCaisse;
            this.initials = userService.userInformations.initials;
            this.theme = userService.getCurrentTheme();
            this.hasQrCode = userService.userInformations.currentSite.hasQrCode;
            this.buttonLabel = "Parametres_Load_More_Tickets";

            this.ticketsService.getTickets()
                .then((tickets) => {
                    this.ticketsForListing = tickets;

                    if (this.ticketsForListing.length < 10) {
                        this.showTicketButton = false;
                    }

                }, error => {
                    // TODO: StatusCode=0
                    this.isOnErrorTickets = true;
                    this.buttonLabel = "Parametres_Retry";

                }).finally(() => {
                    this.isLoadingTickets = false;
                    this.showTickets = true;
                    this.updateScrollIos();
                });
        }

        loadMoreTicketsHasError: boolean = false;
        loadMoreTicketsHasNetworkError: boolean = false;

        updateScrollIos() {
            this.$timeout(50).then(() => {
                this.refreshScrollIos++;
            });
        }

        loadMoreTicket(event: Event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();

            this.loadMoreTicketsHasError = false;
            this.loadMoreTicketsHasNetworkError = false;

            this.canNavigateToTicketDetail = false;
            this.isLoadingMoreTicket = true;

            this.ticketsService.getNextPage()
                .then(tickets => {

                    console.log(tickets.length);

                    if (tickets.length < 10) {
                        this.showTicketButton = false;
                    }

                    this.ticketsForListing = this.ticketsForListing.concat(tickets);
                    //console.log(this.ticketsForListing);

                    this.updateScrollIos();

                    this.hasMoreTickets = tickets.length != 0;

                    this.$timeout(200).then(() => this.canNavigateToTicketDetail = true);

                })
                .catch(error => {
                    checkNetworkError(error, () => this.loadMoreTicketsHasError = true, () => this.loadMoreTicketsHasNetworkError = true);
                    this.buttonLabel = "Parametres_Retry";

                    this.$timeout().then(() => {
                        this.refreshScrollToBottomIos++;

                        var list = document.querySelector('#tickets-container');
                        list.scrollTop = list.clientHeight;
                    });
                }).finally(() => {
                    this.isLoadingMoreTicket = false;
                });
        }

        goToTicketDetails(index: number, event: Event) {
            if (!this.canNavigateToTicketDetail) {
                return;
            }

            this.$state.go("ticketdetail", { index: index });
        }
    }

    angular.module('easypass').controller('ticketsController', ['localizeService', 'redirectionService', '$state', '$stateParams', 'routeService', 'userService', 'ticketsService', '$timeout', '$rootScope', '$scope', '$location', TicketsController]);
}
