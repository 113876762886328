module Core {
    class ForgotPasswordController {

        constructor(private authenticationService: AuthenticationService) {
            (<any>window).DataAnalyticsSender.sendCustomEvent('ForgotPWD_Screen', '');
        }

        email: string;
        isLoading: boolean = false;
        isOnError: boolean = false;
        showConfirmation: boolean = false;

        submit(): void {
            this.isLoading = true;
            this.isOnError = false;
            (<any>window).DataAnalyticsSender.sendCustomEvent('ForgotPWD_BTN_Send', '');

            this.authenticationService.forgotPassword(<IForgotPassword>{ email: this.email })
                .then(() => {
                    this.showConfirmation = true;
                }, error => {
                    // TODO: StatusCode=0
                    this.isOnError = true;
                }).finally(() => {
                    this.isLoading = false;
                });
        }
    }

    angular.module('core').controller('forgotPasswordController', ['authenticationService', ForgotPasswordController]);
}