module Core {
    export class PlatService {
        constructor(private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService) {
        }

        like(restaurantArticleId: number): ng.IPromise<any> {
            var deferred = this.$q.defer();

            this.$http.post(`${this.configService.getConfigValue('url')}/article/${restaurantArticleId}/like`, {})
                .success(() => deferred.resolve())
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        getPlatDetails(restaurantArticleId: number): ng.IPromise<IPlatDetail> {
            var deferred = this.$q.defer<IPlatDetail>();

            this.$http.get<IPlatDetail>(`${this.configService.getConfigValue('url')}/article/${restaurantArticleId}/details`)
                .success((platDetail) => deferred.resolve(platDetail))
                .catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

    }
    angular.module('core').service('platService',
        ['$http', '$q', 'configService', PlatService]);
}
