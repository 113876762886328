module Easypass {
    const pageSize: number = 10;

    export class TicketsService {

        tickets: IFormattedTicket[];
        currentPageIndex: number = 0;

        constructor(
            private $rootScope: ng.IRootScopeService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService,
            private userService: Core.UserService
        ) {
            this.$rootScope.$on('clearUserInMemoryData', () => {
                this.resetLocalTickets();
            });

            this.$rootScope.$on('orderValidated', () => {
                this.resetLocalTickets();
            });
        }

        getTickets(): ng.IPromise<IFormattedTicket[]> {
            if (!!this.tickets && this.tickets.length !== 0) {
                var deferred = this.$q.defer<IFormattedTicket[]>();
                deferred.resolve(this.tickets);
                return deferred.promise;
            }
            else {
                return this.getTicketPage(0);
            }
        }

        resetLocalTickets() {
            this.tickets = [];
            this.currentPageIndex = 0;
        }

        getTicketByIndex(rowIndex: number): ng.IPromise<{ ticket: IFormattedTicket, index: number, hasMoreTickets: boolean }> {
            var deferred = this.$q.defer<{ ticket: IFormattedTicket, index: number, hasMoreTickets: boolean }>();
            if (!this.tickets) {
                this.getTicketPage(0).then(() => {
					this.getTicketByIndex(rowIndex).then(res => deferred.resolve(res));
				}).catch(() => {
					deferred.reject();
				});
            }
            else if (rowIndex >= this.tickets.length) {
                // récupérer les tickets de la page suivante
                this.getTicketPage(this.currentPageIndex + 1).then((results) => {
					var hasMoreTickets: boolean = true;

					if (results.length === 0) {
						rowIndex = rowIndex - 1;
						hasMoreTickets = false;
					}

					this.getTicketByIndex(rowIndex).then(res => {
						deferred.resolve(res);
					});
				}).catch(() => {
					deferred.reject();
				});
            }
            else {
                deferred.resolve({ ticket: this.tickets[rowIndex], index: rowIndex, hasMoreTickets: true });
            }

            return deferred.promise;
        }

        getNextPage(): ng.IPromise<IFormattedTicket[]> {
            return this.getTicketPage(this.currentPageIndex + 1);
        }

        getTicketPage(pageIndex: number): ng.IPromise<IFormattedTicket[]> {
            var deferred = this.$q.defer<IFormattedTicket[]>();

            this.getTicketsFromServer(pageIndex).then((results) => {
				this.currentPageIndex = pageIndex;

				if (pageIndex === 0) {
					this.tickets = results;
				}
				else {
					this.tickets = this.tickets.concat(results);
				}

				deferred.resolve(results);
			}).catch((error) => {
				deferred.reject(error);
			});

            return deferred.promise;
        }

        private getTicketsFromServer(pageIndex: number): ng.IPromise<IFormattedTicket[]> {
            var deferred = this.$q.defer<IFormattedTicket[]>();
            var url = `${this.configService.getConfigValue('url')}/${this.userService.userInformations.currentSite.name}/tickets?pagesize=${pageSize}&pageindex=${pageIndex}&includeDetail=true`;
            this.$http.get<any>(url).success((results: IFormattedTicket[]) => {
				deferred.resolve(results);
			}).catch(error => {
				deferred.reject(error);
			});

            return deferred.promise;
        }
    }

    angular.module('easypass').service('ticketsService', ['$rootScope', '$http', '$q', 'configService', 'userService', TicketsService]);
}