module Easypass {
    interface IShortDateDirectiveScope extends ng.IScope {
        shortDate: Date;
    }

    class ShortDateDirective implements ng.IDirective {
        constructor(private localizeService: IS.Localization.LocalizeService) { }

        restrict = 'A';

        scope = {
            shortDate: '='
        }

        link = (scope: IShortDateDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
            var computeDate = () => {
                var tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1);
                var inputDate = moment(scope.shortDate).toDate(); 
                if (inputDate.toDateString() === new Date().toDateString()) {
                    element[0].textContent = this.localizeService.getLocalizedResource('Today');
                } else if (inputDate.toDateString() === tomorrow.toDateString()) {
                    element[0].textContent = this.localizeService.getLocalizedResource('Tomorrow');
                }
                else {
                    element[0].textContent = moment(inputDate).startOf('day').format('LLLL')
                        .replace(moment(inputDate).startOf('day').format('LT'), "")
                        .replace(moment(inputDate).startOf('day').format('YYYY'), "");
                }
            };

            scope.$watch(() => scope.shortDate, (value: any) => {
                computeDate();
            });

            scope.$on('resourcesUpdated', () => {
                computeDate();
            });
        }

        static factory() {
            return (localizeService: IS.Localization.LocalizeService) => {
                return new ShortDateDirective(localizeService);
            };
        }
    }

    angular.module('easypass').directive('shortDate', ['localizeService', ShortDateDirective.factory()]);
}
