module IS.Localization {
    export class LocalizeServiceProvider implements ng.IServiceProvider {
        private resourceBaseUrl: string = null;
        private resourceFilePattern: string = null;
        private resourceDefaultFile: string = null;
        private resourceKeyNotFound: string = null;
        private cultures: ICulture[] = null;
        private defaultCulture: string = null;

        configure(cultures: ICulture[], defaultCulture: string) {
            this.resourceBaseUrl = 'resources/';
            this.resourceFilePattern = 'resources.{lang}.json';
            this.resourceDefaultFile = 'resources.fr.json';
            this.resourceKeyNotFound = '!!Resource not found : {key}!!';
            this.cultures = cultures || [];
            this.defaultCulture = defaultCulture;
        }

        $get = ['$rootScope', '$http', '$window', ($rootScope: ng.IRootScopeService, $http: ng.IHttpService, $window: ng.IWindowService) => {
            return new LocalizeService($rootScope, $http, $window, this.resourceBaseUrl, this.resourceFilePattern, this.resourceDefaultFile, this.resourceKeyNotFound, this.cultures, this.defaultCulture);
        }];
    }

    angular.module('localization').provider('localizeService', [LocalizeServiceProvider]);
}