module IS.Configuration {
    angular.module('configuration', [])
        //(!) configObject est remplacé à la compilation, voir /gulptasks/compile-app.js
        .constant("configObject", null)
        .constant("configBaseUrl", "config/")
        .constant("configFile", "config.json")
        .constant("configKeyNotFound", "!!Setting not found : {key}!!")
        .run(['configService', (configService: ConfigService) => {
            configService.initializeConfigFiles();
        }]);
}