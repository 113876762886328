module Easypass.DinerTakeAway {
    export class DtBasketPaymentService {

        basket: BasketDTA;
        restaurantId: number;
        lockerId: number;

        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService,
            private creditService: CreditService,
            private redirectionService: Core.IRedirectionService,
            private localizeService: IS.Localization.LocalizeService) {
        }

        sendCommande(): ng.IPromise<number> {
            var deferred = this.$q.defer<number>();

            var basketItems = this.basket.items;
            var articles: { id: number, libelle: string, quantite: number }[] = [];

            basketItems.forEach(item => {
                var libellePortion;
                switch (item.portion.portionType) {
                    case Core.PortionType.Simple:
                        libellePortion=this.localizeService.getLocalizedResource('DT_PortionSolo');
                        break;
                    case Core.PortionType.Famille:
                        libellePortion=this.localizeService.getLocalizedResource('DT_PortionFamily');
                        break;
                    case Core.PortionType.Duo:
                        libellePortion=this.localizeService.getLocalizedResource('DT_PortionDuo');
                        break;                    
                    default:
                        libellePortion="";
                        break;
                }

                articles.push(
                    {
                        id: item.portion.articleId,
                        libelle: item.portion.articleLibelle + ' - ' + libellePortion,
                        quantite: item.quantity
                    });
            });

            var data = {
                restaurantId: Number(this.restaurantId),
                lockerReservationId: this.lockerId,
                panierModel:
                {
                    articles: articles
                }
            };


            this.$http.post<any>(`${this.configService.getConfigValue('url')}/dt/commandes/valider`, data)
                .success((commandeId: number) => {
                    deferred.resolve(commandeId);
                })
                .catch(e => {
                    deferred.reject(e);
                });

            return deferred.promise;
        }


        gotoPayment(basket: BasketDTA, restaurantId: number, lockerId: number): ng.IPromise<any> {
            var deferred = this.$q.defer();
            var paymentType;

            this.basket = basket;
            this.restaurantId = restaurantId;
            this.lockerId = lockerId;

            this.sendCommande()
                .then(commandeId => {
                    this.creditService.getRestaurantConfig(restaurantId).then(creditConfiguration => {
                        if (!!creditConfiguration.moyenPaiements && creditConfiguration.moyenPaiements.length !== 0) {

                            paymentType = this.getPaymentType(creditConfiguration.moyenPaiements[0]);

                            this.creditService.postCredit
                                (
                                    this.basket.amount.total,
                                    creditConfiguration.moyenPaiements[0],
                                    null,
                                    TypeTransaction.PaiementDinerTakeAwayCommande,
                                    commandeId
                                ).then((creditRedirection) => {

                                    this.redirectionService.redirect(creditRedirection).then((r) => {
                                        deferred.resolve(r);
                                    }, () => {
                                        deferred.reject();
                                    });
                                }).catch(reason => {
                                    deferred.reject();
                                });
                        }
                        else {
                            deferred.reject();
                        }
                    });
                },
                    () => {
                        deferred.reject();
                    });

            return deferred.promise;
        }

        //TODO: externaliser cette méthode dans un service à part
        //TODO: à quoi ça sert ?
        getPaymentType(moyenPaiement: IMoyenPaiement): Core.PaymentType {
            switch (moyenPaiement.id) {
                case 3:
                    return Core.PaymentType.Immediate;
                case 4:
                    return Core.PaymentType.Deffered;
            }
        }

    }


    angular.module('easypass').service('dtBasketPaymentService', ['$http', '$q', 'configService', 'creditService', 'redirectionService', 'localizeService', DtBasketPaymentService]);

}