module Easypass {
    class TicketDetailsController {

        ticket: IFormattedTicket;
        index: number;
        name: string;
        isLoading: boolean;
        hasError: boolean;
        hasMoreTickets: boolean;

        constructor(
            private redirectionService: Core.IRedirectionService,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private userService: Core.UserService,
            private ticketsService: TicketsService,
            private routeService: Core.RouteService
        ) {
            this.name = userService.userInformations.displayName;

            this.isLoading = true;
            this.index = $stateParams['index'];

            this.ticketsService.getTicketByIndex(this.index).then((res: { ticket: IFormattedTicket, index: number, hasMoreTickets: boolean }) => {
                this.index = res.index;
                this.ticket = res.ticket;
                this.hasMoreTickets = res.hasMoreTickets;
            }).catch(error => {
                // TODO: StatusCode=0
                this.hasError = true;
            }).finally(() => {
                this.isLoading = false;
            });

            (<any>window).DataAnalyticsSender.sendCustomEvent('TicketsDetails_Screen', '');
        }

        getNextTicket = () => {
            this.$state.go("ticketdetail", { index: this.index + 1 });
        }

        getPrevTicket = () => {
            this.routeService.forceNavigationDirection(Core.NavigationDirection.right);
            this.$state.go("ticketdetail", { index: this.index - 1 });
        }

        export = () => {
            var selectorElem = '#print-zone .container';
            window.scroll(0, 0); // HACK :  pour corriger la capture d'image par html2canvas dansle desktop ce pb est liée au composant qui gére le progressive scroll.
            html2canvas(document.querySelector(selectorElem)).then(canvas => {
                var data = canvas.toDataURL("image/jpeg", 1);
                this.downloadTicket(data);

            });

        }

        downloadTicket = (data) => {
            console.log("downloadTicket executed");
            if (!window.cordova) {
                console.log("download ticket mobile ");
                this.createDownloadLink("ticket.jpg", data).click();
            } else {
                console.log("download ticket cordova");
                this.downloadCordova(data);
            }
        }
        createDownloadLink = (filename: string, data: string) => {
            //cette méthode est inspirée de  : "https://gist.github.com/narainsagar/08a61bace498b909f7ef339f3e60c9bc"
            var dataUri = data.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
            var anchor = document.createElement('a');
            anchor.setAttribute('href', dataUri);
            anchor.setAttribute('download', filename);
            return anchor;
        }

        openInNewTab(data: string) {
            var image = new Image();
            image.src = data;

            var w = window.open("");
            w.document.write(image.outerHTML);
        }

        downloadCordova = (data: string) => {
            var dataUri = data.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
            var fileTransfer = new FileTransfer();
            var fileDirecoryPath = cordova.platformId === 'android'
                                ? cordova.file.externalApplicationStorageDirectory  + "ticket.jpg"
                                : cordova.file.syncedDataDirectory + "ticket.jpg";
            fileTransfer.download(
                dataUri,
                fileDirecoryPath,
                function (entry) {
                    console.log("download complete: " + entry.toURL());
                    cordova.plugins.disusered.open(entry.toURL(), 
                        function(){console.log("open with disusered OK"); } , 
                        function(error){console.log(JSON.stringify(error)); });
                },
                function (error) {
                    console.log("download error source " + error.source);
                    console.log("download error target " + error.target);
                    console.log("download error code" + error.code);
                });
        }

    }

    angular.module('easypass').controller('ticketDetailsController', ['redirectionService', '$state', '$stateParams', 'userService', 'ticketsService', 'routeService', TicketDetailsController]);
}