module Core {
    var orejimeConfig: any;
    var Orejime: any;
    var orejimeInstance;

    export class CookieService {
        rgpdCookieName = 'tc-rgpd-cookies';
        constructor(
            private localizeService: IS.Localization.LocalizeService, 
            private configService: IS.Configuration.ConfigService,
            private $rootScope: ng.IRootScopeService,
            ) { }

        public getCookie(name: string): any {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            console.log('rgpd ca = ' + ca);
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) {
                    console.log('rgpd nameEQ = ' + nameEQ);
                    console.log('rgpd result = ' + c.substring(nameEQ.length, c.length));
                    const result = c.substring(nameEQ.length, c.length);
                    console.log(JSON.parse(result));
                    return JSON.parse(result);
                }
            }
            return null;
        }
        public getOrejimeConfig() {
            const cookieDomain = window.location.hostname;
            const lang = this.localizeService.getCurrentLanguage();
            const translationsFr = this.getTranslationsFr();
            const translationsEn = this.getTranslationsEn();

            let gaUser = this.configService.getConfigValue('gaUser');
            

            return {
                elementID: "orejime",
                appElement: "#container",
                cookieName: this.rgpdCookieName,
                cookieExpiresAfterDays: 177,
                cookieDomain,
                stringifyCookie: (contents) => JSON.stringify(contents),
                parseCookie: (cookie) => JSON.parse(cookie),
                privacyPolicy: "#/parametres/legal-infos",
                default: true,
                mustConsent: false,
                mustNotice: false,
                lang,
                logo: false,
                debug: false,
                translations: {
                    en: translationsEn,
                    fr: translationsFr
                },
                apps: [
                    {
                        name: "google-analytics",
                        title: "Google Analytics",
                        cookies: [
                            "_ga",
                            "_gat",
                            "_gid",
                            "__utma",
                            "__utmb",
                            "__utmc",
                            "__utmt",
                            "__utmz",
                            "_gat_gtag_GTM_UA",
                            "_gat_GTM_UA"
                        ],
                        purposes: ["analytics"],
                        callback: function(consent, app){
                            window[`ga-disable-${gaUser}`] = !consent;
                        },
                        required: false,
                        optOut: false,
                        default: true,
                        onlyOnce: true,
                    },
                    {
                        name: "cookies-fonctionnels",
                        title: "Fonctionalités de base",
                        purposes: ["fonctionnel", "security"],
                        cookies: ["cookies-fonctionnels"],
                        required: true
                    }
                ],
                categories: [
                    {
                        name: "fonctionnels",
                        title: "Cookies fonctionnels",
                        apps: [
                            "cookies-fonctionnels",
                        ]
                    },
                    {
                        name: "analytics",
                        title: "Cookies analytiques",
                        apps: [
                            "google-analytics",
                        ]
                    }

                ]
            };
        }

        public manageRgpdCookies() {
            this.checkGoogleAnalyticsConsent();
            
            orejimeConfig = this.getOrejimeConfig();
            setTimeout(() => {
                if ((<any>window).Orejime) {
                    if (!this.$rootScope.isEmbedded)
                    {
                        (<any>window).orejimeInstance = (<any>window).Orejime.init(orejimeConfig);
                        // this.checkRgpdCookie();
                    }
                    else
                    console.info('IsEmbedded => Don\'t ask for cookies');
                }
                else
                    console.error('Orejime is NULL!');
            }, 1000);
        }

        private checkGoogleAnalyticsConsent(){
            const rgpdCookie = this.getRgpgCookie();
            let gaUser = this.configService.getConfigValue('gaUser');
            let consent = rgpdCookie && rgpdCookie['google-analytics'] === true;
            window[`ga-disable-${gaUser}`] = !consent;
        }

        private checkRgpdCookie() {
            const rgpdCookie = this.getRgpgCookie();
            console.log(`rgpd ${rgpdCookie}`);
            if (rgpdCookie != undefined && rgpdCookie != null) {
                if (rgpdCookie['cookies-fonctionnels'] === false) {
                    console.log('rgpd cookies-fonctionnels false');
                }
                else {
                    console.log('rgpd cookies-fonctionnels true');
                }
            } else {
                console.log('rgpd pas de cookies-fonctionnels');
            }
        }
        private getTranslationsFr() {
            return {
                consentModal: {
                    title: "Gérer mes cookies",
                    description: "Les cookies sont des fichiers de petit format stockés dans la mémoire de tout équipement terminal connecté à un réseau de télécommunication ouvert au public qui servent à conserver une trace des actions des utilisateurs. Ils peuvent être nécessaires à la navigation ou servir d'autres finalités comme la mesure d'audience, la publicité etc.\nTimechef et ses partenaires utilisent des cookies pour le fonctionnement de l’application, son utilisation et la fréquentation du site.",
                    privacyPolicy: {
                        name: "politique de confidentialit\xE9",
                        text: "Pour en savoir plus, merci de lire notre {privacyPolicy}.\n"
                    }
                },
                consentNotice: {
                    changeDescription: "Des modifications ont eu lieu depuis votre derni\xE8re visite, merci de mettre \xE0 jour votre consentement.",
                    description: "Les cookies sont des fichiers de petit format stockés dans la mémoire de tout équipement terminal connecté à un réseau de télécommunication ouvert au public qui servent à conserver une trace des actions des utilisateurs. Ils peuvent être nécessaires à la navigation ou servir d'autres finalités comme la mesure d'audience, la publicité etc.\nTimechef et ses partenaires utilisent des cookies pour le fonctionnement de l’application, son utilisation et la fréquentation du site.",
                    learnMore: "Personnaliser mes choix"
                },
                purposes: {
                    analytics: "Analytique",
                    security: "Sécurité",
                    fonctionnel: "Fonctionnel"
                },
                categories: {
                    analytics: {
                        description: "\n•Les traces de navigation sont enregistrées par Google Analytics pour suivre des statistiques\n générales d'utilisation et améliorer la maintenance (nombre de connexions par jour, type d'appareil le plus utilisé)."
                    },
                    fonctionnels: {
                        description: "Timechef utilise des cookies pour le fonctionnement du site par exemple pour :\n • vous reconnaître lorsque vous vous connectez et utiliser le service\n • vous permettre de suivre vos rechargements et vos commandes\n • vous permettre d'utiliser les fonctionnalités (notifications, alerte solde, préférences)\n • vous aider lorsque vous rencontrez un problème d'inscription, de commande ou de rechargement."
                    }
                },
                accept: "Tout Accepter",
                acceptTitle: "Accepter les cookies",
                acceptAll: "Tout accepter",
                save: "Sauvegarder",
                saveData: "Sauvegarder ma configuration sur les informations collect\xE9es",
                decline: "Tout Refuser",
                declineAll: "Tout refuser",
                close: "Fermer",
                enabled: "Activ\xE9es",
                disabled: "D\xE9sactiv\xE9es",
                app: {
                    optOut: {
                        title: "(opt-out)",
                        description: "Cette application est charg\xE9e par d\xE9faut (mais vous pouvez la d\xE9sactiver)"
                    },
                    required: {
                        title: "(toujours requises)",
                        description: "Cette application est toujours requise"
                    },
                    purposes: "Utilisations",
                    purpose: "Utilisation"
                },
                poweredBy: "",
                newWindow: "nouvelle fen\xEAtre"
            };
        }
        private getTranslationsEn() {
            return {
                consentModal: {
                    title: "Manage my cookies",

                    description: "Cookies are text files with small pieces of data which are stored in the memory of any equipment connected to a public communication network. Those are used to track users actions. Some could serve essential functions on the web as to navigate. Others enable activities such as statistics to collect trafic or provide party advertisers.Timechef use cookies for essential functions and third party providers to measure traffic and users browsing activity.",
                    privacyPolicy: {
                        name: "Privacy notice",
                        text: "For more information, read our Privacy Notice here {privacyPolicy}.\n"
                    }
                },
                consentNotice: {
                    changeDescription: "Updates have been made for your last visit.Please, read the notice and confirm you agreement.",
                    description: "Cookies are text files with small pieces of data which are stored in the memory of any equipment connected to a public communication network. Those are used to track users actions. Some could serve essential functions on the web as to navigate. Others enable activities such as statistics to collect trafic or provide party advertisers. Timechef use cookies for essential functions and third party providers to measure traffic and users browsing activity.",
                    learnMore: "Manage choices"
                },
                purposes: {
                    analytics: "analytics",
                    security: "security",
                    fonctionnel: "Fonctionnal"
                },
                categories: {
                    analytics: {
                        description: "\n•visit traces are registered by Google Analytics to provide statistics and help maintenance (visits per period, user pages traffic, devices most uses..)"
                    },
                    fonctionnels: {
                        description: "Timechef use cookies for essential functions such as :\n • connect yourself, navigate, receive a mail, connect to servives\n • follow your cart activities and your recharges\n • use notifications, change settings, receive alerts) \n • tracks problems to help resolution"
                    }
                },
                accept: "Accept all",
                acceptTitle: "Accept cookies",
                acceptAll: "Accept all",
                save: "Save",
                saveData: "Save configuration on choices\xE9es",
                decline: "Refuse all",
                declineAll: "Refuse all",
                close: "Close",
                enabled: "Activate",
                disabled: "Disable",
                app: {
                    optOut: {
                        title: "(opt-out)",
                        description: "This  (You can deactivate)"
                    },
                    required: {
                        title: "(Always required)",
                        description: "Always required"
                    },
                    purposes: "Purposes",
                    purpose: "Purposes"
                },
                poweredBy: "",
                newWindow: "New window"
            };

        }
        public getRgpgCookie() {
            return this.getCookie(this.rgpdCookieName);
        }

    }
}

