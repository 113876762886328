module Core {
    var hours24Format = (input: string) => {
        return input;
    };

    var hours12Format = (input: string) => {
        if (!input) return "";
        var times = input.match(/(\d+)(:)(\d+)(:\d+)?/);
        if (times.length > 1) { 
            times = times.slice(1);
            times[4] = +times[0] < 12 ? ' AM' : ' PM';
            times[0] = (+times[0] % 12 || 12).toString(); 
        }
        return times.join('');
    };

    angular.module('core').filter('hours', ["localizeService", (localizeService: IS.Localization.LocalizeService) => {
        return (input: string): string => {
            var currentLanguage = localizeService.getCurrentLanguage();
            switch (currentLanguage) {
                case 'fr':
                    return hours24Format(input);
                case 'en':
                default:
                    return hours12Format(input);
            }
        };
    }]);
}