module Core {
    class ValidDateDirective implements ng.IDirective {
        restrict: string = 'A';
        require: string = 'ngModel';

        link(scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ngModel: any) {
            let validator = (ngModelValue) => {
                ngModel.$setValidity('invalid', !ngModelValue || moment(ngModelValue, 'DD/MM/YYYY').isValid());
                return ngModelValue;
            }

            ngModel.$parsers.push(validator);
        }

        static factory() {
            var directive = () => {
                return new ValidDateDirective();
            };
            return directive;
        }
    }

    angular.module('core').directive('validDate', [ValidDateDirective.factory()]);
}