module Core {
    interface IColoredSvgDirectiveScope extends ng.IScope {
        source: string;
        color: string;
        borderColor: string;
    }

    class ColoredSvgDirective implements ng.IDirective {
        restrict: string = 'AE';

        constructor(private $http: ng.IHttpService) {

        }

        scope = {
            source: '=?',
            color: '=?',
            borderColor: '=?'
        }

        link = (scope: IColoredSvgDirectiveScope, element: ng.IAugmentedJQuery): void  => {
            scope.$watch('source', () => {
                if (scope.source) {
                    this.$http.get(scope.source).success((svgString: string) => {
						var svgStartIndexOf: number = svgString.indexOf('<svg');
						var svgEndIndexOf: number = svgString.indexOf('</svg>');
						var svgSubString = svgString.substring(svgStartIndexOf, svgEndIndexOf + 6);

                        var svg = angular.element(svgSubString);
                        var allChilds = svg[0].querySelectorAll("*");
                        for (var i = 0; i < allChilds.length; i++) {
                            if (allChilds[i].hasAttribute('fill')) {
                                allChilds[i].setAttribute('fill', scope.color);
                            }
                            if (allChilds[i].hasAttribute('stroke')) {
                                allChilds[i].setAttribute('stroke', scope.borderColor);
                            }
                        }
                        svg[0].setAttribute('class', 'colored-svg');
                        element.empty().append(svg);
                    });
                }
            });
            scope.$watch('color', () => {
                var allChilds = element[0].querySelectorAll("*");
                for (var i = 0; i < allChilds.length; i++) {
                    if (allChilds[i].hasAttribute('fill')) {
                        allChilds[i].setAttribute('fill', scope.color);
                    }
                }
            });

            scope.$watch('borderColor', () => {
                var allChilds = element[0].querySelectorAll("*");
                for (var i = 0; i < allChilds.length; i++) {
                    if (allChilds[i].hasAttribute('stroke')) {
                        allChilds[i].setAttribute('stroke', scope.borderColor);
                    }
                }
            });
        };

        static factory() {
            var directive = ($http: ng.IHttpService) => {
                return new ColoredSvgDirective($http);
            };

            return directive;
        }

    }


    angular.module('core').directive('coloredSvg', ['$http', ColoredSvgDirective.factory()]);
}