module Core {
    export class SiteService {
        constructor(private $http: ng.IHttpService, private $q: ng.IQService, private configService: IS.Configuration.ConfigService) {
        }

        private searchCanceller: ng.IDeferred<any> = null;
        getSites(searchPattern: string, latitude: number, longitude: number, allowCancel : boolean, hasEntOnly: boolean = false): ng.IPromise<IRegistrationSite[]> {
            var deferred = this.$q.defer<IRegistrationSite[]>();
            var config: ng.IRequestShortcutConfig;

            if (allowCancel) {
                if (this.searchCanceller) {
                    this.searchCanceller.resolve();
                    this.searchCanceller = null;
                }

                this.searchCanceller = this.$q.defer();

                config = {timeout: this.searchCanceller.promise};
            }
            else {
                config = {};
            }


            this.$http.get<any>(`${this.configService.getConfigValue('url')}/sites?searchPattern=${searchPattern}&latitude=${latitude}&longitude=${longitude}&hasEntreprises=${hasEntOnly}`, config)
                .success((sites: IRegistrationSite[]) => {
                    deferred.resolve(sites);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }
    }

    angular.module('core').service('siteService', ['$http', '$q', 'configService', SiteService]);
}