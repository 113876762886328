module Core {
    class CultureParametresController {

        cultures: IS.Localization.ICulture[];
        error: boolean;
        networkError: boolean;

        constructor(private userService: UserService, private localizeService: IS.Localization.LocalizeService, private animationService: AnimationService) {
            this.cultures = localizeService.getAvailablesCultures();
            (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_Language_Screen', '');
        }

        isSelected(culture: IS.Localization.ICulture) {
            return culture.code === this.localizeService.getCurrentLanguage();
        }

        select(culture: IS.Localization.ICulture) {
            if (!this.isSelected(culture)) {
                this.localizeService.setLanguage(culture.code);
                var preferences = this.userService.getPreferences();
                preferences.culture = culture.code;
                this.userService.setPreferences(preferences)
                    .then(() => {
						this.error = false;
						this.networkError = false;
                        (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_Language_BTN_Change', culture.code);

                        this.userService.userInformations.culture = culture.code;
                    }, error => {
						checkNetworkError(error,
							() => {
								this.networkError = false;
								this.error = true;
							},
							() => {
								this.error = false;
								this.networkError = true;
							});
						// TODO: StatusCode=0
                    });

				this.animationService.clearAnimations();
            }
        }

    }
    angular.module('core').controller('cultureParametresController', ['userService', 'localizeService', 'animationService', CultureParametresController]);
}