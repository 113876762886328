module Core {
    export class AuthAutoLoginRequest implements IAuthRequest {
        firstName: string;
        lastName: string;
        email: string;
        timestamp: string;
        token: string;
        siteName: string;
        matricule: string;

        authType: string = "autologin";

        constructor(siteName: string, firstName: string, lastName: string, email: string, timestamp: string, token: string, matricule: string) {
            this.firstName = firstName;
            this.lastName = lastName;
            this.email = email;
            this.timestamp = timestamp;
            this.token = token;
            this.siteName = siteName;
            this.matricule = matricule;
        }
    }
}
