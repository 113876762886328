module Easypass {
    export class IBasketDTAConfiguration {
        restaurantId: number;
        lockerId: number;
        basket: BasketDTA;
        subject: Rx.BehaviorSubject<BasketDTA>;
        timeoutCanceller: ng.IPromise<void>;

        private _date: Date = null;

        constructor() {
            this._date = null;
        }

        get date(): Date {
            return this._date;
        }

        set date(value: Date) {
            this._date = value;
        }

        dtaConfig: IDinnerTakeawayConfiguration;

    }
    export class BasketDTA {

        constructor(private tvaRatePercent: number,
            private internalMaxNumberOfArticles: number,
            public items: IBasketItemDTA[]) {
            this._date = null;
        }

        private _date: Date = null;

        get date(): Date {
            return this._date;
        }

        set date(value: Date) {
            this._date = value;
        }

        get amount(): { total: number, vat: number } {
            var total: number = 0;
            var vat: number = 0;

            var vatRate = this.tvaRatePercent / 100;

            if (this.items) {
                this.items.forEach((article) => {
                    total += article.portion.price * article.quantity;
                });

                vat = total / (1 + vatRate) * vatRate;
            }

            return { total, vat };
        }

        get itemsNumber(): number {
            var nbArticles = 0;

            if (this.items) {
                this.items.forEach(item => {
                    nbArticles += item.quantity;
                });
            }

            return nbArticles;
        }

        get isFull(): boolean {
            return this.itemsNumber >= this.maxNumberOfArticles;
        }

        get maxNumberOfArticles(): number {
            return this.internalMaxNumberOfArticles;
        }

        getQuantityForPortion(articleId: number): number {
            if (this.items) {
                var item = this.items.filter(basketPortion => basketPortion.portion.articleId === articleId)[0];
                if (item) {
                    return item.quantity;
                }
            }

            return 0;
        }

        getBasketArticles(): IBasketArticleDTA[] {
            var articles: IBasketArticleDTA[] = [];

            this.items.forEach((item: IBasketItemDTA) => {
                var libelle = item.portion.articleLibelle;

                var articleIndex: number = _.findIndex(articles, (article) => { return article.libelle == libelle; });

                if (articleIndex > -1) {
                    articles[articleIndex].basketItems.push(item);
                }
                else {
                    var newBasketItems: IBasketItemDTA[] = [];
                    newBasketItems.push(item);

                    articles.push({
                        libelle: libelle,
                        basketItems: newBasketItems
                    });
                }

            });

            return articles;
        }
    }

    export interface IBasketItemDTA {
        portion: Core.IPortion;
        quantity: number;
    }

    export interface IBasketArticleDTA {
        libelle: string;
        basketItems: IBasketItemDTA[];
    }
}