module Core {
    declare var LZString: any; //
    export abstract class StorageService implements IStorageService {
        constructor(protected localStorageProvider: ILocalStorageProvider) { }

        protected restaurantsKey: string = "restaurants";
        protected animationsKey: string = "animations-{restaurantId}";
        protected menusKey: string = "menus-{restaurantId}";
        protected cartesKey: string = "cartes-{restaurantId}";
        protected userInformationsKey: string = "userInformations";
        protected tokenKey: string = "bearerToken";
        protected allergenesKey: string = "userAllergenes";
        protected restaurantOrderKey: string = "restaurantOrder";
        protected preferencesKey: string = "preferences";
        protected notificationsKey: string = "notifications";
        protected uuidKey: string = "uuid";
        protected panierKey: string = "panier";
        protected commandesKey: string = "commandes";
        protected commandesDetailsKey: string = "commandesDetails";
        protected registrationKey: string = "registration";
        protected favoriteRestaurantsKey: string = "favoriteRestaurants";
        protected hiddenRestaurantsKey: string = "hiddenRestaurants";
        protected notationKey: string = "notation";
        protected imageThematiqueGagnante: string = "imageThematiqueGagnante";
        protected imageConcept: string = "imageConcept";
        protected imageThematique: string = "imageThematique";
        protected imageSousThematique: string = "imageSousThematique";
        protected presenceConvivePopInDisplayKey: string =
            "presenceConvivePopInDisplay";
        protected presenceConviveLieuKey: string =
            "presenceConviveLieu";

        public setString(key: string, val: string): void {
            this.localStorageProvider.setString(key, val);
        }

        public getString(key: string): string {
            return this.localStorageProvider.getString(key);
        }

        public setObject(key: string, val: any): void {
            this.localStorageProvider.setObject(key, val);
        }

        public getObject(key: string): any {
            return this.localStorageProvider.getObject(key);
        }

        public removeItem(key: string): void {
            this.localStorageProvider.removeItem(key);
        }

        private convertToJsDate(str: Date): Date {
            let date: Date = moment(str).toDate();
            date.setHours(0, 0, 0, 0);
            return date;
        }

        getFavoriteRestaurants(): number[] {
            return this.getObject(this.favoriteRestaurantsKey);
        }

        saveFavoriteRestaurants(favs: number[]) {
            this.setObject(this.favoriteRestaurantsKey, favs);
        }

        saveUuid(uuid: string) {
            this.setString(this.uuidKey, uuid);
        }

        getUuid(): string {
            return this.getString(this.uuidKey);
        }

        saveAllergenes(username: string, allergenes: string[]) {
            this.setObject(this.allergenesKey + "-" + username, allergenes);
        }

        getAllergenes(username: string): string[] {
            return this.getObject(this.allergenesKey + "-" + username) || [];
        }

        saveRestaurants(restaurants: IRestaurant[]): void {
            this.setObject(this.restaurantsKey, restaurants);
        }

        getRestaurants(): IRestaurant[] {
            const restaurants: IRestaurant[] = this.getObject(this.restaurantsKey);

            if (restaurants) {
                for (let restaurant of restaurants) {
                    restaurant.menuLastUpdate = new Date(<any>restaurant.menuLastUpdate);

                    for (var jourFermeture of restaurant.joursFermetures || []) {
                        jourFermeture.dateDebut = this.convertToJsDate(jourFermeture.dateDebut);
                        jourFermeture.dateFin = this.convertToJsDate(jourFermeture.dateFin);
                    }
                }

                return restaurants;
            }

            return null;
        }

        getAnimations(restaurantId: number): IAnimation[] {
            const animations: IAnimation[] = this.getObject(
                this.animationsKey.replace("{restaurantId}", <any>restaurantId)
            );

            if (animations) {
                for (let animation of animations) {
                    animation.startAt = new Date(<any>animation.startAt);
                    animation.endAt = new Date(<any>animation.endAt);
                }

                return animations;
            }

            return null;
        }

        saveAnimations(animations: IAnimation[], restaurantId: number) {
            this.setObject(
                this.animationsKey.replace("{restaurantId}", <any>restaurantId),
                animations
            );
        }

        removeAnimations(restaurantId: number) {
            this.removeItem(
                this.animationsKey.replace("{restaurantId}", <any>restaurantId)
            );
        }

        saveMenus(menus: ITimestamped<IMenu[]>, restaurantId: number): void {
            this.setObject(
                this.menusKey.replace("{restaurantId}", <any>restaurantId),
                menus
            );
        }

        saveCarte(carte: ITimestamped<ICarte[]>, restaurantId: number): void {
            this.setObject(
                this.cartesKey.replace("{restaurantId}", <any>restaurantId),
                carte
            );
        }

        getCarte(restaurantId: number): ITimestamped<ICarte[]> {
            const cartes: ITimestamped<ICarte[]> = this.getObject(
                this.cartesKey.replace("{restaurantId}", <any>restaurantId)
            );
            if (cartes) {
                cartes.timestamp = new Date(<any>cartes.timestamp);
                cartes.value.map((c) => {
                    c.dateDebut = new Date(<any>c.dateDebut);
                    c.dateFin = new Date(<any>c.dateFin);
                });
                return cartes;
            }
            return null;
        }

        getMenus(restaurantId: number): ITimestamped<IMenu[]> {
            const menus: ITimestamped<IMenu[]> = this.getObject(
                this.menusKey.replace("{restaurantId}", <any>restaurantId)
            );
            if (menus) {
                menus.timestamp = new Date(<any>menus.timestamp);
                menus.value.map((menu) => {
                    menu.date = new Date(<any>menu.date);
                    menu.famillePlats.map((famille) => {
                        famille.plats.map((plat) => {
                            plat.date = menu.date;
                        });
                    });
                });

                return menus;
            }

            return null;
        }

        getUserInformations(): IUserInformations {
            return this.getObject(this.userInformationsKey);
        }

        saveUserInformations(user: IUserInformations) {
            this.setObject(this.userInformationsKey, user);
        }

        getPreferences(): IPreferences {
            return this.getObject(this.preferencesKey);
        }

        savePreferences(preferences: IPreferences) {
            this.setObject(this.preferencesKey, preferences);
        }

        getNotifications(): INotification[] {
            const notifications: INotification[] =
                this.getObject(this.notificationsKey) || [];
            if (notifications.length) {
                for (let notification of notifications) {
                    notification.date = new Date(<any>notification.date);
                }
            }
            return notifications;
        }

        saveRestaurantOrder(restaurantIds: number[]) {
            this.setObject(this.restaurantOrderKey, restaurantIds);
        }

        getRestaurantOrder(): number[] {
            return this.getObject(this.restaurantOrderKey);
        }

        saveNotifications(notifications: INotification[]) {
            this.setObject(this.notificationsKey, notifications);
        }

        getBearerToken(): IToken {
            return this.getObject(this.tokenKey);
        }

        saveBearerToken(token: IToken) {
            this.setObject(this.tokenKey, token);
        }

        getRegistration(): IRegistrationForm {
            let registration = <IRegistrationForm>(
                this.getObject(this.registrationKey)
            );
            if (registration) {
                registration.dateNaissance = new Date(<any>registration.dateNaissance);
            }
            return registration;
        }

        saveRegistration(registration: IRegistrationForm) {
            this.setObject(this.registrationKey, registration);
        }

        clearRegistration() {
            localStorage.removeItem(this.registrationKey);
        }

        clearStorage() {
            for (var key in localStorage) {
                if (
                    key.indexOf(this.allergenesKey) == -1 &&
                    key.indexOf(this.hiddenRestaurantsKey) == -1
                ) {
                    this.removeItem(key);
                }
            }
        }

        savePanier(panier: IPanierStorage) {
            this.setObject(this.panierKey, panier);
        }

        getPanier(): IPanierStorage {
            return this.getObject(this.panierKey);
        }

        deletePanier() {
            this.setString(this.panierKey, null);
        }

        saveCommandes(commandes: Core.ICommandeListItem[]) {
            this.setObject(this.commandesKey, commandes);
        }

        getCommandes(): Core.ICommandeListItem[] {
            return this.getObject(this.commandesKey);
        }

        saveCommandesDetails(commandes: Core.ICommandeDetail[]) {
            this.setObject(this.commandesDetailsKey, commandes);
        }

        getCommandesDetails(): Core.ICommandeDetail[] {
            return this.getObject(this.commandesDetailsKey);
        }

        deleteCommandesDetails() {
            this.removeItem(this.commandesDetailsKey);
        }

        getHiddenRestaurants(email: string): number[] {
            return this.getObject(this.hiddenRestaurantsKey + "-" + email);
        }

        setHiddenRestaurants(ids: number[], email: string) {
            this.setObject(this.hiddenRestaurantsKey + "-" + email, ids);
        }

        getNotationConfig(): Core.INotationConfig {
            return (
                this.getObject(this.notationKey) ||
                <Core.INotationConfig>{
                    version: appVersion,
                    enabled: true,
                    rated: false,
                    countdown: 0,
                }
            );
        }

        setNotationConfig(notation: Core.INotationConfig) {
            this.setObject(this.notationKey, notation);
        }

        saveImageThematiqueGagnante(restaurantId: number, imageId: number) {
            this.setObject(
                this.imageThematiqueGagnante + "_" + restaurantId,
                imageId
            );
        }

        getImageThematiqueGagnante(restaurantId: number): number {
            return this.getObject(this.imageThematiqueGagnante + "_" + restaurantId);
        }

        saveImageConcept(imageId: number, conceptId: number = 1) {
            this.setObject(this.imageConcept, imageId);
        }

        getImageConcept(conceptId: number = 1): number {
            return this.getObject(this.imageConcept);
        }

        saveImageThematique(thematiqueId: number, imageId: number) {
            this.setObject(this.imageThematique + "_" + thematiqueId, imageId);
        }

        getImageThematique(thematiqueId: number): number {
            return this.getObject(this.imageThematique + "_" + thematiqueId);
        }

        saveImageSousThematique(
            restaurantId: number,
            sousThematiqueId: number,
            imageId: number
        ) {
            this.setObject(
                this.imageSousThematique + "_" + restaurantId + "_" + sousThematiqueId,
                imageId
            );
        }

        getImageSousThematique(
            restaurantId: number,
            sousThematiqueId: number
        ): number {
            return this.getObject(
                this.imageSousThematique + "_" + restaurantId + "_" + sousThematiqueId
            );
        }

        savePresenceConvivePopInDisplay(displayIt: boolean) {
            this.setObject(this.presenceConvivePopInDisplayKey, displayIt);
        }

        getPresenceConvivePopInDisplay(): boolean {
            return this.getObject(this.presenceConvivePopInDisplayKey);
        }

    }
}
