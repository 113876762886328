module IS.Configuration {
  export class ConfigService {
    private config: any = null;

    constructor(
      private $rootScope: ng.IRootScopeService,
      private $http: ng.IHttpService,
      private $q: ng.IQService,
      private $window: ng.IWindowService,
      private configBaseUrl: string,
      private configFile: string,
      private configKeyNotFound: string,
      private configObject: any //if defined its bypass config file fetch
    ) {

      if (this.configObject) {
        //set config (and bypass config file fetch)
        this.loadedConfigFilesSuccessCallback(this.configObject);
      }
    }

    private buildUrl(): string {
      return this.configBaseUrl + this.configFile;
    }

    private loadedConfigFilesSuccessCallback(data: any) {
      this.config = data;

      this.$rootScope.$broadcast('configUpdated');
    }

    initializeConfigFiles() {
      if (this.config) {
        return this.$q.resolve(this.config);
      }

      const url = this.buildUrl();
      var deferred = this.$q.defer();
      this.$http
        .get(url, { cache: false })
        .success(data => {
          this.loadedConfigFilesSuccessCallback(data);
          deferred.resolve(data);
        })
        .error(error => {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    getConfigValue(key: string) {
      if (!this.config) {
        console.log(
          "****** Attention ! : Config vide n'est pas encore chargée! (key:" +
          key +
          ') *******'
        );
        return '';
      }

      const configValue = this.config[key];
      return configValue === null || typeof configValue === 'undefined'
        ? this.configKeyNotFound.replace('{key}', key)
        : configValue;
    }

    getConfigValueAsync(key: string): ng.IPromise<any> {
      var deferred = this.$q.defer();
      if (this.config) {
        deferred.resolve(this.getConfigValue(key));
      } else {
        console.log(
          '****** Retry ! : Essayer de récupérer la config en asynchrone *******'
        );
        const url = this.buildUrl();

        this.$http
          .get(url, { cache: false })
          .success(data => {
            this.loadedConfigFilesSuccessCallback(data);
            deferred.resolve(this.getConfigValue(key));
          })
          .error(error => {
            deferred.reject(error);
          });
      }

      return deferred.promise;
    }
  }

  angular
    .module('configuration')
    .service('configService', [
      '$rootScope',
      '$http',
      '$q',
      '$window',
      'configBaseUrl',
      'configFile',
      'configKeyNotFound',
      'configObject',
      ConfigService
    ]);
}
