module Easypass {
  export class PresenceConviveService {
    presences: IPresenceConvive[];
    apiUrl: string;
    constructor(
      private $http: ng.IHttpService,
      private configService: IS.Configuration.ConfigService,
      private $q: ng.IQService
    ) {
      this.apiUrl = this.configService.getConfigValue("url");
    }

    // Récupérer les presences sur une période
    getPresencesConvive(
      start: string,
      end: string
    ): ng.IPromise<IPresenceConvive[]> {
      var deferred = this.$q.defer<IPresenceConvive[]>();
      this.$http
        .get<IPresenceConvive[]>(`${this.apiUrl}/PresenceConvive/GetPresences/${start}/${end}`)
        .success((presences: IPresenceConvive[]) => {
          this.presences = presences;
          deferred.resolve(this.presences);
        })
        .error((error) => {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    // Récupérer les lieux de présence si nécessaire 
    getPresencesLieu(askPresenceLieu: boolean): ng.IPromise<IPresenceLieu[]> {
      var deferred = this.$q.defer<IPresenceLieu[]>();

      if (askPresenceLieu) {
      this.$http
        .get<IPresenceLieu[]>(`${this.apiUrl}/PresenceConvive/GetPresencesLieu`)
        .success((presencesLieu: IPresenceLieu[]) => {
          deferred.resolve(presencesLieu);
        })
        .error((error) => {
          deferred.reject(error);
        });
      }
      else {
        deferred.resolve();
      }
      return deferred.promise;
    }

    // Récupérer les presences sur une période
    getCreneauxPresencesDisponibles(
      start: string,
      end: string,
      lieuId: number
    ): ng.IPromise<ICreneauHoraire[]> {
      var deferred = this.$q.defer<ICreneauHoraire[]>();
      this.$http
        .get<ICreneauHoraire[]>(`${this.apiUrl}/PresenceConvive/GetCreneaux/${start}/${end}/${lieuId}`)
        .success((creneaux) => {
          creneaux.forEach((x) => {
            x.resume = x.label;
            if (x.placesDisponibles)
              x.resume += " (reste " + x.placesDisponibles + ")";
          });   
          deferred.resolve(creneaux);
        })
        .error((error) => {
          deferred.reject(error);
        });
      return deferred.promise;
    }
    getPresenceLieuDatesNonDispo(
      start: string,
      end: string,
      lieuId: number
    ): ng.IPromise<Date[]> {
      var deferred = this.$q.defer<Date[]>();
      this.$http
        .get<Date[]>(`${this.apiUrl}/PresenceConvive/GetPresencesLieuConvive/${lieuId}/${start}/${end}`)
        .success((datesNonDispo) => {
          deferred.resolve(datesNonDispo);
        })
        .error((error) => {
          deferred.reject(error);
        });
      return deferred.promise;
    }
    getPresencesEventType(
      start: string,
      end: string
    ): ng.IPromise<IPresenceEventType[]> {
      var deferred = this.$q.defer<IPresenceEventType[]>();
      this.$http
        .get<IPresenceEventType[]>(`${this.apiUrl}/PresenceConvive/GetPresencesEventType/${start}/${end}`)
        .success((eventsType) => {
          deferred.resolve(eventsType);
        })
        .error((error) => {
          deferred.reject(error);
        });
      return deferred.promise;
    }
    // Enregistrer une presence
    savePresence(
      start: Date,
      end: Date,
      presence: PresenceType,
      selectedHour: string,
      selectedPresenceLieuId?: number,
      selectedPresenceEventTypeId?: number
    ): ng.IPromise<IPresencePeriodConvive> {
      var presencePeriodConvive: IPresencePeriodConvive = {
        start: start,
        end: end,
        presence: presence, 
        selectedHour : selectedHour, 
        selectedPresenceLieuId : selectedPresenceLieuId,
        selectedPresenceEventTypeId: selectedPresenceEventTypeId
      };

      var deferred = this.$q.defer<IPresencePeriodConvive>();
      this.$http
        .post<IPresencePeriodConvive>(
          `${this.configService.getConfigValue(
            "url"
          )}/PresenceConvive/SavePresence`,
          presencePeriodConvive
        )
        .success((presenceConvive: IPresencePeriodConvive) => {
          deferred.resolve(presenceConvive);
        })
        .catch((error) => {
          deferred.reject(error);
        });
      return deferred.promise;
    }

    public initHourSteps(
      startHour: Date,
      endHour: Date,
      step: number, 
      culture: string
    ): ICreneauHoraire[] {
      const steps: ICreneauHoraire[] = [];      
  
        this.GetSteps(
          new Date(),
          step,
          steps,
          startHour.toString(),
          endHour.toString(), 
          culture
        );
      
  
      return steps;
    }

    private GetSteps(
      currentDate: Date,
      step: number,
      steps: ICreneauHoraire[],
      start: string,
      end: string,
      culture: string
    ) {
      if (steps && start && end) {
        const openAt: Date = new Date(currentDate.toDateString());
        const closeAt: Date = new Date(currentDate.toDateString());
  
        const openAtStrTab = start.split(':');
        const closeAtStrTab = end.split(':');
  
        openAt.setMinutes(+openAtStrTab[1]);
        openAt.setHours(+openAtStrTab[0]);
  
        closeAt.setMinutes(+closeAtStrTab[1]);
        closeAt.setHours(+closeAtStrTab[0]);
  
        const currentStep = moment(openAt);
  
        while (currentStep.toDate() < closeAt) {
          const date = currentStep.clone();
          date.second(0);
          const time = date.toDate().getTime();    
          currentStep.minute(currentStep.minutes() + step);
          let stepValue = date
            .toDate()
            .toLocaleTimeString('fr', { // la culture n'est pas à jour !!!
              hour: '2-digit',
              minute: '2-digit'
            });
          let nextStepValue = currentStep
            .toDate()
            .toLocaleTimeString('fr', { // la culture n'est pas à jour !!!
              hour: '2-digit',
              minute: '2-digit'
            });

          let stepObject: ICreneauHoraire = { id: stepValue, label: (stepValue + " - " + nextStepValue), resume: (stepValue + " - " + nextStepValue), placesDisponibles: 0 };
          steps.push(stepObject);
        }

        console.log(steps);

      }
    }

  }

  angular
    .module("easypass")
    .service("presenceConviveService", [
      "$http",
      "configService",
      "$q",
      "$rootScope",
      PresenceConviveService
    ]);
}
