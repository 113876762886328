module Core {
    export class UserService {
        phoneNumberMinLength: number = 8;
        phoneNumberMaxlength: number = 25;

        userInformations: IUserInformations;
        notifications: INotification[] = [];
        private authToken: IToken;
        private defaultTheme: ITheme;
        private preferences: IPreferences;

        private soldeChangedSubject: Rx.BehaviorSubject<ISoldeInfos>;
        soldeChanged: Rx.Observable<ISoldeInfos>;

        private userInfosChangedSubject: Rx.Subject<IUserInformations> = new Rx.Subject<IUserInformations>();
        userInfosChanged: Rx.Observable<IUserInformations> = this.userInfosChangedSubject.asObservable();

        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $rootScope: ng.IRootScopeService,
            private configService: IS.Configuration.ConfigService,
            private localizeService: IS.Localization.LocalizeService,
            private authenticationService: AuthenticationService,
            private storageService: StorageService,
            private pushManagerService: IPushManagerService,
            private helperService: Core.HelperService,
            public themes: ITheme[]) {

            this.userInformations = this.storageService.getUserInformations();

            this.soldeChangedSubject = new Rx.BehaviorSubject<ISoldeInfos>(null);
            this.soldeChanged = this.soldeChangedSubject.asObservable();
            this.raiseSolde(true);

            this.defaultTheme = this.themes[0];
            this.authToken = this.storageService.getBearerToken();
            if (!!this.authToken) {
                (<any>this.$http.defaults.headers.common)['Authorization'] = `Bearer ${this.authToken.accessToken}`;

                this.$rootScope.$on('configUpdated', () => {
                    this.getUserInformations(true);
                    this.refreshSolde();
                });
            }

            this.$rootScope.$broadcast('connectionStateChanged');
            this.preferences = this.storageService.getPreferences();
            this.getPreferences();

            this.notifications = this.storageService.getNotifications();
            this.$rootScope.$broadcast('notificationsUpdated');
            this.getNotifications();
            // 2024-03-22 : Remove to reduce call to api 
            // setInterval(() => this.getNotifications(), 60 * 2 * 1000);
        }

        signInInternal(sitename: string, refreshToken: string): ng.IPromise<IUserInformations> {
            var deferred = this.$q.defer<IUserInformations>();

            if (this.isAuthenticated) {
                this.signOut();
            }

            this.authenticationService.renewToken(refreshToken).then((authToken) => {

                (<any>this.$http.defaults.headers.common)['Authorization'] = `Bearer ${authToken.accessToken}`;
                this.authToken = authToken;
                this.storageService.saveBearerToken(authToken);

                this.getUserInformations(true, sitename).then((userInformations) => {
                    this.userInformations = userInformations;
                    var preferences = this.getPreferences();
                    this.userInformations.culture = preferences.culture;
                    (<any>window).DataAnalyticsSender.setUserAttribute(this.userInformations);

                    deferred.resolve(userInformations);
                    this.$rootScope.$broadcast('connectionStateChanged');
                    this.getNotifications();
                });

            }).catch((response) => {
                deferred.reject(response);
            });

            return deferred.promise;
        }

        signIn(signInRequest: IAuthRequest, scope: string): ng.IPromise<IUserInformations> {
            var deferred = this.$q.defer<IUserInformations>();
            (<any>this.$http.defaults.headers.common)['Authorization'] = ``;
            this.authenticationService.getToken(signInRequest, scope).then((authToken: IToken) => {
                (<any>this.$http.defaults.headers.common)['Authorization'] = `Bearer ${authToken.accessToken}`;
                this.authToken = authToken;

                this.storageService.saveBearerToken(authToken);

                this.getUserInformations(true).then((userInformations) => {
                    this.userInformations = userInformations;
                    var preferences = this.getPreferences();
                    this.userInformations.culture = preferences.culture;
                    (<any>window).DataAnalyticsSender.setUserAttribute(this.userInformations);

                    deferred.resolve(userInformations);
                    this.$rootScope.$broadcast('connectionStateChanged');

                    this.getNotifications();
                });
            }).catch((response) => {
                deferred.reject(response);
            });
            return deferred.promise;
        }

        signOut(): void {

            if (this.userInformations) {
                console.log(`Signout ${this.userInformations.email}`);
            }
            else {
                console.log('Signout');
            }
            if (this.userInformations
                && (this.anyNotificationEnabled(this.userInformations.pushParameters))
            ) {
                this.pushManagerService.unregisterPushInformation();
            }

            this.storageService.clearStorage();
            this.authToken = null;
            this.userInformations = null;
            this.notifications = null;
            (<any>this.$http.defaults.headers.common)['Authorization'] = null;
            this.$rootScope.$broadcast('connectionStateChanged');
            this.$rootScope.$broadcast('clearUserInMemoryData');
            console.log('Signout ended');
        }

        private refreshSignInDeferred: ng.IDeferred<IToken>[];
        refreshSignIn(): ng.IPromise<IToken> {
            var deferred = this.$q.defer<IToken>();

            // Les demandes de refresh sont toutes empilées et resolues lors de la première réponse
            // TODO Faire plutot un appel "débouncé" ?
            if (!this.refreshSignInDeferred || this.refreshSignInDeferred.length === 0) {
                this.refreshSignInDeferred = [];

                const fail = (reason: any) => {
                    for (var i = 0; i < this.refreshSignInDeferred.length; i++) {
                        this.refreshSignInDeferred[i].reject(reason);
                    }
                };

                //il arrive que le token soit null
                if (this.authToken != null) {
                    this.authenticationService.renewToken(this.authToken.refreshToken).then((authToken) => {
                        (<any>this.$http.defaults.headers.common)['Authorization'] = `Bearer ${authToken.accessToken}`;
                        this.authToken = authToken;
                        this.storageService.saveBearerToken(authToken);

                        for (var i = 0; i < this.refreshSignInDeferred.length; i++) {
                            this.refreshSignInDeferred[i].resolve(authToken);
                        }
                    }).catch((response) => {
                        fail(response);
                    }).finally(() => {
                        this.refreshSignInDeferred = null;
                    });
                } else {
                    fail('authToken is missing');
                }
            }

            this.refreshSignInDeferred.push(deferred);

            return deferred.promise;
        }

        register(registration: IRegistration, enforceNumeroBadge: string): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();

            var uri = `${this.configService.getConfigValue('url')}/${registration.siteName}/registration`;
            if (!!enforceNumeroBadge) {
                uri = uri + '/' + enforceNumeroBadge;
            }

            this.$http.post<any>(uri, registration).then(() => {
                deferred.resolve();
            }, (response: ng.IHttpPromiseCallbackArg<any>) => {
                var result: IErrorMessage<any> = { code: response.status, message: response.statusText, data: response.data };
                deferred.reject(result);
            });

            return deferred.promise;
        }

        unregister(): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();
            var uri = `${this.configService.getConfigValue('url')}/${this.userInformations.currentSite.name}/registration/unregister`;

            this.$http.post<any>(uri, null).then(() => {
                this.signOut();
                deferred.resolve();
            }, (response: ng.IHttpPromiseCallbackArg<any>) => {
                var result: IErrorMessage<any> = { code: response.status, message: response.statusText, data: response.data };
                deferred.reject(result);
            });

            return deferred.promise;
        }

        isAuthenticated(): boolean {
            return !!this.authToken && !!this.userInformations;
        }

        getUserInformations(forceUpdate: boolean = false, sitename: string = undefined): ng.IPromise<IUserInformations> {
            var deferred = this.$q.defer<IUserInformations>();
            if (!this.authToken) {
                deferred.reject();
            } else if (!forceUpdate && this.userInformations && this.ensureUserInformationsIsUpToDate()) {
                deferred.resolve(this.userInformations);
            } else {
                this.$http.get<IUserInformations>(`${this.configService.getConfigValue('tokenUrl')}/me`).success((response) => {
                    this.userInformations = response;

                    if (sitename) {
                        this.userInformations.currentSite = _.find(this.userInformations.sites, (site) => site.name == sitename);
                    }
                    else {
                        this.userInformations.currentSite = response.sites && response.sites[0];
                    }
                    var pref = this.storageService.getPreferences() || <IPreferences>{};
                    pref.culture = this.userInformations.culture;
                    this.storageService.savePreferences(pref);
                    if (this.userInformations.culture) {
                        this.localizeService.setLanguage(this.userInformations.culture);
                    }

                    this.storageService.saveUserInformations(this.userInformations);

                    if (this.anyNotificationEnabled(response.pushParameters)) {
                        this.pushManagerService.registerPushInformation();
                    }

                    this.getQrCode();

                    this.userInfosChangedSubject.onNext(this.userInformations);
                    deferred.resolve(this.userInformations);
                }).catch(error => {
                    deferred.reject(error);
                });
            }

            return deferred.promise;
        }

        ensureUserInformationsIsUpToDate(): boolean {
            var isUpToDate = true;

            if (this.userInformations.currentSite.restaurantsDisplayed == undefined) {
                isUpToDate = false;
            }

            return isUpToDate;
        }

        getCurrentTheme(): ITheme {
            return _.find(this.themes, theme => theme.id === this.preferences.theme) || this.defaultTheme;
        }

        getPreferences(): IPreferences {
            const defaultPreferences: IPreferences = {
                culture: this.localizeService.getCurrentLanguage(),
                theme: this.defaultTheme.id
            };
            if (!this.preferences) {
                this.preferences = defaultPreferences;
                this.storageService.savePreferences(this.preferences);
                this.$rootScope.$broadcast('preferencesChanged');
            } else if (_.some(_.keys(defaultPreferences), key => !(<any>this.preferences)[key])) {
                _.defaults(this.preferences, defaultPreferences);
                this.$rootScope.$broadcast('preferencesChanged');
            }
            return this.preferences;
        }

        setPreferences(preferences: IPreferences): ng.IPromise<void> {
            this.preferences = preferences;
            if (!this.preferences.theme) {
                this.preferences.theme = this.defaultTheme.id;
            }
            this.storageService.savePreferences(this.preferences);

            this.$rootScope.$broadcast('preferencesChanged');

            var deferred = this.$q.defer<void>();

            var uri = `${this.configService.getConfigValue('url')}/convive/preferences`;

            this.$http.post<any>(uri, this.preferences).then(() => {
                deferred.resolve();

                this.getNotificationParameters("push").then((param) => {
                    if (this.anyNotificationEnabled(param)) {
                        this.pushManagerService.registerPushInformation();
                        this.pushManagerService.activateAllPushDevicesInformation();
                    }
                    else {
                        this.pushManagerService.deactivateAllPushDevicesInformation();
                    }
                });
            }, (response: ng.IHttpPromiseCallbackArg<any>) => {
                var result: IErrorMessage<any> = { code: response.status, message: response.statusText, data: response.data };
                deferred.reject(result);
            });

            return deferred.promise;
        }

        updateNotificationParametersForProvider(flag: string, provider: string, enable: boolean): ng.IPromise<INotificationParameters> {
            var deferred = this.$q.defer<INotificationParameters>();
            this.$http.put<INotificationParameters>(`${this.configService.getConfigValue('url')}/convive/notification/parameters/${provider}`, {
                flag, enable
            }).success((notificationParameters) => {
                deferred.resolve(notificationParameters);
                switch (provider) {
                    case "sms":
                        this.userInformations.smsParameters = notificationParameters;
                        break;
                    case "email":
                        this.userInformations.emailParameters = notificationParameters;
                        break;
                    case "push":
                        this.userInformations.pushParameters = notificationParameters;
                        if (this.anyNotificationEnabled(notificationParameters)) {
                            this.pushManagerService.registerPushInformation();
                            this.pushManagerService.activateAllPushDevicesInformation();
                        }
                        else {
                            this.pushManagerService.deactivateAllPushDevicesInformation();
                        }
                        break;
                }

                this.storageService.saveUserInformations(this.userInformations);
            }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        private applyNotificationParametersToUserInformations(parameters: { category: string, type: string, enable: boolean }[]) {
            var applyNotificationParameterOnCategory = (parameters: INotificationParameters, category: string, enable: boolean) => {
                switch (category) {
                    case "animation":
                        parameters.isAnimationEnable = enable;
                        break;
                    case "alerteSolde":
                        parameters.isAlerteSoldeEnable = enable;
                        break;
                    case "paiement":
                        parameters.isPaiementEnable = enable;
                        break;
                    case "dinertakeaway":
                        parameters.isDinerTakeAwayEnable = enable;
                        break;
                    case "enquete":
                        parameters.isEnqueteEnable = enable;
                        break;
                    case "resultatVote":
                        parameters.isResultatVoteEnable = enable;
                        break;
                    case "commande":
                        parameters.isCommandeEnable = enable;
                        break;
                }
            };

            parameters.forEach(p => {
                switch (p.type) {
                    case "sms":
                        applyNotificationParameterOnCategory(this.userInformations.smsParameters, p.category, p.enable);
                        break;
                    case "email":
                        applyNotificationParameterOnCategory(this.userInformations.emailParameters, p.category, p.enable);
                        break;
                    case "push":
                        applyNotificationParameterOnCategory(this.userInformations.pushParameters, p.category, p.enable);
                        break;
                }
            });
        }

        updateNotificationParameters(parameters: { category: string, type: string, enable: boolean }[]): ng.IPromise<INotificationParameters> {
            if (!this.userInformations.phoneNumber && parameters.some(n => n.enable && n.type === 'sms')) {
                return this.$q.reject({ reason: 'PhoneNotDefined' });
            }

            var deferred = this.$q.defer<INotificationParameters>();
            this.$http.post<INotificationParameters>(`${this.configService.getConfigValue('url')}/convive/notification/parameters`, parameters).success(() => {
                deferred.resolve();

                this.applyNotificationParametersToUserInformations(parameters);
                this.storageService.saveUserInformations(this.userInformations);

                if (this.anyNotificationEnabled(this.userInformations.pushParameters)) {
                    this.pushManagerService.registerPushInformation();
                    this.pushManagerService.activateAllPushDevicesInformation();
                }
                else {
                    this.pushManagerService.deactivateAllPushDevicesInformation();
                }

            }).catch(error => deferred.reject(error));

            return deferred.promise;
        }

        getNotificationParameters(provider: string): ng.IPromise<INotificationParameters> {
            var deferred = this.$q.defer<INotificationParameters>();
            this.$http.get<INotificationParameters>(`${this.configService.getConfigValue('url')}/convive/notification/parameters/${provider}`).success((notificationParameters) => {

                switch (provider) {
                    case "sms":
                        this.userInformations.smsParameters = notificationParameters;
                        break;
                    case "email":
                        this.userInformations.emailParameters = notificationParameters;
                        break;
                    case "push":
                        this.userInformations.pushParameters = notificationParameters;
                        break;
                }

                this.storageService.saveUserInformations(this.userInformations);
                deferred.resolve(notificationParameters);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        getNotificationParametersForType(notificationType: string): ng.IPromise<INotificationByTypeParameters> {
            var deferred = this.$q.defer<INotificationByTypeParameters>();
            this.$http.get<INotificationByTypeParameters>(`${this.configService.getConfigValue('url')}/convive/notification/parameters/type/${notificationType}`).success((notificationParameters) => {
                deferred.resolve(notificationParameters);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        updatePassword(password: string, newPassword: string): ng.IPromise<{}> {
            var defered = this.$q.defer();
            this.$http.put(`${this.configService.getConfigValue('url')}/user/password`, {
                oldPassword: password,
                newPassword: newPassword
            }).success((authToken: IToken) => {
                (<any>this.$http.defaults.headers.common)['Authorization'] = `Bearer ${authToken.accessToken}`;
                this.authToken = authToken;
                this.getNotificationParameters("push").then((param) => {
                    if (this.anyNotificationEnabled(param)) {
                        this.pushManagerService.registerPushInformation();
                    }
                });
                this.storageService.saveBearerToken(authToken);
                defered.resolve();
            }).catch(error => {
                defered.reject(error);
            });

            return defered.promise;
        }

        updatePhone(phone: string): ng.IPromise<{}> {
            var defered = this.$q.defer();

            if (phone == null) {
                phone = '';
            }

            this.$http.put(`${this.configService.getConfigValue('url')}/user/phone`, {
                phone
            }).success(() => {
                this.userInformations.phoneNumber = phone;

                // reset sms config if no phone number is set
                if (phone == '') {
                    this.setNotificationParametersState('sms', false);
                }

                this.storageService.saveUserInformations(this.userInformations);
                defered.resolve();
            }).catch(error => defered.reject(error));

            return defered.promise;
        }

        updateEmail(password: string, newEmail: string): ng.IPromise<{}> {
            var defered = this.$q.defer();
            this.$http.put(`${this.configService.getConfigValue('url')}/user/email`, {
                password: password,
                newEmail: newEmail
            }).success((authToken: IToken) => {

                (<any>this.$http.defaults.headers.common)['Authorization'] = `Bearer ${authToken.accessToken}`;
                this.authToken = authToken;
                this.storageService.saveBearerToken(authToken);

                // Refresh user informations
                this.getUserInformations(true).then(() => console.log('User informations refresh'));

                defered.resolve();
            }).catch(error => {
                defered.reject(error);
            });

            return defered.promise;
        }

        getNotifications(): ng.IPromise<INotification[]> {
            var deferred = this.$q.defer<INotification[]>();
            if (!this.isAuthenticated()) {
                deferred.reject('user not log in');
            } else {
                if (this.configService.getConfigValue('url') != '') {
                    this.$http.get<any[]>(`${this.configService.getConfigValue('url')}/notifications`).success((response) => {
                        this.notifications = response.map((notification) => {
                            var existingNotification = _.find(this.notifications, n => n.id === notification.id);
                            var link = '';
                            var picto = '';
                            switch (notification.type) {
                                case NotificationType.Animation:
                                    link = `animationOfNotificationWithBack({ restaurantAnimationId: ${notification.referenceId} })`;
                                    picto = 'animation';
                                    break;
                                case NotificationType.Sondage:
                                    link = `enquete({id: ${notification.referenceId}})`;
                                    picto = 'sondage';
                                    break;
                                case NotificationType.AlerteSolde:
                                    link = 'credit';
                                    picto = 'solde';
                                    break;
                                case NotificationType.Paiement:
                                    if (notification.commandeId) {
                                        link = `tc-commandes()`;
                                        picto = 'solde';
                                    }
                                    else {
                                        link = `tickets`;
                                        picto = 'solde';
                                    }
                                    break;
                                case NotificationType.Ticket:
                                    link = `tickets`;
                                    picto = 'solde';
                                    break;
                            }
                            return <INotification>{
                                id: notification.id,
                                type: notification.type,
                                referenceId: notification.referenceId,
                                date: new Date(notification.date),
                                title: notification.title,
                                description: notification.description,
                                lue: existingNotification && existingNotification.lue || notification.lue,

                                link: link,
                                pictoUrl: `./styles/assets/pictos/notifications/${picto}.svg`
                            };
                        });
                        deferred.resolve(this.notifications);
                        this.storageService.saveNotifications(this.notifications);
                        this.$rootScope.$broadcast('notificationsUpdated');
                    }).error(error => {
                        deferred.reject(error);
                    });
                }
            }
            return deferred.promise;
        }

        refreshSolde(): ng.IPromise<number> {
            let deferred: ng.IDeferred<number> = this.$q.defer<number>();

            this.getSoldeFromServer().then(newSolde => {
                if (this.userInformations != null) {
                    this.userInformations.currentSite.solde = newSolde;
                    this.storageService.saveUserInformations(this.userInformations);
                }
                this.raiseSolde();
                deferred.resolve(newSolde);
            }).catch(error => {
                this.raiseSoldeError();
                deferred.reject();
            });

            return deferred.promise;
        }

        setCurrentSite(sitename: string): void {
            const site = _.find(this.userInformations.sites, (site) => site.name == sitename);

            if (site.tcProUrl) {
                const refreshToken = this.authToken.refreshToken;
                const sitename = site.name;
                const tcProUrl = site.tcProUrl;

                this.signOut();
                location.href = `${tcProUrl}/#/login?site=${sitename}&refreshToken=${refreshToken}`;
            }
        }

        private getSoldeFromServer(): ng.IPromise<number> {

            let deferred = this.$q.defer<number>();

            // pas besoin de récupérer ce qui n'existe pas...
            if (this.helperService.isTcPro()) {
                deferred.reject('no solde available in TC PRO');
            }
            else if (!this.isAuthenticated()) {
                deferred.reject('user is not logged in');
            }
            else if (!this.userInformations.currentSite.hasCaisse) {
                deferred.reject('there is no caisse in this site');
            }
            else if (!this.userInformations.currentSite.isRegisteredInCaisse) {
                deferred.reject('user not registered in caisse');
            }
            else {
                const siteName = this.userInformations.currentSite.name;

                this.$http.get(`${this.configService.getConfigValue('url')}/convive/${siteName}/solde`).success((response: { solde: number }) => {
                    deferred.resolve(response.solde);
                }).error(error => {
                    deferred.reject(error);
                });
            }

            return deferred.promise;
        }
        private raiseSolde(dirty: boolean = false) {
            let solde: number = null;
            let alert: boolean = null;

            if (this.userInformations != null) {
                let site: ISiteInformation = this.userInformations.currentSite;
                solde = site.solde;
                alert = (site.alerteSurSolde && solde <= site.montantAlerteSolde) || solde < 0;
            }

            let soldeInfos: ISoldeInfos = {
                solde: solde,
                dirty: dirty,
                alert: alert
            };

            this.soldeChangedSubject.onNext(soldeInfos);
        }
        private raiseSoldeError() {
            this.soldeChangedSubject.onNext(null);
        }

        getQrCode(): ng.IPromise<string> {
            var defered = this.$q.defer<string>();
            if (!this.isAuthenticated() || !this.userInformations.currentSite.hasQrCode) {
                defered.reject();
            } else {
                // if (!!this.userInformations.qrcode) {
                //     defered.resolve(this.userInformations.qrcode);
                // } else {
                    this.$http.get(`${this.configService.getConfigValue('url')}/convive/${this.userInformations.currentSite.name}/qrcode`, { responseType: "blob" }).success((data: Blob) => {
                        var reader = new FileReader();
                        reader.onloadend = () => {
                            if (this.isAuthenticated()) {
                                this.userInformations.qrcode = reader.result as string;
                                this.storageService.saveUserInformations(this.userInformations);
                                defered.resolve(this.userInformations.qrcode);
                            }

                            else {
                                defered.reject();
                            }
                        };
                        reader.readAsDataURL(data);
                    }).error(error => {
                        defered.reject(error);
                    });
               
               // }
            }
            return defered.promise;
        }

        setNotificationState(notificationId: number, read: boolean) {
            var notification = _.find(this.notifications, notification => notification.id === notificationId);
            if (notification) {
                notification.lue = read;
                this.storageService.saveNotifications(this.notifications);
                this.$http.post<any>(`${this.configService.getConfigValue('url')}/notifications/${notification.id}/read`, read);
                this.$rootScope.$broadcast('notificationsUpdated');
            }
        }

        deleteNotification(notificationId: number): ng.IPromise<{}> {
            var deferred = this.$q.defer();
            var notification = _.find(this.notifications, notification => notification.id === notificationId);
            if (notification) {
                _.remove(this.notifications, (notif) => notif.id == notificationId);
                this.storageService.saveNotifications(this.notifications);
                this.$http.post<any>(`${this.configService.getConfigValue('url')}/notifications/${notification.id}/delete`, null).then(() => deferred.resolve());
                this.$rootScope.$broadcast('notificationsUpdated');
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        }

        setNotificationParametersState(provider: string, state: boolean) {
            var parameters: INotificationParameters = null;

            switch (provider) {
                case "sms":
                    parameters = this.userInformations.smsParameters;
                    break;

                case "email":
                    parameters = this.userInformations.emailParameters;
                    break;

                case "push":
                    parameters = this.userInformations.pushParameters;
                    break;
            }

            if (parameters != null) {
                parameters.isAlerteSoldeEnable = state;
                parameters.isAnimationEnable = state;
                parameters.isEnqueteEnable = state;
                parameters.isPaiementEnable = state;
                parameters.isDinerTakeAwayEnable = state;
                parameters.isCommandeEnable = state;
            }
        }

        isPhoneNumberValid(phoneNumber: string): boolean {
            // null ou vide => OK
            if (!phoneNumber || phoneNumber == '') {
                return true;
            }

            // longueur entre min et max => OK
            if (this.phoneNumberMinLength <= phoneNumber.length && phoneNumber.length <= this.phoneNumberMaxlength) {
                return true;
            }

            return false;
        }


        getAlerteSoldeParameter(): ng.IPromise<IAlerteSoldeParameter> {
            var deferred = this.$q.defer<IAlerteSoldeParameter>();

            const siteName = this.userInformations.currentSite.name;

            this.$http.get<IAlerteSoldeParameter>(`${this.configService.getConfigValue('url')}/convive/${siteName}/alerte-solde`).success((response) => {
                deferred.resolve(response);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        updateAlerteSoldeMontant(montant: number): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();
            this.$http.post<any>(`${this.configService.getConfigValue('url')}/convive/update-alerte-solde/${montant}`, montant)
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });
            return deferred.promise;
        }

        /* Moyens Paiement */
        getMoyenPaiements(): ng.IPromise<IConviveMoyenPaiement[]> {
            var deferred = this.$q.defer<IConviveMoyenPaiement[]>();
            this.$http.get<IConviveMoyenPaiement[]>(`${this.configService.getConfigValue('url')}/convive/moyen-paiements`).success((moyenPaiements) => {
                deferred.resolve(moyenPaiements);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        deleteMoyenPaiements(conviveMoyenPaiement: IConviveMoyenPaiement): ng.IPromise<{}> {
            var deferred = this.$q.defer();
            this.$http.delete(`${this.configService.getConfigValue('url')}/convive/moyen-paiements/${conviveMoyenPaiement.id}`)
                .success(() => {
                    deferred.resolve();
                }).error(error => {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        private anyNotificationEnabled(parameters :INotificationParameters) : boolean {
            return parameters.isAnimationEnable
                || parameters.isEnqueteEnable
                || parameters.isAlerteSoldeEnable
                || parameters.isPaiementEnable
                || parameters.isDinerTakeAwayEnable
                || parameters.isResultatVoteEnable
                || parameters.isCommandeEnable
                ;
        }
    }
}