module Core {
  interface IDatepickerDirectiveScope extends ng.IScope {
    convertedModel: Date;
    ngModel: string;
    maxDate: Date;
    triggerId: string;
    isPastOnly: boolean;
    isFutureOnly: boolean;
    initialText: boolean;
    disableDayFn;
    onSelect;
  }

  class DatepickerDirective implements ng.IDirective {
    constructor(private localizeService: IS.Localization.LocalizeService) {}
    restrict = "A";
    scope = {
      convertedModel: "=",
      ngModel: "=",
      maxDate: "=",
      triggerId: "=?",
      isPastOnly: "=?",
      isFutureOnly: "=?",
      initialText: "=?",
      disableDayFn: "&",
      onSelect: "&",
    };

    link = (
      scope: IDatepickerDirectiveScope,
      element: ng.IAugmentedJQuery,
      attrs: ng.IAttributes
    ) => {
      var inputDateId = document.getElementById(attrs["id"]);
      var initialText = this.localizeService.getLocalizedResource(
        "Registration_DateFormat"
      );
      var i18n = JSON.parse(
        this.localizeService.getLocalizedResource("DatePicker_I18n")
      );
      var firstDayOfWeek =
        this.localizeService.getCurrentLanguage().toLowerCase() == "fr" ? 1 : 0;
      //var customTheme = scope.theme ?  scope.theme : null;
      var customTrigger = scope.triggerId
        ? document.getElementById(scope.triggerId)
        : inputDateId;
      var calendar: Pikaday;
      calendar = new Pikaday({
        field: inputDateId,
        i18n: i18n,
        format: "DD/MM/YYYY",
        yearRange: [1900, 2050],
        firstDay: firstDayOfWeek,
        //theme: customTheme,
        trigger: customTrigger,
        disableDayFn: function (date) {
          if (scope.disableDayFn == null) {
            return false;
          }
          return scope.disableDayFn({ dateString: date.toDateString() });
        },
        onSelect: function (date) {
          if (scope.onSelect == null) {
            return false;
          }
          return scope.onSelect({ dateString: date.toDateString() });
        }
      });

      scope.isFutureOnly = angular.isDefined(scope.isFutureOnly)
        ? scope.isFutureOnly
        : true;

      if (scope.isPastOnly) {
        calendar.setMaxDate(new Date());
      } else if (scope.isFutureOnly) {
        calendar.setMinDate(new Date());
      }

      if (scope.maxDate) {
        calendar.setMaxDate(scope.maxDate);
      }

      scope.ngModel = scope.ngModel
        ? scope.ngModel
        : scope.initialText
        ? initialText
        : null;
      scope.$watch("ngModel", (newValue: string) => {
        if (
          newValue != null &&
          newValue != "DD/MM/YYYY" &&
          newValue != "JJ/MM/AAAA"
        ) {
          var date = moment(newValue, "DD/MM/YYYY");
          scope.convertedModel = date.isValid() ? date.toDate() : null;
        }
      });

      scope.$watch("maxDate", (newValue: Date) => {
        if (newValue) {
          calendar.setMaxDate(scope.maxDate);
        }
      });
    }

    static factory() {
      return (localizeService: IS.Localization.LocalizeService) => {
        return new DatepickerDirective(localizeService);
      };
    }
  }

  angular
    .module("core")
    .directive("datepicker", [
      "localizeService",
      DatepickerDirective.factory(),
    ]);
}
