module Core {
    export class AuthRequest implements IAuthRequest {
        username: string;
        password: string;

        authType: string = "";

        constructor(username: string, password: string) {
            this.username = username;
            this.password = password;
        }
    }
}
