module Core {
    export class TypologiesService {
        allTypologies: ITypologie[];
        loadingPromise: ng.IPromise<ITypologie[]>;

        constructor(
            private $rootScope: ng.IRootScopeService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService,
            private localizeService: IS.Localization.LocalizeService) {

            this.getAllTypologies();

            $rootScope.$on('resourcesUpdated', () => {
                this.getAllTypologies(true);
            });
        }

        getAllTypologies(forceUpdate: boolean = false): ng.IPromise<ITypologie[]> {
            if (this.loadingPromise) {
                return this.loadingPromise;
            }

            var deferred = this.$q.defer<ITypologie[]>();
            this.loadingPromise = deferred.promise;

            if (this.allTypologies && !forceUpdate) {
                deferred.resolve(this.allTypologies);
            } else {
                console.log('reload typologies from server');
                this.$http.get<ITypologie[]>(`${this.configService.getConfigValue('url')}/cartes/typologies`)
                    .success((typologies) => {
                        this.allTypologies = typologies;
                        deferred.resolve(typologies);
                    })
                    .error(error => {
                        console.log(error);
                    })
                    .finally(() => this.loadingPromise = null);
            }
            return deferred.promise;
        }

        getTypologies(typologieCodes: string[]): ng.IPromise<ITypologie[]> {
            var deferred = this.$q.defer<ITypologie[]>();

            this.getAllTypologies().then(allTypologies => {
                let typologies = _.filter(allTypologies, (typologie: ITypologie) => _.includes(typologieCodes, typologie.code));
                deferred.resolve(typologies);
            });

            return deferred.promise;
        }

    }

    angular.module('core').service('typologiesService', ['$rootScope', '$http', '$q', 'configService', 'localizeService', TypologiesService]);
}
