module Easypass {
    class PaymentSuccessCallbackController {
        restaurantId: number;

        constructor(private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private storageService: StorageService,
            private panierService: PanierService) {
            var panier = storageService.getPanier();
            if (panier) {
                this.restaurantId = panier.restaurantId;
                this.storageService.deletePanier();
                panierService.getRetrait(this.restaurantId, panier.commandeId).then((val) => {
                    this.$state.go('retrait', { restaurantId: this.restaurantId, retrait: val });
                });
            }

            // dinner takeaway ?
            else {


            }



        }
    }
    angular.module('easypass').controller('paymentSuccessCallbackController', ["$stateParams", "$state", "storageService", "panierService", PaymentSuccessCallbackController]);
}