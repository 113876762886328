module Core {
    angular.module('core').filter('localdate', ["localizeService", (localizeService: IS.Localization.LocalizeService) => {
        return (input: string, full: boolean = true, letters: boolean = false): string => {
            var currentLanguage = localizeService.getCurrentLanguage();

            let separator: string = !!letters ? ' ' : '/';
            let mounth: string = moment(input).format(!!letters ? 'MMMM' : (currentLanguage === 'fr' ? 'MM' : 'M'));
            let year: string = !!full ? (separator + moment(input).format('YYYY')) : '';

            switch (currentLanguage) {
                case 'fr':
                    return moment(input).format('DD') + separator + mounth + year;
                case 'en':
                default:
                    return mounth + separator + moment(input).format('D') + year;
            }
        };
    }]);
}