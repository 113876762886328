module Easypass {
	export enum PresenceType {
		Absent = 0,
		OnSite = 1,
		Lunch = 2,

		disabled = -1,
		otherMonth = 100,

		absentDisabled = -10
	}
}