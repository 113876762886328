module Core {
    class TimechefParametresInfosSoldeDirective implements ng.IDirective {
        restrict: string = 'E';
        templateUrl = "./timechefPatametresInfosSoldeDirectiveTemplate.html";
        replace = true;

        link(scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery) {
        }

        static factory() {
            return () => {
                return new TimechefParametresInfosSoldeDirective();
            };
        }
    }

    angular.module('core').directive('timechefParametresInfosSolde', [TimechefParametresInfosSoldeDirective.factory()]);
}