module Easypass {
    class PanierController {

        articles: Core.IPanierArticle[];
        lastOrder: Core.IPanier;
        restaurantId: number;
        restaurantType: string;
        devis: ICommandeDevis;
        priceChanged: boolean = false;
        refillNeeded: boolean = false;
        errorWithCode: string = null;
        isLoading: boolean = false;
        isError: boolean = false;
        isMissedMoyenPaiement: boolean = false;
        restaurantClosed: boolean = false;
        categoriesASuggerer: Core.ICategorieCarte[] = [];
        showSuggestion: boolean = false;
        hasCaisse: boolean;
        paymentType: Core.PaymentType;
        cbPayment: boolean;
        creditConfiguration: ICreditConfiguration;
        networkError: boolean;
        creneauRetrait: ICreneau;
        lieuRetrait: ILieuRetrait;
        numBadge: string;
        missingArticles: {
            Libelle: string;
            ParentLibelle: string;
        }[];
        isClickAndServe: boolean;
        cgvAccepted: boolean;
        cgvLink: string;
        selectedConviveMoyenPaiement: Core.IConviveMoyenPaiement;

        // Service A Table
        isServiceATable: boolean;
        maxNumeroTable?: number;
        numeroTable?: number;

        hasCreneauRetraits() {
            return this.devis && this.devis.creneauRetraits && this.devis.creneauRetraits.length > 0 && this.creneauRetrait;
        }

        price() {
            return this.articles
                .map(x => x.quantite * x.prix)
                .reduce((prev, current) => prev + current, 0);
        }

        constructor(private panierService: PanierService,
            private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private storageService: StorageService,
            private userService: Core.UserService,
            private $scope: ng.IScope,
            private restaurantsService: RestaurantsService,
            private creditService: CreditService,
            private redirectionService: Core.IRedirectionService,
            private $rootScope: ng.IRootScopeService,
            private localizeService: IS.Localization.LocalizeService
        ) {

            this.cgvAccepted = false;
            this.cgvLink = this.$state.href('termsAndConditions', { restaurantId: this.restaurantId });

            $scope.$on('panierChanged', () => {
                this.resetError();
                this.restaurantClosed = false;
                this.networkError = false;
                this.priceChanged = false;
                this.refillNeeded = false;
                if (this.articles && this.articles.length) {
                    this.getPanierPrix();
                }
            });
            $scope.$on('$viewContentLoaded', () => {
                setTimeout(() => {
                    this.displayArrow();
                }, 0);
            });

            (<any>window).DataAnalyticsSender.sendCustomEvent('Cart_Screen', '');

            var mustShowSuggestion = true;
            this.restaurantId = +this.$stateParams["restaurantId"];
            if (!this.restaurantId) {
                this.restaurantId = this.storageService.getPanier().restaurantId;
            }

            this.restaurantsService.get(this.restaurantId).then(restaurant => {
                this.restaurantClosed = !this.restaurantsService.getIsOrderOpen(restaurant);
                this.restaurantType = restaurant.restaurantType;
                this.isClickAndServe = restaurant.isClickAndServe;
                this.isServiceATable = restaurant.isServiceATable;
                this.maxNumeroTable = restaurant.maxNumeroTable;
            });

            this.restaurantsService.getHasCaisse(this.restaurantId)
                .then(hasCaisse => {
                    this.hasCaisse = hasCaisse;

                    if (!hasCaisse) {
                        this.creditService.getRestaurantConfig(this.restaurantId)
                            .then(config => {
                                if (!!config.moyenPaiements && config.moyenPaiements.length != 0) {
                                    this.creditConfiguration = config;
                                    this.paymentType = this.getPaymentType(config.moyenPaiements[0]);
                                    this.cbPayment = !this.hasCaisse && this.paymentType == Core.PaymentType.Immediate;
                                    // Select first credit card by default
                                    this.selectedConviveMoyenPaiement = config.conviveMoyenPaiements[0];
                                }
                                else {
                                    this.isError = true;
                                    this.isMissedMoyenPaiement = true;
                                }
                            })
                            .catch(error => {
                                checkNetworkError(error, () => this.isError = true, () => this.networkError = true);
                            });
                    }
                })
                .catch(error => {
                    checkNetworkError(error, () => this.isError = true, () => this.networkError = true);
                });

            if (this.panierService.getPanier(this.restaurantId).articles.length == 0) {
                var storagePanier = this.storageService.getPanier();
                if (storagePanier) {
                    this.panierService.setPanier(this.restaurantId, {
                        id: storagePanier.commandeId,
                        articles: storagePanier.articles,
                        prix: 0
                    });
                    this.storageService.deletePanier();
                    mustShowSuggestion = false;
                }
            }

            this.panierService.getDernierPanier(this.restaurantId).success(panier => {
                this.lastOrder = panier;
                this.panierService.calculerPrixPanier(this.lastOrder);
            });

            this.articles = this.panierService.getPanier(this.restaurantId).articles;
            if (mustShowSuggestion) {
                this.panierService.getCategoriesToSuggest(this.restaurantId)
                    .then(categories => {
                        this.categoriesASuggerer = categories;
                        this.showSuggestion = this.categoriesASuggerer.length > 0;
                    })
                    .catch(error => {
                        checkNetworkError(error, () => this.isError = true, () => this.networkError = true);
                    });
            }
            if (this.articles.length) {
                this.getPanierPrix();
            }
        }

        displayArrow() {
            var panierPage = document.querySelector(".panier-page");
            var listPanier = document.getElementById("list-panier");
            var paddingPanierPage = 150;
            var marginListPanier = 30;
            var chevroonDown = document.getElementById("display-chevroon");

            let callback = () => {
                var bottomList = panierPage.scrollTop + panierPage.clientHeight - paddingPanierPage - marginListPanier >= listPanier.clientHeight;

                if (bottomList) {
                    chevroonDown.setAttribute('style', "display : none");
                } else {
                    chevroonDown.setAttribute('style', "display : block");
                }
            };
            callback();
            panierPage.addEventListener('scroll', callback);

        }

        acceptCGV = () => {
            this.cgvAccepted = !this.cgvAccepted;
        }

        getPanierPrix() {
            var panier = this.panierService.getPanier(this.restaurantId);

            this.showSuggestion = false;
            this.priceChanged = false;
            this.resetError();
            this.restaurantClosed = false;
            this.networkError = false;
            this.refillNeeded = false;
            this.isLoading = true;

            this.panierService.validerPrix(this.restaurantId)
                .then((result) => {
                    if (!result.commandeId) {
                        return;
                    }

                    this.devis = result;
                    this.setDefaultCreneauRetrait();
                    this.setDefaultLieuRetrait();

                    if (panier.prix != this.devis.total) {
                        this.priceChanged = true;
                    }

                    if (this.hasCaisse) {
                        this.userService.getUserInformations()
                            .then(userInformation => {
                                if (userInformation.currentSite.solde < this.devis.total && userInformation.currentSite.modeGestion != Core.ModeGestion.postPaiement) {
                                    this.refillNeeded = true;
                                }
                            })
                            .catch(error => {
                                checkNetworkError(error, () => this.isError = true, () => this.networkError = true);
                            });
                    }
                })
                .catch(error => {
                    checkNetworkError(error, () => {
                        if (error.status === 403) {
                            this.restaurantClosed = true;
                        }
                        else if (error.status === 400) {
                            this.isError = true;
                            if (error.data && error.data.data && error.data.data.missingArticles && error.data.data.missingArticles.length > 0) {
                                this.missingArticles = error.data.data.missingArticles;
                            }
                        }
                    }, () => this.networkError = true);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        goToPayment() {
            this.showSuggestion = false;
            this.refillNeeded = false;
            this.resetError();
            this.restaurantClosed = false;
            this.networkError = false;
            this.isLoading = true;

            if (this.hasCaisse) {
                this.numBadge = this.userService.userInformations.currentSite.numBadge;
            }

            var dateDebut = null;
            var dateFin = null;
            if (this.hasCreneauRetraits()) {
                dateDebut = this.creneauRetrait.dateDebut;
                dateFin = this.creneauRetrait.dateFin;
            }

            this.panierService.validerPanier(this.restaurantId, this.paymentType, dateDebut, dateFin, this.lieuRetrait, this.numBadge, this.numeroTable).then((retraitInfos) => {
                if (retraitInfos.isPaiementRequired) {
                    this.storageService.savePanier({ articles: this.articles, restaurantId: this.restaurantId, commandeId: this.devis.commandeId });
                    this.creditService.postCredit(this.devis.total, this.creditConfiguration.moyenPaiements[0], this.selectedConviveMoyenPaiement, TypeTransaction.PaiementCommande, this.devis.commandeId).then((creditRedirection) => {
                        this.redirectionService.redirect(creditRedirection).then(() => {
                            this.$rootScope.$broadcast('refreshCommandList');
                            this.$rootScope.$broadcast('orderValidated');

                            this.storageService.deletePanier();
                            this.$state.go('retrait', { restaurantId: this.restaurantId, retrait: retraitInfos });

                        }, () => {
                            this.$state.go('paymentError');
                        });
                    }).catch(error => {
                        checkNetworkError(error, () => {
                            this.restaurantsService.get(this.restaurantId).then(restaurant => {
                                if (!this.restaurantsService.getIsOrderOpen(restaurant)) {
                                    this.restaurantClosed = true;
                                }
                                else {
                                    this.isError = true;
                                }
                            })
                        }, () => this.networkError = true);
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
                else {
                    this.$rootScope.$broadcast('refreshCommandList');
                    this.$rootScope.$broadcast('orderValidated');

                    this.$state.go('retrait', { restaurantId: this.restaurantId, retrait: retraitInfos });
                }
            }).catch((reason) => {
                checkNetworkError(reason, () => {
                    if (reason.status === 400 && reason.data && reason.data.code) {
                        switch (reason.data.code) {
                            case 1:
                                this.errorWithCode = this.localizeService.getLocalizedResource("Panier_Error_Depassement_Montant");
                                return;
                            case 2:
                                this.errorWithCode = this.localizeService.getLocalizedResource("Panier_Error_HeureRetrait_NotDefined");
                                return;
                            case 3:
                                this.errorWithCode = this.localizeService.getLocalizedResource("Panier_Error_LieuRetrait_NotDefined");
                                return;
                            case 4:
                                this.errorWithCode = this.localizeService.getLocalizedResource("Panier_Error_NumeroTable_Required");
                                return;
                            case 5:
                                this.errorWithCode = this.localizeService.getLocalizedResource("Panier_Error_NumeroTable_Invalid");
                                if (!!this.maxNumeroTable) {
                                    this.errorWithCode += ' ' + this.localizeService.getLocalizedResource("Panier_Error_NumeroTable_OutOfRange").replace('{{maxNumeroTable}}', this.maxNumeroTable.toString());
                                }
                                return;
                        }
                    }

                    if (this.hasCaisse && reason.status === 400) {
                        this.refillNeeded = true;
                    }
                    else if (reason.status === 403) {
                        this.restaurantClosed = true;
                    }
                    else {
                        this.isError = true;
                    }

                }, () => this.networkError = true);
            }).finally(() => {
                this.isLoading = false;
            });

        }

        useLastOrder() {
            if (this.lastOrder) {
                var newPanier = _.cloneDeep(this.lastOrder);
                newPanier.id = null; // pour ne pas écraser la dernière commande, il faut mettre l'id à null

                this.panierService.setPanier(this.restaurantId, newPanier);
                this.articles = newPanier.articles;
                if (this.articles.length) {
                    this.getPanierPrix();
                }

                (<any>window).DataAnalyticsSender.sendCustomEvent('Cart_BTN_Empty', '');
            }
        }

        goToRefill() {
            this.storageService.savePanier({ articles: this.articles, restaurantId: this.restaurantId, commandeId: this.devis.commandeId });
            this.$state.go('credit', { restaurantId: this.restaurantId });
        }

        goBack() {
            this.panierService.broadcastPanierChanged(this.restaurantId);
            this.$state.go('clickAndCollect', { id: this.restaurantId });
        }

        getPaymentType(moyenPaiement: IMoyenPaiement): Core.PaymentType {
            switch (moyenPaiement.id) {
                case 3: // WorlLine
                case 5: // Ingénico
                    return Core.PaymentType.Immediate;
                case 4:
                    return Core.PaymentType.Deffered;
            }
        }

        resetError() {
            this.isError = false;
            this.isMissedMoyenPaiement = false;
            this.errorWithCode = null;
        }

        setDefaultCreneauRetrait() {
            if (this.devis.creneauRetraits != null && this.devis.creneauRetraits.length > 0) {
                this.creneauRetrait = {
                    dateDebut: this.devis.creneauRetraits[0].dateDebut,
                    dateFin: this.devis.creneauRetraits[0].dateFin
                };
            }
        }
        setDefaultLieuRetrait() {
            if (this.devis.lieuRetraits != null && this.devis.lieuRetraits.length > 0) {
                let filtredLieuRetraits = this.devis.lieuRetraits != null && this.devis.defaultLieuRetraitId != null
                    ? this.devis.lieuRetraits.filter(l => l.id == this.devis.defaultLieuRetraitId)
                    : null;
                this.lieuRetrait = filtredLieuRetraits != null && filtredLieuRetraits.length > 0
                    ? filtredLieuRetraits[0]
                    : this.devis.lieuRetraits[0];
            }
        }
    }
    angular.module('easypass').controller('panierController', ["panierService", "$stateParams", "$state", "storageService", "userService", "$scope", "restaurantsService", "creditService", "redirectionService", "$rootScope", "localizeService", PanierController]);
}