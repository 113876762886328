module Easypass {
    class CreditController {
        hasCaisse: boolean = false;
        isRegisteredInCaisse: boolean = false;
        displayName: string;
        initials: string;
        theme: Core.ITheme;
        hasQrCode: boolean;
        isMontantSelecteds: boolean[];
        isRefillMenu: boolean = true;
        skip: number = 0;
        take: number = 10;
        showTickets = false;
        isLoadingRefill: boolean = true;
        isOnErrorRefill: boolean = false;
        onErrorRefillMessage: string;
        onErrorRefillMessageTitle: string;
        refreshScrollIos: number = 0;
        restaurantId: number;
        isSoldeLoading: boolean = false;

        timeoutHandler: number;

        refreshScrollToBottomIos: number = 0;

        constructor(
            private localizeService: IS.Localization.LocalizeService,
            private creditService: CreditService,
            private redirectionService: Core.IRedirectionService,
            private $state: ng.ui.IStateService,
            private $stateParams: ng.ui.IStateParamsService,
            private routeService: Core.RouteService,
            private userService: Core.UserService,
            private $timeout: ng.ITimeoutService,
            private $rootScope: ng.IRootScopeService,
            private $scope: ng.IScope,
            private $location: ng.ILocationService
        ) {
            (<any>window).DataAnalyticsSender.sendCustomEvent('Recharge_Screen', '');

            this.isLoadingRefill = true;
            this.isOnErrorRefill = false;
            this.restaurantId = +$stateParams["restaurantId"];
            //maj du solde quand on arrive ici
            this.$rootScope.$broadcast('soldeChanged');

            this.displayName = userService.userInformations.displayName;
            this.hasCaisse = userService.userInformations.currentSite.hasCaisse;
            this.isRegisteredInCaisse = userService.userInformations.currentSite.isRegisteredInCaisse;
            this.initials = userService.userInformations.initials;
            this.theme = userService.getCurrentTheme();
            this.hasQrCode = userService.userInformations.currentSite.hasQrCode;

            this.creditService.getSiteConfig().then((creditConfiguration) => {
                this.creditConfiguration = creditConfiguration;
                this.selectedMoyenPaiement = creditConfiguration.moyenPaiements[0];
                this.selectedConviveMoyenPaiement = creditConfiguration.conviveMoyenPaiements[0];
                this.isMontantSelecteds = new Array(creditConfiguration.montants.length);

                //s'il n'y a pas de moyen de paiement, on affiche l'erreur d'indisponibilité de rechargement
                if (this.selectedMoyenPaiement == null) {
                    this.manageRefillError();
                    return;
                }
            }).catch(error => {
                checkNetworkError(error, () => this.manageRefillError(error), () => this.refillHasNetworkError = true);
            }).finally(() => {
                this.isLoadingRefill = false;
                this.updateScrollIos();
            });
        }

        isConfirmLoading: boolean = false;
        isConfirmOtherLoading: boolean = false;
        isRefillAvailable: boolean = true;
        //isTicketAvailable: boolean = true;
        refillHasNetworkError: boolean = false;

        creditConfiguration: ICreditConfiguration;

        selectedMontant: number;
        selectedMoyenPaiement: IMoyenPaiement;
        selectedConviveMoyenPaiement: Core.IConviveMoyenPaiement;

        updateScrollIos() {
            this.$timeout(50).then(() => {
                this.refreshScrollIos++;
            });
        }

        confirm(conviveMoyenPaiement: Core.IConviveMoyenPaiement): void {
            if (conviveMoyenPaiement == null && this.selectedConviveMoyenPaiement) {
                this.isConfirmOtherLoading = true;
            }
            else {
                this.isConfirmLoading = true;
            }
            this.isRefillAvailable = true;
            this.refillHasNetworkError = false;
            (<any>window).DataAnalyticsSender.sendCustomEvent('Recharge_BTN_Confirm', this.selectedMontant);

            this.creditService.postCredit(this.selectedMontant, this.selectedMoyenPaiement, conviveMoyenPaiement, TypeTransaction.Rechargement).then((creditRedirection) => {
                this.redirectionService.redirect(creditRedirection).then(() => {
                    // Remarque : ce code ne s'execute qu'en mode Apps (ios/android)
                    this.$state.go('creditsuccess', { restaurantId: this.restaurantId, transactionId: creditRedirection.transactionId });
                }, () => {
                    this.$state.go('crediterror');
                });
            }).catch(error => {
                checkNetworkError(error, () => this.manageRefillError(error), () => this.refillHasNetworkError = true);
            }).finally(() => {
                this.isConfirmLoading = false;
                this.isConfirmOtherLoading = false;
            });
        }

        setActiveMontant(index: number) {
            for (var i = 0; i < this.isMontantSelecteds.length; i++) {
                this.isMontantSelecteds[i] = false;
            }

            this.isMontantSelecteds[index] = true;
            this.selectedMontant = this.creditConfiguration.montants[index];
        }

        private manageRefillError(error: any = null): void {
            var exceptionError = error && error.data as Core.IError;
            if (exceptionError && exceptionError.exception !== undefined) {
                var exception = exceptionError.exception;
                switch (exception) {
                    // Add specific errors here
                    case "CompteClientNotFoundException":
                    case "CompteClientLockedException":
                        this.onErrorRefillMessage = this.localizeService.getLocalizedResource('Badge_Locked');
                        this.onErrorRefillMessageTitle = this.localizeService.getLocalizedResource('Badge_Locked_Title');
                        break;
                    case "CompteClientExpiredException":
                        this.onErrorRefillMessage = this.localizeService.getLocalizedResource('Badge_Expired');
                        this.onErrorRefillMessageTitle = this.localizeService.getLocalizedResource('Badge_Expired_Title');
                        break;
                    case "CompteClientPendingClosureException":
                        this.onErrorRefillMessage = this.localizeService.getLocalizedResource('Exception_' + exception);
                        break;
                    case "CaisseUnavailableException":
                        this.onErrorRefillMessage = this.localizeService.getLocalizedResource('Caisse_Unavailable');
                        this.onErrorRefillMessageTitle = this.localizeService.getLocalizedResource('Caisse_Unavailable_Title');
                        break;
                    default:
                        this.onErrorRefillMessage = this.localizeService.getLocalizedResource('Recharger_Unavailable');
                        this.onErrorRefillMessageTitle = this.localizeService.getLocalizedResource('Refill_Unavailable_Title');
                        break;
                }
            }
            else {
                this.onErrorRefillMessage = this.localizeService.getLocalizedResource('Recharger_Unavailable');
                this.onErrorRefillMessageTitle = this.localizeService.getLocalizedResource('Refill_Unavailable_Title');
            }

            this.isRefillAvailable = false;
            this.isOnErrorRefill = true;
        }

    }

    angular.module('easypass').controller('creditController', ['localizeService', 'creditService', 'redirectionService', '$state', '$stateParams', 'routeService', 'userService', '$timeout', '$rootScope', '$scope', '$location', CreditController]);
}
