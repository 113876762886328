module Core {
    class ParametresController {

        hasCaisse: boolean = false;
        alerteSurSolde: boolean = false;
        isRegisteredInCaisse: boolean = false;
        displayName: string;
        initials: string;
        culture: IS.Localization.ICulture;
        theme: ITheme;
        popinIsUpdated: boolean = false;
        popinAlreadyUpdated: boolean = false;
        popinFailUpdated: boolean = false;
        showPopIn: boolean = false;

        isNotTcPro: boolean = false;
        showAllergenes: boolean = true;
        showHelp: boolean = true;
        showMoyenPaiements: boolean = false;

        constructor(
            private userService: UserService,
            private localizeService: IS.Localization.LocalizeService,
            private $timeout: ng.ITimeoutService,
            private helperService: Core.HelperService) {

            this.displayName = userService.userInformations.displayName;
            this.hasCaisse = userService.userInformations.currentSite.hasCaisse;
            this.alerteSurSolde = userService.userInformations.currentSite.alerteSurSolde;
            this.isRegisteredInCaisse = userService.userInformations.currentSite.isRegisteredInCaisse;
            this.initials = userService.userInformations.initials;
            this.culture = localizeService.getCurrentCulture();
            this.theme = userService.getCurrentTheme();
            this.showMoyenPaiements = userService.userInformations.currentSite.showMoyenPaiementParameters;

            this.isNotTcPro = !helperService.isTcPro();
            
            if (!userService.userInformations.currentSite.restaurantsDisplayed) {
                this.manageTimechefSansRestaurants();
            }
        }

        logout() {
            (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_BTN_Signout', '');
            this.userService.signOut();
        }

        openCookie() {
            if ((<any>window).orejimeInstance)
                (<any>window).orejimeInstance.show();
            else
                console.error('Orejime is NULL!');
        }

        private manageTimechefSansRestaurants(): void {
            this.showHelp = false;
            this.showAllergenes = false;
        }
    }
    //angular.module('core').controller('parametresController', ['userService',  'localizeService',  '$timeout',  'configService', 'rootElement', 'helperService',  ParametresController]); 
    angular.module('core').controller('parametresController', ['userService', 'localizeService', '$timeout', 'helperService', ParametresController]);
}