module Core {
    @Component('core', 'restaurantTile', {
        controllerAs: 'vm',
        bindings: {
            restaurant: '<',
            campagne: '<',
            animation: '<',
            voteState: '<',
            sousThematiqueEnCours: '<',
            conceptInfos: '<',
            isOpen: '<'
        },
        templateUrl: './restaurantTile.html'
    })
    class RestaurantTileComponent {
        static $inject: string[] = ['configService','storageService'];
        restaurant: IRestaurant;
        sousThematiqueEnCours: ISousThematiqueGagnanteGlobal;
        conceptInfos: IConceptInfos;
        constructor(private configService: IS.Configuration.ConfigService, private storageService: Core.StorageService) {

        }

        getImageUrl() {
            let imageDocumentId = null;
            let defaultImageUrl = './styles/assets/default-restaurant.jpg';

            if (this.restaurant) {
                if (this.restaurant.voteEstActif) {
                    imageDocumentId = this.getThematiqueGagnanteImageDocumentId();
                    if (!imageDocumentId) {
                        imageDocumentId = this.getConceptImageDocumentId();
                    }
                    if (imageDocumentId) {
                        if(this.restaurant.id === 1443)
                            console.log(`Image ${this.restaurant.libelle} est ${this.configService.getConfigValue("url")}/documents/${imageDocumentId}`);
                        return `${this.configService.getConfigValue("url")}/documents/${imageDocumentId}`;
                    }
                }
                if(this.restaurant.id === 1443)
                    console.log(`Image ${this.restaurant.libelle} est ${this.restaurant.imageUrl}`);
                return this.restaurant.imageUrl;
            } else {
                if(this.restaurant.id === 1443)
                    console.log(`Image ${this.restaurant.libelle} est ${defaultImageUrl}`);
                return defaultImageUrl;
            }
        }

        private getThematiqueGagnanteImageDocumentId(): number {
            return this.sousThematiqueEnCours && this.sousThematiqueEnCours.defaultSousThematiqueGagnante
                ? this.sousThematiqueEnCours.defaultSousThematiqueGagnante.imageDocumentId
                : this.storageService.getImageThematiqueGagnante(this.restaurant.id);
        }

        private getConceptImageDocumentId(): number {
            return this.conceptInfos
                ? this.conceptInfos.imageDocumentId
                : this.storageService.getImageConcept();
        }

        getPictogram(sousThematiqueId: number): string {
            return `${this.configService.getConfigValue('url')}/vote/sous-thematique/${sousThematiqueId}/pictogram`;
        }
    }
}