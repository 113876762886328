module Easypass.DinerTakeAway {
    @Component('easypass', 'dtHome', {
        controllerAs: 'vm',
        bindings: {
            bindedRestaurantId: '<restaurantId'
        },
        templateUrl: './dtHome.html'
    })
    class HomeComponent {
        static $inject: string[] = ['lockerService', '$q', 'carteServiceDTA', 'restaurantsService', 'userService', 'lockerReservationService', 'dinnerTakeawayConfigService', '$timeout', '$state', 'dtBasketService', 'enqueteService', 'localizeService'];

        lockerDays: ILockerPerDay[] = [];
        restaurant: Core.IRestaurant;
        restaurantId: number;
        bindedRestaurantId: string;
        isLoading: boolean = false;
        isInError: boolean = false;
        showNotificationsSettings: boolean = false;
        setNotificationsFailed: boolean = false;
        orderCloseAt: string;
        showErrorStartingReservation: boolean = false;

        maxOrderHourInMinutes: number;
        notifications: { name: string, value: boolean, phoneNumber?: string, showPhoneNumber?: boolean }[];
        notificationParameters: {
            isSmsEnable?: boolean,
            isEmailEnable?: boolean,
            phoneNumber?: string,
            phoneNumberError?: string,
            showPhoneNumber: boolean
        };
        private cartes: ICarteDTA[];
        private timeoutTicker: ng.IPromise<any>;
        private maxArticlesToDisplay: number = 2;
        hasExpressYourself = false;

        phoneNumberMinLength: number;
        phoneNumberMaxlength: number;

        constructor(private lockerService: Easypass.LockerService,
            private $q: ng.IQService,
            private carteServiceDTA: CarteServiceDTA,
            private restaurantsService: Core.RestaurantsService,
            private userService: Core.UserService,
            private lockerReservationService: LockerReservationService,
            private dinnerTakeawayConfigService: DinnerTakeawayConfigService,
            private $timeout: ng.ITimeoutService,
            protected $state: ng.ui.IStateService,
            protected basketService: DtBasketService,
            private enqueteService: EnqueteService,
            private localizeService: IS.Localization.LocalizeService) {
            this.restaurantId = +this.bindedRestaurantId;
            this.phoneNumberMinLength = userService.phoneNumberMinLength;
            this.phoneNumberMaxlength = userService.phoneNumberMaxlength;
        }

        $onInit = () => {
            this.isLoading = true;

            this.restaurantsService.get(this.restaurantId).then(restaurant => {
                this.restaurant = restaurant;

                // If DinnerTakeAway, check if one notification provider is at least selected
                if (this.restaurant.isDinnerTakeaway || this.restaurant.isFoodles) {
                    if (this.restaurant.isDinnerTakeaway) {
                        this.dinnerTakeawayConfigService.getConfig(this.restaurantId).then(config => {
                            var maxOrderHour = moment().utc().hour(0).second(0).minutes(config.maxOrderHourInMinutes).local();
                            this.maxOrderHourInMinutes = config.maxOrderHourInMinutes;
                            this.orderCloseAt = maxOrderHour.format('LT').replace(':', 'H');
                        });
                    } else {
                        var tmpDate = this.restaurant.orderOpenAt || this.restaurant.openAt;
                        this.orderCloseAt = tmpDate.replace(':', 'H');
                    }

                    if (this.restaurant.isDinnerTakeaway) {
                        this.userService.getNotificationParametersForType('dinertakeaway').then(parameters => {
                            if (!parameters.email && !parameters.sms) {
                                this.showNotificationsSettings = true;

                                this.notificationParameters = {
                                    showPhoneNumber: !this.userService.userInformations.phoneNumber
                                }
                            }
                        });
                    }
                }

                // Get the next 5 available cartes which will be the day to display
                this.carteServiceDTA.getCarteSemaineDTA(this.restaurantId).then(
                    cartes => {
                        this.cartes = cartes;

                        this.cartes.forEach((carte) => {
                            this.lockerDays.push({
                                date: carte.dateDebut,
                                loadingData: this.restaurant.isDinnerTakeaway,
                                articles: carte.plats.splice(0, this.maxArticlesToDisplay)
                            });
                        });

                        if (this.restaurant.isDinnerTakeaway) {
                            this.getAvailabilities();
                        }
                    }).finally(() => {
                        this.isLoading = false;
                    });

                this.enqueteService.gets(true, this.restaurantId).then(campagnes => {
                    var campagne = _.find(campagnes, (camp) => !camp.dejaRepondueAujourdhui);

                    this.hasExpressYourself = !!campagne;
                });

            }).catch(error => {
                this.isInError = true;
                this.isLoading = false;
            });
        }

        $onDestroy = () => {
            if (this.timeoutTicker) {
                this.$timeout.cancel(this.timeoutTicker);
                this.timeoutTicker = undefined;
            }
        }

        setNotifications = () => {
            this.setNotificationsFailed = false;

            if (!this.notificationParameters.isEmailEnable && !this.notificationParameters.isSmsEnable)
                return false;

            let pendingTask: ng.IPromise<any> = this.$q.resolve();
            // Validation du numéro de téléphone s'il devait être saisi
            if (this.notificationParameters.isSmsEnable && this.notificationParameters.showPhoneNumber) {

                if (!this.notificationParameters.phoneNumber) {
                    this.notificationParameters.phoneNumberError = this.localizeService.getLocalizedResource('NoPhoneNumberAlert');
                    return false;
                } else if (!this.userService.isPhoneNumberValid(this.notificationParameters.phoneNumber)) {
                    this.notificationParameters.phoneNumberError = this.localizeService.getLocalizedResource('UpdatePhone_ErrorFormat');
                    return false;
                } else {
                    this.notificationParameters.phoneNumberError = null;
                }

                pendingTask = this.userService.updatePhone(this.notificationParameters.phoneNumber);
            }

            pendingTask.then(() => {
                return this.userService.updateNotificationParameters(
                    [
                        {
                            category: 'dinertakeaway',
                            type: 'email',
                            enable: this.notificationParameters.isEmailEnable
                        },
                        {
                            category: 'dinertakeaway',
                            type: 'sms',
                            enable: this.notificationParameters.isSmsEnable
                        }
                    ]);
            })
                .then(() => {
                    this.showNotificationsSettings = false;
                })
                .catch((error) => {
                    this.setNotificationsFailed = true;
                });
        }

        getAvailabilities = () => {
            this.lockerReservationService.getCurrentReservations(this.restaurantId).then((currentReservations) => {
                if (currentReservations) {
                    currentReservations.forEach(reservation => {
                        var lockers = this.lockerDays.filter(locker => moment(locker.date).format('L') === moment(reservation.targetDate).format('L'));
                        lockers.forEach(locker => {
                            locker.currentlyOrdering = true;
                        });
                    });
                }
            });

            this.lockerDays.forEach(c => {
                this.lockerService.getLockerAvailability(this.restaurantId, c.date).then((availability: { available: number, booked: number, temporarilyReserved: number, date: Date }) => {
                    c.availableLockers = availability.available;
                    c.loadingData = false;
                }).catch(error => {
                    c.errorLoading = true;
                });
            });

            this.timeoutTicker = this.$timeout(() => { this.getAvailabilities(); }, 30 * 1000);
        }

        canOrderNow = (day: ILockerPerDay) => {
            if (this.restaurant.isFoodles) {
                return true;
            }

            if (day.currentlyOrdering) {
                return true;
            }

            if (day.availableLockers <= 0) {
                return false;
            }

            // Pour garantir que l'on part bien de 0:00 on fixe hour et second en utc car maxOrderHourInMinutes est par rapport � l'heure UTC
            var maxDayOrderDate = moment(day.date).utc().hour(0).second(0).minutes(this.maxOrderHourInMinutes);
            return moment() <= maxDayOrderDate;
        }

        dismissCannotStartReservation = () => {
            this.showErrorStartingReservation = false;
        }

        goToOffers = (day: ILockerPerDay) => {
            if (this.restaurant.isDinnerTakeaway) {
                day.reservingLocker = true;
                this.basketService.createBasket(this.restaurantId, day.date).then(() => {
                    day.reservingLocker = false;
                    this.$state.go('dt-offers', {
                        restaurantId: this.bindedRestaurantId,
                        currentDate: moment(day.date).format('YYYY-MM-DD')
                    });
                }).catch(() => {
                    this.showErrorStartingReservation = true;
                    day.reservingLocker = false;
                });
            } else {
                this.$state.go('dt-offers', {
                    restaurantId: this.bindedRestaurantId,
                    currentDate: moment(day.date).format('YYYY-MM-DD')
                });
            }
        }

        goToExpressYourself() {
            if (this.hasExpressYourself) {
                this.$state.go('dt-expressyourself', {
                    restaurantId: this.bindedRestaurantId
                });
            }
        }
    }
}