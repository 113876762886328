
module Core {
    var isSameDate = (startDate: Date, endDate: Date) => {
        return moment(startDate).format('DD MM') === moment(endDate).format('DD MM');
    };

    var inSameMonth = (startDate: Date, endDate: Date) => {
        return moment(startDate).format('MM') === moment(endDate).format('MM');
    };
    

    angular.module('core').filter('dateInterval', ["localizeService", (localizeService) => {
        return (startDate: Date, endDate ): string => {

            // one date
            if (isSameDate(startDate, endDate)) {
                var templateSameDate = localizeService.getLocalizedResource('Common_Period');
                return templateSameDate.replace("{{value}}", moment(startDate).format('dddd DD MMMM'));
            }

            // two different dates
            var template = localizeService.getLocalizedResource('Common_Period_From_To');
            var startDateText = inSameMonth(startDate, endDate)
                ? moment(startDate).format('DD')
                : moment(startDate).format('DD MMMM');
            var endDateText = moment(endDate).format('DD MMMM');

            return template.replace("{{startValue}}", startDateText)
                .replace("{{endValue}}", endDateText);
        };

    }]);
}