module Easypass {
    class RestaurantsController extends Core.RestaurantsController {
        stopDraging = false;
        showDragPicto = false;
        showPopInPresenceConvive = false;
        askPresenceOnSite: boolean = false;
        askPresenceLunch: boolean = false;
        askPresenceArrivalHour: boolean = false;
        askPresenceEvent: boolean = false;
        presenceEvents: IPresenceEventType[];
        showPopInPresenceEvents = false;
        showPopInMustChangeEmail = false;

        constructor(
            restaurantsService: RestaurantsService,
            animationService: Core.AnimationService,
            enqueteService: EnqueteService,
            voteCampagneService: Core.VoteCampagneService,
            storageService: StorageService,
            private userService: Core.UserService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            private $rootScope: ng.IRootScopeService
        ) {
            super(restaurantsService, animationService, enqueteService, voteCampagneService, storageService, $state, $scope);

            this.userService
                .getUserInformations(true)
                .then((userInformations) => {
                    if (userInformations) {
                        var siteInfos = userInformations.currentSite;
                        var displayPopIn = true;
                        var displayPopInFromStorage = this.storageService.getPresenceConvivePopInDisplay();
                        if (displayPopInFromStorage !== null) {
                            displayPopIn = displayPopInFromStorage;
                        }

                        this.askPresenceOnSite = siteInfos.askPresenceOnSite;
                        this.askPresenceLunch = siteInfos.askPresenceForLunch;
                        this.askPresenceArrivalHour = siteInfos.askPresenceOnSiteHeureArrivee;

                        // Must change email
                        this.showPopInMustChangeEmail = userInformations.mustChangeEmail;
                        // Si l'email doit être changé on n'affiche pas la popin de présence
                        if (this.showPopInMustChangeEmail) {
                            displayPopIn = false;
                        }

                        // Presence Events pour la réservation
                        this.askPresenceEvent = siteInfos.askPresenceEvent;
                        // par contre le presence events est dans le core et le model presenceEvent type est dans Timechef
                        // => Déplacer vers le core le IPresenceEventType !!!
                        this.presenceEvents = siteInfos.presenceEvents;
                        this.showPopInPresenceEvents = this.askPresenceEvent && siteInfos.presenceEvents.length > 0;
                        this.showPopInPresenceConvive = (displayPopIn && (siteInfos.askPresenceOnSite || siteInfos.askPresenceForLunch || siteInfos.askPresenceOnSiteHeureArrivee));
                        this.$rootScope.$broadcast("refreshPresenceConvive");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        goTo(restaurant: Core.IRestaurant, voteState: Core.IVoteState = null) {
            super.goTo(restaurant);

            if (this.showDragPicto) {
                this.showDragPicto = false;
                return;
            }
            this.stopDraging = true;

            if (voteState && voteState.convivePeutVoter) {
                this.$state.go("vote", { restaurantId: restaurant.id });
            } else {
                (<RestaurantsService>this.restaurantsService).goToRestaurant(restaurant);
            }
        }

        isOpen(restaurant: Core.IRestaurant): boolean {
            if (!restaurant.isClickAndCollect) {
                return this.restaurantsService.getIsOpen(restaurant);
            }
            return this.restaurantsService.getIsOrderOpen(restaurant);
        }

        acceptPresenceConvive = () => {
            // Réponse : Oui
            //console.log("======> acceptPresenceConvive");
            this.storageService.savePresenceConvivePopInDisplay(false);
            this.$state.go("presenceConvive");
        };

        cancelPresenceConvive = () => {
            // Réponse : ne plus demander
            //console.log("======> cancelPresenceConvive");
            this.storageService.savePresenceConvivePopInDisplay(false);
            return true;
        };

        bookEvent(eventId: number) {
            this.$state.go("presenceConvive", { eventId: eventId });
        }

        goChangeEmail = () => {
            this.$state.go("emailParametres");
        };
    }

    angular.module("easypass").controller("restaurantsController", ["restaurantsService", "animationService", "enqueteService", "voteCampagneService", "storageService", "userService", "$state", "$scope", "$rootScope", RestaurantsController]);
}
