module Core {
    interface IValidationFieldDirectiveScope extends ng.IScope {
        state: ValidationState;
        innerWidth: number;
        innerColor: string;
    }

    export enum ValidationState {
        empty,
        invalid,
        warning,
        valid
    }

    class ValidationFieldDirective implements ng.IDirective {
        template: string = `
            <div class='validationField'>
                <div ng-style="{width : innerWidth + '%', 'background-color': innerColor}"></div>
            </div>
        `;

        restrict: string = 'E';
        replace: boolean = true;
        scope = {
            state: '<',
            innerColor: '<',
            innerWidth: '<'
        };

        link(scope: IValidationFieldDirectiveScope) {    
            let applyStyle = () => {
                switch (scope.state) {
                    case ValidationState.empty: 
                        scope.innerWidth = 0;
                        break;
                    case ValidationState.invalid:
                        scope.innerColor = 'red';
                        scope.innerWidth = 20;
                        break;
                    case ValidationState.warning:
                        scope.innerColor = 'orange';
                        scope.innerWidth = 50;
                        break;
                    case ValidationState.valid:
                        scope.innerColor = 'green';
                        scope.innerWidth = 100;
                        break;
                }
            };
            
            scope.state = scope.state || ValidationState.invalid;
            scope.$watch((scope: IValidationFieldDirectiveScope) => scope.state, () => applyStyle());
        }

        static factory() {
            var directive = () => {
                return new ValidationFieldDirective();
            };
            return directive;
        }
    }

    angular.module('core').directive('validationField', [ValidationFieldDirective.factory()]);
}
