module Core {
    export class EnqueteService {
		protected myCampagnes: { [id: number]: ICampagne[] } = {};
		protected myEnquetes: { [id: number]: IEnquete } = {};
		protected getsFromServerOrchestrator: IS.SingleCallbackOrchestrator;

        constructor(
            protected $http: ng.IHttpService,
			protected $q: ng.IQService,
			protected configService: IS.Configuration.ConfigService,
			protected userService: UserService,
			protected restaurantsService: RestaurantsService,
            protected localizeService: IS.Localization.LocalizeService,
            rootScope: ng.IRootScopeService
		) {
            //rootScope.$on('connectionStateChanged', () => {
            //    this.getAllEnquete();
            //});

            rootScope.$on('clearUserInMemoryData', () => {
				this.myCampagnes = {};
				this.myEnquetes = {};
            });
        }

		gets(force: boolean = false, restaurantId: number = null): ng.IPromise<ICampagne[]> {
			var deferred = this.$q.defer<ICampagne[]>();

			var isEmpty = Object.getOwnPropertyNames(this.myCampagnes).length === 0;
			if (force || isEmpty || this.getsFromServerOrchestrator != null) {
				this.getsFromServer()
					.then(campagnes => {
						this.myCampagnes = campagnes;
						deferred.resolve(this.filter(this.myCampagnes, restaurantId));
					})
					.catch(error => {
						deferred.reject(error);
					})
					.finally(() => {
						this.getsFromServerOrchestrator = null;
					});
			}
			else {
				deferred.resolve(this.filter(this.myCampagnes, restaurantId));
			}

			return deferred.promise;
        }

		get(campagneId: number): ng.IPromise<ICampagne> {
			var deferred = this.$q.defer<ICampagne>();

			this.gets().then(campagnes => {
				var campagne = _.find(campagnes, campagne => campagne.id === campagneId);

				if (campagne == null) {
					deferred.reject(`Campagne ${campagneId} not found`);
					return;
				}

				deferred.resolve(campagne);
			}, error => {
				deferred.reject(error);
			});

			return deferred.promise;
        }

		protected getsFromServer(): ng.IPromise<{ [id: number]: ICampagne[] }> {
			if (this.getsFromServerOrchestrator == null) {
				this.getsFromServerOrchestrator = new IS.SingleCallbackOrchestrator(this.$q, () => {
					var deferred = this.$q.defer<{ [id: number]: ICampagne[] }>();

					var results: { [id: number]: ICampagne[] } = {};

					var promises: ng.IPromise<void>[] = [];

					this.restaurantsService.gets().then(restaurants => {
                        for (var restaurant of restaurants) {
                            results[restaurant.id] = restaurant.campagnes
                                                               .map((rep: any) => this.convertToCampagne(rep, restaurant));
							//promises.push(this.getsByRestaurantFromServer(restaurant).then(result => {
							//	results[result.id] = result.campagnes;
							//}));
						}

						this.$q.all(promises).then(_ => {
							deferred.resolve(results);
						});
					});

					return deferred.promise;
				});
			}

			return this.$q.resolve(this.getsFromServerOrchestrator.execute());
        }

		protected getsByRestaurantFromServer(restaurant: IRestaurant): ng.IPromise<{ id: number, campagnes: ICampagne[] }> {
			var deferred = this.$q.defer<{ id: number, campagnes: ICampagne[] }>();

			this.$http.get<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurant.id}/campagnes`)
				.success(result => {
					var campagnes: ICampagne[] = result.map((rep: any) => this.convertToCampagne(rep, restaurant));

					deferred.resolve({ id: restaurant.id, campagnes: campagnes });
				})
				.error(error => {
					deferred.reject(error);
				});

			return deferred.promise;
        }

		protected filter(campagnes: { [id: number]: ICampagne[] }, restaurantId: number = null): ICampagne[] {
			if (!!restaurantId) {
				return campagnes[restaurantId];
			}
			else {
				var flattened: ICampagne[] = [];

				for (var key in campagnes) {
					var id: number = +key;
					flattened = flattened.concat(campagnes[id]);
				}

				return flattened;
			}
		}

        protected convertToCampagne(campagne: any, restaurant: IRestaurant): ICampagne {
            return <ICampagne>{
                id: campagne.id,
                enqueteId: campagne.enqueteId,
                restaurantId: campagne.restaurantId,
                dejaRepondueAujourdhui: campagne.dejaRepondueAujourdhui,
                dateDebut: campagne.dateDebut,
                dateFin: campagne.dateFin,
                fontColor: "#ffffff",
                backgroundColor: "#363636",
                pictoUrl: "./styles/assets/pictos/notifications/sondage.svg",
                libelle: this.localizeService.getLocalizedResource('Campagne_Libelle')
            };
        }

		getNext(currentCampagneId: number, restaurantId: number = null): ng.IPromise<ICampagne> {
			return this.getByDirection(currentCampagneId, 1, restaurantId);
		}

        getPrevious(currentCampagneId: number, restaurantId: number = null): ng.IPromise<ICampagne> {
			return this.getByDirection(currentCampagneId, -1, restaurantId);
		}

        protected getByDirection(currentCampagneId: number, direction: number, restaurantId: number = null): ng.IPromise<ICampagne> {
			var deferred = this.$q.defer<ICampagne>();

			this.gets(false, restaurantId)
				.then(campagnes => {
					if (campagnes == null || campagnes.length === 0) {
						deferred.reject('No suitable campagne found');
						return;
					}

					var index = _.findIndex(campagnes, campagne => campagne.id === currentCampagneId);
					if (index === -1) {
						index = 0;
					}

					var campagne = campagnes[(index + direction + campagnes.length) % campagnes.length];
					deferred.resolve(campagne);
				})
				.catch(error => {
					deferred.reject(error);
				});

			return deferred.promise;
		}

		getsCount(restaurantId: number = null): ng.IPromise<number> {
			var deferred = this.$q.defer<number>();

			this.gets(false, restaurantId).then(campagnes => {
				deferred.resolve(!!campagnes ? campagnes.length : 0);
			}, error => {
				deferred.reject(error);
			});

			return deferred.promise;
		}

        getCurrent(restaurantId: number = null): ng.IPromise<ICampagne> {
			var deferred = this.$q.defer<ICampagne>();

			this.gets(false, restaurantId).then(campagnes => {
				if (!!campagnes && campagnes.length) {
					deferred.resolve(campagnes[0]);
				} else {
					deferred.reject('No running campagne');
				}
			}, error => {
				deferred.reject(error);
			});

			return deferred.promise;
		}

        getFirst(): ng.IPromise<ICampagne> {
            var deferred = this.$q.defer<ICampagne>();

			this.gets().then(campagnes => {
				if (campagnes.length > 0) {
					deferred.resolve(campagnes[0]);
				}
				else {
					deferred.reject('No campagne found');
				}
			});

            return deferred.promise;
        }

		getContent(enqueteId: number): ng.IPromise<IEnquete> {
			// from cache
            var enquete = this.myEnquetes[enqueteId];
            if (!!enquete) {
				return this.$q.resolve(enquete);
            }

			// from server
            var deferred = this.$q.defer<IEnquete>();

			this.$http.get<IEnquete>(`${this.configService.getConfigValue('url')}/enquete/${enqueteId}`)
				.success((enquete) => {
					this.myEnquetes[enqueteId] = enquete;
					deferred.resolve(enquete);
				})
				.catch(error => {
					deferred.reject(error);
				});

            return deferred.promise;
		}

        sendResponses(responses: IQuestion[], commentaire: string, campagneId: number, restaurantId: number): ng.IPromise<{}> {
            var deferred = this.$q.defer();

			var reponses = responses.map((question) => {
				return {
					id: question.id,
					note: question.note
				};
			});

			var body = {
				reponses,
				commentaire: commentaire,
				restaurantId: restaurantId
			};
			this.$http.post(`${this.configService.getConfigValue('url')}/enquete/${campagneId}`, body).then(() => {
				this.get(campagneId).then(campagne => {
                    campagne.dejaRepondueAujourdhui = true;
                    	// check if convive should rate the app
					this.checkOnSurvey(_.sum(responses, response => response.note) / responses.length);

					deferred.resolve();
				}, error => {
					deferred.reject(error);
				});
			}, error => {
				deferred.reject(error);
			});

            return deferred.promise;
        }

        //overrided by TC specific service
        checkOnSurvey(responsesRate: number) {
			//nothing?
        }
    }
    //angular.module('core').service('enqueteService', ['$http', '$q', 'configService', 'userService', 'restaurantsService', 'notationService', '$rootScope', EnqueteService]);
}