module Core {
    class PasswordParametresController {

        currentPassword: string;
        password: string;
        confirmPassword: string;
        isLoading: boolean;
        error: boolean;
        networkError: boolean;
        hasSucceeded: boolean;
        nom: string;
        prenom: string;

        constructor(private userService: UserService, private $rootScope: ng.IRootScopeService) {
            this.userService.getUserInformations().then((userInfo) => {
                this.nom = userInfo.lastName;
                this.prenom = userInfo.firstName;
            });
        }

        changePassword() {
            if (this.isLoading) return;
            this.isLoading = true;

            this.userService.updatePassword(this.currentPassword, this.password)
                .then(() => {
                    this.error = false;
                    this.networkError = false;

                    this.hasSucceeded = true;
                })
                .catch(error => {
                    this.hasSucceeded = false;

                    checkNetworkError(error,
                        () => {
                            this.networkError = false;
                            this.error = true;
                        },
                        () => {
                            this.error = false;
                            this.networkError = true;
                        });
                    // TODO: StatusCode=0
                })
                .finally(() => this.isLoading = false);
        }
    }
    angular.module('core').controller('passwordParametresController', ['userService', '$rootScope', PasswordParametresController]);
}