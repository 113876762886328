module Easypass {
    var weekday: { [key: string]: string[] } = {
        "fr": ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        "en": ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    };

    angular.module('easypass').filter('weekday', ["localizeService", (localizeService: IS.Localization.LocalizeService) => {
        return (input: number): string => {
            var currentLanguage = localizeService.getCurrentLanguage();
            return weekday[currentLanguage][input];
        };
    }]);
}