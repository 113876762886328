module Easypass.DinerTakeAway {
    @Component('easypass', 'dtPaymentError', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<'
        },
        templateUrl: './dtPaymentError.html'
    })
    class ConfirmOrderComponent {
        static $inject: string[] = ['dtBasketService', 'redirectionService', '$state', 'commandeService'];

        restaurantId: number;
        basketDate: string;
        loading: boolean;
        constructor(private basketService: DtBasketService,
            private redirectionService: Core.IRedirectionService,
            private $state: ng.ui.IStateService,
            private commandeService: CommandeService) { }

        $onInit() {
            this.loading = true;
            this.basketService.getBasketDate(+this.restaurantId).then(date => {
                if (date) {
                    this.basketDate = moment(date).format('YYYY-MM-DD');
                }
            }).finally(() => {
                this.loading = false;
                this.commandeService.getMesCommandes(true).then(commmandes => {
                }); //TODO pourquoi il n'y a rien de codé ici?
            });
        }

        goBack = () => {

            // web version do the wordlone navigation in the same view than the app so we have to do 2 back nav
            window.history.go(this.redirectionService.isRedirectionDoneInSeparateView ? -2 : -3);
        }
    }
}