module Core {
    export class AppCoreConfiguratorProvider implements ng.IServiceProvider {
        public config($compileProvider: ng.ICompileProvider,
            $httpProvider: ng.IHttpProvider,
            $stateProvider: ng.ui.IStateProvider,
            $urlRouterProvider: ng.ui.IUrlRouterProvider,
            localizeServiceProvider: IS.Localization.LocalizeServiceProvider,
            userServiceProvider: UserServiceProvider,
            cookieServiceProvider: CookieServiceProvider,
            routeServiceProvider: RouteServiceProvider,
            localStorageProvider: ILocalStorageProvider) {

            if (!$httpProvider.defaults.headers.get) {
                $httpProvider.defaults.headers.get = <any>{};
            }

            //disable IE ajax request caching
            (<any>$httpProvider.defaults.headers.get)['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
            (<any>$httpProvider.defaults.headers.get)['Cache-Control'] = 'no-cache';
            (<any>$httpProvider.defaults.headers.get)['Pragma'] = 'no-cache';

            routeServiceProvider.configure();
            $compileProvider.debugInfoEnabled(true);
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|ghttps?|ms-appx|x-wmapp0):/);
            $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|data|base64|blob|ghttps?|ms-appx|x-wmapp0):/);
            (<any>$httpProvider.defaults)['useXDomain'] = true;
            delete (<any>$httpProvider.defaults.headers.common)['X-Requested-With'];

            var preferences = localStorageProvider.getObject('preferences');
            var culture = preferences ? preferences.culture : null;
            (<any>$httpProvider.defaults.headers.common)['Accept-Language'] = culture;

            localizeServiceProvider.configure([{ code: 'fr', name: 'Français' }, { code: 'en', name: 'English' }], culture);

            var themes = [{ id: 'dark', name: 'Dark', cssClass: 'dark-theme' }, { id: 'light', name: 'Light', cssClass: 'light-theme' }]
            userServiceProvider.configure(themes);
            cookieServiceProvider.configure();

            $httpProvider.interceptors.push('authenticationInterceptor');
            $httpProvider.interceptors.push('siteNotFoundInterceptor');         
        }

        $get = [() => { return new AppCoreConfigurator(); }];
    }

    export class AppCoreConfigurator {
        constructor() {
        }

        public run($state: ng.ui.IStateService,
            $rootScope: IRootScopeWithHeaderConfiguration,
            $exceptionHandler: ng.IExceptionHandlerService,
            $http: ng.IHttpService,
            userService: UserService,
            cookieService: CookieService,
            routeService: RouteService,
            localizeService: IS.Localization.LocalizeService,
            pushManager: IPushManagerService,
            configService: IS.Configuration.ConfigService,
            uuidService: UuidService) {

            //(<any>window).DataAnalyticsSender.initialize();

            $rootScope.$on('configUpdated', (a) => {
                if (userService.isAuthenticated()) {
                    userService.getNotificationParameters("push").then((param) => {
                        if (param.isAnimationEnable
                            || param.isEnqueteEnable
                            || param.isAlerteSoldeEnable
                            || param.isPaiementEnable) {
                            pushManager.registerPushInformation();
                        }
                    });
                }
            });

            $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams, error) => {
                event.preventDefault();
                if (error.reason === 'notauthenticated') {
                    return $state.go('login');
                }

                $exceptionHandler(error);
            });

            $rootScope.$on('resourcesUpdated', () => {
                (<any>$http.defaults.headers.common)['Accept-Language'] = localizeService.getCurrentLanguage();
            });


            let gaUser = configService.getConfigValue("gaUser");
            var uuid = uuidService.getUuid();
            this.initializeGoogleAnalytics(gaUser, uuid);

            $rootScope.$on('configUpdated', () => {
                gaUser = configService.getConfigValue("gaUser");
                uuid = uuidService.getUuid();
                this.initializeGoogleAnalytics(gaUser, uuid);
            });
        }

        private initializeGoogleAnalytics(gaUser, uuid): void {
            if (gaUser && gaUser != '') {
                let ga = (<any>(window)).ga;
                ga('create', gaUser, {
                    'storage': 'none',
                    'clientId': uuid
                });
                ga('set', 'checkProtocolTask', null);
            }
        }
    }
    angular.module('core').provider('appCoreConfigurator', [AppCoreConfiguratorProvider]);
}