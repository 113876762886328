module Easypass {

    export class AnalyticsService {

        constructor() { }

        private sendEvent(eventCategory: string, eventAction: string, eventLabel: string) {
            var ga = (<any>window).ga;

            ga('send', 'event', {
                eventCategory,
                eventAction,
                eventLabel
            });
        }
    }

    angular.module('easypass').service('analyticsService');
}