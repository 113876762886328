module Easypass {
    class LoginController {

        login: string;
        password: string;
        loginFailed: boolean;
        accountLocked: boolean;
        isLoging: boolean;
        error: boolean;
        connectionFailed: boolean;
        sessionExpired: boolean;
        redirectUrl: string;
        isSsoLogin: boolean;

        constructor(
            private $state: ng.ui.IStateService,
            private userService: Core.UserService,
            private $stateParams: ng.ui.IStateParamsService,
            private $location: ng.ILocationService,
            private $timeout: ng.ITimeoutService,
            private $window: ng.IWindowService,
            private $rootScope: ng.IRootScopeService,
            private restaurantsService: RestaurantsService
        ) {
            (<any>window).DataAnalyticsSender.sendCustomEvent('Login_Screen', '');
            this.sessionExpired = $stateParams['sessionExpired'];
            this.redirectUrl = $stateParams['redirectUrl'];

            $rootScope.isEmbedded = !!$stateParams['isEmbedded'];
            
            // params : site & nom & prenom & email & timestamp & token & matricule
            if (!!$stateParams['token']) {
                this.isSsoLogin = true;
                if (!!$stateParams['timestamp']) {

                    // If the user is already authenticated redirect the user to the right screen
                    if (this.userService.isAuthenticated()) {
                        if (this.userService.userInformations.email === $stateParams['email']) {
                            console.log('user already authenticated --> redirect if needed');
                            this.redirect($stateParams['redirectUrl'], this.userService.userInformations);
                        }
                        // the user authenticated is not the good one, signout
                        else {
                            this.userService.signOut();
                            this.signIn(new Core.AuthAutoLoginRequest(
                                $stateParams['site'],
                                $stateParams['prenom'],
                                $stateParams['nom'],
                                $stateParams['email'],
                                $stateParams['timestamp'],
                                $stateParams['token'],
                                $stateParams['matricule']),
                                $stateParams['redirectUrl']);
                        }

                    }
                    else {
                        this.signIn(new Core.AuthAutoLoginRequest(
                            $stateParams['site'],
                            $stateParams['prenom'],
                            $stateParams['nom'],
                            $stateParams['email'],
                            $stateParams['timestamp'],
                            $stateParams['token'],
                            $stateParams['matricule']),
                            $stateParams['redirectUrl']);
                    }
                }
                else {
                    this.signIn(new Core.AuthTokenRequest(
                        $stateParams['site'],
                        $stateParams['token']),
                        $stateParams['redirectUrl']);
                }
            }
            else {
                this.isSsoLogin = false;
                if (!!$stateParams['refreshToken']) {
                    this.signInInternal(
                        $stateParams['site'],
                        $stateParams['refreshToken']
                    );
                }
            }
        }

        submit(): void {
            this.loginFailed = false;
            this.connectionFailed = false;
            this.accountLocked = false;
            this.error = false;

            this.signIn(new Core.AuthRequest(this.login, this.password), this.redirectUrl);
        }

        protected signIn(signInRequest: Core.IAuthRequest, redirectUrl?: string): void {
            console.log('signIn begin');

            this.isLoging = true;
            (<any>window).DataAnalyticsSender.sendCustomEvent('Login_BTN_SignIn', '');

            this.userService.signIn(signInRequest, Core.Scopes.TIMECHEF)
                .then((userInformations) => {
                    this.$rootScope.$broadcast("changeTheme");
                    this.redirect(redirectUrl, userInformations);
                }, (response) => {
                    // TODO: StatusCode=0

                    if (response.status === -1) {
                        this.connectionFailed = true;
                    } else if (response.status === 404) {
                        this.$state.go('register', { email: response.data.email, nom: response.data.nom, prenom: response.data.prenom, site: response.data.site, association: true });
                    } else if (response.status === 500 && response.data && response.data.exception && response.data.exception === 'UserLockedOutException') {
                        this.accountLocked = true;
                    } else if (response.status === 500
                        && response.data
                        && response.data.exception
                        && response.data.exception === 'ConfirmedEmailRequiredException'
                        && signInRequest instanceof Core.AuthRequest) {
                        this.$state.go('confirmEmail', <any>{ email: signInRequest.username, callFrom: 'login' }, { location: 'replace' });
                    } else if (response.status === 401) {
                        this.loginFailed = true;
                    } else {
                        this.loginFailed = true;
                    }
                })
                .finally(() => {
                    this.isLoging = false;

                });
        }

        protected redirect(redirectUrl: string, userInformations: Core.IUserInformations): void {
            if (redirectUrl && this.checkReturnUrl(redirectUrl)) {
                // Dans le cas d'un restaurant on appel la fonction de redirection des restaurants
                var matches = redirectUrl.match('^\/restaurant\/(?<restaurantId>\\d+)$');
                if (matches && matches.length > 1) {
                    let restaurantId = +matches[1];
                    console.log(`goToRestaurantId ${restaurantId}`);
                    this.$timeout(() => {
                        this.restaurantsService.goToRestaurantId(restaurantId);
                    }, 0, false);
                } else {
                    console.log('Redirect using redirectUrl');
                    this.$timeout(() => {
                        this.$location.url(redirectUrl);
                    }, 0, false);
                }
            }
            else {
                if (userInformations.currentSite.restaurantsDisplayed) {
                    console.log('Redirect to restaurants');
                    this.$state.go('restaurants');
                } else {
                    console.log('Redirect to Recharger because restaurantsDisplayed is false');
                    this.goToCompteRecharger();
                }
            }
        }

        protected signInInternal(sitename: string, refreshToken: string): void {
            //
        }

        goToCompteRecharger(): void {
            this.$state.go('credit');
        }

        checkReturnUrl(url: string): boolean {
            if (url && !(/^\/[^\/].*/.test(url))) {
                // This is an extra check to prevent open redirects.
                console.error(`Invalid return url (${url}). The return url needs to be relative.`);
                return false;
            }
            return true;
        }
    }
    angular.module('easypass').controller('loginController', ['$state', 'userService', '$stateParams', '$location', '$timeout', '$window', '$rootScope', 'restaurantsService', LoginController]);
} 