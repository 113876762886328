module Core {
    export class UuidService {
        constructor(private storageService: StorageService) {
            for (var i = 0; i <= 15; i++) {
                this.dec2hex[i] = i.toString(16);
            }
        }

        dec2hex: string[] = [];


        getUuid = () => {
            var uuid = this.storageService.getUuid();
            if (uuid)
                return uuid;            
            uuid = '';
            for (var i = 1; i <= 36; i++) {
                if (i === 9 || i === 14 || i === 19 || i === 24) {
                    uuid += '-';
                } else if (i === 15) {
                    uuid += 4;
                } else if (i === 20) {
                    uuid += this.dec2hex[(Math.random() * 4 | 0 + 8)];
                } else {
                    uuid += this.dec2hex[(Math.random() * 15 | 0)];
                }
            }
            this.storageService.saveUuid(uuid);
            return uuid;
        };
    }
    angular.module('core').service('uuidService', ['storageService',UuidService]);
}

    