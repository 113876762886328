module Core {
    class HelpController {
        templates: string[];

        constructor(
            private localizeService: IS.Localization.LocalizeService,
            private $stateParams: ng.ui.IStateParamsService
        ) {
            this.templates = [];
            var registrationType = $stateParams['registrationType'];

            this.templates.push(`./resources/aide/register1.html`);
            this.templates.push(`./resources/aide/register2.html`);

            switch (registrationType) {
                case 'solde':
                    this.templates.push(`./resources/aide/register3solde.html`);
                    break;
                case 'badge':
                    this.templates.push(`./resources/aide/register3badge.html`);
                    break;
                default:
                    this.templates.push(`./resources/aide/register3.html`);
                    break;
            }
        }

    }
    angular.module('core').controller('helpController', ['localizeService', '$stateParams', HelpController]);
}