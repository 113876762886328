module Easypass {
    class CreditSuccessCallbackController {
        restaurantId: number;
        transactionId: number;

        constructor(private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private storageService: StorageService,
            private ticketsService: TicketsService,
            private userService: Core.UserService,
            private $rootScope: ng.IRootScopeService) {
            var panier = storageService.getPanier();
            this.ticketsService.resetLocalTickets();
            this.$rootScope.$broadcast('soldeChanged');
            if (panier) {
                this.restaurantId = panier.restaurantId;
            }

            this.transactionId = +$stateParams['transactionId'];
        }

        goTo() {
            this.$state.go("panier", { restaurantId: this.restaurantId });
        }
    }
    angular.module('easypass').controller('creditSuccessCallbackController', ["$stateParams", "$state", "storageService", "ticketsService", "userService","$rootScope", CreditSuccessCallbackController]);
} 