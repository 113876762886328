module Core {
    class ThemeParametresController {
        
        themes: ITheme[];
        error: boolean;
        networkError: boolean;

        constructor(private userService: UserService,
            private $rootScope: ng.IRootScopeService) {
            this.themes = userService.themes;
        }

        isSelected(theme: ITheme) {
            return theme.id === this.userService.getCurrentTheme().id;
        }

        select(theme: ITheme) {
            if (!this.isSelected(theme)) {
                var preferences = this.userService.getPreferences();
                preferences.theme = theme.id;
                this.$rootScope.$broadcast("changeTheme");
                this.userService.setPreferences(preferences)
                    .then(() => {
						this.error = false;
						this.networkError = false;
                    }, error => {
						checkNetworkError(error,
							() => {
								this.networkError = false;
								this.error = true;
							},
							() => {
								this.error = false;
								this.networkError = true;
							});
						// TODO: StatusCode=0
                    });
            }
        }

    }
    angular.module('core').controller('themeParametresController', ['userService', '$rootScope', ThemeParametresController]);
}