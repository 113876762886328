module Core {
    export class VoteCampagneService {
        constructor(
            private $http: ng.IHttpService,
            protected $q: ng.IQService,
            protected configService: IS.Configuration.ConfigService) {
        }

        /** Récupére l'état du vote sur le restaurant.
         * Si vote en cours et si user peut voter
         */
        public getVoteState(restaurant: IRestaurant): ng.IPromise<IVoteStateGlobal> {
            var defered = this.$q.defer<IVoteStateGlobal>();

            if (restaurant.voteEstActif) { // Ce check permet d'optimiser les appels serveurs sans raison.
                this.$http.get<IVoteStateGlobal>(`${this.configService.getConfigValue('url')}/vote/restaurant/${restaurant.id}/vote-state`)
                    .success((voteState: IVoteStateGlobal) => {
                        defered.resolve(voteState);
                    }).error(error => {
                        defered.reject(error);
                    });
            } else {
                defered.resolve(<IVoteStateGlobal>{
                    restaurantHasVoteEnCours: false,
                    convivePeutVoter: false,
                    conceptThematiqueStates: []
                });
            }

            return defered.promise;
        }

        public getVoteStateByRestaurantId(restaurantId: number): ng.IPromise<IVoteStateGlobal> {
            return this.getVoteState({
                id: restaurantId,
                voteEstActif: true
            } as IRestaurant);
        }

        /** Récupére la compage de vote en cours. */
        public getVoteEnCours(restaurantId: number) {
            var defered = this.$q.defer<IVoteModel>();

            this.$http.get<IVoteModel>(`${this.configService.getConfigValue('url')}/vote/restaurant/${restaurantId}/vote-en-cours`)
                .success((vote: IVoteModel
                ) => {
                    defered.resolve(vote);
                }).error(error => {
                    defered.reject(error);
                });

            return defered.promise;
        }

        /** Récupére les résultats des votes dont les services n'ont pas encore démarrés. */
        public getVoteLastResultats(restaurantId: number) {
            var defered = this.$q.defer<IVoteLastResultatModel>();

            this.$http.get<IVoteLastResultatModel>(`${this.configService.getConfigValue('url')}/vote/restaurant/${restaurantId}/derniers-resultats-vote`)
                .success((voteResultats: IVoteLastResultatModel
                ) => {
                    defered.resolve(voteResultats);
                }).error(error => {
                    defered.reject(error);
                });

            return defered.promise;
        }

        /** Prise en charge du choix de vote du convive. */
        public conviveVote(voteChoix: IVoteChoixModel): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();

            var uri = `${this.configService.getConfigValue('url')}/vote/convive-effectue-vote`;
            this.$http.post(uri, voteChoix)
                .success(() => deferred.resolve())
                .catch(error => deferred.reject(error));
            return deferred.promise;
        }

        /** Récupére les informations d'une thématique dans un vote */
        public getThematiqueByVoteCampagne(campagneId: number, thematiqueId: number) {
            var defered = this.$q.defer<IThematiqueDetail>();

            this.$http.get<IThematiqueDetail>(`${this.configService.getConfigValue('url')}/vote/campagne/${campagneId}/thematique/${thematiqueId}`)
                .success((thematique: IThematiqueDetail
                ) => {
                    this.castDatesFormats(thematique);
                    defered.resolve(thematique);
                }).error(error => {
                    defered.reject(error);
                });

            return defered.promise;
        }

        /** Récupére les informations d'une thématique dans un service. */
        public getThematiqueByService(serviceId: number, thematiqueId: number) {
            var defered = this.$q.defer<IThematiqueDetail>();

            this.$http.get<IThematiqueDetail>(`${this.configService.getConfigValue('url')}/vote/service/${serviceId}/thematique/${thematiqueId}`)
                .success((thematique: IThematiqueDetail
                ) => {
                    this.castDatesFormats(thematique);
                    defered.resolve(thematique);
                }).error(error => {
                    defered.reject(error);
                });

            return defered.promise;
        }

        /** Récupére la sous thématique gagnante */
        public getSousThematiqueGagnante(restaurant: IRestaurant, date?: Date) {
            var defered = this.$q.defer<ISousThematiqueGagnanteGlobal>();
            if (restaurant.voteEstActif) {
                this.$http.get<ISousThematiqueGagnanteGlobal>(`${this.configService.getConfigValue('url')}/vote/restaurant/${restaurant.id}/sous-thematique-gagnantes` + (date ? '?date=' + date.toUTCString() : ''))
                    .success((sousThematiquesGlobal: ISousThematiqueGagnanteGlobal
                    ) => {
                        defered.resolve(sousThematiquesGlobal);
                    }).error(error => {
                        defered.reject(error);
                    });
            }

            return defered.promise;
        }

        /**  Récupére les informations sur le concept de vote(description marketing de l'escale) */
        public getConceptInfos(conceptId = null) {
            var defered = this.$q.defer<IConceptInfos>();

            var url = conceptId ? `${this.configService.getConfigValue('url')}/vote/info-concept/${conceptId}`
                                : `${this.configService.getConfigValue('url')}/vote/info-concept`;
            this.$http.get<IConceptInfos>(url)
                .success((voteType: IConceptInfos
                ) => {
                    defered.resolve(voteType);
                }).error(error => {
                    defered.reject(error);
                });

            return defered.promise;
        }

        /** Convertir les dates en string (récupérés par l'API) au format date */
        private castDatesFormats(thematique) {
            thematique.sousThematiques.forEach(
                st => {
                    st.dateDebut = moment(st.dateDebut).toDate();
                    st.dateFin = moment(st.dateFin).toDate();
                }
            );
        }

    }

    angular.module('core').service('voteCampagneService', ['$http', '$q', 'configService', VoteCampagneService]);
}