module Core {
    interface IStrongPasswordDirectiveScope extends ng.IScope {
        state: ValidationState,
    }

    class StrongPasswordDirective implements ng.IDirective {
        restrict: string = 'A';
        require: string = 'ngModel';
        scope = {
            state: '=strongPassword'
        };

        link(scope: IStrongPasswordDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ngModel: any) {
            let validator = () => {
                ngModel.$setValidity('notSafe', scope.state == ValidationState.valid);
            }
            scope.$watch((scope: IStrongPasswordDirectiveScope) => scope.state, validator)
        }

        static factory() {
            var directive = () => {
                return new StrongPasswordDirective();
            };
            return directive;
        }
    }

    angular.module('core').directive('strongPassword', [StrongPasswordDirective.factory()]);
}