module Easypass {
    class RegistrationSoldeBadgeController {
        constructor(private $scope: ng.IScope,
            private $state: ng.ui.IStateService,
            private userService: Core.UserService,
            private siteService: Core.SiteService,
            private $stateParams: ng.ui.IStateParamsService,
            private $rootScope: Core.IRootScopeWithHeaderConfiguration,
            private storageService: StorageService,
            private $timeout: ng.ITimeoutService
        ) {
        }

        registration: Core.IRegistrationForm = <Core.IRegistrationForm>{};
        isRegistering: boolean = false;
        isUserRegistered: boolean = false;
        isRegistrationFailed: boolean;
        errorMessage: string;
        updateScrollIosNum: number = 0;
        updateScrolllIosTimer: any;
        updateScrollIos = () => {
            if (this.updateScrolllIosTimer && this.updateScrolllIosTimer.$$state.status == 0) {
                this.$timeout.cancel(this.updateScrolllIosTimer);
            }
            this.updateScrolllIosTimer = this.$timeout(200).then(() => this.updateScrollIosNum++);
        };

        tmpDateNaissance: string;
        showNumeroBadge(): boolean {
            return this.registration.site && this.registration.site.hasBadgeRegistration;
        }


        showSolde(): boolean {
            return this.registration.site && this.registration.site.hasSoldeRegistration;
        }

        selectAccount(account: Core.IMatchingAccount): void {
            this.submit(account.numeroBadge);
            this.updateScrollIos();
        }


        goToCgu(): void {
            this.storageService.saveRegistration(this.registration);
            this.$state.go('cgu');
        }
        goToHelp(): void {
            this.storageService.saveRegistration(this.registration);
            var registrationType = "";
            if (!this.registration.site) {
                registrationType = "unknown";
            }
            else if (this.registration.site.hasSoldeRegistration) {
                registrationType = "solde";
            }
            else if (this.registration.site.hasBadgeRegistration) {
                registrationType = "badge";
            }
            this.$state.go('help', { registrationType: registrationType });
        }

        cancel(): void {
            this.updateScrollIos();
            this.registration.matchingAccounts = null;
            this.registration.isReadOnly = false;
        }


        submit(enforceNumeroBadge: string): void {
            this.updateScrollIos();
            this.isRegistrationFailed = false;
            if (!this.registration.accepteCgu) {
                this.isRegistrationFailed = true;
                this.errorMessage = 'Do_Accept_CGU';
                this.updateScrollIos();
            }
            else {
                var reg = <Core.IRegistrationUser>{
                    numeroBadge: this.registration.numeroBadge,
                    solde: this.registration.solde,
                    accepteCgu: this.registration.accepteCgu,
                };

                this.isRegistering = true;
                this.updateScrollIos();
                //this.userService.register(reg, enforceNumeroBadge)
                //    .then(() => {
                //        this.userService.signIn(new AuthRequest(reg.email, reg.password))
                //            .then(() => {
                //                this.updateScrollIos();
                //            }, error => {
                //                // TODO: StatusCode=0
                //            })
                //            .finally(() => {
                //                this.$state.go('restaurants');
                //            });
                //    }, (error: IErrorMessage<any>) => {
                //        // TODO: StatusCode=0

                //        switch (error.code) {
                //            case 300:
                //                this.registration.matchingAccounts = error.data;
                //                this.registration.isReadOnly = true;
                //                break;
                //            case 404:
                //                this.isRegistrationFailed = true;
                //                this.errorMessage = 'Convive_Not_Found';
                //                break;
                //            case 400:
                //                this.isRegistrationFailed = true;
                //                this.errorMessage = 'Email_Already_Used';
                //                break;
                //            default:
                //                this.isRegistrationFailed = true;
                //                this.errorMessage = 'Registration_Error';
                //        }
                //    }).finally(() => {
                //        this.isRegistering = false;
                //        this.updateScrollIos();
                //    });
            }
        }


        forceAccepteCGU = (element: boolean) => {
            this.updateScrollIos();
            this.registration.accepteCgu = !element;
        };
    }

    angular.module('easypass').controller('registrationSoldeBadgeController',
        ['$scope', '$state', 'userService', 'siteService', '$stateParams', '$rootScope', 'storageService', '$timeout', RegistrationSoldeBadgeController]);
}