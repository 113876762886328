module Easypass.DinerTakeAway {
    @Component('easypass', 'dtBasketArticlePortion', {
        controllerAs: 'vm',
        bindings: {
            item: '=',
            removeItem: '&',
            quantityChanged: '&',
            basketCapacity: '<',
        },
        templateUrl: './dtBasketArticlePortion.html'
    })
    class BasketArticlePortionComponent {
        static $inject: string[] = [];
        

        item: IBasketItemDTA;

        _basketCapacity: number;

        portionSolo: Core.PortionType = Core.PortionType.Simple;
        portionFamille: Core.PortionType = Core.PortionType.Famille;
        portionDuo: Core.PortionType = Core.PortionType.Duo;

        nbArticlesOptions: number[] = [];

        removeItem: (data: { item: IBasketItemDTA }) => void;
        quantityChanged: (data: { item: IBasketItemDTA }) => void;
        $this = this;

        constructor() {
        }

        $onInit = () => {
            //this.updateNbArticlesOptions();
        }


        set basketCapacity(capacity: number)
        {
            this._basketCapacity = capacity;
            
            // TODO : Find a way to use this.updateNbArticlesOptions()
            let limit = this.item.quantity + this._basketCapacity;

            this.nbArticlesOptions = [];

            for (let i = 0; i <= limit; i++) {
                this.nbArticlesOptions.push(i);
            }
        }

        get basketCapacity(): number
        {
            return this._basketCapacity;
        }

        $onChanges = (changes) => {
        }

        $onDestroy = () => {
        }

        updateNbArticlesOptions = () => {
            let limit = this.item.quantity + this._basketCapacity;

            this.nbArticlesOptions = [];

            for (let i = 0; i <= limit; i++) {
                this.nbArticlesOptions.push(i);
            }
        }

        updateQuantity = () => {
            this.quantityChanged({ item: this.item });
            //this.updateNbArticlesOptions();
        }

        remove = () => {
            this.removeItem({ item: this.item });
        }
    }
}