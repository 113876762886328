module Core {
    angular.module('core').filter('noteToText', [() => {
        return (note: number): string => {
            switch (note) {
                case 0: return "Note0Stars";
                case 1: return "Note1Stars";
                case 2: return "Note2Stars";
                case 3: return "Note3Stars";
                case 4: return "Note4Stars";
                case 5: return "Note5Stars";
                default: return "Note0Stars";
            }
        };
    }]);
}