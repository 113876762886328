module Core {
    export class PanierServiceBase {
        protected paniers: { [index: number]: IPanier };
        maxQuantity: number = 10;

        constructor(
            protected $http: ng.IHttpService,
            protected configService: IS.Configuration.ConfigService,
            protected carteService: CarteService,
            protected $q: ng.IQService,
            protected $rootScope: ng.IRootScopeService
        ) {
            this.paniers = {};

            this.$rootScope.$on('clearUserInMemoryData', () => {
                this.paniers = {};
            });
        }

        getPanier(restaurantId: number): IPanier {
            if (!this.paniers[restaurantId]) {
                this.resetPanier(restaurantId);
            }
            return this.paniers[restaurantId];
        }

        setPanier(restaurantId: number, panier: IPanier) {
            this.paniers[restaurantId] = panier;
        }

        resetPanier(restaurantId: number) {
            this.paniers[restaurantId] = {
                id: null,
                articles: [],
                prix: 0
            };
            this.broadcastPanierChanged(restaurantId);
        }

        ajouterArticleAuPanier(restaurantId: number, article: IArticle, categorie: ICategorieCarte): boolean {
            if (!this.paniers[restaurantId]) {
                this.resetPanier(restaurantId);
            }

            var articleIndex = _.findIndex(this.paniers[restaurantId].articles, art => art.id === article.id && (art.articles == null || art.articles.length === 0));
            if (articleIndex !== -1) {
                var currentArticle = this.paniers[restaurantId].articles[articleIndex];

                if (currentArticle.quantite + article.quantite > this.maxQuantity) {
                    return false;
                }

                currentArticle.quantite += article.quantite;
            } else {
                article.quantite = article.quantite ? article.quantite : article.quantiteMinimum || 1;
                this.paniers[restaurantId].articles.push(this.createPanierArticleFromArticle(article, categorie, false));
            }
            this.broadcastPanierChanged(restaurantId);
            (<any>window).DataAnalyticsSender.sendCustomEvent('Item_BTN_Add', article.libelle);
            return true;
        }

        retirerArticleAuPanier(restaurantId: number, article: IPanierArticle) {
            var articleIndex = _.findIndex(this.paniers[restaurantId].articles, art => art === article);
            if (articleIndex !== -1) {
                this.paniers[restaurantId].articles[articleIndex].quantite--;
                if (this.paniers[restaurantId].articles[articleIndex].quantite <= 0) {
                    this.paniers[restaurantId].articles.splice(articleIndex, 1);
                }
                this.broadcastPanierChanged(restaurantId);
            }
        }

        supprimerArticleDuPanier(restaurantId: number, article: IPanierArticle) {
            var articleIndex = _.findIndex(this.paniers[restaurantId].articles, art => art === article);
            if (articleIndex !== -1) {
                this.paniers[restaurantId].articles.splice(articleIndex, 1);
                this.broadcastPanierChanged(restaurantId);
                (<any>window).DataAnalyticsSender.sendCustomEvent('Cart_BTN_Delete', '');
            }
        }

        ajouterComposableAuPanier(restaurantId: number, composable: IArticle, categorie: ICategorieCarte) {
            var panierCompo = this.createPanierArticleFromArticle(composable, categorie, composable.estFormule);
            panierCompo.articles = [];
            composable.sousCategories.forEach(categorie => {
                panierCompo.articles = panierCompo.articles.concat(categorie.sousArticles.filter(x => x.estSelectionne)
                    .map(x => this.createPanierArticleFromArticle(x, null, true)));
            });
            if (composable.estComposable) {
                panierCompo.articles = panierCompo.articles.concat(composable.sousArticles.map(x => this.createPanierArticleFromArticle(x, null, true)));
            }

            this.paniers[restaurantId].articles.push(panierCompo);
            this.broadcastPanierChanged(restaurantId);
        }

        createPanierArticleFromArticle(article: IArticle, categorie: ICategorieCarte, estFormule: boolean): IPanierArticle {

            var sousArticles = article.sousArticles ? article.sousArticles.map(x => this.createPanierArticleFromArticle(x, null, true)) : null;
            return {
                id: article.id,
                libelle: article.libelle,
                prix: article.prix,
                articles: sousArticles,
                quantite: article.quantite ? article.quantite : article.quantiteMinimum || 1,
                quantiteLivree: null,
                estFormule: estFormule,
                aSuggegerer: categorie ? categorie.aSuggerer : false,
                proposeSuggestion: categorie ? categorie.proposeSuggestion : false,
                categorieId: categorie ? categorie.rootId : null,
                tva: article.tva,
                quantiteMaximum: article.quantiteMaximum ? article.quantiteMaximum : null,
                quantiteMinimum: article.quantiteMinimum ? article.quantiteMinimum : null,
                dateLivraison: article.dateLivraison,
                fournisseur: article.fournisseur,
                restrictionSalle: article.restrictionSalle,
                descriptionPrix: article.descriptionPrix
            };
        }

        getCategoriesToSuggest(restaurantId: number): ng.IPromise<ICategorieCarte[]> {
            var deferred = this.$q.defer<ICategorieCarte[]>();
            var categoriesToSuggest: ICategorieCarte[] = [];
            var panier = this.getPanier(restaurantId);
            if (_.some(panier.articles, article => article.estFormule)
                || !_.some(panier.articles, article => article.proposeSuggestion)) {
                deferred.resolve(categoriesToSuggest);
            } else {
                this.carteService.getCarte(restaurantId).then(carte => {
                    var categories = carte.categories.filter(cat => cat.aSuggerer && !_.some(panier.articles, art => art.categorieId === cat.id));
                    setTimeout(() => deferred.resolve(categories), 500);
                }).catch(error => deferred.reject(error));
            }

            return deferred.promise;
        }

        broadcastPanierChanged(restaurantId: number) {
            this.calculerPrixPanierByRestaurantId(restaurantId);
            this.$rootScope.$broadcast('panierChanged');
        }

        calculerPrixPanierByRestaurantId(restaurantId: number) {
            var panier = this.paniers[restaurantId];

            this.calculerPrixPanier(panier);
        }

        calculerPrixPanier(panier: IPanier) {
            var prix = panier.articles
                .map(x => x.quantite * x.prix)
                .reduce((prev, current) => prev + current, 0);

            panier.prix = Math.ceil(prix * 100.0) / 100.0; // HACK: Bug javascript => 1.9 * 3 = 5.699999999 au lieu de 5.7
        }
    }
}