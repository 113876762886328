module Easypass {
    class ClickAndCollectInnovorderController {
        restaurant: Core.IRestaurant;
        isLoading: boolean = true;
        networkError: boolean;
        clickAndCollectUrl: string;

        constructor(private $rootScope: Core.IRootScopeWithHeaderConfiguration,
            private restaurantsService: RestaurantsService,
            private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private $sce: ng.ISCEService
        ) {
            //this.isLoading = true;
            var restaurantId = +$stateParams['id'];

            this.restaurantsService.getInnovorder(restaurantId).success(innovorder => {
                this.clickAndCollectUrl = $sce.trustAsResourceUrl(innovorder.url);
            }).catch(error => {
                checkNetworkError(error, () => { /* do nothing */ }, () => this.networkError = true);
            //    // TODO: StatusCode=0
            });
        }
    }

    angular.module('easypass').controller('clickAndCollectInnovorderController',
        ['$rootScope', 'restaurantsService', '$stateParams', '$state', '$sce', ClickAndCollectInnovorderController]);
}
