module Core {
    @Component('core', 'soldeComponent', {
        controllerAs: 'vm',
        bindings: {},
        templateUrl: './solde.html'
    })
    class SoldeComponent {
        static $inject: string[] = ['userService'];

        constructor(private userService: Core.UserService) {
            this.subscriber = this.userService.soldeChanged.subscribe(soldeInfos => {
                this.soldeInfos = soldeInfos;
            });
        }

        private subscriber: Rx.IDisposable;

        soldeInfos: ISoldeInfos = {
            solde: null,
            dirty: true,
			alert: false
        };

        updating: boolean = false;
        showPopin: boolean = false;
        soldeUpdated: boolean = false;
        soldeUpToDate: boolean = false;
        soldeUpdateFailed: boolean = false;

        $onDestroy() {
            if (this.subscriber != null) {
                this.subscriber.dispose();
                this.subscriber = null;
            }
        }

        refresh() {
            this.updating = true;

            this.soldeUpdated = false;
            this.soldeUpToDate = false;
            this.soldeUpdateFailed = false;

            setTimeout(() => {

                this.userService.refreshSolde().then(newSolde => {
                    this.showPopin = true;

                    if (this.soldeInfos == null || this.soldeInfos.solde !== newSolde) {
                        this.soldeUpdated = true;
                    }
                    else {
                        this.soldeUpToDate = true;
                    }
                }).catch(() => {
                    this.showPopin = true;
                    this.soldeUpdateFailed = true;
                }).finally(() => {
                    this.updating = false;
                });

            }, 3000);
        }
    }
}