module Easypass {
    export class CreditService {
        constructor(
            private $rootScope: ng.IRootScopeService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService,
            private storageService: StorageService,
            private userService: Core.UserService
        ) {
        }

        getSiteConfig(): ng.IPromise<ICreditConfiguration> {
            var deferred = this.$q.defer<ICreditConfiguration>();

            this.$http.get<any>(`${this.configService.getConfigValue('url')}/${this.userService.userInformations.currentSite.name}/credit/config`)
                .success((creditConfiguration: ICreditConfiguration) => {
                    deferred.resolve(creditConfiguration);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        getRestaurantConfig(restaurantId: number): ng.IPromise<ICreditConfiguration> {
            var deferred = this.$q.defer<ICreditConfiguration>();

            this.$http.get<any>(`${this.configService.getConfigValue('url')}/${this.userService.userInformations.currentSite.name}/credit/restaurant/${restaurantId}/config`)
                .success((creditConfiguration: ICreditConfiguration) => {
                    deferred.resolve(creditConfiguration);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        postCredit = (montant: number, moyenPaiement: IMoyenPaiement, conviveMoyenPaiement: Core.IConviveMoyenPaiement, transactionType: TypeTransaction, commandeId: number = null): ng.IPromise<Core.ICreditRedirection> => {
            var deferred = this.$q.defer<Core.ICreditRedirection>();
            var conviveMoyenPaiementId: number = null;
            if (conviveMoyenPaiement) {
                conviveMoyenPaiementId = conviveMoyenPaiement.id;
            }
            this.$http.post<Core.ICreditRedirection>(`${this.configService.getConfigValue('url')}/${this.userService.userInformations.currentSite.name}/${moyenPaiement.controllerName}`, {
                montant,
                moyenPaiement,
                conviveMoyenPaiementId,
                transactionType,
                commandeId
            })
                .success((creditRedirection: Core.ICreditRedirection) => {
                    deferred.resolve(creditRedirection);
                })
                .catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }
    }

    angular.module('easypass').service('creditService', ['$rootScope', '$http', '$q', 'configService', 'storageService', 'userService', CreditService]);
}