module Easypass {
    var  toHHMM = (input : Date) =>{
        var toHhmmss = input.toTimeString().split(" ")[0];
        return toHhmmss.substring(0,5);
     }

    angular.module('easypass').filter('hoursRange', function () {
       
        return function (input : ICreneau, separation : string) {
            var dateDebut = toHHMM(new Date(input.dateDebut.toString()));

            if (!moment(input.dateDebut).isSame(Date.now(), 'day')) {
                dateDebut = moment(input.dateDebut).format('dddd') + ' ' + dateDebut
            }

            if(!input.dateFin){
                return dateDebut;
            }
            var dateFin = toHHMM(new Date(input.dateFin.toString()));
            var result = dateDebut + separation +  dateFin;
            return result;
        };
    });
}