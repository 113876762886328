module Easypass {
    interface IPanierItemDirectiveScope extends ng.IScope {
        article: Core.IPanierArticle;
        restaurantId: number;
        isScrolled: boolean;
    }

    class PanierItemDirective implements ng.IDirective {
        restrict: string = 'E';

        constructor(private panierService: PanierService) {
        }

        scope = {
            article: '=',
            restaurantId: '='
        }

        template = `
<div class="panier-item clear no-border">
    <div data-ng-click="vm.remove()" class="remove no-border"><img height="20" src="./styles/assets/pictos/delete.svg"/></div>
    <div class="prix-quantite no-border">
        <div class='flex-container no-border'>
            <select ng-model="vm.article.quantite" ng-change="vm.quantiteChanged()" ng-options="n for n in [] | range:1:vm.max"></select>
            <span class="prix" currency="vm.article.prix * vm.article.quantite"  ng-style="{'visibility': vm.article.prix ? 'visible' : 'hidden'}"></span>
        </div>
    </div>
    <div class="libelle no-border">
        <div class="article-libelle no-border">{{vm.article.libelle}}</div>
        <div class="composition-libelle no-border" ng-class="{ 'active' : vm.isScrolled }" data-ng-if="vm.article.articles != null">{{vm.formuleComp}}</div>
    </div>   
    <div ng-if='vm.article.articles.length > 0' class='dropdown-chevroon  no-border' ng-class="{ 'active' : vm.isScrolled }" ng-click='vm.toggleClass()' class='no-border'>
        <div class=" no-border">
            <img src="./styles/assets/pictos/up-chevroon.png">
        </div>
    </div>
</div>
`;

        controller = ["$scope", "panierService", function ($scope: IPanierItemDirectiveScope, panierService: PanierService) {
            this.article = $scope.article;
            this.max = panierService.maxQuantity;
            this.isScrolled = false;
            if ($scope.article.articles) {
                this.formuleComp = $scope.article.articles.map(x => x.libelle).join(" | ");
            }
            this.remove = () => {
                panierService.supprimerArticleDuPanier($scope.restaurantId, this.article);
            };
            this.quantiteChanged = () => {
                panierService.broadcastPanierChanged($scope.restaurantId);
            };
            this.toggleClass = () => {
                this.isScrolled = this.isScrolled ? false : true;
            }
        }];

        controllerAs = 'vm';

        static factory() {

            var directive = (panierService: PanierService) => {
                return new PanierItemDirective(panierService);
            };

            return directive;
        }

    }


    angular.module('easypass').directive('panierItem', ["panierService", PanierItemDirective.factory()]);
}