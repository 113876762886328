module Easypass.DinerTakeAway {
    @Component('easypass', 'dtOffer', {
        controllerAs: 'vm',
        bindings: {
            plat: '<',
            disableQuantity: '<',
            isLocker: '<',
            onQuantityChanged: '&quantityChanged',
            restaurantId: '<',
            currentDate: '<'
        },
        templateUrl: './dtOffer.html'
    })
    class OfferComponent {
        static $inject: string[] = ['allergenesService'];

        plat: IArticleDTA;
        isLocker: boolean;
        allergenes: string[];
        isAllergic: boolean;
        restaurantId: number;
        currentDate: Date;

        constructor(
            protected allergeneService: Core.AllergenesService
        )
        {
        }

        $onInit = () => {
            if (this.plat) {
                this.allergenes = this.plat.allergenes
            }
            this.isAllergic = this.allergeneService.isAllergicToPlat(this.allergenes);
        }
    }
}