module Easypass {
    export class CommandeService {

        commandes: Core.ICommandeListItem[] = [];
        commandesDetails: Core.ICommandeDetail[] = [];
        apiUrl: string;

        constructor(
            private $http: ng.IHttpService,
            private configService: IS.Configuration.ConfigService,
            private storageService: Core.StorageService,
            private $q: ng.IQService,
            private $rootScope: ng.IRootScopeService,
            protected userService: Core.UserService
        ) {
            this.apiUrl = this.configService.getConfigValue('url');

            this.$rootScope.$on('clearUserInMemoryData', () => {
                this.commandes = [];
                this.commandesDetails = [];
            });
        }

        getCommandesFromStorage(): Core.ICommandeListItem[] {
            return this.storageService.getCommandes() || [];
        }

        getCommandesDetailsFromStorage(): Core.ICommandeDetail[] {
            return this.storageService.getCommandesDetails() || [];
        }

        getMesCommandes(forceServer: boolean = false): ng.IPromise<Core.ICommandeListItem[]> {
            var deferred = this.$q.defer<Core.ICommandeListItem[]>();

            if (forceServer) {
                this.storageService.deleteCommandesDetails();

                this.commandes = [];
                this.commandesDetails = [];
            }
            else {
                this.commandes = this.getCommandesFromStorage();
            }

            if (!!this.commandes && this.commandes.length !== 0) {
                deferred.resolve(this.commandes);
            }
            else {
                this.$http.get<any>(`${this.apiUrl}/commandes/?pageSize=10000`).success((commandes: Core.ICommandeListItem[]) => {

                    this.commandes = commandes;

                    this.storageService.saveCommandes(this.commandes);

                    deferred.resolve(this.commandes);
                }).error(error => {
                    deferred.reject(error);
                });
            }

            return deferred.promise;
        }


        getCommandeDetail(index: number, preloadSiblings: boolean = false): ng.IPromise<Core.ICommandeDetail> {
            var deferred = this.$q.defer<Core.ICommandeDetail>();

            this.commandes = this.getCommandesFromStorage();
            this.commandesDetails = this.getCommandesDetailsFromStorage();

            var commandeNotInStorage: boolean = false;
            var commandeId = this.commandes[index].commandeId;

            var commande: Core.ICommandeDetail;

            // Preload prev / next
            if (preloadSiblings) {
                if (!!this.commandes[index + 1]) { this.getCommandeDetail(index + 1); }
                if (!!this.commandes[index - 1]) { this.getCommandeDetail(index - 1); }
            }


            // Has something in localstorage
            if (!!this.commandesDetails && this.commandesDetails.length !== 0) {
                commande = _.find(this.commandesDetails, (commande) => { return commande.commandeId === commandeId; });

                // Commande found
                if (commande) {
                    deferred.resolve(commande);
                }
                else {
                    commandeNotInStorage = true;
                }
            }

            // Get commande from API
            if (!this.commandesDetails || this.commandesDetails.length === 0 || commandeNotInStorage) {
                this.$http.get<any>(`${this.apiUrl}/commandes/${commandeId}`).success((cmd) => {

                    if (cmd) {
                        this.commandesDetails.push(cmd);
                        this.storageService.saveCommandesDetails(this.commandesDetails);

                        deferred.resolve(cmd);
                    }
                    else {
                        deferred.reject();
                    }

                }).catch(() => {
                    deferred.reject();
                });

            }


            return deferred.promise;
        }

        getInnovorderCommandeListUrl(): ng.IHttpPromise<IInnovorder> {
            var apiUrl = this.configService.getConfigValue('url');
            return this.$http.get<IInnovorder>(`${apiUrl}/${this.userService.userInformations.currentSite.name}/commandes/innovorder`);
        }
    }

    angular.module('easypass').service('commandeService', ['$http', 'configService', 'storageService', '$q', '$rootScope', 'userService', CommandeService]);
}