module Core {
    class MenuItemsDirective implements ng.IDirective {
        restrict: string = 'E';
        templateUrl = "./menuItemsDirectiveTemplate.html";
        replace = true;

        link(scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery) {
        }

        static factory() {
            return () => {
                return new MenuItemsDirective();
            };
        }
    }

    angular.module('core').directive('menuItems', [MenuItemsDirective.factory()]);
}