module Easypass {
    class PlatController {
        constructor($stateParams: ng.ui.IStateParamsService,
            $rootScope: Core.IRootScopeWithHeaderConfiguration,
            private allergenesService: Core.AllergenesService,
            private platService: Core.PlatService,
            private restaurantsService: RestaurantsService,
            private panierService: PanierService,
            private $timeout: ng.ITimeoutService,
            private $state: ng.ui.IStateService,
            private configService: IS.Configuration.ConfigService
        ) {
            this.plat = $stateParams['plat'];
            this.imageUrl = this.plat.documentId ? `${this.configService.getConfigValue("url")}/documents/${this.plat.documentId}` : '';
            this.restaurantId = +$stateParams['restaurantId'];
            this.article = <Core.IArticle>$stateParams['article'];

            this.restaurantsService.get(this.restaurantId).then(restaurant => {
                this.restaurant = restaurant;
                this.getPlatDetails();
                (<any>window).DataAnalyticsSender.sendCustomEvent('Restaurant_BTN_Dish', this.restaurant.restaurantType);
            });
        }

        plat: Core.IPlat;
        restaurantId: number;
        restaurant: Core.IRestaurant;
        closed: boolean;
        article: Core.IArticle;
        comment: string;
        isLoading: boolean;
        isLoadingLike: boolean;
        error: boolean;
        networkError: boolean;
        active: boolean = false;
        refused: boolean = false;
        categorie: Core.ICategorieCarte;
        imageUrl: string;
        allergenes: Core.IAllergene[];
        typologiesTypes = Core.TypologiesTypes;
        mathRound = Math.round;

        caloriesKnobOptions: any = {
            startAngle: 0,
            endAngle: 360,
            size: 70,
            readOnly: true,
            max: 2000,
            trackWidth: 12,
            barWidth: 12,
            fontSize: 20, //px
            trackColor: 'rgba(176,172,173,1)',
            barColor: 'rgba(103,165,55,1)'

        };
        
        like() {
            if (!!this.plat) {
                this.isLoadingLike = true;
                this.error = false;
                this.networkError = false;
                this.platService.like(this.plat.id)
                    .then(() => {
                        this.platService.getPlatDetails(this.plat.id)
                            .then(data => {
                                this.plat.nbLike = data.nombreLikes;
                                this.plat.hasLike = data.like;
                            })
                            .catch(error => {
                                checkNetworkError(error, () => this.error = true, () => this.networkError = true);
                            })
                            .finally(() => {
                                this.isLoadingLike = false;
                            });
                    }
                    )
                    .catch(error => {
                        checkNetworkError(error, () => this.error = true, () => this.networkError = true);
                    })
                    .finally(() => {
                        if (this.restaurant != null && this.article != null) {
                            (<any>window).DataAnalyticsSender.sendCustomEvent('Item_BTN_Like', this.restaurant.libelle + '/' + this.plat.libelle);
                        }
                        // this.isLoadingLike = false;
                        // this.getPlatDetails();
                    });
            }
        }

        getPlatDetails() {
            this.isLoading = true;
            this.error = false;
            this.networkError = false;
            this.platService.getPlatDetails(this.plat.id)
                .then(data => {
                    this.plat.nbLike = data.nombreLikes;
                    this.plat.hasLike = data.like;
                    this.plat.allergenes = data.allergenes;
                    this.plat.description = data.description;
                    this.plat.nutriscore = data.nutriscore;
                    this.plat.calories = data.calories;
                    this.plat.typologies = data.typologies;
                    this.allergenes = this.allergenesService.subListForPlat(data.allergenes);
                })
                .catch(error => {
                    checkNetworkError(error, () => this.error = true, () => this.networkError = true);
                })
                .then(() => {
                    if (this.plat.fromClickAndCollect) {
                        //this.closed = !this.restaurantsService.getIsOrderOpen(this.restaurant);
                        let now = moment().toDate();
                        this.closed = !this.restaurantsService.getIsOrderOpen(this.restaurant)
                              || !this.restaurantsService.getIsAnOpenningDay(this.restaurant, now)
                              || (this.restaurant.isClickAndCollect && !this.restaurant.clickAndCollectActif); // Le service de commande n'est pas actif
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        buttonClick = () => {
            if (!this.article.estFormule) {

                if (this.panierService.ajouterArticleAuPanier(this.restaurantId, this.article, this.categorie)) {
                    if (this.restaurant != null && this.article != null) {
                        (<any>window).DataAnalyticsSender.sendCustomEvent('Restaurant_BTN_Add', this.restaurant.restaurantType + '/' + this.article.libelle);
                    }
                    this.active = true;
                    this.$timeout(1000).then(() => this.active = false);
                } else {
                    this.refused = true;
                    this.$timeout(1000).then(() => this.refused = false);
                }
            }
        }

        composeFormule = () => {
            this.$state.go("composeFormule", { article: this.article, id: this.restaurantId, categorie: this.categorie });
        }

    }

    angular.module('easypass').controller('platController', ['$stateParams', '$rootScope', 'allergenesService', 'platService', 'restaurantsService', 'panierService', '$timeout', '$state', 'configService', PlatController]);
}
