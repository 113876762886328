module Core {
    class LegalParametresController {

        templateUrl: string;

        constructor(
            private userService: UserService,
            private localizeService: IS.Localization.LocalizeService,
            private helperService: Core.HelperService) {

            let culture = localizeService.getCurrentCulture().code;
            if (!culture && userService.userInformations){
                culture = userService.userInformations.culture;
            }

            if (!culture){
                culture = 'fr';
            }
                
            var tcpro = "";
            if (helperService.isTcPro()) {
                tcpro = "-tcpro";
            }

            this.templateUrl = `./resources/legal/legal.${culture}${tcpro}.html`;
        }

    }
    angular.module('core').controller('legalParametresController', ['userService', 'localizeService', 'helperService', LegalParametresController]);
}