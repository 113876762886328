module Easypass.DinerTakeAway {
    @Component('easypass', 'dtOffers', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<',
            currentDate: '<'
        },
        templateUrl: './dtOffers.html'
    })
    class OffersComponent {
        static $inject: string[] = ['carteServiceDTA', 'restaurantsService', 'dtBasketService', '$state', '$q'];

        restaurantId: number;
        currentDate: string;
        internalDate: Date;

        carte: ICarteDTA;
        isLoading: boolean;
        isLocker: boolean;
        disableQuantity = false;
        basketIsExpired = false;
        basketIsEmpty: boolean = true;
        basketOnlyContainArticlesFromDivers: boolean = false;

        basket: BasketDTA;
        private basketObservableCanceller: Rx.Disposable;

        constructor(protected carteServiceDTA: CarteServiceDTA,
            protected restaurantsService: RestaurantsService,
            private basketService: DtBasketService,
            protected $state: ng.ui.IStateService,
            private $q: ng.IQService) {
            this.internalDate = new Date(this.currentDate);
        }

        $onInit = () => {
            var cartePromise = this.carteServiceDTA.getCarteDTA(this.restaurantId, this.currentDate);
            cartePromise.then(carte => {
                this.carte = carte;
            });

            this.restaurantsService.get(this.restaurantId).then(restaurant => {
                this.isLocker = restaurant.isDinnerTakeaway;
                if (this.isLocker) {
                    this.basketObservableCanceller = this.basketService.getBasketObserver(this.restaurantId, this.internalDate).subscribe(basket => {
                        this.basket = basket;
                        this.basketIsEmpty = basket.itemsNumber === 0;
                        this.disableQuantity = basket.isFull;

                        cartePromise.then(() => {
                            this.carte.entrees.forEach((element: IArticleDTA) => {
                                element.portions.forEach((portion: any) => {
                                    portion.quantity = this.basket.getQuantityForPortion(portion.articleId);
                                });
                            });

                            this.carte.plats.forEach((element: IArticleDTA) => {
                                element.portions.forEach((portion: any) => {
                                    portion.quantity = this.basket.getQuantityForPortion(portion.articleId);
                                });
                            });

                            this.carte.desserts.forEach((element: IArticleDTA) => {
                                element.portions.forEach((portion: any) => {
                                    portion.quantity = this.basket.getQuantityForPortion(portion.articleId);
                                });
                            });

                            var articleDiversQuantity = 0;
                            this.carte.divers.forEach((element: IArticleDTA) => {
                                element.portions.forEach((portion: any) => {
                                    portion.quantity = this.basket.getQuantityForPortion(portion.articleId);
                                    articleDiversQuantity += portion.quantity;
                                });
                            });

                            this.basketOnlyContainArticlesFromDivers = (articleDiversQuantity > 0 && articleDiversQuantity === basket.itemsNumber);
                        });
                    }, () => { }, () => this.basketIsExpired = true);
                }
            });
        }

        $onDestroy = () => {
            if (this.basketObservableCanceller) {
                this.basketObservableCanceller.dispose();
            }
        };

        quantityChanged = (portion: Core.IPortion, quantity: number) => {
            this.basketService.addOrUpdatePortion(this.restaurantId, this.internalDate, portion, quantity, true);
        };

        goToBasket = () => {
            this.$state.go('dt-basket', {
                restaurantId: this.restaurantId,
                currentDate: this.currentDate
            });
        }
    }
}