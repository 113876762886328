module Easypass {
    export enum CommandeState {
        CC_NON_PAYEE = 0,

        CC_PAYEE_EN_ATTENTE = 1,
        CC_PAYEE_EN_PREPARATION = 2,
        CC_PAYEE_PREPAREE = 3,
        CC_PAYEE_LIVREE = 4,

        CC_PAIEMENT_REFUSE = 99,

        // Room Service
        RS_RECU = 200,
        RS_EN_COURS_DE_TRAITEMENT = 201,
        RS_LIVREE = 202,
        RS_FACTURE = 203,
        RS_ANNULEE = 204,

        // DinnerTakeAway
        DTA_NON_PAYEE = 100,
        DTA_PAIEMENT_REFUSE = 101,
        DTA_PAYEE_COMMANDEE = 102,

        /// <summary>
        /// The order is available (locker is FILLED)
        /// </summary>
        DTA_PAYEE_DISPONIBLE = 103,

        DTA_PAYEE_NON_RETIREE = 104,

        DTA_PAYEE_RETIREE = 105,
        DTA_PAYEE_ANNULEE = 106,
        DTA_PAYEE_ERREUR_RESA_CASIER_PHYSIQUE = 107,
        DTA_PAYEE_RESA_CASIER_PHYSIQUE_FAITE = 108,
    }
}