module Easypass {
    export class LockerReservationService {
        constructor(private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService) {
        }

        getCurrentReservations(restaurantId: number): ng.IPromise<Easypass.ILockerReservation[]> {
            var deferred = this.$q.defer<Easypass.ILockerReservation[]>();

            this.$http.get<Easypass.ILockerReservation[]>
                (`${this.configService.getConfigValue('url')}/${restaurantId}/locker/myStartedReservations`)
                .success((data: any) => {
                    data.forEach(element => {
                        element.id = element.id;
                        element.startDate = new Date(element.startDate);
                        element.targetDate = new Date(element.targetDate);
                    });
                    deferred.resolve(data);
                })
                .catch(e => deferred.reject(e));

            return deferred.promise;
        }

        createReservation(restaurantId: number, date: Date): ng.IPromise<any> {
            var deferred = this.$q.defer();

            this.$http.post(`${this.configService.getConfigValue('url')}/${restaurantId}/locker/${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`, {})
                .success(r => deferred.resolve(r))
                .catch(e => deferred.reject(e));

            return deferred.promise;
        }

        getReservationByCommande(commandeId: number): ng.IPromise<any> {
            var deferred = this.$q.defer();

            this.$http.post(`${this.configService.getConfigValue('url')}/`, {})
                .success(r => deferred.resolve(r))
                .catch(e => deferred.reject(e));

            return deferred.promise;
        }
    }
    angular.module('easypass').service('lockerReservationService',
        ['$http', '$q', 'configService', LockerReservationService]);
}