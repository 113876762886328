module Easypass {
    interface IProductDirectiveScope extends ng.IScope {
        article: Core.IArticle;
        restaurantId: number;
        categorie: Core.ICategorieCarte;
        closed: boolean;
        composeFormule: () => void;
    }

    class ProductDirective implements ng.IDirective {
        restrict: string = 'E';

        constructor(private $http: ng.IHttpService) {

        }

        scope = {
            article: '=',
            restaurantId: '=',
            closed: '=',
            categorie: '='
        }

        template = `
<div class="product clear">
    <div ng-if="vm.showPicture" class="product-image" ng-style="{backgroundImage: 'url('+vm.imageUrl+'), url(./styles/assets/default-restaurant.jpg)'}"> </div>
    <div class="product-information">
        <div class="libelle">{{vm.article.libelle}}</div>
        <div class="price" currency="vm.article.prix" ng-style="{\'visibility\': vm.article.prix ? \'visible\' : \'hidden\'}"></div> 
    </div>
    <div class="action" ng-if="!vm.closed">
        <button ng-if="!vm.article.estFormule && !vm.article.estComposable" ng-disabled="vm.active" ng-class="{ active: vm.active, refused: vm.refused }" class="backfacable-button" data-is-touchend="vm.buttonClick()" data-i18n-attr="Product_Added|data-hover/Product_Full|data-product-full"><span data-i18n="Product_Add"></span></button>
        <button ng-if="vm.article.estFormule || vm.article.estComposable" is-touchend="vm.composeFormule()" ng-disabled="vm.active" ng-class="{ active: vm.active, refused: vm.refused }" class="backfacable-button" data-i18n-attr="Product_Added|data-hover/Product_Full|data-product-full"><span data-i18n="Product_Compose"></span></button>
    </div>
</div>
`;
        controller = ["$scope", "panierService", "configService", "$timeout", "$state", function ($scope: IProductDirectiveScope, panierService: PanierService, configService: IS.Configuration.ConfigService, $timeout: ng.ITimeoutService, $state: ng.ui.IStateService) {
            this.article = $scope.article;
            this.imageUrl = `${configService.getConfigValue("url")}/documents/${$scope.article.imageId}`
            this.showPicture = $scope.article.imageId != null;
            this.active = false;
            this.refused = false;
            this.closed = $scope.closed;
            this.buttonClick = () => {
                if (!this.article.estFormule) {
                    if (panierService.ajouterArticleAuPanier($scope.restaurantId, this.article, $scope.categorie)) {
                        this.active = true;
                        (<any>window).DataAnalyticsSender.sendCustomEvent('Item_BTN_Add', this.article.libelle);
                        $timeout(1000).then(() => this.active = false);
                    } else {
                        this.refused = true;
                        $timeout(1000).then(() => this.refused = false);
                    }
                }
            };  
            this.composeFormule = () => {
                $state.go("composeFormule", { article: $scope.article, id: $scope.restaurantId, categorie: $scope.categorie })
            }
        }];

        controllerAs = 'vm';

        link = (scope: IProductDirectiveScope, element: ng.IAugmentedJQuery): void => {
        };

        static factory() {

            var directive = ($http: ng.IHttpService) => {
                return new ProductDirective($http);
            };

            return directive;
        }

    }


    angular.module('easypass').directive('product', ['$http', ProductDirective.factory()]);
}