Easypass.Registration.setCCRegister((stateProvider: ng.ui.IStateProvider) => {

    //Attention dupliqu� du Core.routeservice
    var headerWithKey = (key: string, showBackButton: boolean = false): ng.ui.IState => ({
        template: `<h1 data-i18n="${key}" data-ng-class="{ 'with-back': ${showBackButton} }"></h1>`
    });

    //Attention dupliqu� dans timechef et pro registration
    var easypassHeader = (...classList: string[]): ng.ui.IState => ({
        template: `
                <h1 class="${classList.concat('easypass-title').join(' ')}">
                    <div class="logo" brand-specific-logo data-is-tcpro="false"></div>
                </h1>
            `
    })

    stateProvider.state('clickAndCollectInnovorder', <Core.IStateWithConfiguration>{
        url: '/click-and-collect-Innovorder/{id}',
        parent: 'globalLayout',
        menuItem: Core.MenuItem.Restaurant,
        params: { index: null, categorie: null },
        views: {
            header: easypassHeader(),
            "": {
                templateUrl: './clickAndCollectInnovorder.html',
                controller: 'clickAndCollectInnovorderController',
                controllerAs: 'vm'
            }
        },
        headerConfiguration: {
            showMenu: true,
            activeMenu: true
        },
        transitionConfiguration: {
        }
    }).state('clickAndCollect', <Core.IStateWithConfiguration>{
        url: '/click-and-collect/{id}',
        parent: 'globalLayout',
        menuItem: Core.MenuItem.Commande,
        params: { index: null, categorie: null },
        views: {
            "": {
                templateUrl: './clickAndCollect.html',
                controller: 'clickAndCollectController',
                controllerAs: 'vm'
            }
        },
        headerConfiguration: {
            showMenu: true,
            activeMenu: true
        },
        transitionConfiguration: {
        }
    }).state('composeFormule', <Core.IStateWithConfiguration>{
        url: '/click-and-collect/{id}/compose-formule',
        menuItem: Core.MenuItem.Commande,
        parent: 'globalLayout',
        params: { article: null, categorie: null, stopAnimation: null },
        views: {
            "": {
                templateUrl: './composeFormule.html',
                controller: 'composeFormuleController',
                controllerAs: 'vm'
            }
        },
        headerConfiguration: {
            showBack: true,
            showMenu: false,
            activeMenu: true
        },
        transitionConfiguration: {
        }
    }).state('composeComposable', <Core.IStateWithConfiguration>{
        url: '/click-and-collect/{id}/compose-composable',
        menuItem: Core.MenuItem.Commande,
        parent: 'globalLayout',
        params: { article: null, parent: null },
        views: {
            "": {
                templateUrl: './composeFormule.html',
                controller: 'composeFormuleController',
                controllerAs: 'vm'
            }
        },
        headerConfiguration: {
            showBack: true,
            showMenu: false,
            activeMenu: true
        },
        transitionConfiguration: {
        }
    });
});