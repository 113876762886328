module Core {

    interface ICarouselScope extends ng.IScope {
        reloadUi: () => void;
        sliderClass: string;
        isAutomatic: boolean;
    }

    class CarouselDirective implements ng.IDirective {

        constructor(private $timeout: ng.ITimeoutService,
            private $interval: ng.IIntervalService,
            private $window: ng.IWindowService) {
        }


        replace: boolean = true;
        priority = 1;
        restrict: string = 'AE';
        transclude = true;
        template = `<div>
                        <div ng-transclude class="slider clear" ></div>
                        <div class= "dots"></div>
                    </div>`;
        nbImage: number;
        currentX = 0;
        element: ng.IAugmentedJQuery;
        subParent: ng.IAugmentedJQuery;
        dots: ng.IAugmentedJQuery;
        index: number;
        delta = 0;
        touchX = 0;
        max = 0;
        dragging = false;
        sliderClass: string;
        isAutomatic: boolean;
        interval: any;

        scope = {
            sliderClass: '@sliderClass',
            isAutomatic: '='
        };

        mouseClick = (evt: MouseEvent, interval?: boolean) => {
            if (!interval) {
                this.$interval.cancel(this.interval);
                this.setSliderInterval();
            }
            var oldIndex = this.index;
            if (this.index < this.nbImage - 1) {
                this.index++;
            }
            else if (this.index = this.nbImage - 1) {
                this.index = 0;
            }
            else {
                this.index = oldIndex;
            }
            this.switchToSlide(this.index);
            // console.log(this.delta);
        }

        private switchToSlide(slideIndex?: number) {
            this.index = slideIndex || this.index;
            var width = this.isAutomatic ? window.innerWidth : this.element[0].clientWidth;
            this.currentX = this.index * width;
            requestAnimationFrame(() => {
                this.subParent.css('transition', '');
                this.dots.children().removeClass('active');
                this.dots.children()[this.index].className += ' active';
                this.subParent.css('transform', `translate3d(-${this.currentX}px,0,0)`);
            });
        }

        touchStart = (evt: PointerEvent | TouchEvent | MouseEvent) => {
            this.delta = 0;
            this.dragging = true;
            this.touchX = this.getXFromEvent(evt);
            requestAnimationFrame(() => {
                this.subParent.css('transition', 'none');
            });
        }

        touchMove = (evt: PointerEvent | TouchEvent | MouseEvent) => {
            if (!this.dragging) {
                return;
            }
            this.delta = this.touchX - this.getXFromEvent(evt);
            var shift = 20;
            if (this.isAutomatic) {
                shift = 0;
            }
            if (this.delta + this.currentX < -shift) {
                this.delta = -this.currentX - shift;
            } else if (this.delta + this.currentX > this.max + shift) {
                this.delta = this.max - this.currentX + shift;
            }

            requestAnimationFrame(() => {
                this.subParent.css('transform', `translate3d(${-(this.currentX)}px,0,0)`);
            });
        }

        touchEnd = (evt: PointerEvent | TouchEvent | MouseEvent) => {
            if (!this.dragging) {
                return;
            }
            this.dragging = false;
            this.currentX += this.delta;
            var oldIndex = this.index;
            this.index = this.delta > 0 ? this.index + 1 : this.index - 1;
            if (this.index < 0 || this.index >= this.nbImage || Math.abs(this.delta) < 10) {
                this.index = oldIndex;
            }
            this.delta = 0;
            var width = this.isAutomatic ? window.innerWidth : this.element[0].clientWidth;
            this.currentX = this.index * width;
            requestAnimationFrame(() => {
                this.subParent.css('transition', '');
                this.dots.children().removeClass('active');
                this.dots.children()[this.index].className += ' active';
                this.subParent.css('transform', `translate3d(-${this.currentX}px,0,0)`);
            });
        }

        link = (scope: ICarouselScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes): void => {
            this.isAutomatic = scope.isAutomatic;
            this.sliderClass = scope.sliderClass;

            scope.reloadUi = () => {
                if (!!this.subParent) {
                    var childs = this.subParent.children();
                    this.nbImage = childs.length;
                    this.max = (this.nbImage - 1) * element[0].clientWidth;
                    var dot = new Array(this.nbImage + 1).join('<div class="dot"></div>');
                    childs.addClass('slide');
                    while (this.dots[0].firstChild) {
                        this.dots[0].removeChild(this.dots[0].firstChild);
                    }
                    this.dots.append(dot);
                    this.dots.children()[0].className += ' active';
                }
            };

            if (this.isAutomatic) {
                this.setSliderInterval();
                var self = this;
                window.onblur = function () {
                    self.$interval.cancel(self.interval);
                };
                window.onfocus = function () {
                    self.setSliderInterval();
                };
            }

            this.$timeout().then(() => {
                this.subParent = angular.element(element[0].querySelector('[ng-transclude]'));
                var childs = this.subParent.children();
                if (!this.sliderClass) {
                    element.addClass('slider-container');
                } else {
                    element.addClass(scope.sliderClass);
                }
                this.nbImage = childs.length;
                this.index = 0;
                this.element = element;
                this.dots = angular.element(element[0].querySelector('.dots'));
                var dot = new Array(this.nbImage + 1).join('<div class="dot"></div>');
                var touchX = 0;
                this.currentX = 0;
                this.max = (this.nbImage - 1) * element[0].clientWidth;
                this.dots.append(dot);
                this.dots.children()[0].className += ' active';
                childs.addClass('slide');
                element.append(this.subParent);
                element.append(this.dots);
                element.css('-ms-touch-action', 'none');

                if ((<any>element[0])['on' + this.touchEvents.pointer[0]] !== undefined) {
                    this.touchEvents.activeEvent.push(this.touchEvents.pointer);
                } else {

                    if ((<any>element[0])['on' + this.touchEvents.touch[0]] !== undefined) {
                        this.touchEvents.activeEvent.push(this.touchEvents.touch);
                    }

                    if ((<any>element[0])['on' + this.touchEvents.mouse[0]] !== undefined) {
                        this.touchEvents.activeEvent.push(this.touchEvents.mouse);
                    }
                }
                element[0].addEventListener("click", this.mouseClick, false);

                for (let i = 0; i < this.touchEvents.activeEvent.length; i++) {
                    for (let j = 0; j < this.touchEvents.activeEvent[i].length; j++) {
                        element[0].addEventListener(this.touchEvents.activeEvent[i][j], this.touchEvents.handlers[j], false);
                    }
                }
            });

            // on s'abonne au resize pour recalculer la largeur du caroussel
            angular.element(this.$window)
                .bind('resize',
                    _.debounce(() => this.switchToSlide(), 100, { maxWait: 500 })
                );
        }

        setSliderInterval = () => {
            var self = this;
            this.interval = this.$interval(function () {
                self.mouseClick(null, true);
            }, 5000);
        }

        touchEvents = {
            pointer: ['pointerdown', 'pointermove', 'pointerup', 'pointercancel', 'pointerleave'],
            touch: ['touchstart', 'touchmove', 'touchend', 'touchcancel', 'touchleave'],
            mouse: ['mousedown', 'mousemove', 'mouseup', 'mouseout'],
            handlers: [this.touchStart, this.touchMove, this.touchEnd, this.touchEnd, this.touchEnd],
            activeEvent: <any>[]
        };

        getXFromEvent = (evt: any) => {
            if (evt.touches) {
                evt.preventDefault();
                return evt.touches[0].pageX;
            } else {
                return evt.pageX;
            }
        }

        static factory() {
            var directive = ($timeout: ng.ITimeoutService, $interval: ng.IIntervalService, $window: ng.IWindowService) => {
                return new CarouselDirective($timeout, $interval, $window);
            };
            return directive;
        }
    }

    angular.module('core').directive('carousel', ['$timeout', '$interval', '$window', CarouselDirective.factory()]);
}