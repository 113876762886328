module Core {
    angular.module('core').filter('restaurantsLayout', () => {
        return (input: IRestaurant[]): string => {
            if (!!input) {
                switch (input.length) {
                    case 1:
                        return 'restaurant-1';
                    case 2:
                        return 'restaurant-2';
                    case 3:
                        return 'restaurant-3';
                    default:
                        return '';
                }
            } else {
                return '';
            }
        };
    });
}