module Easypass {
  export class DownloadFileService {
    constructor() {}

    downloadCordova = (data: string) => {
        var dataUri = data.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
        var fileTransfer = new FileTransfer();
        var fileDirecoryPath = cordova.platformId === 'android'
                            ? cordova.file.externalApplicationStorageDirectory  + "ticket.jpg"
                            : cordova.file.syncedDataDirectory + "ticket.jpg";
        fileTransfer.download(
            dataUri,
            fileDirecoryPath,
            function (entry) {
                console.log("download complete: " + entry.toURL());
                cordova.plugins.disusered.open(entry.toURL(), 
                    function(){console.log("open with disusered OK"); } , 
                    function(error){console.log(JSON.stringify(error)); });
            },
            function (error) {
                console.log("download error source " + error.source);
                console.log("download error target " + error.target);
                console.log("download error code" + error.code);
            });
    };

    createDownloadLink = (filename: string, data: string) => {
        //cette méthode est inspirée de  : "https://gist.github.com/narainsagar/08a61bace498b909f7ef339f3e60c9bc"
        var dataUri = data.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
        var anchor = document.createElement('a');
        anchor.setAttribute('href', dataUri);
        anchor.setAttribute('download', filename);
        return anchor;
    };

  }
  angular.module("easypass").service("downloadFileService", [DownloadFileService]);
}
