module Core {
    @Component('core', 'voteClos', {
        controllerAs: 'vm',
        templateUrl: './voteClos.html',
        bindings: {
            dateProchainVote: '<'
        }
    })
    class VoteClosComponent {
        dateProchainVote: Date;
        tempsRestantEnJour: number;
        tempsRestantEnHeure: number;

        constructor() {
        }

        ngOnChanges() {
            console.log("cote clos ngOnChanges");
        }

        getNombreJourRestants(): number {
            if (this.dateProchainVote) {
                let today = moment().startOf('day');
                let tempsRestantEnJour = moment.duration(moment(this.dateProchainVote).startOf('day').diff(today));
                this.tempsRestantEnJour = Math.max(tempsRestantEnJour.asDays(), 0);

                let now = moment();
                let tempsRestantEnHeure = moment.duration(moment(this.dateProchainVote).diff(now));
                this.tempsRestantEnHeure = Math.max(tempsRestantEnHeure.asHours(), 1);

                return this.tempsRestantEnJour;
            }
            return null;
        }
    }
}