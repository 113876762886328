module Core {
    export class AuthenticationInterceptor {
        constructor(
            private $q: ng.IQService,
            private $injector: any) {
        }

        responseError: (rejection: any) => ng.IPromise<any> = (rejection: any) => {
            if (rejection.status === 401) {
                var $state: ng.ui.IStateService = this.$injector.get('$state');

                //  pour savoir si le contenu c'est du JSON -legacy false- /!\ rejection.config.headers['Content-Type'].indexOf('application/json') !== -1 /!\ content-type n'est pas toujours présent
                if (!!rejection.data && !!rejection.data.Code) {
                    if (rejection.data.Code === 8) { //rejection.data.Code = 8 correspond à l'exception (int)UnauthorizedCode.NOT_MATCHING_TC_AND_CAISSE_IDENTIFICATIONKEY

                        // renvoie vers la page d'association de compte en cas d'erreur
                        $state.go('register', { email: rejection.data.data.email, nom: rejection.data.data.nom, prenom: rejection.data.data.prenom, site: rejection.data.data.site, association: true });

                        return this.$q.reject(rejection);
                    }
                }

                if (rejection.config.url.indexOf('/oauth') === -1) {
                    console.log('responseError 401');
                    var $http: ng.IHttpService = this.$injector.get('$http');
                    var userService: UserService = this.$injector.get('userService');

                    return userService.refreshSignIn()
                        .then((token: IToken) => {
                            console.log('renew successfully');

                            rejection.config.headers['Authorization'] = `Bearer ${token.accessToken}`;
                            return $http(rejection.config);
                        }, (reason) => {
                            if (reason && reason.status === 401) {
                                console.log('renew failed, if unauthorized go to login page');
                                userService.signOut();
                                $state.go('login');
                                return this.$q.reject(rejection);
                            } else {
                                return this.$q.reject(reason);
                            }

                        });
                }
            }

            return this.$q.reject(rejection);
        }
    }

    angular.module('core')
        .service('authenticationInterceptor', ['$q', '$injector', AuthenticationInterceptor]);
}