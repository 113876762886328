module Easypass {
    export class EnqueteService extends Core.EnqueteService {
        constructor(
            $http: ng.IHttpService,
            $q: ng.IQService,
            configService: IS.Configuration.ConfigService,
            userService: Core.UserService,
            restaurantsService: RestaurantsService,
            private notationService: Core.INotationService,
            localizeService: IS.Localization.LocalizeService,
            rootScope: ng.IRootScopeService) {
            super($http, $q, configService, userService, restaurantsService, localizeService, rootScope);
        }

        checkOnSurvey(responsesRate: number) {
            super.checkOnSurvey(responsesRate);

            // check if convive should rate the app
            this.notationService.checkOnSurvey(responsesRate);
        }


    }

    angular.module('easypass').service('enqueteService', ['$http', '$q', 'configService', 'userService', 'restaurantsService', 'notationService', 'localizeService', '$rootScope', EnqueteService]);
}