module Easypass.DinerTakeAway {
    @Component('easypass', 'dtTermsAndConditions', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<'
        },
        templateUrl: './dtTermsAndConditions.html'
    })
    class TermsAndConditionsComponent {
        static $inject: string[] = ['dinnerTakeawayConfigService'];
        isLoading: boolean;
        hasError: boolean;
        restaurantId: number;

        config: IDinnerTakeawayConfiguration;

        constructor(private dinnerTakeawayConfigService: DinnerTakeawayConfigService) { }

        $onInit() {
            this.isLoading = true;

            this.dinnerTakeawayConfigService.getConfig(this.restaurantId)
                .then(c => {
                   
                    this.config = c;
                    this.isLoading = false;
                }).catch(r => { this.hasError = true; this.isLoading = false; });
        }
    }
} 