module Easypass {

    interface IPaymentBtnDirectiveScope extends ng.IScope {
        defaultText: string;
        confirmText: string;
        callbackFn: () => void;
    }


    class PaymentBtnDirective implements ng.IDirective
    {
        private timeoutTicker: ng.IPromise<any>;

        constructor(
            private localizeService: IS.Localization.LocalizeService,
            private $timeout: ng.ITimeoutService)
            {
                
            }

        restrict = 'A';

        scope =
        {
            defaultText: '<',
            confirmText: '<',
            callbackFn: '&'
        }

        link = (scope: IPaymentBtnDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes): void =>
        {
            var func = attrs['paymentBtn'];

            var firstClickDone: boolean = false;
            var secondClickDone: boolean = false;
            var clickInterval: number = 5000;

                         
            var setBtnText = () =>
            {
                if(firstClickDone) 
                    element[0].textContent = this.localizeService.getLocalizedResource(scope.confirmText);
                else
                    element[0].textContent = this.localizeService.getLocalizedResource(scope.defaultText);
            }

            var startTimer = () =>
            {
                this.timeoutTicker = this.$timeout(() =>
                {
                    stopTimer();
                }, clickInterval);
            }

            var stopTimer = () =>
            {
                if (this.timeoutTicker)
                {
                    this.$timeout.cancel(this.timeoutTicker);
                    this.timeoutTicker = undefined;

                    if(!secondClickDone) firstClickDone = false;
                    setBtnText();
                }
            }
            
            
            element.bind('click', (clickEvent) =>
            {
                clickEvent.preventDefault();
                clickEvent.stopPropagation();
                clickEvent.stopImmediatePropagation();


                if(firstClickDone)
                {
                    //secondClickDone = true;
                    stopTimer();

                    scope.callbackFn();
                }
                else
                {
                    firstClickDone = true;
                    
                    startTimer();
                }

                setBtnText();
            });
            

            setBtnText();

            scope.$on('resourcesUpdated', () =>
            {
                setBtnText();
            });
        };

       
        
        static factory()
        {
            return (localizeService: IS.Localization.LocalizeService, $timeout: ng.ITimeoutService) => {
                return new PaymentBtnDirective(localizeService, $timeout);
            };
        }
    }

    angular.module('easypass').directive('paymentBtn', ['localizeService', '$timeout', PaymentBtnDirective.factory()]);
}