module Easypass {
    class VoteResultatController {
        isLoading: boolean = true;
        isOnError: boolean = false;
        networkError: boolean;
        restaurantId: number;
        mode: string;
        voteResultats: Core.IVoteResultatModel[];
        voteResultatEnCours: Core.IVoteResultatModel;
        dateProchainVote: Date;
        conceptInfos: Core.IConceptInfos;
        descriptionToggled: boolean;
        sousThematiqueEnCours: Core.ISousThematiqueGagnanteGlobal;
        sousThematiqueEnCoursDefault: Core.ISousThematiqueGagnante;
        imageUrl: string;

        // Restaurant Data
        restaurant: Core.IRestaurant;
        isRestaurantOpen: boolean = false;



        constructor(
            private voteCampagneService: Core.VoteCampagneService,
            private restaurantsService: RestaurantsService,
            private configService: IS.Configuration.ConfigService,
            private storageService: Core.StorageService,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $stateParams: ng.ui.IStateParamsService,
            private routeService: Core.RouteService,
            private $window: ng.IWindowService,
            private $sce: ng.ISCEService
        ) {
            this.isOnError = false;
            this.restaurantId = +$stateParams['restaurantId'];
            this.mode = $stateParams['mode'];
            this.loadCachedImageUrl();

            this.loadVoteResultatModel();

            this.loadRestaurantInfos();
        }

        loadVoteResultatModel() {
            this.isOnError = false;
            this.isLoading = true;
            this.voteCampagneService.getVoteLastResultats(this.restaurantId)
                .then((lastResultats: Core.IVoteLastResultatModel) => {
                    if (lastResultats.resultats && lastResultats.resultats.length > 0) {
                        const votePlusProcheConceptThematique = lastResultats.resultats.sort((a, b) => {return a.dateFinVote < b.dateFinVote ? -1 : 1; });
                        const votePlusProcheConceptThematiqueId = votePlusProcheConceptThematique && votePlusProcheConceptThematique.length > 0 
                        ? votePlusProcheConceptThematique[0].conceptThematiqueId
                        :null;
                        this.loadVoteConceptInfos(votePlusProcheConceptThematiqueId);
                    }

                    this.voteResultats = lastResultats.resultats;
                    this.dateProchainVote = lastResultats.prochainVote ? lastResultats.prochainVote.dateDebutVote : undefined;

                    let voteEnCoursFound = this.voteResultats.filter(res => res.voteStatus === Core.VoteStatus.EnCours);
                    if (voteEnCoursFound.length > 0) {
                        this.voteResultatEnCours = voteEnCoursFound.sort((a, b) => {return a.dateFinVote > b.dateFinVote ? 1 : -1; } )[0];
                        if (this.mode === "merci") {
                            this.voteResultats = [this.voteResultatEnCours];
                        }
                    }

                })
                .catch((error) => {
                    this.isOnError = true;
                }).finally(() => { this.isLoading = false; });
        }

        loadCachedImageUrl() {
            var imageDocumentId = this.storageService.getImageConcept();
            if (imageDocumentId) {
                this.imageUrl = `${this.configService.getConfigValue("url")}/documents/${imageDocumentId}`;
            }
        }

        loadRestaurantInfos() {
            this.restaurantsService.get(this.restaurantId)
                .then(restaurant => {
                    this.restaurant = restaurant;
                    this.isRestaurantOpen = this.restaurantsService.getIsOpen(restaurant);
                    (<any>window).DataAnalyticsSender.sendCustomEvent('Crowd_Screen', restaurant.restaurantType);
                    this.loadSousThematiqueGagnante();
                })
                .catch(error => {
                    checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
                });
        }

        loadVoteConceptInfos(conceptThematiqueId) {
            this.voteCampagneService.getConceptInfos(conceptThematiqueId)
                .then((conceptInfos: Core.IConceptInfos) => {
                    this.conceptInfos = conceptInfos;
                    this.conceptInfos.description = this.$sce.trustAsHtml(this.conceptInfos.description);
                    this.storageService.saveImageConcept(this.conceptInfos.imageDocumentId);
                    this.imageUrl = !!this.conceptInfos.imageDocumentId ? `${this.configService.getConfigValue("url")}/documents/${this.conceptInfos.imageDocumentId}` : '';
                });
        }

        private loadSousThematiqueGagnante(date?: Date) {
            this.voteCampagneService.getSousThematiqueGagnante(this.restaurant, date).then(sousThematiqueEnCours => {
                this.sousThematiqueEnCours = sousThematiqueEnCours;
                this.sousThematiqueEnCoursDefault = sousThematiqueEnCours.defaultSousThematiqueGagnante;
            }, error => {
                this.sousThematiqueEnCours = undefined;
                this.sousThematiqueEnCoursDefault = undefined;
            });
        }

        gotoMenu() {
            this.$state.go('restaurant', { id: this.restaurantId });
        }

        toggleConceptInfos() {
            document.body.scrollTop = 0;
            this.$timeout(100).then(() => {
                this.descriptionToggled = !this.descriptionToggled;
            });
        }

        getImageUrl() {
            let imageDocumentId = null;
            let defaultImageUrl = './styles/assets/default-restaurant.jpg';

            if (this.restaurant) {
                if (this.restaurant.voteEstActif) {
                    // imageDocumentId = this.getThematiqueGagnanteImageDocumentId();
                    // if (!imageDocumentId) {
                        imageDocumentId = this.getConceptImageDocumentId();
                    // }
                    if (imageDocumentId) {
                        return `${this.configService.getConfigValue("url")}/documents/${imageDocumentId}`;
                    }
                }
                return this.restaurant.imageUrl;
            } else {
                return defaultImageUrl;
            }
        }
        private getThematiqueGagnanteImageDocumentId(): number {
            if (this.sousThematiqueEnCoursDefault) {
                return this.sousThematiqueEnCoursDefault.imageDocumentId;
            }

            //if (!this.selectedMenu || this.selectedMenu.date.getTime() === this.getToday().getTime()) {
            return this.storageService.getImageThematiqueGagnante(this.restaurant.id);
            //}
        }

        private getConceptImageDocumentId(): number {
            return this.conceptInfos
                ? this.conceptInfos.imageDocumentId
                : this.storageService.getImageConcept();
        }

        getPictogram(sousThematiqueId: number): string {
            return `${this.configService.getConfigValue('url')}/vote/sous-thematique/${sousThematiqueId}/pictogram`;
        }
    }

    angular.module('easypass').controller('voteResultatController', ['voteCampagneService', 'restaurantsService', 'configService', 'storageService', '$state', '$timeout', '$stateParams', 'routeService', '$window', '$sce', VoteResultatController]);
}