module Core {
    class GlobalHelpController {
        public templates: string[];
        hasFluidity: boolean = false;
        isRoomService: boolean = false;
        isClickandCollect: boolean = false;
        isBonPlan: boolean = false;
        isClickAndServe: boolean = false;
        constructor(
            private localizeService: IS.Localization.LocalizeService,
            private restaurantsService: RestaurantsService
        ) {
            let culture = localizeService.getCurrentCulture().code;
            this.restaurantsService.gets().then(restaurants => {
                this.hasFluidity = restaurants.some(restaurant => restaurant.hasFluidite);
                this.isRoomService = restaurants.some(restaurant => restaurant.isRoomService);
                this.isClickandCollect = restaurants.some(restaurant => restaurant.isClickAndCollect);
                this.isBonPlan = restaurants.some(restaurant => restaurant.isBonPlan);
                this.isClickAndServe = restaurants.some(restaurant => restaurant.isClickAndServe);

                this.templates = [`./resources/aide/refill.${culture}.html`];

                if (this.hasFluidity) {
                    this.templates.push(`./resources/aide/crowd.${culture}.html`);
                }

                this.templates.concat([
                    `./resources/aide/menu.${culture}.html`
                    , `./resources/aide/allergens.${culture}.html`
                    , `./resources/aide/survey.${culture}.html`
                ])

                if (this.isClickandCollect) {
                    this.templates.push(`./resources/aide/clickandcollect.${culture}.html`);
                }

                if (this.isClickAndServe) {
                    this.templates.push(`./resources/aide/clickandserve.${culture}.html`);
                }

                if (this.isBonPlan) {
                    this.templates.push(`./resources/aide/bonplan.${culture}.html`);
                }

                if (this.isRoomService) {
                    this.templates.push(`./resources/aide/roomservice.${culture}.html`);
                }

                this.templates.push(`./resources/aide/favorite.${culture}.html`);
            });

        }

    }
    angular.module('core').controller('globalhelpController', ['localizeService', 'restaurantsService', GlobalHelpController]);
}