module Core {
    class MoyenPaiementsParametresController {
        isLoading: boolean;
        isOnError: boolean;
        isOnNetworkError: boolean;

        conviveMoyenPaiements: Core.IConviveMoyenPaiement[];
        selectedConviveMoyenPaiement: Core.IConviveMoyenPaiement;
        isDeleteLoading: boolean;

        constructor(private userService: UserService) {
            (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_Moyen_Paiements', '');

            this.isOnError = false;
            this.isOnNetworkError = false;
            this.isLoading = true;

            userService.getMoyenPaiements()
                .then((moyenPaiements) => {
                    this.conviveMoyenPaiements = moyenPaiements;
                })
                .catch(error => {
                    checkNetworkError(error,
                        () => {
                            this.isOnNetworkError = false;
                            this.isOnError = true;
                        },
                        () => {
                            this.isOnError = false;
                            this.isOnNetworkError = true;
                        });
                    // TODO: StatusCode=0
                })
                .finally(() => {
                    this.isLoading = false;
                });

        }

        delete(conviveMoyenPaiement: Core.IConviveMoyenPaiement): void {
            this.isDeleteLoading = true;
            this.isOnNetworkError = false;

            (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_Moyen_Paiements_BTN_Delete', conviveMoyenPaiement.id);

            this.userService.deleteMoyenPaiements(conviveMoyenPaiement)
                .then(() => {
                    this.selectedConviveMoyenPaiement = null;
                    _.remove(this.conviveMoyenPaiements, (moyenPaiement: IConviveMoyenPaiement) => moyenPaiement === conviveMoyenPaiement);
                })
                .catch(error => {
                    checkNetworkError(error,
                        () => {
                            this.isOnNetworkError = false;
                            this.isOnError = true;
                        },
                        () => {
                            this.isOnError = false;
                            this.isOnNetworkError = true;
                        });
                    // TODO: StatusCode=0
                })
                .finally(() => {
                    this.isDeleteLoading = false;
                });
        }
    }
    angular.module('core').controller('moyenPaiementsParametresController', ['userService', MoyenPaiementsParametresController]);
}