module Easypass {
  class ClickAndCollectController {
    restaurant: Core.IRestaurant;
    animation: Core.IAnimation;
    isLoading: boolean = true;
    animations: Core.IAnimation[];
    carte: Core.ICarte;
    currentCategorie: Core.ICategorieCarte;
    errorLoadingCarte: boolean = false;
    campagne: Core.ICampagne;
    searchMode: boolean;
    search: string;
    startWhen: number = 1;
    closed: boolean;
    panier: Core.IPanier;
    nbArticles = () =>
      this.panier.articles.reduce((cur, next) => cur + next.quantite, 0);
    networkError: boolean;
    missingCarte: boolean = false;
    descriptionToggled: boolean = false;
    joursFermetures: Core.IDateInterval;

    toggleDescription() {
      document.body.scrollTop = 0;
      this.$timeout(100).then(() => {
        this.descriptionToggled = !this.descriptionToggled;
      });
    }

    constructor(
      private $rootScope: Core.IRootScopeWithHeaderConfiguration,
      private restaurantsService: RestaurantsService,
      private carteService: Core.CarteService,
      private $stateParams: ng.ui.IStateParamsService,
      private $state: ng.ui.IStateService,
      private animationService: Core.AnimationService,
      private enqueteService: Core.EnqueteService,
      private $timeout: ng.ITimeoutService,
      private panierService: PanierService,
      private $q: ng.IQService,
      private $sce: ng.ISCEService,
      private storageService: StorageService
    ) {
      this.isLoading = true;
      var id = +$stateParams["id"];
      this.panier = panierService.getPanier(id);
      var task1 = restaurantsService
        .get(id)
        .then((restaurant) => {
          this.restaurant = restaurant;

          //pour autoriser ng-binding-html à injecter le css des descriptions
          if (
            this.restaurant.description &&
            typeof this.restaurant.description === "string"
          ) {
            this.restaurant.description = $sce.trustAsHtml(
              this.restaurant.description
            );
          }

          (<any>window).DataAnalyticsSender.sendCustomEvent(
            "Restaurant_Screen",
            restaurant.restaurantType
          );

          this.joursFermetures =
            this.restaurantsService.getJoursFermetures(restaurant);

          this.animationService
            .getCurrent(this.restaurant.id)
            .then((animation) => {
              this.animation = animation;
            })
            .catch((error) => {
              // TODO: StatusCode=0
            });

          this.animationService
            .gets(false, this.restaurant.id)
            .then((animations) => {
              this.animations = animations;
            })
            .catch((error) => {
              // TODO: StatusCode=0
            });

          this.enqueteService
            .gets(false, this.restaurant.id)
            .then((campagnes) => {
              this.campagne = campagnes.length ? campagnes[0] : null;
            })
            .catch((error) => {
              // TODO: StatusCode=0
            });

          let now = moment().toDate();
          this.closed =
            !restaurantsService.getIsOrderOpen(restaurant) ||
            !restaurantsService.getIsAnOpenningDay(restaurant, now) ||
            (this.restaurant.isClickAndCollect &&
              !this.restaurant.clickAndCollectActif); // Le service de commande n'est pas actif
        })
        .catch((error) => {
          checkNetworkError(
            error,
            () => {
              /* do nothing */
            },
            () => (this.networkError = true)
          );
          // TODO: StatusCode=0
        });
      var task2 = carteService
        .getCarte(id, "", true)
        .then((result) => {
          if (!result) {
            this.missingCarte = true;
          } else {
            this.formatCarte(result);
          }
        })
        .catch((error) => {
          checkNetworkError(
            error,
            () => (this.errorLoadingCarte = true),
            () => (this.networkError = true)
          );
          // TODO: StatusCode=0
        })
        .finally(() => {
          this.isLoading = false;
        });

      $q.all([task1, task2]).then(() => {
        if (!this.carte && this.restaurant.description) {
          this.toggleDescription();
        }

        var savedCategorie = this.storageService.getString(
          "lastVisitedCategorie-" + this.restaurant.id
        );
        if (savedCategorie) {
          this.storageService.removeItem(
            "lastVisitedCategorie-" + this.restaurant.id
          );
          this.changeCategorie(
            _.find(
              this.carte.categories,
              (x) => x.id == parseInt(savedCategorie, 10)
            )
          );
        } else {
          setTimeout(() => this.startWhen++);
        }
      });
    }

    isOpen(): boolean {
      return this.restaurantsService.getIsOpen(this.restaurant);
    }

    private formatCarte(carteToFormat: Core.ICarte): void {
      this.carte = {
        dateDebut: new Date(<any>carteToFormat.dateDebut),
        dateFin: new Date(<any>carteToFormat.dateFin),
        id: carteToFormat.id,
        categories: [],
        fournisseurCatalogues: [],
      };
      carteToFormat.categories.map((categorieToFormat) => {
        this.carte.categories.push({
          id: categorieToFormat.id,
          rootId: categorieToFormat.id,
          imageId: categorieToFormat.id,
          libelle: categorieToFormat.libelle,
          prix: categorieToFormat.prix,
          quantiteMaximum: categorieToFormat.quantiteMaximum,
          quantiteMinimum: categorieToFormat.quantiteMinimum,
          sousArticles: null,
          sousLibelle: categorieToFormat.sousLibelle,
          sousCategories: this.computeCategories(categorieToFormat),
          aSuggerer: categorieToFormat.aSuggerer,
          proposeSuggestion: categorieToFormat.proposeSuggestion,
          isDesactivated: false,
          isToggled: false,
        });
      });
      var allArticles = <Core.ICategorieCarte>{
        id: 0,
        rootId: 0,
        sousLibelle: "Tous",
        sousCategories: [],
        imageId: 0,
        libelle: "Tous",
        prix: 0,
        quantiteMaximum: 0,
        quantiteMinimum: 0,
        sousArticles: [],
        aSuggerer: false,
        proposeSuggestion: false,
        isDesactivated: false,
      };
      this.carte.categories.map((categorieToFormat) => {
        categorieToFormat.sousCategories.map((sousCategorie) => {
          allArticles.sousCategories.push(sousCategorie);
        });
      });
      this.carte.categories.splice(0, 0, allArticles);
      var selectedCategorie = <Core.ICategorieCarte>(
        this.$stateParams["categorie"]
      );
      if (selectedCategorie) {
        this.currentCategorie = _.find(
          this.carte.categories,
          (x) => x.id == selectedCategorie.id
        );
      } else {
        this.currentCategorie = this.carte.categories[0];
      }
      this.scrollToActiveCategorie();
    }

    goToPlat(article: Core.IArticle, categorie: Core.ICategorieCarte) {
      var platArticle: Core.IArticle;
      if (article.sousArticles && article.sousArticles.length) {
        platArticle = article.sousArticles[0];
      } else if (!article.estFormule) {
        platArticle = article;
      }
      if (!platArticle) {
        return;
      }

      this.storageService.setString(
        "lastVisitedCategorie-" + this.restaurant.id,
        this.currentCategorie.id + ""
      );
      var plat: Core.IPlat = {
        id: platArticle.id,
        allergenes: [],
        animationId: null,
        documentId: platArticle.imageId,
        date: null,
        hasLike: false,
        nbLike: -1,
        fromClickAndCollect: true,
        libelle: platArticle.libelle,
        description: platArticle.description,
        prix: platArticle.prix,
        typologies: [],
      };
      this.$state.go("plat", {
        plat,
        article,
        restaurantId: this.restaurant.id,
        categorie,
      });
    }

    computeCategories(categorie: Core.ICategorieCarte): Core.ICategorieCarte[] {
      var categories: Core.ICategorieCarte[] = [];
      var articles: Core.IArticle[] = [];
      if (
        categorie.sousArticles.length > 0 &&
        categorie.sousCategories.length === 0
      ) {
        categories.push({
          id: categorie.id,
          rootId: categorie.id,
          sousLibelle: categorie.sousLibelle,
          sousCategories: null,
          sousArticles: categorie.sousArticles,
          imageId: categorie.imageId,
          libelle: categorie.libelle,
          prix: categorie.prix,
          quantiteMaximum: categorie.quantiteMaximum,
          quantiteMinimum: categorie.quantiteMinimum,
          aSuggerer: categorie.aSuggerer,
          proposeSuggestion: categorie.proposeSuggestion,
          isDesactivated: false,
          isToggled: false,
        });
        return categories;
      }

      if (categorie.sousCategories) {
        categorie.sousCategories.map((cat) => {
          categories.push({
            libelle: cat.libelle,
            rootId: categorie.id,
            prix: null,
            quantiteMinimum: cat.quantiteMinimum,
            quantiteMaximum: cat.quantiteMaximum,
            sousArticles: this.computeCarte(cat),
            id: cat.id,
            imageId: cat.imageId,
            sousCategories: null,
            sousLibelle: cat.sousLibelle,
            aSuggerer: categorie.aSuggerer,
            proposeSuggestion: categorie.proposeSuggestion,
            isDesactivated: false,
            isToggled: false,
          });
        });
        return categories;
      }
    }

    computeCarte(categorie: Core.ICategorieCarte): Core.IArticle[] {
      var articles: Core.IArticle[] = [];
      if (categorie.sousArticles) {
        articles = articles.concat(categorie.sousArticles);
      }
      if (categorie.sousCategories) {
        categorie.sousCategories.map((cat) => {
          articles = articles.concat(this.computeCarte(cat));
        });
      }
      return articles;
    }

    getLocalAnimationFromId(animationId: number): Core.IAnimation {
      return _.find(this.animations, { id: animationId });
    }

    isAnimationInProgress(animationId: number): boolean {
      var animation = this.getLocalAnimationFromId(animationId);
      return (
        !!animation &&
        animation.endAt > new Date() &&
        animation.startAt < new Date()
      );
    }

    animationPictoUrl(animationId: number): string {
      var animation = this.getLocalAnimationFromId(animationId);
      if (!!animation) {
        return _.find(this.animations, { id: animationId }).pictoUrl;
      }
      return null;
    }

    animationColor(animationId: number): string {
      var animation = this.getLocalAnimationFromId(animationId);
      if (!!animation) {
        return _.find(this.animations, { id: animationId }).backgroundColor;
      }
      return null;
    }

    goToAnimation(animation: Core.IAnimation) {
      this.$state.go("animationsOfRestaurantWithBack", {
        restaurantAnimationId: animation.restaurantAnimationId,
        restaurantId: animation.restaurantId,
      });
    }

    activateSearchMode() {
      (<any>window).DataAnalyticsSender.sendCustomEvent(
        "Restaurant_BTN_Search",
        ""
      );

      this.searchMode = true;
      this.currentCategorie = this.carte.categories[0];
      this.$timeout(() => {
        var menu = document.querySelector("#categories-container");
        menu.scrollLeft = 0;
      });
    }

    deactivateSearchMode() {
      this.searchMode = false;
      this.search = "";
      this.$timeout(() => {
        var menu = document.querySelector("#categories-container");
        menu.scrollLeft = 0;
      });
    }

    changeCategorie(categorie: Core.ICategorieCarte) {
      this.currentCategorie = categorie;
      this.startWhen++;
    }

    goToPanier = () => {
      this.$state.go("panier", {
        restaurantId: this.restaurant.id,
      });
    };

    scrollToActiveCategorie() {
      setTimeout(() => {
        var container = document.querySelector(".categories-container");
        var child = document.querySelector(".categories-container .active");
        if (container && child) {
          container.scrollLeft = (<any>child).offsetLeft - 205;
        }
      }, 0);
    }
  }

  angular
    .module("easypass")
    .controller("clickAndCollectController", [
      "$rootScope",
      "restaurantsService",
      "carteService",
      "$stateParams",
      "$state",
      "animationService",
      "enqueteService",
      "$timeout",
      "panierService",
      "$q",
      "$sce",
      "storageService",
      ClickAndCollectController,
    ]);
}
