module Easypass {
    export class FluiditeService {
        constructor(
            private $rootScope: ng.IRootScopeService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService,
            private storageService: StorageService,
            private userService: Core.UserService
        ) {
        }

        getFluidite(restaurantId: number): ng.IPromise<IFluidite> {
            var deferred = this.$q.defer<IFluidite>();

            this.$http.get<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurantId}/fluidite`)
                .success((fluidite: IFluidite) => {
                    deferred.resolve(fluidite);
                })
                .catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        getFluiditeStatut(restaurantId: number): ng.IPromise<IFluiditeStatut> {
            var deferred = this.$q.defer<IFluiditeStatut>();
            this.$http.get<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurantId}/fluidite/statut`)
                .success((fluiditeStatut: IFluiditeStatut) => {
                    deferred.resolve(fluiditeStatut);
                })
                .catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }
    }

    angular.module('easypass').service('fluiditeService', ['$rootScope', '$http', '$q', 'configService', 'storageService', 'userService', FluiditeService]);
}