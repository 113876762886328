module Easypass {

    interface IProgressBarScope extends ng.IScope {
        percent: number;
    }

    class ProgressBarDirective implements ng.IDirective {
        restrict: string = 'AE';
        template = `<div></div>`;

        scope = {
            percent: '='
        };

        link($scope: IProgressBarScope, elem: ng.IAugmentedJQuery) {
            var bar = <HTMLElement>elem[0].querySelector(':first-child');
            bar.style.width = `${$scope.percent}%`;
        }

        static factory() {
            return () => {
                return new ProgressBarDirective();
            };
        }
    }

    angular.module('easypass').directive('progressBar', [ProgressBarDirective.factory()]);
}