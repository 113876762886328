module Core {
    @Component('core', 'voteResultatBloc', {
        controllerAs: 'vm',
        bindings: {
            voteResultat: '<'
        },
        templateUrl: './voteResultatBloc.html'
    })
    class VoteResultatBlocComponent {
        voteResultat: IVoteResultatModel;
        slider: any;

        constructor() {
            this.forcePercentageIfNoVote();
            this.configSlider();
        }

        configSlider() {
            this.slider = {
                options: {
                    floor: 0,
                    ceil: Math.max(this.voteResultat.totalNombreDeVotes, 1),
                    showSelectionBar: true,
                    readOnly: true,
                    hidePointerLabels: true,
                    hideLimitLabels: true
                }
            };
        }

        forcePercentageIfNoVote() {
            var isThematiqueGagnanteSansVote = this.voteResultat.thematiqueGagnanteId && this.voteResultat.totalNombreDeVotes === 0;
            if (isThematiqueGagnanteSansVote) {
                this.voteResultat.thematiqueResultats.forEach(thematique => {
                    if (thematique.id === this.voteResultat.thematiqueGagnanteId) {
                        thematique.nombreDeVotes = 1,
                            thematique.percentage = 100;
                        thematique.roundedPercentage = 100;
                    }
                });
            }
        }

    }
}