module Easypass {
    class VoteThematiqueController {
        isLoading: boolean = true;
        isOnError: boolean = false;
        networkError: boolean;
        hasVoteEnCours: boolean;
        peutVoter: boolean;
        mode: string;
        thematiqueId: number;
        thematique: Core.IThematiqueDetail;
        sousThematiqueEnCours: Core.ISousThematiqueGagnante;
        campagneId: number;
        imageUrl: string;
        // Restaurant Data
        restaurantId: number;
        restaurant: Core.IRestaurant;
        isRestaurantOpen: boolean = false;

        constructor(
            private voteCampagneService: Core.VoteCampagneService,
            private restaurantsService: RestaurantsService,
            private configService: IS.Configuration.ConfigService,
            private storageService: Core.StorageService,
            private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private $window: ng.IWindowService
        ) {

            this.restaurantId = +$stateParams['restaurantId'];
            this.campagneId = +$stateParams['campagneId'];
            this.thematiqueId = +$stateParams['thematiqueId'];
            this.mode = $stateParams['mode'];
            this.loadCachedImageUrl();

            this.loadRestaurantInfos();
            this.loadThematique();
        }

        loadCachedImageUrl() {
            let imageDocumentId = this.storageService.getImageThematique(this.thematiqueId);
            if (imageDocumentId) {
                this.imageUrl = `${this.configService.getConfigValue("url")}/documents/${imageDocumentId}`;
            }
        }
        loadRestaurantInfos() {
            this.restaurantsService.get(this.restaurantId)
                .then(restaurant => {
                    this.restaurant = restaurant;
                    this.isRestaurantOpen = this.restaurantsService.getIsOpen(restaurant);
                    (<any>window).DataAnalyticsSender.sendCustomEvent('Crowd_Screen', restaurant.restaurantType);
                    this.getVoteState(this.restaurant);
                    if (this.mode === 'decouverte') {
                        this.loadSousThematiqueGagnante();
                    }
                })
                .catch(error => {
                    checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
                });
        }

        private loadThematique() {
            this.isLoading = true;
            if (this.mode === 'decouverte') {
                this.voteCampagneService.getThematiqueByVoteCampagne(this.campagneId, this.thematiqueId).then(thematique => {
                    this.thematique = thematique;
                    this.storageService.saveImageThematique(this.thematique.id, this.thematique.imageDocumentId);
                    if (this.thematique.imageDocumentId) {
                        this.imageUrl = `${this.configService.getConfigValue("url")}/documents/${this.thematique.imageDocumentId}`;
                    }
                }, error => {
                    checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
                }).finally(() => this.isLoading = false);
            } else {
                this.voteCampagneService.getThematiqueByService(this.campagneId, this.thematiqueId).then(thematique => {
                    this.thematique = thematique;
                    this.storageService.saveImageThematique(this.thematique.id, this.thematique.imageDocumentId);
                    this.imageUrl = !!this.thematique.imageDocumentId ? `${this.configService.getConfigValue("url")}/documents/${this.thematique.imageDocumentId}` : '';
                }, error => {
                    checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
                }).finally(() => this.isLoading = false);
            }
        }

        private loadThemathiqueSuccess(thematique: Core.IThematiqueDetail) {
            this.thematique = thematique;
            this.imageUrl = !!this.thematique.imageDocumentId ? `${this.configService.getConfigValue("url")}/documents/${this.thematique.imageDocumentId}` : '';
        }

        private loadThemathiqueFail(error) {
            checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
        }

        private loadSousThematiqueGagnante(date?: Date) {
            this.voteCampagneService.getSousThematiqueGagnante(this.restaurant, date).then(sousThematiqueEnCours => {
                this.sousThematiqueEnCours = sousThematiqueEnCours.defaultSousThematiqueGagnante;
            }, error => {
                this.sousThematiqueEnCours = undefined;
            });
        }


        private getVoteState(restaurant: Core.IRestaurant) {
            this.voteCampagneService.getVoteState(restaurant).then(voteState => {
                this.hasVoteEnCours = voteState.restaurantHasVoteEnCours;
                this.peutVoter = voteState.convivePeutVoter;
            }, error => {
                this.hasVoteEnCours = false;
                this.peutVoter = false;
            });
        }

        getPictogram(sousThematiqueId: number): string {
            return `${this.configService.getConfigValue('url')}/vote/sous-thematique/${sousThematiqueId}/pictogram`;
        }

        goBack() {
            this.$window.history.back();
        }
    }

    angular.module('easypass').controller('voteThematiqueController', ['voteCampagneService', 'restaurantsService', 'configService', 'storageService', '$stateParams', '$state', '$window', VoteThematiqueController]);
}