module Timechef {
    class RootController extends Core.RootController {
        constructor($rootScope: Core.IRootScopeWithHeaderConfiguration, userService: Core.UserService, protected cookieService: Core.CookieService) {
            super($rootScope, userService, cookieService);
            this.cookieService.manageRgpdCookies();
        }

      
    }
    angular.module('easypass').controller('rootController', ['$rootScope', 'userService', 'cookieService', RootController]);
}