module Easypass.DinerTakeAway {
    @Component('easypass', 'dtHeader', {
        controllerAs: 'vm',
        bindings: {
            restaurant: '<'
        },
        templateUrl: './dtHeader.html',
        transclude: true
    })
    class HeaderComponent {
        restaurant: Core.IRestaurant;
    }
}
