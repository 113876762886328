module Easypass {
    export class PanierService extends Core.PanierServiceBase {

        constructor(
            $http: ng.IHttpService,
            configService: IS.Configuration.ConfigService,
            carteService: Core.CarteService,
            $q: ng.IQService,
            $rootScope: ng.IRootScopeService
        ) {
            super($http, configService, carteService, $q, $rootScope);

        }

        getDernierPanier(restaurantId: number): ng.IHttpPromise<Core.IPanier> {
            var apiUrl = this.configService.getConfigValue('url');
            return this.$http.get<Core.IPanier>(`${apiUrl}/restaurant/${restaurantId}/click-and-collect/panier/last`);
        }

        validerPrix(restaurantId: number): ng.IPromise<ICommandeDevis> {
            var deferred = this.$q.defer<ICommandeDevis>();
            this.$http.post<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurantId}/click-and-collect/panier/prix`, this.getPanier(restaurantId))
                .success((devis: ICommandeDevis) => {
                    this.paniers[restaurantId].id = devis.commandeId;
                    deferred.resolve(devis);
                })
                .catch(error => {
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        validerPanier(restaurantId: number, paymentType: Core.PaymentType, dateRetrait: Date, dateFinRetrait : Date, lieuRetrait : ILieuRetrait,  numBadge: string, numeroTable?: number): ng.IPromise<ICommandeRetrait> {
            var deferred = this.$q.defer<ICommandeRetrait>();

            if (!!this.paniers[restaurantId] && !!this.paniers[restaurantId].id) {
                var commandeId = this.paniers[restaurantId].id;
                // Mise en commantaire SPO : c'est à l'api de faire le travail. 
                //if (paymentType === Core.PaymentType.Immediate) {
                //    //TODO: TDB
                //}
                //else {
                    this.$http.post<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurantId}/click-and-collect/panier/${commandeId}/valider`, <IValiderPanier>{ dateRetrait: dateRetrait, dateFinRetrait:dateFinRetrait, lieuRetrait : lieuRetrait,  numBadge: numBadge, numeroTable: numeroTable })
                        .success((retrait) => {
                            retrait.dateRetrait = new Date(<any>retrait.dateRetrait);
                            retrait.isDeffered = (paymentType === Core.PaymentType.Deffered);
                            deferred.resolve(retrait);
                            this.resetPanier(restaurantId);
                        })
                        .catch(error => {
                            deferred.reject(error);
                        });
                //}
            }
            else {
                deferred.reject('le panier n\'est pas valide');
            }
            return deferred.promise;
        }

        getRetrait(restaurantId: number, commandeId: number): ng.IPromise<ICommandeRetrait> {
            var deferred = this.$q.defer<ICommandeRetrait>();
            this.$http.post<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurantId}/click-and-collect/retrait/${commandeId}`, {})
                .success((retrait) => {
                    retrait.dateRetrait = new Date(<any>retrait.dateRetrait);
                    retrait.isDeffered = false;
                    deferred.resolve(retrait);
                })
                .catch(error => {
                    deferred.reject(error);
                });
            return deferred.promise;
        }
    }

    angular.module('easypass').service('panierService', ['$http', 'configService', 'carteService', '$q', '$rootScope', PanierService]);

}