module Easypass {
    class PlatAllergenesController {
        platAllergenes: string[];
        allergenesList: Core.IAllergene[];

        constructor($stateParams: ng.ui.IStateParamsService, allergenesService: Core.AllergenesService, $rootScope: Core.IRootScopeWithHeaderConfiguration) {
            this.platAllergenes = $stateParams['platAllergenes'];
            (<any>window).DataAnalyticsSender.sendCustomEvent('Allergens_Screen', '');
            this.allergenesList = _.filter(allergenesService.allergenes, (allergene) => this.isInPlat(allergene));
        }

        isInPlat(allergene: Core.IAllergene): boolean {
            return _.includes(this.platAllergenes, allergene.key);
        }
    }

    angular.module('easypass').controller('platAllergenesController', ['$stateParams', 'allergenesService', '$rootScope', PlatAllergenesController]);
}
