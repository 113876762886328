module Core {
    export interface ISiteInformation {
        solde: number;
        numBadge: string;
        name: string;
        hasCaisse: boolean;
        isRegisteredInCaisse: boolean;
        hasQrCode: boolean;
        marqueImageId: number;
        alerteSurSolde: boolean;
        montantAlerteSolde: number;
        siteContactMail: string;
        canHideRestaurants: boolean;
        restaurantsDisplayed: boolean;
        marqueLogoTimechefId: number;
        marqueLogoTimechefBlId: number;
        marqueLogoTimechefProId: number;
        marqueLogoTimechefProBlId: number;
        tcProUrl: string;
        libelleLong: string;
        modeGestion: ModeGestion;
        askPresenceForLunch : boolean;
        askPresenceOnSite :boolean; 
        askPresenceOnSiteHeureArrivee :boolean;
        askPresenceOnSiteLieu : boolean;
        askPresenceEvent : boolean;
        presenceOnSiteNbJourMaxReservation: number;
        presenceOnSiteHeureArriveeDebut: Date;
        presenceOnSiteHeureArriveeFin: Date;
        presenceOnSiteHeureArriveeStep: number;
        presenceEvents: Easypass.IPresenceEventType[];
        showMoyenPaiementParameters: boolean;
    }

    export enum ModeGestion {
        cash = "Cash",
        prePaiement = "PrePaiement",
        postPaiement = "PostPaiement"
    }
}