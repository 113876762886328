module Easypass {

    interface IFluiditePictoScope extends ng.IScope {
        restaurantId: number;
        fluditeStatut: IFluiditeStatut;
        small: boolean;
        hasFluidite: boolean;
        isLoading: boolean;
        isOccupationOnly: boolean;
    }

    class FluiditePictoDirective implements ng.IDirective {
        constructor(private fluiditeService: Easypass.FluiditeService, private restaurantService: RestaurantsService) { }

        restrict = 'EA';

        scope = {
            restaurantId: '=',
            small: '=?',
            isOccupationOnly: '=?',
        }

        template =
        `<div class="fluidite-picto {{isLoading?'picto-loading':''}} {{small?'small':''}} {{fluditeStatut.tauxOccupation | fluiditeTauxOccupationToClass}} {{fluditeStatut.tendance | fluiditeTendanceToClass}} {{isOccupationOnly?'fluidite-occupation-only':''}}" data-ng-if="hasFluidite">
         </div><div class="fluidite-occupation-na {{isOccupationOnly?'fluidite-picto fluidite-occupation-only':''}}" data-ng-if="!hasFluidite"></div>`;

        element: ng.IAugmentedJQuery;

        link = (scope: IFluiditePictoScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes): void => {
            scope.$watch('restaurantId', (restaurantId: number) => {
                if (restaurantId) {
                    this.restaurantService.get(restaurantId)
                        .then(restaurant => {
                            if (this.restaurantService.getIsOpen(restaurant)) {
                                scope.hasFluidite = true;
                                scope.isLoading = true;
                                this.fluiditeService.getFluiditeStatut(restaurantId)
                                    .then(fluiditeStatut => {
                                        scope.fluditeStatut = fluiditeStatut;
                                    })
									.catch(error => {
										// TODO: StatusCode=0
										scope.hasFluidite = false;
									})
                                    .finally(() => scope.isLoading = false);
                            } else {
                                scope.hasFluidite = false;
                            }
                        });
                } else {
                    scope.hasFluidite = false;
                }
            });
        };        

        static factory() {
            return (fluiditeService: Easypass.FluiditeService, restaurantService: RestaurantsService) => {
                return new FluiditePictoDirective(fluiditeService, restaurantService);
            };
        }
    }

    angular.module('easypass').directive('fluiditePicto', ['fluiditeService','restaurantsService', FluiditePictoDirective.factory()]);
}