module Easypass {
    @Component('easypass', 'tcCommandeArticleCc', {
        controllerAs: 'vm',
        bindings: {
            article: '<'
        },
        templateUrl: './commandeArticleCC.html'
    })
    class CommandeArticleCC {
        static $inject: string[] = [];
        article;

        constructor() {
            
        }

        $onInit = () => {;
        }
	}
}