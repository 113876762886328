module Core {
    class AlerteSoldeParametersController {
        parameters: IAlerteSoldeParameter;
        connectionFailed: boolean = false;
        isLoading: boolean = false;
        isUpdating: boolean = false;
        popinIsUpdated: boolean = false;
        popinFailUpdated: boolean = false;
        showPopIn: boolean = false;
        alerteSurSolde: boolean = false;
        alerteSoldeMontantMin: number = 1;

        constructor(private userService: UserService, private $window: ng.IWindowService) {
            this.alerteSurSolde = userService.userInformations.currentSite.alerteSurSolde;
            this.getAlerteSoldeParameter();
        }

        private getAlerteSoldeParameter() {
            this.isLoading = true;
            let self = this;
            this.userService.getAlerteSoldeParameter()
                .then(function (parameters: IAlerteSoldeParameter) {
                    self.parameters = parameters;
                    self.connectionFailed = false;
                }).catch(function (error) {
                    self.connectionFailed = true;
                }).finally(function () {
                    self.isLoading = false;
                });
        }

        updateAlertSoldeMontant(montant: number) {
            this.isUpdating = true;
            this.hidePopin();
            let self = this;
            this.userService.updateAlerteSoldeMontant(montant)
                .then(function () {
                    self.showPopinSuccess();
                }).catch(function () {
                    self.showpopinFail();
                }).finally(function () {
                    self.isUpdating = false;
                });
        }

        decreaseMontant(): number {
            var montant = this.parameters.conviveAlerteSoldeMontant;
            if (montant && montant > 1) {
                montant--;
            }
            return montant;
        }
        
        increaseMontant(): number {
            var montant = this.parameters.conviveAlerteSoldeMontant;
            if (montant && montant < this.parameters.alerteSoldeMontantMax) {
                montant++;
            }
            return montant;
        }


        private hidePopin() {
            this.showPopIn = false;
            this.popinIsUpdated = false;
            this.popinFailUpdated = false;
        }

        private showPopinSuccess() {
            this.showPopIn = true;
            this.popinIsUpdated = true;
        }

        private showpopinFail() {
            this.showPopIn = true;
            this.popinFailUpdated = true;
        }

    }
    angular.module('core').controller('alerteSoldeParametersController', ['userService', '$window', AlerteSoldeParametersController]);

}