module Core {
    class PhoneParametresController {
		minLength: number = 8;
		maxlength: number = 25;

        phone: string;
        isLoading: boolean;
        error: boolean;
        networkError: boolean;
        hasSucceeded: boolean;
		errorFormat: boolean;

        constructor(private userService: UserService) {
            userService.getUserInformations().then((userInfo) => this.phone = userInfo.phoneNumber);
        }

        changePhone() {
			this.hasSucceeded = false;

			if (!this.checkPhoneFormat())
				return;

            this.isLoading = true;

            this.userService.updatePhone(this.phone)
                .then(() => {
					this.error = false;
					this.networkError = false;

					this.hasSucceeded = true;
				})
                .catch(error => {
					this.hasSucceeded = false;

					checkNetworkError(error,
						() => {
							this.networkError = false;
							this.error = true;
						},
						() => {
							this.error = false;
							this.networkError = true;
						});
					// TODO: StatusCode=0
				})
                .finally(() => this.isLoading = false);
        }

        checkPhoneFormat(): boolean {
            var isPhoneNumberValid = this.userService.isPhoneNumberValid(this.phone);
            this.errorFormat = !isPhoneNumberValid;
            return isPhoneNumberValid;
		}
    }
    angular.module('core').controller('phoneParametresController', ['userService', PhoneParametresController]);
}