module Core {
    class AideController {

        templateUrl: string;

        constructor(private localizeService: IS.Localization.LocalizeService) {

            let culture = localizeService.getCurrentCulture().code;

            this.templateUrl = `./resources/aide/site-aide.html`;
        }

    }
    angular.module('core').controller('aideController', ['localizeService', AideController]);
}