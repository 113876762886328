module Core {
    class ResetPasswordController {

        email: string;
        newPassword: string;
        passwordResetToken: string;

        isLoading: boolean = false;
        isOnError: boolean = false;
        showConfirmation: boolean = false;

        constructor(private authenticationService: AuthenticationService,
            private $stateParams: ng.ui.IStateParamsService) {
            this.email = $stateParams['email'];
            this.passwordResetToken = $stateParams['token'];
        }

        submit(): void {
            this.isLoading = true;
            this.isOnError = false;

            this.authenticationService.resetPassword(<IResetPassword>{ email: this.email, newPassword: this.newPassword, passwordResetToken: this.passwordResetToken })
                .then(() => {
                    this.showConfirmation = true;
                }, error => {
					// TODO: StatusCode=0
                    this.isOnError = true;
                }).finally(() => {
                    this.isLoading = false;
                });
        }
    }

    angular.module('core').controller('resetPasswordController', ['authenticationService', '$stateParams', ResetPasswordController]);
}