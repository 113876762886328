module Core {
    export class MenuService {
        constructor(private $rootScope: ng.IRootScopeService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService,
            private restaurantsService: RestaurantsService,
            private storageService: StorageService) {
            var now = new Date();
            this.restaurantsService.gets().then(restaurants => {
                restaurants.map(restaurant => {
                    var menus = this.storageService.getMenus(restaurant.id);
                    if (menus) {
                        this.menuDictionary[restaurant.id] = menus;

						if (!this.isCacheValid(now, menus)) {
							this.getMenusFromServer(restaurant.id);
						}
                    }
                });
            });

            this.$rootScope.$on('clearUserInMemoryData', () => {
                this.menuDictionary = {};
                this.getMenusDeferred = {};
            });
        }

		cacheIntervalInSeconds: number = 60 * 60 * 12;
        menuDictionary: { [id: number]: ITimestamped<IMenu[]> } = {};

        getMenus(restaurantId: number): { menus: ITimestamped<IMenu[]>, promise: ng.IPromise<ITimestamped<IMenu[]>> } {
			var result: any = {};
			result.menus = this.menuDictionary[restaurantId];

            var now = new Date();
			if (result.menus == null || result.menus.value.length == 0 || !this.isCacheValid(now, result.menus)) {
				result.promise = this.getMenusFromServer(restaurantId);
			}

			return result;
        }

		private isCacheValid(date: Date, cacheMenu: ITimestamped<IMenu[]>): boolean {
			if (date.getDate() != cacheMenu.timestamp.getDate()
				|| date.getMonth() != cacheMenu.timestamp.getMonth()
				|| date.getFullYear() != cacheMenu.timestamp.getFullYear()) {
				return false;
			}

			var cacheTag: number = this.computeTag(cacheMenu.timestamp);
			var newTag: number = this.computeTag(date);
			if (newTag != cacheTag) {
				return false;
			}

			return true;
		}

		private computeTag(date: Date) {
			var seconds = date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();

			var tag: number = Math.floor(seconds / this.cacheIntervalInSeconds);
			return tag;
		}

        private getMenusDeferred: { [id: number]: ng.IDeferred<ITimestamped<IMenu[]>>[] } = {};
		getMenusFromServer(restaurantId: number): ng.IPromise<ITimestamped<IMenu[]>> {
			var deferred = this.$q.defer<ITimestamped<IMenu[]>>();

			if (!this.getMenusDeferred[restaurantId] || this.getMenusDeferred[restaurantId].length == 0) {
				this.getMenusDeferred[restaurantId] = [];

				this.$http.get<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurantId}/menus`)
                    .success((menus: IMenu[]) => {
                        menus.map((menu) => {
                            menu.date = new Date((<any>menu.date));
                            menu.famillePlats.map((famille) => {
                                famille.plats.map((plat) => {
                                    plat.date = menu.date;
                                });
                            });
                        });

                        var today = new Date();
                        var timestampedMenus: ITimestamped<IMenu[]> = {
                            timestamp: today,
                            value: menus
                        };

                        this.menuDictionary[restaurantId] = timestampedMenus;
                        this.storageService.saveMenus(timestampedMenus, restaurantId);

                        for (var i = 0; i < this.getMenusDeferred[restaurantId].length; i++) {
                            this.getMenusDeferred[restaurantId][i].resolve(timestampedMenus);
                        }
                    })
                    .catch(error => {
                        for (var i = 0; i < this.getMenusDeferred[restaurantId].length; i++) {
                            this.getMenusDeferred[restaurantId][i].reject(error);
                        }
					})
					.finally(() => {
                        this.getMenusDeferred[restaurantId] = null;
					});
			}

            this.getMenusDeferred[restaurantId].push(deferred);

            return deferred.promise;
		}
    }

    angular.module('core').service('menuService', ['$rootScope', '$http', '$q', 'configService', 'restaurantsService', 'storageService', MenuService]);
}