module Easypass {
    interface ICompareToDirectiveScope extends ng.IScope {
        targetModel: string;
        isCaseSensitive: boolean;
        invertResult: boolean;
    }

    class CompareToDirective implements ng.IDirective {
        restrict: string = 'A';
        require: string = 'ngModel';
        scope = {
            targetModel: '=compareTo',
            isCaseSensitive: '=',
            invertResult: '=?'
        };

        link(scope: ICompareToDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ngModel: any) {
            var result: Boolean;
            var invertResult = angular.isDefined(scope.invertResult) ? scope.invertResult : false;

            ngModel.$validators.compareTo = (modelValue: any) => {
                if (scope.isCaseSensitive && typeof modelValue === "string") {
                    result = modelValue.toLowerCase() === scope.targetModel.toLowerCase();
                } else {
                    result = modelValue === scope.targetModel;
                }

                return invertResult ? !result : result;
            };

            scope.$watch('targetModel', newValue => {
                var result: Boolean;
                var invertResult = angular.isDefined(scope.invertResult) ? scope.invertResult : false;

                if (scope.isCaseSensitive && typeof newValue === "string") {
                    result = ngModel.$modelValue.toLowerCase() === newValue.toLowerCase();
                } else {
                    result = ngModel.$modelValue === newValue;
                }

                ngModel.$setValidity('compareTo', invertResult ? !result : result);
            });
        }

        static factory() {
            var directive = () => {
                return new CompareToDirective();
            };
            return directive;
        }
    }

    angular.module('core').directive('compareTo', [CompareToDirective.factory()]);
}