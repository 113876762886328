module Easypass {

    interface IPanierLinkScope extends ng.IScope{
        restaurantId: number;
    }

    class PanierLinkDirective implements ng.IDirective {
        restrict: string = 'E';

        constructor() {

        }

        scope = {
            restaurantId: '='
        };

        template = `
<button class="notifications panier" data-ng-click="vmLink.goToPanier()">
    <div class="number" data-ng-show="vmLink.nbArticle()!=0">{{vmLink.nbArticle()}}</div>
</button>
`;
        controller = ["$scope", "panierService", "$state", function ($scope: IPanierLinkScope, panierService: PanierService, $state: ng.ui.IStateService) {
            var destroy = $scope.$watch('restaurantId', (newValue) => {
                if (newValue) {
                    var articles = panierService.getPanier($scope.restaurantId).articles;
                    this.nbArticle = () => articles.reduce((cur, next) => cur + next.quantite, 0);
                    this.goToPanier = () => {
                        $state.go("panier", {
                            restaurantId: $scope.restaurantId
                        });
                    }
                    destroy();
                }
            });
            
        }];

        controllerAs = 'vmLink';
        

        static factory() {

            var directive = () => {
                return new PanierLinkDirective()
            };

            return directive;
        }

    }


    angular.module('easypass').directive('panierLink', [PanierLinkDirective.factory()]);
}