module Easypass {
    class QrCodeController {

        image: string;
        error: boolean;
        loading: boolean;
        constructor(
            private userService: Core.UserService,
			private $window: ng.IWindowService,
            private $rootScope: ng.IRootScopeService
		) {
            this.loading = true;
            console.log("try to get qr code");
            userService.getQrCode()
				.then((qrcode) => {
                    console.log("this is the image get : ");
					if (!!qrcode) {
                        this.image = qrcode;
                        console.log(this.image);
					} else {
                        this.error = true;
                        console.log("error");
					}
				})
				.catch(error => {
                    console.log("error : " + error);
					// TODO: StatusCode=0
				})
				.finally(() => this.loading = false);
        }

        goBack() {
			this.$rootScope.$broadcast('soldeChanged'); // userSoldechanged
            this.$window.history.back();
        }

    }

    angular.module('easypass').controller('qrCodeController',
        ['userService', '$window', '$rootScope', QrCodeController]);
}