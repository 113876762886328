module Easypass.DinerTakeAway {
    export class DtBasketService {
        private _baskets: IBasketDTAConfiguration[] = [];

        get baskets(): IBasketDTAConfiguration[] {
            return this._baskets;
        }

        private state: LockeReservationState;
        private basketTimeout = 1200000; //// 1200000 ms => 20 mins;

        private initializationPromise: ng.IPromise<any[]>;

        private lockerId: number;

        constructor(private restaurantsService: Core.RestaurantsService,
            private storageService: Easypass.IStorageService,
            private lockerReservationService: LockerReservationService,
            private dinnerTakeawayConfigService: DinnerTakeawayConfigService,
            private dtBasketPaymentService: DtBasketPaymentService,
            private $timeout: ng.ITimeoutService,
            private $q: ng.IQService) {

            var initializationPromises: ng.IPromise<void>[] = [];

            var initializationDeferred = this.$q.defer<any[]>();
            this.initializationPromise = initializationDeferred.promise;

            this.restaurantsService.gets().then(restaurants => {
                restaurants.forEach(restaurant => {
                    if (restaurant.isDinnerTakeaway) {
                        // For each dinner take away restaurant, get current reservations and rehydrate the basket
                        var promise = this.lockerReservationService.getCurrentReservations(restaurant.id).then(reservations => {
                            //TODO: pourquoi on recoit tjr un tableau
                            if (reservations && reservations[0]) {
                                this.lockerId = reservations[0].id;

                                // Get DinerTakeAway Config
                                return this.dinnerTakeawayConfigService.getConfig(restaurant.id).then(config => {
                                    var dtaConfig: IDinnerTakeawayConfiguration = config;

                                    // A current reservation exists, create the basket and initialize the timeout
                                    var remainingTime: number = this.basketTimeout - (Date.now() - reservations[0].startDate.getTime());
                                    if (remainingTime > 0) {
                                        var reservationDay = new Date(<any>reservations[0].targetDate);
                                        reservationDay.setHours(0, 0, 0, 0);

                                        var panier = this.storageService.getPanierDTA(restaurant.id);
                                        if (panier) {
                                            var currentBasketDate = new Date(panier.date);
                                            currentBasketDate.setHours(0, 0, 0, 0);
                                            if (currentBasketDate.getTime() !== reservationDay.getTime()) {
                                                // The basket is set for another day, delete it
                                                this.storageService.removePanierDta(restaurant.id);
                                                panier.items = undefined;
                                            }
                                        }

                                        var items = panier ? panier.items || [] : [];
                                        var basket = new BasketDTA(dtaConfig.tvaRatePercent, dtaConfig.maxNumberOfArticleByPanier, items);
                                        basket.date = reservations[0].targetDate;
                                        var converseConfig = new IBasketDTAConfiguration();
                                        converseConfig.restaurantId = restaurant.id;
                                        converseConfig.lockerId = reservations[0].id;
                                        converseConfig.date = reservations[0].targetDate;
                                        converseConfig.basket = basket;
                                        converseConfig.subject = new Rx.BehaviorSubject<BasketDTA>(basket);
                                        converseConfig.timeoutCanceller = this.$timeout(() => this.expireBasket(restaurant.id, reservations[0].targetDate), remainingTime);
                                        converseConfig.dtaConfig = dtaConfig;
                                        this.baskets.push(converseConfig);

                                        this.storageService.savePanierDTA(restaurant.id, { date: reservations[0].targetDate, items: items, dtaConfig: dtaConfig });

                                    } else {
                                        // The current reservation is expired, remove the basket from the storage if exists
                                        this.storageService.removePanierDta(restaurant.id);
                                    }

                                    return this.$q.resolve();
                                });
                            }

                            // There is no current reservation, remove the basket from the storage if exists
                            this.storageService.removePanierDta(restaurant.id);

                            return this.$q.resolve();
                        });

                        initializationPromises.push(promise);
                    }
                });

                this.$q.all(initializationPromises).then(() => {
                    initializationDeferred.resolve();
                });
            });
        }

        getBasketObserver(restaurantId: number, date?: Date): Rx.Observable<BasketDTA> {
            return Rx.Observable.fromPromise(this.initializationPromise).flatMap(() => {
                var basket : IBasketDTAConfiguration;
                if (date) {
                    basket = this.baskets.filter(basket => basket.restaurantId === restaurantId && basket.date.getTime() === date.getTime())[0];
                } else {
                    basket = this.baskets.filter(basket => basket.restaurantId === restaurantId)[0];
                }

                if (basket) {
                    return basket.subject.asObservable();
                }

                return Rx.Observable.of(null);
            });
        }


        private expireBasket(restaurantId: number, date: Date) {
            var basketConfiguration = this.baskets.filter(basket => basket.restaurantId === restaurantId && basket.date.getTime() === date.getTime())[0];
            if (basketConfiguration) {
                var index = this.baskets.indexOf(basketConfiguration);
                this.baskets.splice(index, 1);

                basketConfiguration.basket.items = [];
                basketConfiguration.subject.onNext(basketConfiguration.basket);
                basketConfiguration.subject.onCompleted();
                this.$timeout.cancel(basketConfiguration.timeoutCanceller);

                basketConfiguration = null;

                this.storageService.removePanierDta(restaurantId);
            }
        }

        createBasket(restaurantId: number, date: Date): ng.IPromise<void> {
            var currentBasketConfiguration = this.baskets.filter(basket => basket.restaurantId === restaurantId)[0];

            // Basket already exists, just return an observable
            if (currentBasketConfiguration && moment(currentBasketConfiguration.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                return this.$q.resolve();
            }
            var deferred = this.$q.defer<void>();

            // Basket already exists => create new one and replace the current
            // Basket does not exist => create new one
            this.lockerReservationService.createReservation(restaurantId, date).then((reservation) => {
                this.lockerId = reservation.id;

                this.dinnerTakeawayConfigService.getConfig(restaurantId).then(config => {
                    var dtaConfig: IDinnerTakeawayConfiguration = config;

                    if (currentBasketConfiguration) {
                        currentBasketConfiguration.basket.items = [];
                        currentBasketConfiguration.date = date;
                        currentBasketConfiguration.basket.date = date;

                        this.$timeout.cancel(currentBasketConfiguration.timeoutCanceller);
                        currentBasketConfiguration.timeoutCanceller = this.$timeout(() => this.expireBasket(restaurantId, date), this.basketTimeout);
                    } else {
                        var basket = new BasketDTA(dtaConfig.tvaRatePercent, dtaConfig.maxNumberOfArticleByPanier, []);
                        console.log('creating basket for : ' + date.toISOString());
                        basket.date = date;
                        currentBasketConfiguration = new IBasketDTAConfiguration();
                        currentBasketConfiguration.restaurantId = restaurantId;
                        currentBasketConfiguration.lockerId = reservation.id;
                        currentBasketConfiguration.date = date;
                        currentBasketConfiguration.basket = basket;
                        currentBasketConfiguration.subject = new Rx.BehaviorSubject<BasketDTA>(basket);
                        currentBasketConfiguration.timeoutCanceller = this.$timeout(() => this.expireBasket(restaurantId, date), 1200000);
                        currentBasketConfiguration.dtaConfig = dtaConfig;

                        this.baskets.push(currentBasketConfiguration);

                        this.storageService.savePanierDTA(restaurantId, { date: date, items: [], dtaConfig: dtaConfig });
                        //TODO: est-ce nécessaire vu qu'on l'initialise juste avant ?
                        // currentBasketConfiguration.subject.onNext(currentBasketConfiguration.items);
                    }

                    deferred.resolve();
                });
            }, () => {
                deferred.reject();
            });

            return deferred.promise;
        }

        destroyBasket = (restaurantId: number, date: Date) => {
            this.expireBasket(restaurantId, date);
        }

        resetBasketTimeout(restaurantId: number, date: Date): ng.IPromise<void> {
            var deferred = this.$q.defer<void>();

            var basket = this.baskets.filter(basket => basket.restaurantId === restaurantId)[0];

            this.$timeout.cancel(basket.timeoutCanceller);
            basket.timeoutCanceller = this.$timeout(() => this.expireBasket(restaurantId, date), this.basketTimeout);

            return deferred.promise;
        }

        getBasketDate(restaurantId: number): ng.IPromise<Date> {
            return this.$q.when(this.initializationPromise).then(() => {
                var basketConfiguration = this.baskets.filter(basket => basket.restaurantId === restaurantId)[0];

                if (basketConfiguration) {
                    return basketConfiguration.date;
                }

                return null;
            });
        }

        addOrUpdatePortion(restaurantId: number, date: Date, portion: Core.IPortion, quantity: number, removeForce: boolean = false) {
            var basketConfiguration = this.baskets.filter(basket => basket.restaurantId === restaurantId && basket.date.getTime() === date.getTime())[0];

            if (basketConfiguration) {
                var basket = basketConfiguration.basket;

                var item = basket.items.filter(basketPortion => basketPortion.portion.articleId === portion.articleId)[0];

                if (quantity === 0 && removeForce === true) {
                    var index = _.findIndex(basket.items, (basketPortion => basketPortion.portion.articleId === portion.articleId));
                    basket.items.splice(index, 1);
                }
                else {
                    if (item && quantity > item.quantity && basket.isFull) {
                        // We can't add some items because the basket is already full
                        return;
                    }

                    if (!item && quantity) {
                        basket.items.push({ portion: portion, quantity: quantity });
                    } else {
                        item.quantity = quantity;
                    }
                }

                basketConfiguration.subject.onNext(basket);
                this.storageService.savePanierDTA(restaurantId, { date: basketConfiguration.date, items: basket.items, dtaConfig: basketConfiguration.dtaConfig });
            }
        }

        removeItemFromBasket(restaurantId: number, date: Date, portion: Core.IPortion) {
            this.addOrUpdatePortion(restaurantId, date, portion, 0, true);
        }


        gotoPayment(basket: BasketDTA, restaurantId: number): ng.IPromise<any> {
            return this.dtBasketPaymentService.gotoPayment(basket, restaurantId, this.lockerId);
        }
    }

    angular.module('easypass').service('dtBasketService', ['restaurantsService', 'storageService', 'lockerReservationService', 'dinnerTakeawayConfigService', 'dtBasketPaymentService', '$timeout', '$q', DtBasketService]);
}
