module Core {
    export class AuthTokenRequest implements IAuthRequest {
        siteName: string;
        token: string;

        authType: string = "token";

        constructor(siteName: string, token: string) {
            this.siteName = siteName;
            this.token = token;
        }
    }
}
