Easypass.Registration.setCommandesRegister((stateProvider: ng.ui.IStateProvider) => {
    
    var headerWithKey = (key: string, showBackButton: boolean = false): ng.ui.IState => ({
        template: `<h1 data-i18n="${key}" data-ng-class="{ 'with-back': ${showBackButton} }"></h1>`
    });

    stateProvider
        // Commandes - liste
        .state('tc-commandes', <Core.IStateWithConfiguration>{
            url: '/commandes/',
            headerConfiguration: {
                showMenu: true,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Commande,
            parent: 'globalLayout',
            views: {
                header: headerWithKey("Commandes_Title"),
                '': {
                    template: '<tc-commandes></tc-commandes>'
                }
            }
        })

        // Commande - detail
        .state('tc-commande-detail', <Core.IStateWithConfiguration>{
            url: '/commandes/:index',
            headerConfiguration: {
                showMenu: false,
                showBack: true,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Commande,
            parent: 'globalLayout',
            views: {
                header: headerWithKey("CommandeDetail_Title"),
                '': {
                    template: '<tc-commande-detail index="$resolve.index"></tc-commande-detail>',
                    resolve: {
                        index: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return $stateParams['index'];
                        }]
                    }
                }
            }
        })

});