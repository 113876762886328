module Core {
    @Component('core', 'voteCountDown', {
        controllerAs: 'vm',
        bindings: {
            dateFin: '<',
            hasVoted: '<'
        },
        templateUrl: './voteCountDown.html'
    })
    class VoteCountDownComponent {
        static $inject: string[] = ['$interval'];
        dateFin: Date;
        seconds: number;
        minutes: number;
        hours: number;
        days: number;

        constructor(private $interval: ng.IIntervalService) {
            this.calcTempsRestant();
            this.startTimer();
        }

        startTimer() {
            var self = this;
            this.$interval(function () {
                self.calcTempsRestant();
            }, 1000);
        }

        calcTempsRestant() {
            var now = moment();
            var tempsRestant = moment.duration(moment(this.dateFin).diff(now));
            if (tempsRestant.asSeconds() < 0) {
                tempsRestant = moment.duration(0);
            }

            this.seconds = tempsRestant.seconds();
            this.minutes = tempsRestant.minutes();
            this.hours = tempsRestant.hours();
            this.days = tempsRestant.days();
        }
    }
}