module Easypass {
    angular.module('easypass').filter('positive', [() => {
        return (input: number): string => {
            if (!input) {
                return "0";
            }

            return Math.abs(input).toFixed(2);
        };
    }]);
}