module Core {
    export abstract class RestaurantsController {
        restaurants: { restaurant: IRestaurant, animation?: IAnimation, campagne?: ICampagne, voteState: IVoteState, conceptInfos: IConceptInfos }[];
        restaurantType: Core.RestaurantType = null;

        constructor(
            protected restaurantsService: RestaurantsService,
            protected animationService: AnimationService,
            protected enqueteService: EnqueteService,
            protected voteCampagneService: VoteCampagneService,
            protected storageService: StorageService,
            protected $state: ng.ui.IStateService,
            protected $scope: ng.IScope

        ) {
            this.loadRestaurants();
            (<any>window).DataAnalyticsSender.sendCustomEvent('RestaurantsHome_Screen', '');

            $scope.$on('onResumeCordova', () => {
                this.loadRestaurants(true);
            });
        }

        private loadRestaurants(force?: boolean) {
            this.isLoading = true;

            this.restaurantsService.gets(force)
                .then(restaurants => {
                    this.restaurants = [];

                    restaurants.forEach((restaurant) => {
                        var element = <{ restaurant: IRestaurant, animation?: IAnimation, campagne?: ICampagne; voteState: IVoteState, sousThematiqueEnCours: ISousThematiqueGagnanteGlobal, conceptInfos: IConceptInfos }>{ restaurant: restaurant };
                        if (this.$state.params['restaurantType'] == null || Core.RestaurantType[this.$state.params['restaurantType']] == element.restaurant.restaurantType) {
                            this.restaurants.push(element);

                            this.animationService.getCurrent(restaurant.id)
                                .then((animation) => {
                                    element.animation = animation;
                                });

                            this.enqueteService.getCurrent(restaurant.id)
                                .then(campagne => {
                                    element.campagne = campagne;
                                });

                            if (restaurant.voteEstActif) {
                                this.voteCampagneService.getVoteState(restaurant)
                                    .then(voteState => {
                                        element.voteState = voteState;
                                        if (!element.conceptInfos) {
                                            this.loadInfoConcept(element, voteState.defaultConceptThematiqueId);
                                        }                                     
                                    });

                                this.voteCampagneService.getSousThematiqueGagnante(restaurant)
                                    .then(sousThematiqueEnCours => {
                                        element.sousThematiqueEnCours = sousThematiqueEnCours;
                                        const sousThematiqueImageId = element.sousThematiqueEnCours && element.sousThematiqueEnCours.defaultSousThematiqueGagnante
                                                                ? element.sousThematiqueEnCours.defaultSousThematiqueGagnante.imageDocumentId
                                                                : null;
                                        this.storageService.saveImageThematiqueGagnante(restaurant.id, sousThematiqueImageId);
                                        
                                        if (sousThematiqueEnCours && sousThematiqueEnCours.defaultSousThematiqueGagnante)  {
                                            const conceptThematiqueId = sousThematiqueEnCours.defaultSousThematiqueGagnante 
                                                            ? sousThematiqueEnCours.defaultSousThematiqueGagnante.conceptThematiqueId 
                                                            : null;
                                            this.loadInfoConcept(element, conceptThematiqueId);
                                        }
                                    }).catch(() => this.storageService.saveImageThematiqueGagnante(restaurant.id, null));  
                            }
                        }
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                    (<any>window).DataAnalyticsSender.activateCampaignDisplay();
                });
        }

        private loadInfoConcept(element, conceptId) {
            this.voteCampagneService.getConceptInfos(conceptId)
            .then(conceptInfos => {
                element.conceptInfos = conceptInfos;
                this.storageService.saveImageConcept(element.conceptInfos ? element.conceptInfos.imageDocumentId : null);
            });
        }

        getNumber(): number {
            return 5;
        }

        sortableOptions = { scrollableLayout: '.restaurants', additionalPlaceholderClass: 'restaurant-placeholder', orderChanged: (event: any) => this.saveRestaurantOrder(event) }

        isLoading: boolean;
        networkError: boolean;
        favoriteRestaurants: number[];
        stopDraging = false;
        showDragPicto = false;
        saveRestaurantOrder = (changes: any) => {
            this.restaurantsService.setOrder(changes.dest.sortableScope.modelValue.map((restaurant: { restaurant: IRestaurant }) => restaurant.restaurant.id));
        }

        goTo(restaurant: IRestaurant, voteState: Core.IVoteState = null) {

            // implémenté dans les classes RestaurantController "filles" de TC et TC PRO
        }

        abstract isOpen(restaurant: IRestaurant): boolean;
    }
}