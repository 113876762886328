module Easypass {
    export class RestaurantsService extends Core.RestaurantsService {

        constructor(
            $rootScope: ng.IRootScopeService,
            $http: ng.IHttpService,
            $q: ng.IQService,
            storageService: StorageService,
            configService: IS.Configuration.ConfigService,
            userService: Core.UserService,
            $state: ng.ui.IStateService) {
            super($rootScope, $http, $q, storageService, configService, userService, $state);
        }

        getInnovorder(restaurantId: number): ng.IHttpPromise<IInnovorder> {
            var apiUrl = this.configService.getConfigValue('url');
            return this.$http.get<IInnovorder>(`${apiUrl}/restaurant/${restaurantId}/click-and-collect/innovorder`);
        }

        getHomeRedirectionInformation(restaurantId: number): ng.IHttpPromise<IRedirectionInformation> {
            var apiUrl = this.configService.getConfigValue('url');
            return this.$http.get<IRedirectionInformation>(`${apiUrl}/${this.userService.userInformations.currentSite.name}/restaurant/${restaurantId}/home-redirection`);
        }

        getCommandesRedirectionInformation(restaurantId: number): ng.IHttpPromise<IRedirectionInformation> {
            var apiUrl = this.configService.getConfigValue('url');
            return this.$http.get<IRedirectionInformation>(`${apiUrl}/${this.userService.userInformations.currentSite.name}/restaurant/${restaurantId}/commandes-redirection`);
        }

        goToRestaurantId(restaurantId: number): void {
            this.get(restaurantId).then((restaurant) => {
                this.goToRestaurant(restaurant);
            });
        }

        goToRestaurant(restaurant: Core.IRestaurant): void {
            if (restaurant.isRestaurantExterne) {
                this.getHomeRedirectionInformation(restaurant.id)
                    .success((redirectionInfo) => {
                        if (redirectionInfo.isEmbedded) {
                            this.$state.go("restaurantExterne", { restaurantId: restaurant.id, restaurantUrl: redirectionInfo.url });
                        } else {
                            window.open(redirectionInfo.url, "_self");
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (restaurant.isClickAndCollectInnovorder) {
                this.$state.go("clickAndCollectInnovorder", { id: restaurant.id });
            } else if (restaurant.isClickAndCollect) {
                this.$state.go("clickAndCollect", { id: restaurant.id });
            } else if (restaurant.isDinnerTakeaway || restaurant.isFoodles) {
                this.$state.go("dt-home", { restaurantId: restaurant.id });
            } else {
                this.$state.go("restaurant", { id: restaurant.id });
            }
        }
    }

    angular.module('easypass').service('restaurantsService',
        ['$rootScope', '$http', '$q', 'storageService', 'configService', 'userService', '$state', RestaurantsService]);
}