module Easypass {
    class RegistrationController extends Core.RegistrationController {
        constructor(
            $scope: ng.IScope,
            $state: ng.ui.IStateService,
            userService: Core.UserService,
            siteService: Core.SiteService,
            $stateParams: ng.ui.IStateParamsService,
            $rootScope: Core.IRootScopeWithHeaderConfiguration,
            storageService: StorageService,
            $timeout: ng.ITimeoutService
        ) {
            super($scope, $state, userService, siteService, $stateParams, $rootScope, storageService, $timeout);
            (<any>window).DataAnalyticsSender.sendCustomEvent('SignUp_Screen', '');
        }

        public isLoadingAccounts: boolean = false;
        isInformatiqueEtLiberteOpen: boolean = false;

        showNumeroBadge(): boolean {
            return this.registration.site && this.registration.site.hasBadgeRegistration && (this.registration.hasNoBadge == false);
        }

        showSolde(): boolean {
            return this.registration.site && this.registration.site.hasSoldeRegistration && (this.registration.hasNoBadge == false);
        }

        requireBirthDate(): boolean {
            return this.showBirthDate() && this.registration.site.birthdateRequired;
        }

        selectAccount(account: Core.IMatchingAccount): void {
            this.isLoadingAccounts = true;
            this.submit(account.numeroBadge);
            this.updateScrollIos();
        }

        submit(enforceNumeroBadge: string): void {
            (<any>window).DataAnalyticsSender.sendCustomEvent('SignUp_BTN_Confirm', '');
            this.updateScrollIos();
            this.isRegistrationFailed = false;
            if (!this.registration.accepteCgu) {
                this.isRegistrationFailed = true;
                this.errorMessage = 'Do_Accept_CGU';
                this.updateScrollIos();
                this.isLoadingAccounts = false;
                return;
            }

            if (this.registration.dateNaissance && moment(this.registration.dateNaissance).toDate() > moment().subtract(14, 'year').toDate()) {
                this.isRegistrationFailed = true;
                this.errorMessage = 'Registration_BirthDateUnauthorized';
                return;
            }

            if (!this.registration.site) {
                this.isRegistrationFailed = true;
                this.errorMessage = 'SiteUndefined';
                this.updateScrollIos();
                return;
            }

            var reg = <Core.IRegistration>{
                isUserRegistered: this.isUserRegistered,
                numeroBadge: this.registration.numeroBadge,
                email: this.registration.email,
                civilite: this.registration.civilite,
                prenom: this.registration.prenom,
                nom: this.registration.nom,
                numTelephone: null,
                dateNaissance: this.registration.dateNaissance,
                password: this.registration.password,
                siteName: this.registration.site.siteName,
                solde: this.registration.solde,
                accepteCgu: this.registration.accepteCgu,
                accepteRecevoirOffresElior: this.registration.accepteRecevoirOffresElior,
                accepteRecevoirOffresPartenaires: this.registration.accepteRecevoirOffresPartenaires,
                hasNoBadge: this.registration.hasNoBadge
            };

            if (this.registration.site.typeContratRequired) {
                reg.typeContrat = this.registration.typeContrat;
            }

            this.errorMessageDetails = <string[]>[];
            this.isRegistering = true;
            this.updateScrollIos();
            this.userService.register(reg, enforceNumeroBadge)
                .then(() => {
                    this.userService.signIn(new Core.AuthRequest(reg.email, reg.password), Core.Scopes.TIMECHEF)
                        .then((userInformations) => {
                            this.updateScrollIos();
                            if (userInformations.currentSite.restaurantsDisplayed) {
                                this.$state.go('restaurants');
                            } else {
                                this.$state.go('credit');
                            }
                        }, error => {
                            // TODO: StatusCode=0
                        })
                        .finally(() => {
                            // TODO : put some code or Delete finally
                        });
                }, (error: Core.IErrorMessage<any>) => {
                    // TODO: StatusCode=0
                    switch (error.code) {
                        case 300:
                            this.registration.matchingAccounts = error.data;
                            this.registration.isReadOnly = true;
                            break;
                        case 404:
                            this.isRegistrationFailed = true;
                            this.errorMessage = 'Convive_Not_Found';
                            break;
                        case 400:
                            this.isRegistrationFailed = true;
                            // Il faut afficher tous les messages
                            // if (error.data && error.data.email) {
                            //     this.errorMessage = 'Email_Already_Used';
                            // }
                            // else {
                            //  this.errorMessage = 'Registration_Error';
                                this.errorMessageDetails = _(error.data).values().flatten().value() as string[]; //on affiche les erreurs
                            // }
                            break;
                        case 500:
                            if (error.data && error.data.exception) {
                                if (error.data.exception === 'ConfirmedEmailRequiredException') {
                                    this.$state.go('confirmEmail', <any>{ email: reg.email, callFrom: 'registration' }, { location: 'replace' });
                                }
                                else {
                                    this.isRegistrationFailed = true;
                                    this.errorMessage = 'Exception_' + error.data.exception;
                                }
                            }
                            else {
                                this.isRegistrationFailed = true;
                                this.errorMessage = 'Registration_Error';
                            }
                            break;
                        default:
                            this.isRegistrationFailed = true;
                            this.errorMessage = 'Registration_Error';
                    }
                    this.isLoadingAccounts = false;

                }).finally(() => {
                    this.isRegistering = false;
                    this.updateScrollIos();
                });
        }

        onCguClicked() {
            this.isInformatiqueEtLiberteOpen = !this.isInformatiqueEtLiberteOpen;
            this.updateScrollIos();

            (<any>window).DataAnalyticsSender.sendCustomEvent('SignUp_BTN_DataProtectionLink', '');
        }
    }

    angular.module('easypass').controller('registrationController',
        ['$scope', '$state', 'userService', 'siteService', '$stateParams', '$rootScope', 'storageService', '$timeout', RegistrationController]);
}