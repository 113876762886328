module Easypass {
    class VoteController {
        isLoading: boolean = true;
        isVoteLoading: boolean = false;
        isOnError: boolean = false;
        networkError: boolean;
        vote: Core.IVoteModel;
        conceptInfos: Core.IConceptInfos;
        descriptionToggled: boolean;
        selectedThematiqueId: number;
        sousThematiqueEnCours: Core.ISousThematiqueGagnanteGlobal;
        sousThematiqueEnCoursDefault: Core.ISousThematiqueGagnante;
        imageUrl: string;

        // Restaurant Data
        restaurantId: number;
        restaurant: Core.IRestaurant;
        isRestaurantOpen: boolean = false;

        public voteForm: ng.IFormController;


        constructor(
            private voteCampagneService: Core.VoteCampagneService,
            private restaurantsService: RestaurantsService,
            private configService: IS.Configuration.ConfigService,
            private storageService: Core.StorageService,
            private $state: ng.ui.IStateService,

            private $timeout: ng.ITimeoutService,
            private $stateParams: ng.ui.IStateParamsService,
            private routeService: Core.RouteService,
            private $sce: ng.ISCEService
        ) {
            this.restaurantId =  +this.$stateParams['restaurantId'];
            this.init();
        }

        init(){
            this.loadCachedImageUrl();
            this.loadVoteModel();
            this.loadRestaurantInfos();
        }

        loadVoteModel() {
            this.isOnError = false;
            this.isLoading = true;
            this.voteCampagneService.getVoteEnCours(this.restaurantId)
                .then((vote: Core.IVoteModel) => {
                    this.vote = vote;
                    this.loadVoteConceptInfos(vote.conceptThematiqueId);
                })
                .catch((error) => {
                    // TODO :manage error
                    this.isLoading = true;
                    this.loadVoteConceptInfos();
                }).finally(() => { this.isLoading = false; });
        }

        loadVoteConceptInfos(conceptId = null) {
            this.voteCampagneService.getConceptInfos(conceptId)
                .then((conceptInfos: Core.IConceptInfos) => {
                    this.conceptInfos = conceptInfos;
                    this.conceptInfos.description = this.$sce.trustAsHtml(this.conceptInfos.description);
                    this.storageService.saveImageConcept(this.conceptInfos.imageDocumentId);
                    this.imageUrl = !!this.conceptInfos.imageDocumentId ? `${this.configService.getConfigValue("url")}/documents/${this.conceptInfos.imageDocumentId}` : '';
                });
        }

        loadCachedImageUrl() {
            let imageDocumentId = this.storageService.getImageConcept();
            if (imageDocumentId) {
                this.imageUrl = `${this.configService.getConfigValue("url")}/documents/${imageDocumentId}`;
            }
        }

        loadRestaurantInfos() {
            this.restaurantsService.get(this.restaurantId)
                .then(restaurant => {
                    this.restaurant = restaurant;
                    this.isRestaurantOpen = this.restaurantsService.getIsOpen(restaurant);
                    (<any>window).DataAnalyticsSender.sendCustomEvent('Crowd_Screen', restaurant.restaurantType);
                    this.loadSousThematiqueGagnante();
                })
                .catch(error => {
                    checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
                });
        }

        private loadSousThematiqueGagnante(date?: Date) {
            this.voteCampagneService.getSousThematiqueGagnante(this.restaurant, date).then(sousThematiqueEnCours => {
                this.sousThematiqueEnCours = sousThematiqueEnCours;
                this.sousThematiqueEnCoursDefault = sousThematiqueEnCours.defaultSousThematiqueGagnante;
            }, error => {
                this.sousThematiqueEnCours = undefined;
                this.sousThematiqueEnCoursDefault = undefined;
            });
        }
        conviveVote() {
            if (this.voteForm.$invalid) {
                return;
            }
            this.isOnError = false;
            this.isVoteLoading = true;
            this.voteCampagneService.conviveVote(<Core.IVoteChoixModel>{
                campagneId: this.vote.campagneId,
                restaurantId: this.vote.restaurantId,
                selectedThematiqueId: this.selectedThematiqueId
            })
                .then(() => {
                    this.isOnError = false;
                    this.fetchForOtherVotes();
                })
                .catch((error) => {
                    this.isVoteLoading = false;
                    this.isOnError = true;
                }
                );

        }

        fetchForOtherVotes() {
            this.voteCampagneService.getVoteState(this.restaurant)
            .then((voteState) => {
                if (voteState.convivePeutVoter) {
                    window.location.reload();
                } else {
                    this.$state.go('voteResultat', { restaurantId: this.vote.restaurantId, mode: 'merci' });
                }
            })
            .catch(() => {
                this.isVoteLoading = false;
                this.isOnError = true;
            });
        }

        toggleConceptInfos() {
            document.body.scrollTop = 0;
            this.$timeout(100).then(() => {
                this.descriptionToggled = !this.descriptionToggled;
            });
        }

        getImageUrl() {
            let imageDocumentId = null;
            let defaultImageUrl = './styles/assets/default-restaurant.jpg';

            if (this.restaurant) {
                if (this.restaurant.voteEstActif) {
                    // imageDocumentId = this.getThematiqueGagnanteImageDocumentId();
                    // if (!imageDocumentId) {
                        imageDocumentId = this.getConceptImageDocumentId();
                    // }
                    if (imageDocumentId) {
                        return `${this.configService.getConfigValue("url")}/documents/${imageDocumentId}`;
                    }
                }
                console.log("vote resultatController - image du restaurant : "+ this.restaurant.imageUrl);
                return this.restaurant.imageUrl;
            } else {
                console.log("pas d'image donc image par defautl : " + defaultImageUrl);
                return defaultImageUrl;
            }
        }
            private getThematiqueGagnanteImageDocumentId(): number {
            if (this.sousThematiqueEnCoursDefault) {
                return this.sousThematiqueEnCoursDefault.imageDocumentId;
            }

            //if (!this.selectedMenu || this.selectedMenu.date.getTime() === this.getToday().getTime()) {
            return this.storageService.getImageThematiqueGagnante(this.restaurant.id);
            //}
        }

        getPictogram(sousThematiqueId: number): string {
            return `${this.configService.getConfigValue('url')}/vote/sous-thematique/${sousThematiqueId}/pictogram`;
        }

        private getConceptImageDocumentId(): number {
            return this.conceptInfos
                ? this.conceptInfos.imageDocumentId
                : this.storageService.getImageConcept();
        }
    }

    angular.module('easypass').controller('voteController', ['voteCampagneService', 'restaurantsService', 'configService', 'storageService', '$state', '$timeout', '$stateParams', 'routeService', '$sce', VoteController]);
}