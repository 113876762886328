module Easypass {
    class FluiditeController {

        constructor(
            private localizeService: IS.Localization.LocalizeService,
            private fluiditeService: FluiditeService,
            private restaurantsService: RestaurantsService,
            private $state: ng.ui.IStateService,
            private $timeout: ng.ITimeoutService,
            private $stateParams: ng.ui.IStateParamsService,
            private routeService: Core.RouteService,
            private chartHelperService: IChartHelperService,
            private $window: ng.IWindowService
        ) {
            this.isOnError = false;
            this.restaurantId = +$stateParams['restaurantId'];

            // Calcul du nombre de restaurant
            this.restaurantsService.gets(false, true, true)
                .then(restaurants => {
                    this.restaurantsCount = restaurants.length;
                })
                .catch(error => {
                    checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
                });

            // Récupération des informations restaurant pour le header
            this.restaurantsService.get(this.restaurantId)
                .then(restaurant => {
                    this.restaurant = restaurant;
                    this.isRestaurantOpen = this.restaurantsService.getIsOpen(restaurant);
                    (<any>window).DataAnalyticsSender.sendCustomEvent('Crowd_Screen', restaurant.restaurantType);
                })
                .catch(error => {
                    checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
                });

            this.isLoading = true;

            this.fluiditeService.getFluidite(this.restaurantId)
                .then((fluidite) => {
                    this.loadingData = true;
                    this.$timeout(600).then(() => {
                        var removeTimeZone = (date: string): string => date.replace('T', ' ').substr(0, date.indexOf('+'));
                        fluidite.dateDebut = removeTimeZone(fluidite.dateDebut);
                        fluidite.dateFin = removeTimeZone(fluidite.dateFin);
                        this.fluidite = fluidite;
                        this.chartData = chartHelperService.computeData(fluidite);
                        this.loadingData = false;
                        if (this.chartData[0].values.length === 0 && this.chartData[1].values.length === 0) {
                            this.chartDataEmpty = true;
                        }
                    });
                }, error => {
                    checkNetworkError(error, () => this.isOnError = true, () => this.networkError = true);
                }).finally(() => {
                    this.isLoading = false;
                });

        }

        isLoading: boolean = true;
        isOnError: boolean = false;
        networkError: boolean;
        fluidite: IFluidite;
        restaurantId: number;
        restaurant: Core.IRestaurant;
        loadingData: boolean;
        hideGraph: boolean = false;
        restaurantsCount: number;
        chartDataEmpty: boolean = false;
        isRestaurantOpen: boolean = false;

        chartApi: nv.LineChart;
        chartData: any[];

        chartOptions: any = {
            chart: {
                deepWatchData: false,
                type: 'lineChart',
                height: 400,
                margin: {
                    top: 20,
                    right: 20,
                    bottom: 60,
                    left: 55
                },
                x: function (d: any) { return d.x; },
                y: function (d: any) { return d.y; },
                showValues: true,
                //valueFormat: function (d: any) {
                //    return d3.time.format("%H:%M")(new Date(d));
                //},
                transitionDuration: 800,
                xAxis: {
                    //tickPadding: 100,
                    tickFormat: function (d: any) {
                        return moment(d).format('HH[h]mm');
                    }
                },
                yAxis: {
                    axisLabelDistance: 30
                },
                noData: ''
            }
        };

        placesDisponibles(): number {
            if (this.fluidite)
                if (this.fluidite.statut.occupation != null)
                    return Math.max(this.fluidite.capacite - this.fluidite.statut.occupation, 0);
                else
                    return this.fluidite.capacite
        }

        getNextRestaurant() {
            this.hideGraph = true;
            this.$timeout().then(() => {
                this.restaurantsService.getNext(this.restaurant.id, true, true).then(restaurant => {
                    this.routeService.forceNavigationDirection(Core.NavigationDirection.left);
                    this.$state.go('fluidite', { restaurantId: restaurant.id });
                });
            });
        }

        getPreviousRestaurant() {
            this.hideGraph = true;
            this.$timeout().then(() => {
                this.restaurantsService.getPrevious(this.restaurant.id, true, true).then(restaurant => {
                    this.routeService.forceNavigationDirection(Core.NavigationDirection.right);
                    this.$state.go('fluidite', { restaurantId: restaurant.id });
                });
            });
        }

        goBack() {
            this.hideGraph = true;
            this.$timeout().then(() => this.$window.history.back());
        }

        goToMenu(restaurantId: number) {
            this.$state.go('restaurant', { id: this.restaurantId });
        }
    }
    
    angular.module('easypass').controller('fluiditeController', ['localizeService', 'fluiditeService', 'restaurantsService', '$state', '$timeout', '$stateParams', 'routeService', 'chartHelperService', '$window', FluiditeController]);
}