module Easypass {
    angular.module('easypass').filter('fluiditeTauxOccupationToClass', [() => {
        return (tauxOccupation: IFluiditeTauxOccupationValues): string => {
            switch (tauxOccupation) {
                case IFluiditeTauxOccupationValues.Eleve: return "fluidite-occupation-eleve";
                case IFluiditeTauxOccupationValues.Moyen: return "fluidite-occupation-moyen";
                case IFluiditeTauxOccupationValues.Faible: return "fluidite-occupation-faible";
                default: return "fluidite-occupation-na";
            }
        };
    }]);
}