module Core {
    export interface IHeaderConfiguration {
        showBack?: boolean;
        showMenu?: boolean;
        showNotifications?: boolean;
        activeMenu?: boolean;
        menuCssClass?: string;
        showPub?: boolean;
    }

    export enum MenuItem {
        Restaurant,
        Enquete,
        Animation,
        Commande,
        Parametre,
        Notification,
        None, 
        RoomService,
        Recharger,
        MesTickets,        
        Affluence,
        PrestationSurMesure,
        MaPresence
    }

    export interface IStateWithConfiguration extends ng.ui.IState {
        headerConfiguration: IHeaderConfiguration;
        transitionConfiguration: INavigationConfiguration;
        isRootState?: boolean;
        menuItem: any;
        sansRestaurantsAccess?: boolean; // Pour autoriser aux pages Timechef Sans Restaurants
    }

    export interface IRootScopeWithHeaderConfiguration extends ng.IRootScopeService {
        headerConfiguration: IHeaderConfiguration;
    }

    export interface IScopeWithHeaderConfiguration extends ng.IScope {
        headerConfiguration: IHeaderConfiguration;
    }
}