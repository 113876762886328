module Core {


    class MenuTouchDirective implements ng.IDirective {

        constructor(private $timeout: ng.ITimeoutService) {
        }

        restrict: string = 'AE';
        priority = 1;
        element: ng.IAugmentedJQuery;
        menuElement: ng.IAugmentedJQuery;
        dragging = false;
        touchX: number;
        maxDelta: number;
        delta: number;
        isOpen: boolean;
        isGestureActive: boolean = false;

        touchStart = (evt: PointerEvent | TouchEvent | MouseEvent) => {
            if (this.dragging || !this.isGestureActive) return;
            this.touchX = this.getXFromEvent(evt);
            this.isOpen = this.menuElement.hasClass('menu-open');
            this.delta = 0;
            if (this.touchX < 50 || this.isOpen) {

                requestAnimationFrame(() => {
                    this.menuElement.css('transition', 'none');
                });
                this.dragging = true;

            }
        };

        touchMove = (evt: PointerEvent | TouchEvent | MouseEvent) => {
            if (this.dragging) {
                this.delta = this.getXFromEvent(evt) - this.touchX;
                if (this.isOpen) this.delta += this.maxDelta;
                if (this.delta > this.maxDelta) {
                    this.delta = this.maxDelta;
                }
                requestAnimationFrame(() => {
                    this.menuElement.css('transform', `translate3d(${this.delta - this.maxDelta}px, 0, 0)`);
                });
            }
        };

        touchEnd = (evt: PointerEvent | TouchEvent | MouseEvent) => {
            if (!this.dragging) {
                return;
            }
            this.dragging = false;
            if ((!this.isOpen && this.delta < 80) || ((this.maxDelta - this.delta) < 80 && this.isOpen)) {
                requestAnimationFrame(() => {
                    this.menuElement.css('transition', '');
                    this.menuElement.css('transform', '');
                });
                return;
            }
            evt.preventDefault();
            requestAnimationFrame(() => {
                this.menuElement.css('transition', '');
                this.menuElement.css('transform', '');
                this.menuElement.toggleClass('menu-open');
            });
        };

        link = (scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery): void  => {
            scope.$on('headerConfigurationHasChanged', (event: any, headerConfiguration: IHeaderConfiguration) => {
                this.isGestureActive = headerConfiguration.showMenu;
            });
            this.$timeout().then(() => {
                this.menuElement = angular.element(document.querySelector('menu'));
                this.menuElement[0].addEventListener('click', () => {
                    this.menuElement.removeClass('menu-open');
                });
                this.maxDelta = this.menuElement[0].clientWidth;

                if ((<any>element[0])['on' + this.touchEvents.touch[0]] !== undefined) {
                    this.touchEvents.activeEvent.push(this.touchEvents.touch);
                }

                if ((<any>element[0])['on' + this.touchEvents.mouse[0]] !== undefined) {
                    this.touchEvents.activeEvent.push(this.touchEvents.mouse);
                }

                for (let i = 0; i < this.touchEvents.activeEvent.length; i++) {
                    for (let j = 0; j < this.touchEvents.activeEvent[i].length; j++) {
                        element[0].addEventListener(this.touchEvents.activeEvent[i][j], this.touchEvents.handlers[j], false);
                    }
                }
                this.isGestureActive = scope.headerConfiguration.showMenu;
            });
        };

        touchEvents = {
            touch: ['touchstart', 'touchmove', 'touchend', 'touchcancel', 'touchleave'],
            mouse: ['mousedown', 'mousemove', 'mouseup', 'mouseout'],
            handlers: [this.touchStart, this.touchMove, this.touchEnd, this.touchEnd, this.touchEnd],
            activeEvent: <any>[]
        };

        getXFromEvent = (evt: any) => {
            if (evt.touches) {
                return evt.touches[0].pageX;
            } else {
                return evt.pageX;
            }
        };

        static factory() {
            var directive = ($timeout: ng.ITimeoutService) => {
                return new MenuTouchDirective($timeout);
            };
            return directive;
        }
    }

    angular.module('core').directive('menuTouch', ['$timeout', MenuTouchDirective.factory()]);
}