module Easypass {
    export enum LockeReservationState {
        /// <summary>
        /// L’utilisateur a débuté une commande
        /// </summary>
        Started = 1,

        /// <summary>
        /// L’utilisateur finalise sa commande (paiement OK)
        /// </summary>
        Finalized = 2,

        /// <summary>
        /// L’opérateur a chargé la machine
        /// </summary>
        Filled = 3,

        /// <summary>
        /// Le client est venu chercher sa commande
        /// </summary>
        Collected = 4,

        /// <summary>
        /// Le gérant a annulé la commande faite en amont suite à une rupture produit
        /// </summary>
        Canceled = 5,
    }
}