module Easypass {
    class RestaurantController {
        constructor(private restaurantsService: RestaurantsService,
            private menuService: Core.MenuService,
            private animationService: Core.AnimationService,
            private enqueteService: Core.EnqueteService,
            private voteCampagneService: Core.VoteCampagneService,
            private storageService: Core.StorageService,
            private $state: ng.ui.IStateService,
            $stateParams: ng.ui.IStateParamsService,
            private $timeout: ng.ITimeoutService,
            private $sce: ng.ISCEService,
            private $scope: ng.IScope,
            private allergenService: Core.AllergenesService,
            private configService: IS.Configuration.ConfigService,
            private routeService: Core.RouteService) {
            this.isLoading = true;
            var id = +$stateParams['id'];

            this.restaurantsService.gets().then(restaurants => {
                this.restaurantsNumber = restaurants.length;
            }, error => {
                // TODO: StatusCode=0
            });
            this.restaurantsService.get(id).then(restaurant => {
                (<any>window).DataAnalyticsSender.sendCustomEvent('Restaurant_Screen', restaurant.restaurantType);

                this.restaurant = restaurant;

                if (this.restaurant.description && (typeof this.restaurant.description === "string")) {
                    this.restaurant.description = $sce.trustAsHtml(this.restaurant.description);
                }

                this.joursFermetures = this.restaurantsService.getJoursFermetures(restaurant);

                this.loadAnimations(restaurant);

                this.loadEnquetes(restaurant);

                this.loadMenus(restaurant);

                if (restaurant.voteEstActif) {
                    this.getVoteState(restaurant);
                }
            }).catch(error => {
                // TODO: StatusCode=0

                // AFFICHER UN MESSAGE : Ce restaurant n'existe pas ou a été désactivé. Veuillez réessayer plus tard.

                if (error === 'Restaurant not found') {
                }
                this.isLoading = false;
            }).finally(() => {
                this.isLoading = false;
            });
        }

        menus: Core.IMenu[];
        isLoading: boolean = false;
        errorLoadingMenus: boolean = false;
        isVoteStateLoading: boolean = true;
        networkError: boolean;
        restaurant: Core.IRestaurant;
        animation: Core.IAnimation;
        animations: Core.IAnimation[];
        campagne: Core.ICampagne;
        hasVoteEnCours: boolean;
        conceptInfos: Core.IConceptInfos;
        sousThematiqueEnCours: Core.ISousThematiqueGagnanteGlobal;
        sousThematiqueEnCoursDefault: Core.ISousThematiqueGagnante;
        peutVoter?: boolean;
        selectedMenu: Core.IMenu;
        images: string[];
        restaurantsNumber: number;
        isChanging: boolean;
        currentDay: Date;
        descriptionToggled: boolean = false;
        joursFermetures: Core.IDateInterval;

        goToPlat(plat: any) {

            this.storageService.setString('lastVisitedMenuDate-' + this.restaurant.id, this.currentDay.toString());
            this.$state.go('plat', { restaurantId: this.restaurant.id, plat: plat })
        }

        toggleDescription() {
            document.body.scrollTop = 0;
            this.$timeout(100).then(() => {
                this.descriptionToggled = !this.descriptionToggled;
            });
        }

        isOpen(): boolean {
            return this.restaurantsService.getIsOpen(this.restaurant);
        }

        selectMenu(menu: Core.IMenu) {
            (<any>window).DataAnalyticsSender.sendCustomEvent('Restaurant_BTN_Day', '');
            this.storageService.setString('lastVisitedMenuDate-' + this.restaurant.id, menu.date.toString());
            this.currentDay = menu.date;
            if (this.selectedMenu != menu) {
                this.isChanging = true;
                this.$timeout(250).then(() => {
                    this.selectedMenu = menu;
                    this.loadSousThematiqueGagnante(this.selectedMenu.date);
                });
                this.$timeout(500).then(() => {
                    this.isChanging = false;
                });
            }
        }

        private loadAnimations(restaurant: Core.IRestaurant) {
            this.animationService.getCurrent(restaurant.id).then(animation => {
                this.animation = animation;
            }, error => {
                // TODO: StatusCode=0
            });

            this.animationService.gets(false, restaurant.id).then(animations => {
                this.animations = animations;
            }, error => {
                // TODO: StatusCode=0
            });
        }
        private loadEnquetes(restaurant: Core.IRestaurant) {
            this.enqueteService.getCurrent(restaurant.id).then(campagne => {
                this.campagne = campagne;
            }, error => {
                // TODO: StatusCode=0
            });
        }
        private loadMenus(restaurant: Core.IRestaurant) {
            var result = this.menuService.getMenus(restaurant.id);

            this.displayMenus(result.menus, restaurant.jourOuverture, restaurant.joursFermetures);

            if (!!result.promise) {
                result.promise.then(menus => {
                    this.displayMenus(menus, restaurant.jourOuverture, restaurant.joursFermetures);

                    if ((!menus.value || menus.value.length < 1) && this.restaurant.description) {
                        this.toggleDescription();
                    }
                }, error => {
                    // TODO: StatusCode=0

                    if (result.menus == null || result.menus.value.length === 0) {
                        if (error.status > 0) {
                            this.errorLoadingMenus = true;
                        } else {
                            this.networkError = true;
                        }
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            }
            else {
                if ((!result.menus.value || result.menus.value.length < 1) && this.restaurant.description) {
                    this.toggleDescription();
                }
            }
        }

        private getVoteState(restaurant: Core.IRestaurant) {
            this.isVoteStateLoading = true;
            this.voteCampagneService.getVoteState(restaurant).then(voteState => {
                this.hasVoteEnCours = voteState.restaurantHasVoteEnCours;
                this.peutVoter = voteState.convivePeutVoter;
                this.loadVoteConceptInfos(voteState.defaultConceptThematiqueId);
            }, error => {
                this.hasVoteEnCours = false;
                this.peutVoter = null;
                this.loadVoteConceptInfos(null);
            }).finally(() => this.isVoteStateLoading = false);
        }

        private loadVoteConceptInfos(conceptId) {
            this.voteCampagneService.getConceptInfos(conceptId)
                .then(conceptInfos => {
                    this.conceptInfos = conceptInfos;
                    this.conceptInfos.description = this.$sce.trustAsHtml(this.conceptInfos.description);
                    this.storageService.saveImageConcept(this.conceptInfos ? this.conceptInfos.imageDocumentId : null);
                });
        }

        private loadSousThematiqueGagnante(date?: Date) {
            this.voteCampagneService.getSousThematiqueGagnante(this.restaurant, date).then(sousThematiqueEnCours => {
                this.sousThematiqueEnCoursDefault = sousThematiqueEnCours.defaultSousThematiqueGagnante;
                this.sousThematiqueEnCours = sousThematiqueEnCours;
                if (this.sousThematiqueEnCoursDefault) {
                    this.storageService.saveImageConcept(this.sousThematiqueEnCoursDefault.imageDocumentId);
                }
            }, error => {
                this.sousThematiqueEnCours = undefined;
                this.sousThematiqueEnCoursDefault = undefined;
            });
        }

        private displayMenus(timestampedMenus: Core.ITimestamped<Core.IMenu[]>, joursOuvertures: Core.JourOuvertureFlag, joursFermetures: Core.IDateInterval[]) {
            let menus: Core.IMenu[] = [];
            if (!!timestampedMenus && !!timestampedMenus.value) {
                menus = timestampedMenus.value;
            }

            this.menus = this.filter(this.sort(menus), joursOuvertures, joursFermetures);

            if (this.menus.length !== 0) {
                var lastVisitedMenuDateStored = this.storageService.getString('lastVisitedMenuDate-' + this.restaurant.id);
                this.storageService.removeItem('lastVisitedMenuDate-' + this.restaurant.id);
                var index = -1;
                if (lastVisitedMenuDateStored) {
                    var lastVisitedMenuDate = new Date(lastVisitedMenuDateStored);
                    lastVisitedMenuDate.setHours(0, 0, 0, 0);
                    index = _.findIndex(this.menus, menu => {
                        var menuDate = menu.date;
                        menuDate.setHours(0, 0, 0, 0);
                        return menuDate.getTime() === lastVisitedMenuDate.getTime();
                    });
                }

                if (index === -1) {
                    var today = new Date();
                    today.setHours(0, 0, 0, 0);

                    index = _.findIndex(this.menus, menu => {
                        var menuDate = menu.date;
                        menuDate.setHours(0, 0, 0, 0);
                        return menuDate >= today;
                    });
                }

                if (index === -1) {
                    index = this.menus.length - 1;
                }

                this.currentDay = this.menus[index].date;

                this.$timeout(600).then(() => {
                    this.isLoading = false;
                    this.selectedMenu = this.menus[index];
                    this.loadSousThematiqueGagnante(this.selectedMenu.date);
                });
            }
            else {
                this.selectedMenu = null;
                this.loadSousThematiqueGagnante();
            }
        }
        private sort(menus: Core.IMenu[]): Core.IMenu[] {
            let sorted: Core.IMenu[] = menus != null ? menus.sort((m1, m2) => m1.date.getTime() - m2.date.getTime()) : [];
            return sorted;
        }
        private filter(menus: Core.IMenu[], joursOuvertures: Core.JourOuvertureFlag, restaurantFermetures: Core.IDateInterval[]): Core.IMenu[] {
            let joursFermetures: Date[] = this.getJoursFermetures(restaurantFermetures || []);

            // vérifier que les menus reçus doivent bien être affichés
            let i: number = 0;
            while (i < menus.length) {
                let menu: Core.IMenu = menus[i];
                var date: Date = menu.date;
                date.setHours(0, 0, 0, 0);

                let jour: Core.JourOuvertureFlag = this.restaurantsService.getJourOuvertureFlagByJSDate(date);
                let estJourOuverture: boolean = (jour & joursOuvertures) !== 0;

                // la date du menu courant est une date de fermeture exceptionnelle
                let index: number = _.findIndex(joursFermetures, jfe => jfe.getTime() === date.getTime());
                if (index != -1) {
                    joursFermetures.splice(index, 1); // on le supprime pour ne pas avoir à le parcourir dans la suite de l'algo

                    if (estJourOuverture) {
                        // jour d'ouverture habituelle : on affiche le jour mais à l'état 'FERMÉ'
                        menu.closed = true;
                    }
                    else {
                        // pas un jour d'ouverture habituelle : on n'affiche pas le jour
                        menus.splice(i, 1);
                        continue; // on ne veut pas incrémenter 'i'
                    }
                }
                else {
                    // le menu est valide, on pourra l'afficher
                    menu.closed = false;
                }

                ++i;
            }

            // insérer dans la liste des menus les jours de fermeture exceptionnelle
            for (var jourFermeture of joursFermetures) {

                // le jour du menu courant n'est pas un jour d'ouverture habituelle
                let jour: Core.JourOuvertureFlag = this.restaurantsService.getJourOuvertureFlagByJSDate(jourFermeture);
                if ((jour & joursOuvertures) !== jour) {
                    continue;
                }

                let menuJourFermeture: Core.IMenu = this.createMenuByDate(jourFermeture);

                let index: number = _.findIndex(menus, menu => {
                    let date: Date = menu.date;
                    date.setHours(0, 0, 0, 0);
                    return jourFermeture < date;
                });
                if (index == -1) {
                    // aucun menu dont la date est supérieure au jour de fermeture courant, on ajoute ce jour à la fin
                    menus.push(menuJourFermeture);
                }
                else {
                    // un menu avec une date supérieure existe, il faut donc ajouter le jour de fermeture juste avant
                    menus.splice(index, 0, menuJourFermeture);
                }
            }

            return _.take(menus, 5);
        }
        private createMenuByDate(date: Date): Core.IMenu {
            let menu: Core.IMenu = <Core.IMenu>{
                date: date,
                closed: true,
                famillePlats: null,
            };
            return menu;
        }
        private getJoursFermetures(restaurantFermetures: Core.IDateInterval[]): Date[] {
            let today: moment.Moment = moment(new Date().setHours(0, 0, 0, 0));
            let dates: Date[] = [];

            for (let restaurantFermeture of restaurantFermetures) {
                let debut: moment.Moment = moment(restaurantFermeture.dateDebut).hour(0).minute(0).second(0).millisecond(0);
                let fin: moment.Moment = moment(restaurantFermeture.dateFin).hour(0).minute(0).second(0).millisecond(0);

                while (debut.isSameOrBefore(fin)) {
                    if (today.isSameOrBefore(debut)) {
                        dates.push(debut.toDate());
                    }
                    debut = debut.clone().add(1, 'days');
                }
            }

            return dates;
        }

        isAllergicToPlat(plat: Core.IPlat): boolean {
            return this.allergenService.isAllergicToPlat(plat.allergenes);
        }

        getLocalAnimationFromId(animationId: number): Core.IAnimation {
            return _.find(this.animations, { id: animationId });
        }

        isAnimationInProgress(animationId: number): boolean {
            var animation = this.getLocalAnimationFromId(animationId);
            return !!animation && animation.endAt > this.selectedMenu.date && animation.startAt < this.selectedMenu.date;
        }

        animationPictoUrl(animationId: number): string {
            var animation = this.getLocalAnimationFromId(animationId);
            if (!!animation) {
                return _.find(this.animations, { id: animationId }).pictoUrl;
            }
            return null;
        }

        animationColor(animationId: number): string {
            var animation = this.getLocalAnimationFromId(animationId);
            if (!!animation) {
                return _.find(this.animations, { id: animationId }).backgroundColor;
            }
            return null;
        }

        goToAnimation(animation: Core.IAnimation) {
            this.$state.go('animationsOfRestaurantWithBack', {
                restaurantAnimationId: animation.restaurantAnimationId,
                restaurantId: animation.restaurantId
            });
        }

        getNextRestaurant() {
            this.restaurantsService.getNext(this.restaurant.id, true).then(restaurant => {
                this.routeService.forceNavigationDirection(Core.NavigationDirection.left);
                this.$state.go('restaurant', { id: restaurant.id });
            });
        }

        getPreviousRestaurant() {
            this.restaurantsService.getPrevious(this.restaurant.id, true).then(restaurant => {
                this.routeService.forceNavigationDirection(Core.NavigationDirection.right);
                this.$state.go('restaurant', { id: restaurant.id });
            });
        }

        getToday(): Date {
            var now = moment().toDate();
            now.setHours(0, 0, 0, 0);
            return now;
        }

        getImageUrl() {
            let imageDocumentId = null;
            let defaultImageUrl = './styles/assets/default-restaurant.jpg';

            if (this.restaurant) {
                if (this.restaurant.voteEstActif) {
                    imageDocumentId = this.getThematiqueGagnanteImageDocumentId();
                    if (!imageDocumentId) {
                        imageDocumentId = this.getConceptImageDocumentId();
                    }
                    if (imageDocumentId) {
                        return `${this.configService.getConfigValue("url")}/documents/${imageDocumentId}`;
                    }
                }
                return this.restaurant.imageUrl;
            } else {
                return defaultImageUrl;
            }
        }

        private getThematiqueGagnanteImageDocumentId(): number {
            if (this.sousThematiqueEnCours && this.sousThematiqueEnCours.defaultSousThematiqueGagnante) {
                return this.sousThematiqueEnCours.defaultSousThematiqueGagnante.imageDocumentId;
            }

            //if (!this.selectedMenu || this.selectedMenu.date.getTime() === this.getToday().getTime()) {
            return this.storageService.getImageThematiqueGagnante(this.restaurant.id);
            //}
        }

        private getConceptImageDocumentId(): number {
            return this.conceptInfos
                ? this.conceptInfos.imageDocumentId
                : this.storageService.getImageConcept();
        }

        getPictogram(sousThematiqueId: number): string {
            return `${this.configService.getConfigValue('url')}/vote/sous-thematique/${sousThematiqueId}/pictogram`;
        }
    }

    angular.module('easypass').controller('restaurantController',
        ['restaurantsService', 'menuService', 'animationService', 'enqueteService', 'voteCampagneService', 'storageService', '$state', '$stateParams', '$timeout', '$sce', '$scope', 'allergenesService', 'configService', 'routeService', RestaurantController]);
}
