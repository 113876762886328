module Core {
    declare var LZString: any; // Je n'ai pas réussi à faire fonctionner les définitions de type :-(

    export interface ILocalStorageProvider {
        setString(key: string, val: string): void;
        getString(key: string): string;
        setObject(key: string, val: any): void;
        getObject(key: string): any;
        removeItem(key: string): void;
    }

    export class LocalStorageProvider implements ng.IServiceProvider {
        // ATTENTION : en cas de modification sur la sécurisation prévoir la réversibilité des données déjà stockée
        shouldCompress: boolean = false;
        compressPrefix: string = 'secure;';

        setString(key: string, val: string): void {
            if (val && this.shouldCompress) {
                try {
                    val = this.compressPrefix + LZString.compressToUTF16(val);
                } catch (error) {
                    throw error;
                }
            }

            localStorage.setItem(key, val);
        }

        getString(key: string): string {
            let data = localStorage.getItem(key);

            if (data) {
                // on essaie de décompresser la donnée
                if (_.startsWith(data, this.compressPrefix)) {
                    try {
                        data = data.substring(this.compressPrefix.length);
                        data = LZString.decompressFromUTF16(data);
                        if (!this.shouldCompress)
                        {
                            this.setString(key, data);
                        }
                    } catch (error) {
                        // silent fail
                    }
                } else {
                    if (this.shouldCompress) {
                        // La donnée n'est pas compressée, on la compresse
                        this.setString(key, data);
                    }
                }
            }

            return data;
        }

        setObject(key: string, val: any): void {
            const data = JSON.stringify(val);
            this.setString(key, data);
        }

        getObject(key: string): any {
            const data = this.getString(key);
            if (data) {
                return JSON.parse(data);
            }

            return null;
        }

        removeItem(key: string): void {
            localStorage.removeItem(key);
        }

        public $get(): ILocalStorageProvider {
            return new LocalStorageProvider();
        }
    }

    angular.module('core').provider('localStorage', [LocalStorageProvider]);
}