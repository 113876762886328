Easypass.Registration.setDTRegister((stateProvider: ng.ui.IStateProvider) => {
    var headerWithKey = (key: string, showBackButton: boolean = false): ng.ui.IState => ({
        template: `<h1 data-i18n="${key}" data-ng-class="{ 'with-back': ${showBackButton} }"></h1>
                    <dt-basket-icon restaurant-id='$resolve.restaurantId' current-date='$resolve.currentDate'></dt-basket-icon>`,
        resolve: {
            restaurantId: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                return +$stateParams['restaurantId'];
            }],
            currentDate: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                return $stateParams['currentDate']
            }]
        }
    });

    stateProvider
        // DTA - HOME PAGE
        .state('dt-home', <Core.IStateWithConfiguration>{
            url: '/dt/home/:restaurantId',
            headerConfiguration: {
                showMenu: true,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Restaurant,
            parent: 'globalLayout',
            views: {
                '': {
                    template: '<dt-home restaurant-id="$resolve.restaurantId"></dt-home>',
                    resolve: {
                        restaurantId: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return +$stateParams['restaurantId'];
                        }]
                    }
                }
            }
        })

        // DTA - DAY'S OFFERS
        .state('dt-offers', <Core.IStateWithConfiguration>{
            url: '/dt/offers/:restaurantId/:currentDate',
            headerConfiguration: {
                showMenu: false,
                showBack: true,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Restaurant,
            parent: 'globalLayout',
            params: { isLocker: null },
            views: {
                header: headerWithKey("DT_Offers_Title"),
                '': {
                    template: `<dt-offers
                                    restaurant-id="$resolve.restaurantId"
                                    current-date="$resolve.currentDate" is-locker="vm.isLocker" class="scrollable-content">
                                </dt-offers>`,
                    resolve: {
                        restaurantId: ['$stateParams', 'dtBasketService', '$state', 'restaurantsService', '$q', ($stateParams: ng.ui.IStateParamsService, basketService: Easypass.DinerTakeAway.DtBasketService, $state: ng.ui.IStateService, restaurantsService: Core.RestaurantsService, $q: ng.IQService) => {
                            var restaurantId = +$stateParams['restaurantId'];
                            var date = new Date($stateParams['currentDate']);

                            var deferred = $q.defer();
                            existsBasket(restaurantId, date, $q, restaurantsService, basketService).then(() => {
                                deferred.resolve(restaurantId);
                            }, () => {
                                $state.go('dt-home', {
                                    restaurantId: restaurantId
                                });

                                deferred.reject();
                            });

                            return deferred.promise;
                        }],
                        currentDate: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return $stateParams['currentDate']
                        }]
                    }
                }
            }
        })

        // DTA - BASKET PAGE
        .state('dt-basket', <Core.IStateWithConfiguration>{
            url: '/dt/basket/:restaurantId/:currentDate',
            headerConfiguration: {
                showMenu: false,
                showBack: true,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Restaurant,
            parent: 'globalLayout',
            views: {
                header: headerWithKey("DT_Basket_Title"),
                '': {
                    template: `<dt-basket
                                    restaurant-id="$resolve.restaurantId"
                                    current-date="$resolve.currentDate">
                                </dt-basket>`,
                    resolve: {
                        restaurantId: ['$stateParams', 'dtBasketService', '$state', 'restaurantsService', '$q', ($stateParams: ng.ui.IStateParamsService, basketService: Easypass.DinerTakeAway.DtBasketService, $state: ng.ui.IStateService, restaurantsService: Core.RestaurantsService, $q: ng.IQService) => {
                            var restaurantId = +$stateParams['restaurantId'];
                            var date = new Date($stateParams['currentDate']);
                            var deferred = $q.defer();
                            existsBasket(restaurantId, date, $q, restaurantsService, basketService).then(() => {
                                deferred.resolve(restaurantId);
                            }, () => {
                                $state.go('dt-home', {
                                    restaurantId: restaurantId
                                });

                                deferred.reject();
                            });

                            return deferred.promise;
                        }],
                        currentDate: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return $stateParams['currentDate']
                        }]
                    }

                }
            }
        })

        // DTA - ARTICLE PAGE
        .state('dt-article', <Core.IStateWithConfiguration>{
            url: '/dt/article/:restaurantId/:currentDate/:articleId',
            headerConfiguration: {
                showMenu: false,
                showBack: true,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Restaurant,
            parent: 'globalLayout',
            views: {
                header: headerWithKey("DT_Article_Title"),
                '': {
                    template: `<dt-article
                                    restaurant-id="$resolve.restaurantId"
                                    current-date="$resolve.currentDate"
                                    article-id="$resolve.articleId">
                                </dt-article>`,
                    resolve: {
                        restaurantId: ['$stateParams', 'dtBasketService', '$state', 'restaurantsService', '$q', ($stateParams: ng.ui.IStateParamsService, basketService: Easypass.DinerTakeAway.DtBasketService, $state: ng.ui.IStateService, restaurantsService: Core.RestaurantsService, $q: ng.IQService) => {
                            var restaurantId = +$stateParams['restaurantId'];
                            var date = new Date($stateParams['currentDate']);

                            var deferred = $q.defer();
                            existsBasket(restaurantId, date, $q, restaurantsService, basketService).then(() => {
                                deferred.resolve(restaurantId);
                            }, () => {
                                $state.go('dt-home', {
                                    restaurantId: restaurantId
                                });

                                deferred.reject();
                            });

                            return deferred.promise;
                        }],
                        currentDate: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return $stateParams['currentDate']
                        }],
                        articleId: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return $stateParams['articleId']
                        }]
                    }
                }
            }
        })

        // DTA - DONNEZ VOTRE AVIS PAGE
        .state('dt-expressyourself', <Core.IStateWithConfiguration>{
            url: '/dt/expressyourself/:restaurantId',
            headerConfiguration: {
                showMenu: false,
                showBack: true,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Restaurant,
            parent: 'globalLayout',
            views: {
                header: headerWithKey("DT_ExpressYourself", true),
                '': {
                    template: `<dt-expressyourself
                                    restaurant-id="$resolve.restaurantId">
                                </dt-expressyourself>`,
                    resolve: {
                        restaurantId: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return +$stateParams['restaurantId'];
                        }]
                    }
                }
            }
        })

        // DTA - ORDER CONFIRM PAGE
        .state('dt-payment-success', <Core.IStateWithConfiguration>{
            url: '/dt/payment/success/:commandeId',
            headerConfiguration: {
                showMenu: true,
                showBack: false,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Restaurant,
            parent: 'globalLayout',
            views: {
                header: headerWithKey("DT_Payment_Confirm", true),
                '': {
                    template: `<dt-confirm-order commande-id="$resolve.commandeId"></dt-confirm-order>`,
                    resolve: {
                        commandeId: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return $stateParams['commandeId'];
                        }]
                    }
                }
            }
        })

        // DTA - ORDER ERRROR PAGE
        .state('dt-payment-error', <Core.IStateWithConfiguration>{
            url: '/dt/payment/error/:restaurantId',
            headerConfiguration: {
                showMenu: true,
                showBack: false,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Restaurant,
            parent: 'globalLayout',
            views: {
                header: headerWithKey("DT_Payment_Error", true),
                '': {
                    template: `<dt-payment-error restaurant-id="$resolve.restaurantId">
                                </dt-payment-error>`,
                    resolve: {
                        restaurantId: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return +$stateParams['restaurantId'];
                        }]
                    }
                }
            }
        })

        // DTA - TERMS AND CONDITIONS
        .state('dt-termsAndConditions', <Core.IStateWithConfiguration>{
            url: '/dt/termsAndConditions/:restaurantId',
            headerConfiguration: {
                showMenu: false,
                showBack: true,
                showNotifications: false,
                activeMenu: true
            },
            transitionConfiguration: {},
            menuItem: Core.MenuItem.Restaurant,
            parent: 'globalLayout',
            views: {
                header: headerWithKey("DT_TermsAndConditions", true),
                '': {
                    template: `<dt-terms-and-conditions restaurant-id="$resolve.restaurantId">
                                </dt-terms-and-conditions>`,
                    resolve: {
                        restaurantId: ['$stateParams', ($stateParams: ng.ui.IStateParamsService) => {
                            return +$stateParams['restaurantId'];
                        }]
                    }
                }
            }
        });

    var existsBasket = (restaurantId: number, date: Date, $q: ng.IQService, restaurantsService: Core.RestaurantsService, basketService: Easypass.DinerTakeAway.DtBasketService): ng.IPromise<any> => {
        var deferred = $q.defer();

        restaurantsService.get(restaurantId).then(restaurant => {
            if (restaurant.isFoodles) {
                deferred.resolve();
            }

            basketService.getBasketObserver(restaurantId, date).take(1).subscribe(basket => {
                if (basket) {
                    deferred.resolve();
                }
                else {
                    deferred.reject();
                }
            });
        });

        return deferred.promise;
    }
});
