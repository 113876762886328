module Core {
    export class UserServiceProvider implements ng.IServiceProvider {
        private themes: ITheme[] = null;

        configure(themes: ITheme[]) {
            this.themes = themes || [];
        }

        $get = ['$http', '$q', '$rootScope', 'configService', 'localizeService', 'authenticationService', 'storageService', 'pushManagerService', 'helperService',
            ($http: ng.IHttpService,
            $q: ng.IQService,
            $rootScope: ng.IRootScopeService,
            configService: IS.Configuration.ConfigService,
            localizeService: IS.Localization.LocalizeService,
            authenticationService: AuthenticationService,
            storageService: StorageService,
            pushManagerService: IPushManagerService,
            helperService: Core.HelperService,
            enqueteService: EnqueteService) => {
                return new UserService($http, $q, $rootScope, configService, localizeService, authenticationService, storageService, pushManagerService, helperService, this.themes);
        }];
    }

    angular.module('core').provider('userService', [UserServiceProvider]);
}