module Easypass {

    interface IVotePictoScope extends ng.IScope {
        restaurantId: number;
    }

    class VotePictoDirective implements ng.IDirective {
        constructor(private voteCampagneService: Core.VoteCampagneService) { }

        restrict = 'EA';

        scope = {
            restaurantId: '=',
            peutVoter: '=',
            isLabel: "="
        };
        template =
            `<a data-ng-if="isLabel" class="square-button  big " data-ng-click="vm.gotoVote()"
               data-ng-class="peutVoter? 'red' : 'white'" data-ng-style="{ marginTop: '6px' }">
                <img src="./styles/assets/pictos/picto-votez.png" />
                <span data-i18n="Vote_Votez"></span>
            </a>
            
            <div data-ng-if="!isLabel" class="picto vote-open" data-ng-click="vm.gotoVote()"> </div>
            `;
        controller = ["$scope", "$state", "voteCampagneService", function ($scope: IVotePictoScope, $state: ng.ui.IStateService, voteCampagneService: Core.VoteCampagneService) {
            var self = this;

            this.gotoVote = () => {
                console.log("gotoVote Clicked");
                if ($scope.peutVoter) {
                    $state.go('vote', { restaurantId: $scope.restaurantId });
                } else if ($scope.peutVoter === false) {
                    $state.go('voteResultat', { restaurantId: $scope.restaurantId, mode: 'list' });
                }
            };
        }];
        controllerAs = 'vm';

        link = (scope: IVotePictoScope): void => {
        };

        static factory() {
            return (voteCampagneService: Core.VoteCampagneService) => {
                return new VotePictoDirective(voteCampagneService);
            };
        }
    }

    angular.module('easypass').directive('votePicto', ['voteCampagneService', VotePictoDirective.factory()]);
}