module Core {
    class ConditionalViewDirective implements ng.IDirective {
        restrict: string = 'A';

        link(scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery) {
            scope.$watch(() => element[0].childNodes.length, (newValue, oldValue) => {
                if (element.find('h1').hasClass('hidden-desktop')) {
                    element.addClass('hidden-desktop');
                } else {
                    element.removeClass('hidden-desktop');
                }
                if (newValue === 0) {
                    element.removeClass('active');
                } else {
                    element.addClass('active');
                }
            });
        }

        static factory() {
            return () => {
                return new ConditionalViewDirective();
            };
        }
    }

    angular.module('core').directive('conditionalView', [ConditionalViewDirective.factory()]);
}