module Core {
    class EmailParametresController {

        currentPassword: string;
        newEmail: string;
        confirmNewEmail: string;

        isLoading: boolean;
        error: boolean;
        errorMessage: string;
        errorMessageDetails: string[];
        networkError: boolean;
        hasSucceeded: boolean;
        email: string;

        constructor(
            private localizeService: IS.Localization.LocalizeService,
            private userService: UserService,
            private $rootScope: ng.IRootScopeService
        ) {
            this.userService.getUserInformations().then((userInfo) => {
                this.email = userInfo.email;
            });
        }

        changeEmail() {
            if (this.isLoading) { return; }

            // Reset all result
            this.isLoading = true;
            this.hasSucceeded = false;
            this.error = false;
            this.networkError = false;
            this.errorMessage = null;
            this.errorMessageDetails = null;


            this.userService.updateEmail(this.currentPassword, this.newEmail)
                .then(() => {
                    this.error = false;
                    this.networkError = false;

                    this.hasSucceeded = true;
                })
                .catch(error => {
                    this.hasSucceeded = false;
                    checkNetworkError(error,
                        () => this.manageError(error),
                        () => {
                            this.error = false;
                            this.networkError = true;
                        }
                    );
                })
                .finally(() => this.isLoading = false);
        }

        private manageError(error: any = null): void {
            var exceptionError = error && error.data as Core.IError;

            if (error.status === 400) {
                this.errorMessageDetails = _(error.data).values().flatten().value() as string[]; //on affiche les erreurs
            }
            else if (exceptionError && exceptionError.exception !== undefined) {
                var exception = exceptionError.exception;
                switch (exception) {
                    // Add specific errors here
                    case "CaisseUnavailableException":
                        this.errorMessage = this.localizeService.getLocalizedResource('ModifyEmail_Update_Error_CaisseUnavailableException');
                        break;
                    case "InvalidPasswordException":
                    case "UserLockedOutException":
                        this.errorMessage = this.localizeService.getLocalizedResource('Exception_' + exception);
                        break;
                    default:
                        this.errorMessage = this.localizeService.getLocalizedResource('ModifyEmail_Update_Error');
                        break;
                }
            }
           else {
                this.errorMessage = this.localizeService.getLocalizedResource('ModifyEmail_Update_Error');
            }

            this.networkError = false;
            this.error = true;
        }
    }
    angular.module('core').controller('emailParametresController', ['localizeService', 'userService', '$rootScope', EmailParametresController]);
}