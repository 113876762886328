module Core {
    export class SiteNotFoundInterceptor {
        constructor(
            private $q: ng.IQService,
            private $injector: any) {
        }

        private promise: ng.IPromise<{ previousSite: string, newSite: string }> = null;

        responseError: (rejection: any) => ng.IPromise<any> = (rejection: any) => {
            if (rejection.status === 404) {
                if (!!rejection.data && rejection.data.Code === 3) { // 3 == Site Not Found

                    var $http: ng.IHttpService = this.$injector.get('$http');
                    var userService: UserService = this.$injector.get('userService');

                    // les autres appels sont en attente
                    if (this.promise != null) {
                        return this.promise.then(({ previousSite, newSite }) => {
                            // si le site est différent du context user, on rappelle en remplaçcant le code du site
                            // on teste en vérifiant l'url résultant du remplacement de site
                            const urlWithReplacedSite = rejection.config.url.replace(previousSite, newSite);
                            if (rejection.config.url != urlWithReplacedSite) {
                                rejection.config.url = urlWithReplacedSite;
                                return $http(rejection.config);
                            }
                            // else 
                            return this.$q.reject(rejection);
                        }).catch(error => {
                            return this.$q.reject(rejection);
                        });
                    }

                    // on fait l'appel à l'API pour renew le token et rappelé le /me
                    var deferred = this.$q.defer<any>();
                    this.promise = deferred.promise;

                    var $state: ng.ui.IStateService = this.$injector.get('$state');

                    return userService.refreshSignIn()
                        .catch((reason) => {
                            if (reason && reason.status === 401) {
                                console.log('renew failed, if unauthorized go to login page');
                                userService.signOut();
                                $state.go('login');
                                return this.$q.reject(rejection);
                                
                            } else {
                                deferred.reject(reason);
                            }
                        })
                        .then((token: IToken) => {
                            console.log('renew successfully');

                            rejection.config.headers['Authorization'] = `Bearer ${token.accessToken}`;

                            var previousSite = userService.userInformations.currentSite.name;

                            return userService.getUserInformations(true)
                                .catch((error) => {
                                    console.log('getUserInformations failed');

                                    deferred.reject(error);

                                    return this.$q.reject(rejection);
                                })
                                .then((result) => {
                                    console.log('getUserInformations successfully');

                                    const newSite = result.currentSite.name;
                                    // on resolve la promise pour les futurs appels
                                    deferred.resolve({ previousSite, newSite });

                                    // si le site est différent du context user, on rappelle en remplaçcant le code du site
                                    // on teste en vérifiant l'url résultant du remplacement de site
                                    const urlWithReplacedSite = rejection.config.url.replace(previousSite, newSite);
                                    if (rejection.config.url != urlWithReplacedSite) {
                                        rejection.config.url = urlWithReplacedSite;
                                        return $http(rejection.config);
                                    }
                                    // else 
                                    return this.$q.reject(rejection);
                                });
                        })
                        .finally(() => {
                            this.promise = null;
                        });
                }
            }
            return this.$q.reject(rejection);
        }
    }

    angular.module('core')
        .service('siteNotFoundInterceptor', ['$q', '$injector', SiteNotFoundInterceptor]);
}