module Easypass {
    export class LockerService {
        constructor(private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService) {
        }

        getLockerAvailability(restaurantId: number, when: Date): ng.IPromise<{ available: number, booked: number, temporarilyReserved: number, date: Date }> {
            var deferred = this.$q.defer<{ available: number, booked: number, temporarilyReserved: number, date: Date }>();

            this.$http.get<{ available: number, booked: number, temporarilyReserved: number, date: Date }>
                (`${this.configService.getConfigValue('url')}/${restaurantId}/locker/availibility/${when.toISOString()}`)
                .success((r) => deferred.resolve(
                    {
                        available: r.available,
                        booked: r.booked,
                        temporarilyReserved: r.temporarilyReserved,
                        date: moment(r.date).toDate()
                    }
                ))
                .catch(e => deferred.reject(e));

            return deferred.promise;
        }

    }
    angular.module('easypass').service('lockerService',
        ['$http', '$q', 'configService', LockerService]);
}