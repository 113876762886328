Easypass.Registration.setAuthRegister((stateProvider: ng.ui.IStateProvider) => {
    //Attention dupliqué du routeService
    var easypassHeader = (...classList: string[]): ng.ui.IState => ({
        template: `
					<h1 class="${classList.concat('easypass-title').join(' ')}">
					   <div class="logo" />
					</h1>
				`
    });
    //attention dupliqué du routeService
    var headers = {
        easypass: easypassHeader(),
        easypassWithBack: easypassHeader('with-back'),
        easypassXs: easypassHeader('hidden-desktop'),
        easypassXsWithBack: easypassHeader('hidden-desktop', 'with-back')
    };


    stateProvider
        .state('login', <Core.IStateWithConfiguration>{
            url: '/login?site&nom&prenom&email&timestamp&token&sessionExpired&redirectUrl&matricule&isEmbedded',
        parent: 'globalLayout',
        templateUrl: './login.html',
        controller: 'loginController',
        controllerAs: 'vm',
        headerConfiguration: {},
        menuItem: Core.MenuItem.None,
        transitionConfiguration: {
        },
        sansRestaurantsAccess: true
        })
        .state('resetPassword', <Core.IStateWithConfiguration>{
        url: '/login/resetPassword?email&token',
        parent: 'globalLayout',
        views: {
            header: headers.easypassXs,
            "": {
                templateUrl: './resetPassword.html',
                controller: 'resetPasswordController',
                controllerAs: 'vm'
            }
        },
        menuItem: Core.MenuItem.None,
        headerConfiguration: {},
        transitionConfiguration: {
            paramsSerializer: (params: any): string => {
                return `${params.email}${params.token}`;
            }
        }
        })
        .state('register', <Core.IStateWithConfiguration>{
        url: '/register?email&nom&prenom&site&association',
        menuItem: Core.MenuItem.None,
        parent: 'globalLayout',
        views: {
            header: headers.easypassXs,
            "": {
                templateUrl: './registration.html',
                controller: 'registrationController',
                controllerAs: 'vm'
            }
        },
        headerConfiguration: {},
        transitionConfiguration: {
            paramsSerializer: (params: any): string => {
                return `${params.email}${params.nom}${params.prenom}${params.site}`;
            }
        }
        })
        .state('registerSoldeBadge', <Core.IStateWithConfiguration>{
        url: '/register/finalize?email&nom&prenom&site',
        menuItem: Core.MenuItem.None,
        parent: 'globalLayout',
        views: {
            header: headers.easypassXs,
            "": {
                templateUrl: './registrationSoldeBadge.html',
                controller: 'registrationSoldeBadgeController',
                controllerAs: 'vm'
            }
        },
        headerConfiguration: {},
        transitionConfiguration: {
            paramsSerializer: (params: any): string => {
                return `${params.email}${params.nom}${params.prenom}${params.site}`;
            }
        }
        })
        .state('confirmEmail', <Core.IStateWithConfiguration>{
            url: '/confirmEmail?email&callFrom&userid&token',
            parent: 'globalLayout',
            templateUrl: './confirmEmail.html',
            controller: 'confirmEmailController',
            controllerAs: 'vm',
            headerConfiguration: {},
            menuItem: Core.MenuItem.None,
            transitionConfiguration: {
                paramsSerializer: (params: any): string => {
                    return `${params.email}${params.callFrom}${params.userid}${params.token}`;
                }
            }
        });
});