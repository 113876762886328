module Easypass {
  export class StorageService extends Core.StorageService {
    constructor(localStorageProvider: Core.ILocalStorageProvider) {
      super(localStorageProvider);
    }

    private smartAppBannerViewKey: string = "smartAppBannerView";
    private panierDtaKey: string = "panierDTA-{restaurantId}";

    setSmartAppBannerView() {
      this.setObject(this.smartAppBannerViewKey, new Date());
    }

    getSmartAppBannerView(): Date {
      return this.getObject(this.smartAppBannerViewKey);
    }

    savePanierDTA(
      restaurantId: number,
      panier: {
        date: Date;
        items: any;
        dtaConfig: Easypass.IDinnerTakeawayConfiguration;
      }
    ) {
      var key = this.panierDtaKey.replace("{restaurantId}", <any>restaurantId);
      this.setObject(key, panier);
    }

    getPanierDTA(
      restaurantId: number
    ): {
      date: string;
      items: any;
      dtaConfig: Easypass.IDinnerTakeawayConfiguration;
    } {
      var key = this.panierDtaKey.replace("{restaurantId}", <any>restaurantId);
      return this.getObject(key);
    }
    removePanierDta(restaurantId: number) {
      var key = this.panierDtaKey.replace("{restaurantId}", <any>restaurantId);
      this.removeItem(key);
    }
   

    savePresenceConviveLieu(lieuId: IPresenceLieu) {
      this.setObject(this.presenceConviveLieuKey, lieuId);
    }

    getPresenceConviveLieu(): IPresenceLieu {
      return this.getObject(this.presenceConviveLieuKey);
    }
  }

  angular
    .module("easypass")
    .service("storageService", ["localStorage", StorageService]);
}
