module Easypass.DinerTakeAway {
    @Component('easypass', 'dtDay', {
        controllerAs: 'vm',
        bindings: {
            availableLockers: '<',
            currentlyOrdering: '<',
            errorLoading: '<',
            day: '<',
            isLocker: '<',
            loadingData: '<',
            articles: '<',
            canOrder: '<'
        },
        templateUrl: './dtDay.html'
    })
    class DayComponent {
        isLocker: boolean = false;
        availableLockers: number;
        currentlyOrdering: boolean;
        errorLoading: boolean;
        day: Date;
        loadingData: boolean;
        articles: Core.IArticle[];
        disabled: boolean;

        static $inject: string[] = ['$state'];

        constructor(protected $state: ng.ui.IStateService) {
        }

        //goTo = (restaurantId: number) => {
        //    if (!this.loadingData) {
        //        this.$state.go('dt-offers', {
        //            id: restaurantId,
        //            currentDate: moment(this.day).format('YYYY-MM-DD'),
        //            isLocker: this.isLocker
        //        });
        //    }
        //    else return
        //}
    }

}