module Easypass.DinerTakeAway {
    @Component('easypass', 'dtArticle', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<',
            currentDate: '<',
            articleId: '<'
        },
        templateUrl: './dtArticle.html'
    })
    class ArticleDetailComponent {
        restaurantId: number;
        currentDate: string;
        articleId: number;
        article: Easypass.IArticleDTA;
        isLocker: boolean;
        isLoading: boolean;
        isBasketLoading: boolean;
        error: boolean;
        networkError: boolean;
        hasDetails: boolean;
        disableQuantity: boolean = false;
        internalDate: Date;

        quantity: number;

        basket: BasketDTA;

        static $inject: string[] = ['carteServiceDTA', 'platService', 'lockerService', 'restaurantsService', 'dtBasketService'];

        constructor(
            private carteServiceDTA: Easypass.CarteServiceDTA,
            private platService: Core.PlatService,
            private lockerService: Easypass.LockerService,
            private restaurantsService: Core.RestaurantsService,
            private basketService: Easypass.DinerTakeAway.DtBasketService
        ) {
        }

        $onInit = () => {
            this.isLoading = true;
            this.isBasketLoading = true;
            this.error = false;
            this.networkError = false;
            this.hasDetails = false;
            this.internalDate = new Date(this.currentDate);

            this.restaurantsService.get(this.restaurantId).then((restaurant) => {
                this.isLocker = restaurant.isDinnerTakeaway;

                if (this.isLocker) {
                    this.basketService.getBasketObserver(this.restaurantId, this.internalDate).take(1).subscribe(basket => {
                        this.disableQuantity = basket.isFull;
                        this.basket = basket;

                        this.isBasketLoading = false;
                    });
                }
                else {
                    this.isBasketLoading = false;
                }
            });

            this.carteServiceDTA.getArticle(this.restaurantId, moment(this.currentDate).format('YYYY-MM-DD'), this.articleId).then(result => {
                this.isLoading = false;
                this.article = result;

                this.getDetails();
            });
        }

        quantityChanged = (portion: Core.IPortion, quantity: number) => {
            this.basketService.addOrUpdatePortion(this.restaurantId, this.internalDate, portion, quantity);
            this.disableQuantity = this.basket.isFull;
        }

        getQuantityFromBasket = (articleId: number): number => {
            if (this.basket) {
                return this.basket.getQuantityForPortion(articleId);
            }
            else {
                return null;
            }
        }

        like() {
            if (!!this.article) {
                this.isLoading = true;
                this.error = false;
                this.networkError = false;
                this.platService.like(this.articleId)
                    .catch(error => {
                        checkNetworkError(error, () => this.error = true, () => this.networkError = true);
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.getDetails();
                    });
            }
        }

        getDetails() {
            this.isLoading = true;
            this.error = false;
            this.networkError = false;
            this.platService.getPlatDetails(this.articleId)
                .then(data => {
                    this.article.nbLike = data.nombreLikes;
                    this.article.hasLike = data.like;
                    this.article.allergenes = data.allergenes;
                    this.article.description = data.description;

                    this.hasDetails = true;
                })
                .catch(error => {
                    checkNetworkError(error, () => this.error = true, () => this.networkError = true);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
}
