module Easypass {
    @Directive('core', 'scrollableContent', {
        controllerAs: 'vm',
        restrict: 'E',
        transclude: true,
        template: '<div class="scrollable-content" data-ng-transclude></div>',
        scope: true
    })
    class ScrollableContent {
    }
}