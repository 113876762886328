module Core {
    export class CookieServiceProvider implements ng.IServiceProvider {
        configure() {
        }

        $get = ['localizeService', 'configService', '$rootScope', (localizeService: IS.Localization.LocalizeService, configService: IS.Configuration.ConfigService, $rootScope: ng.IRootScopeService) => {
            return new CookieService(localizeService, configService, $rootScope);
        }];
    }

    angular.module('core').provider('cookieService', [CookieServiceProvider]);
}
