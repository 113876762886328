module Core {
    @Component('core', 'typologiesComponent', {
        controllerAs: 'vm',
        bindings: {
            typologieCodes: "<",
            typologieType: "<",
            showTitle: "<?",
            showPicto: "<?",
            hasDetail: "<?"
        },
        templateUrl: './typologies.html'
    })
    class TypologiesComponent {

        typologieCodes: string[];
        typologieType: TypologiesTypes;
        showTitle: boolean;
        showPicto: boolean;
        hasDetail: boolean;

        typologies: ITypologie[];
        showComponent: boolean;
        title: string;

        static $inject: string[] = ['configService', 'typologiesService', 'localizeService', '$state'];

        constructor(
            private configService: IS.Configuration.ConfigService,
            private typologiesService: Core.TypologiesService,
            private localizeService: IS.Localization.LocalizeService,
            private $state: ng.ui.IStateService

        ) {
            // default values
            this.showTitle = true;
            this.showPicto = true;
            this.hasDetail = true;
        }

        // $onInit = () => {

        // }

        $onChanges = (changes) => {
            if (!changes.typologieCodes) { return; }

            this.typologiesService.getTypologies(this.typologieCodes).then(platTpologies => {
                this.typologies = _.filter(platTpologies, (typologie: ITypologie) => typologie.type === this.typologieType);
                this.showComponent = !!this.typologies && this.typologies.length > 0;
                switch (this.typologieType) {
                    case TypologiesTypes.Label:
                        this.title = this.localizeService.getLocalizedResource('Plat_Typologie_Labels');
                        break;
                    case TypologiesTypes.Marqueur:
                        this.title = this.localizeService.getLocalizedResource('Plat_Typologie_Marqueurs');
                        break;
                    case TypologiesTypes.Autre:
                        this.title = this.localizeService.getLocalizedResource('Plat_Typologie_Autres');
                        break;
                    default:
                        this.title = 'ERREUR : Typologie inconnue';
                        break;
                    }
                });
        }

        getPictogram(pictogrammeDocumentId: number): string {
            return pictogrammeDocumentId ? `${this.configService.getConfigValue("url")}/documents/${pictogrammeDocumentId}` : '';
        }

        goToTypologies(): void {
            switch (this.typologieType) {
                case TypologiesTypes.Label:
                    this.$state.go('platTypologiesLabels', { typologieCodes: this.typologieCodes });
                    break;
                case TypologiesTypes.Marqueur:
                    this.$state.go('platTypologiesMarqueurs', { typologieCodes: this.typologieCodes });
                    break;
                default:
                    break;
            }
        }
    }
}