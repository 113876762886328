module Easypass {
    export class CommandeStatusService {

        statusCodePrefix: string = "Commande_Status_";

        constructor(
            private localizeService: IS.Localization.LocalizeService
        ) {
            
        }

        getStatusText(code: number): string
        {
            return this.localizeService.getLocalizedResource(this.statusCodePrefix + code);
        }

    }

    angular.module('easypass').service('commandeStatusService', ['localizeService', CommandeStatusService]);
}