module Easypass.DinerTakeAway {
    @Component('easypass', 'dtExpiredBasket', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<',
            isVisible: '<'
        },
        templateUrl: './dtExpiredBasket.html',
        transclude: true
    })
    class ExpiredBasketComponent {
        static $inject: string[] = ['$state'];

        restaurantId: Core.IRestaurant;

        constructor(protected $state: ng.ui.IStateService) { }

        goToHome = () => {
            this.$state.go('dt-home', {
                restaurantId: this.restaurantId
            });
        }
    }
}
