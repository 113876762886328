module Easypass.DinerTakeAway {
    @Component('easypass', 'dtConfirmOrder', {
        controllerAs: 'vm',
        bindings: {
            commandeId: '<'
        },
        templateUrl: './dtConfirmOrder.html'
    })
    class ConfirmOrderComponent {
        static $inject: string[] = ['userService', 'commandeService'];

        commandeId: number;
        orderDate: string;
        sms: boolean;
        push: boolean;
        email: boolean;
        isLoading: boolean;
        commande: Core.ICommandeListItem;

        constructor(private userService: Core.UserService,
            private commandeService: CommandeService) { }

        $onInit() {
            this.isLoading = true;
            this.userService.refreshSolde();
            this.commandeService.getMesCommandes(true).then((commandes) => {
                commandes.forEach((commande) => {
                    if (commande.commandeId === this.commandeId) {
                        this.commande = commande;
                    }
                });
                if (this.commande) {
                    var format = 'DD/MM/YYYY';
                    this.orderDate = moment(this.commande.dateDeRetrait).format(format);
                }
                this.userService.getNotificationParametersForType('dinertakeaway').then(parameters => {
                    this.sms = parameters.sms;
                    this.push = parameters.push;
                    this.email = parameters.email;
                }).finally(() => {
                    this.isLoading = false;
                });
            });
        }
    }
}