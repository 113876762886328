module Easypass {
    interface IProductDirectiveScope extends ng.IScope {
        article: Core.IArticle;
        composable: Core.IArticle;
        categorieId: number;
        restaurantId: number;
        composeFormule: () => void;
    }

    class FormuleProductDirective implements ng.IDirective {
        restrict: string = 'E';

        constructor(private $http: ng.IHttpService) {

        }

        scope = {
            article: '=',
            composable: '=',
            categorieId: '=',
            restaurantId: '='
        }

        template = `
<div class="product clear" style="margin-top: 18px;">
    <div ng-if="vm.showPicture" class="product-image" ng-style="{backgroundImage: 'url('+vm.imageUrl+'), url(./styles/assets/default-restaurant.jpg)'}"> </div>
    <div class="product-information">
        <div class="libelle">{{vm.article.libelle}}</div>
    </div>
    <div class="action" ng-if="!vm.article.estFormule && !vm.article.estComposable" ng-disabled="vm.active" data-ng-click="vm.buttonClick($event)">
        <button ng-class="{ active: vm.article.estSelectionne }" class="backfacable-button" data-i18n-attr="Formule_Product_Added|data-hover"><span data-i18n="Product_Add"></span></button>
    </div>
    <div class="action" ng-if="vm.article.estFormule || vm.article.estComposable" ng-click="vm.composeFormule($event)" ng-disabled="vm.active" >        
        <button ng-class="{ active: vm.article.estSelectionne }" class="backfacable-button" data-i18n-attr="Formule_Product_Added|data-hover"><span data-i18n="Product_Compose"></span></button>
    </div>
</div>`;
        controller = ["$scope", "panierService", "configService", "$timeout", "$state", function ($scope: IProductDirectiveScope, panierService: PanierService, configService: IS.Configuration.ConfigService, $timeout: ng.ITimeoutService, $state: ng.ui.IStateService) {
            this.article = $scope.article;
            this.imageUrl = `${configService.getConfigValue("url")}/documents/${$scope.article.imageId}`
            this.showPicture = $scope.article.imageId != null;
            this.composable = $scope.composable;
            this.categorie = _.find($scope.composable.sousCategories, x => x.id == $scope.categorieId);
            this.buttonClick = () => {
                if (this.article.estSelectionne) {
                    this.article.estSelectionne = false;
                }
                else {
                    this.addArticle()
                }
            };

            this.addArticle = () => {
                var quantiteInformation = this.checkMax();
                if (quantiteInformation.current >= quantiteInformation.max) {
                    if (quantiteInformation.max == 1) {
                        this.categorie.sousArticles.forEach((x: Core.IArticle) => x.estSelectionne = false);
                        this.article.estSelectionne = true;
                    }
                } else {
                    this.article.estSelectionne = true;
                }
            }

            this.composeFormule = ($event: ng.IAngularEvent) => {
                if (this.article.estSelectionne) {
                    this.article.estSelectionne = false;
                    this.article.sousCategories.forEach((x: Core.ICategorieCarte) => x.sousArticles.forEach((x: Core.IArticle) => x.estSelectionne = false));
                } else {
                    $event.stopPropagation();
                    $state.go("composeComposable", { article: $scope.article, parent: $scope.composable, id: $scope.restaurantId })
                };
            }

            this.checkMax = (): { max: number, current: number } => {

                var max = this.categorie.quantiteMaximum;
                var current = this.categorie.sousArticles.filter((x: Core.IArticle) => x.estSelectionne).length;
                return {
                    max, current
                };
            }
        }];

        controllerAs = 'vm';

        link = (scope: IProductDirectiveScope, element: ng.IAugmentedJQuery): void => {
        };

        static factory() {

            var directive = ($http: ng.IHttpService) => {
                return new FormuleProductDirective($http);
            };

            return directive;
        }

    }


    angular.module('easypass').directive('formuleProduct', ['$http', FormuleProductDirective.factory()]);
}