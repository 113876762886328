module Easypass {
    class PaymentErrorCallbackController {
        restaurantId: number;

        constructor(private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private storageService: StorageService) {
            var panier = storageService.getPanier();
            if (panier) {
                this.restaurantId = panier.restaurantId;
            }
        }

        goTo() {
            this.$state.go("panier", { restaurantId: this.restaurantId });
        }
    }
    angular.module('easypass').controller('paymentErrorCallbackController', ["$stateParams", "$state", "storageService", PaymentErrorCallbackController]);
}