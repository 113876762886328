module Easypass {

    interface IMenuPictoScope extends ng.IScope {
        restaurantId: number;
    }

    class MenuPictoDirective implements ng.IDirective {
        constructor(private voteCampagneService: Core.VoteCampagneService) { }

        restrict = 'EA';

        scope = {
            restaurantId: '='
        };
        template =
            `
            <div class="picto menu-open" data-ng-click="vm.gotoMenu()"> </div>
            `;
        controller = ["$scope", "$state", "voteCampagneService", function ($scope: IMenuPictoScope, $state: ng.ui.IStateService, voteCampagneService: Core.VoteCampagneService) {
            var self = this;

            this.gotoMenu = () => {
                $state.go('restaurant', { id: $scope.restaurantId });
              };
        }];
        controllerAs = 'vm';

        link = (scope: IMenuPictoScope): void => {
            console.log("MeunPicto Directive Link Method");
        }
        
        static factory() {
            return (voteCampagneService: Core.VoteCampagneService) => {
                return new MenuPictoDirective(voteCampagneService);
            };
        }
    }

    angular.module('easypass').directive('menuPicto', ['voteCampagneService', MenuPictoDirective.factory()]);
}