module Core {
    interface IStarsScope extends ng.IScope {
        readonly: string;
        getColor: (position: number) => string;
        getBorderColor: (position: number) => string;
        setNote: (note: number) => void;
    }

    class StarsDirective implements ng.IDirective {
        restrict: string = 'AE';
        template =
        `<div class='score'>
            <colored-svg ng-repeat="i in [1, 2, 3, 4, 5]" source="'./styles/assets/star-like.svg'" color="getColor(i)" data-ng-click="setNote(i)" border-color="getBorderColor(i)"></colored-svg>
        </div>`;

        require = 'ngModel';

        scope = {
            readonly: '@?'
        };
        link($scope: IStarsScope, elem: ng.IAugmentedJQuery, attrs: ng.IAttributes, ngModelController: ng.INgModelController) {
            $scope.getColor = (position: number): string => ngModelController.$viewValue >= position ? '#ffd800' : '#d1d1d1';
            $scope.getBorderColor = (position: number): string => ngModelController.$viewValue >= position ? '#e8c400' : '#c2c2c2';
            $scope.setNote = (note: number) => {
                if (!$scope.readonly) {
                    ngModelController.$setViewValue(note);
                }
            };
        }

        static factory() {
            return () => {
                return new StarsDirective();
            };
        }
    }

    angular.module('core').directive('stars', [StarsDirective.factory()]);
}