module Easypass {
    class ComposeFormuleController {

        article: Core.IArticle;
        categorieArticle: Core.ICategorieCarte;
        parent: Core.IArticle;
        restaurantId: number;
        restaurant: Core.IRestaurant;
        currentCategorieselectedCategorie: string;
        currentCategorie: Core.ICategorieCarte;
        imageUrl: string;
        timeoutHandler: number = 0;
        stopAnimation: boolean;
        imageId: number = 0;

        constructor(private restaurantsService: RestaurantsService,
            private carteService: Core.CarteService,
            private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private animationService: Core.AnimationService,
            private enqueteService: Core.EnqueteService,
            private $timeout: ng.ITimeoutService,
            private panierService: PanierService,
            private configService: IS.Configuration.ConfigService,
            private $scope: ng.IScope
        ) {

            this.stopAnimation = $stateParams["stopAnimation"];
            this.parent = (<Core.IArticle>$stateParams['parent']);
            this.categorieArticle = <Core.ICategorieCarte>$stateParams['categorie'];
            if (this.parent) {
                this.article = (<Core.IArticle>$stateParams['article']);
            } else {
                this.article = _.cloneDeep(<Core.IArticle>$stateParams['article']);
            }

            this.imageUrl = !!this.article.imageId ? `${configService.getConfigValue("url")}/documents/${this.article.imageId}` : '';
            this.restaurantId = +$stateParams['id'];
            restaurantsService.get(this.restaurantId)
                .then(restaurant => {
                    this.restaurant = restaurant;
                    this.sendAnalyticsEvent('Formule_Screen', 'ComposeDish_Screen');
                })
                .catch(error => {
                    // TODO: StatusCode=0
                });
            this.currentCategorie = this.article.sousCategories[0];


            this.$scope.$watch(
                () => {
                    return this.watchCategorieValidity(this.currentCategorie);
                },
                (newValue, oldValue) => {
                    if (oldValue == null || newValue == null) {
                        return;
                    }

                    if (newValue.categorie.id != oldValue.categorie.id) {
                        return;
                    }

                    if (this.timeoutHandler) {
                        clearTimeout(this.timeoutHandler);
                        this.timeoutHandler = 0;
                    }

                    if (newValue.isValid) {
                        this.timeoutHandler = setTimeout(() => {
                            this.timeoutHandler = 0;

                            var index = _.findIndex(this.article.sousCategories, x => !this.isCategorieValid(x));
                            if (index != -1) {
                                this.changeCategorie(this.article.sousCategories[index]);
                                this.scrollToActiveCategorie();
                                $scope.$apply();
                            }
                            else {
                                // On ne redirige pas vers le panier par défaut
                                //this.addComposable();
                            }
                        }, !!this.stopAnimation ? 0 : 1000);
                        this.stopAnimation = false;
                    }
                }, true);
        }

        changeCategorie(categorie: Core.ICategorieCarte) {
            this.currentCategorie = categorie;
        };

        getCategorieSelectedCount(categorie: Core.ICategorieCarte): number {
            return categorie.sousArticles.filter(x => x.estSelectionne).length;
        }

        isComposableValid() {
            return this.article.sousCategories.every(categ => {
                var current = this.getCategorieSelectedCount(categ);
                return current >= categ.quantiteMinimum &&
                    current <= categ.quantiteMaximum;
            });
        }

        addComposable() {
            if (this.isComposableValid()) {
                if (this.parent) {
                    this.article.estSelectionne = true;
                    var sousArticle: Core.IArticle[] = [];
                    this.article.sousCategories.forEach(x => sousArticle = sousArticle.concat(x.sousArticles.filter(y => y.estSelectionne)));
                    this.article.sousArticles = this.article.sousArticles.concat(sousArticle);
                    this.$state.go('composeFormule', { article: this.parent, id: this.restaurantId, stopAnimation: true });
                } else {
                    this.panierService.ajouterComposableAuPanier(this.restaurantId, this.article, this.categorieArticle);
                    this.$state.go('panier', { restaurantId: this.restaurantId });
                }
            }
        }

        cancel() {
            if (this.parent) {
                this.article.sousCategories.forEach(x => x.sousArticles.forEach(y => y.estSelectionne = false));
                this.$state.go('composeFormule', { article: this.parent, id: this.restaurantId });
            } else {
                this.$state.go('clickAndCollect', { id: this.restaurantId });
            }

            this.sendAnalyticsEvent('Formule_BTN_Cancel', 'ComposeDish_BTN_Cancel');
        }

        private sendAnalyticsEvent(formuleKey: string, composableKey: string) {
            if (this.restaurant != null && this.article != null) {
                if (this.article.estComposable) {
                    (<any>window).DataAnalyticsSender.sendCustomEvent(composableKey, this.restaurant.restaurantType + '/' + this.article.libelle);
                }
                else if (this.article.estFormule) {
                    (<any>window).DataAnalyticsSender.sendCustomEvent(formuleKey, this.restaurant.restaurantType + '/' + this.article.libelle);
                }
            }
        }

        watchCategorieValidity(categorie: Core.ICategorieCarte): { categorie: Core.ICategorieCarte, isValid: boolean } {
            return {
                categorie: categorie,
                isValid: this.isCategorieValid(categorie)
            };
        }

        isCategorieValid(categorie: Core.ICategorieCarte): boolean {
            if (this.getCategorieSelectedCount(categorie) >= categorie.quantiteMaximum) {
                return true;
            }
            return false;
        }

        scrollToActiveCategorie() {
            setTimeout(() => {
                var container = document.querySelector(".categories-container");
                var child = document.querySelector(".categories-container .active");
                container.scrollLeft = (<any>child).offsetLeft - 45;
            }, 0);
        }
    }

    angular.module('easypass').controller('composeFormuleController',
        ['restaurantsService', 'carteService', '$stateParams', '$state', 'animationService', 'enqueteService', '$timeout', 'panierService', 'configService', '$scope', ComposeFormuleController]);
}