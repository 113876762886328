module Core {
    @Component('core', 'voteThematiqueTitle', {
        controllerAs: 'vm',
        bindings: {
            libelle: '<'
        },
        templateUrl: './voteThematiqueTitle.html'
    })
    class VoteThematiqueTitleComponent {
    }
}