module Core {
    class AsynchronousBackgroundControllerDirective {
    }

    class AsynchronousBackgroundDirective implements ng.IDirective {
        restrict = 'EA';
        replace = true;
        controllerAs = 'vm';
        controller = AsynchronousBackgroundControllerDirective;
        scope = {
            url: '<'
        };
        bindToController = true;
        transclude = true;
        template = `
          <div class="background" data-ng-style="{ 'background': 'url(' + vm.url + ') 50% center / cover no-repeat, url(./styles/assets/loader.gif) center 20% no-repeat'}" data-ng-transclude>
          </div>
        `;

        static factory() {
            return () => {
                return new AsynchronousBackgroundDirective();
            };
        }
    }

    angular.module('core').directive('asynchronousBackground', [AsynchronousBackgroundDirective.factory()]);
}