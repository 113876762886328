module Easypass {
    angular.module('easypass').filter('fluiditeTendanceToClass', [() => {
        return (fluiditeTendance: IFluiditeTendanceValues): string => {
            switch (fluiditeTendance) {
                case IFluiditeTendanceValues.Augmente: return "fluidite-tendance-augmente";
                case IFluiditeTendanceValues.Diminue: return "fluidite-tendance-diminue";
                case IFluiditeTendanceValues.Stable: return "fluidite-tendance-stable";
                default: return "fluidite-tendance-na";
            }
        };
    }]);
}