module Core {
    interface PopInButtonsConfiguration {
        showAccept?: boolean;
        showCancel?: boolean;
        showClose?: boolean;
        showCloseButton?: boolean;

		canCloseFromBackground?: boolean;

        acceptButtonResource?: string;
        cancelButtonResource?: string;
        closeButtonResource?: string;

        acceptCallback: () => boolean;
        cancelCallback: () => boolean;
    }

    class PopInDirectiveController {
        acceptCallback: () => void;
        cancelCallback: () => void;
        isVisible: boolean;
        config: PopInButtonsConfiguration;
        buttonsConfiguration: PopInButtonsConfiguration;

        constructor() {
            //HACK: Weird behavior, NG seems to not apply changes to the property config made in the constructor
            this.buttonsConfiguration = angular.extend({
                acceptButtonResource: 'Global_Button_Confirm',
                cancelButtonResource: 'Global_Button_Cancel',
                closeButtonResource: 'Global_Button_Close',
				showAccept: true,
                showCancel: true,
                showClose: true,
                showCloseButton: false,
				canCloseFromBackground: false,
            }, this.config);
        }

        cancel = () => {
            if (!this.cancelCallback || this.cancelCallback()) {
                this.close();
            }
        };

        accept = () => {
            if (!this.acceptCallback || this.acceptCallback()) {
                this.close();
            }
        };

        close = () => {
            this.isVisible = false;
        }

		closeFromBackground = () => {
			if (this.config.canCloseFromBackground === true)
				this.close();
        }
    }

    class PopInDirective implements ng.IDirective {
        restrict = 'EA';
        controllerAs = 'vm';
        controller = PopInDirectiveController;
        scope = {
            acceptCallback: "&?",
            cancelCallback: "&?",
            isVisible: '=',
            config: '<buttonsConfiguration'
        };
        bindToController = true;
        transclude = true;
        // HACK : add the z-index style inline because it's not working in the css class (bug angular directives?)
        template = `
          <div class="pop-in ng-hide" data-ng-show='vm.isVisible' data-ng-click="vm.closeFromBackground(); $event.stopPropagation();" style='z-index: 99000 !important'>
            <div class="pop-in-container">
                <div data-ng-if='vm.buttonsConfiguration.showClose' class="close" data-ng-click="vm.close(); $event.stopPropagation();"></div>
                <div class="pop-in-content" data-ng-transclude></div>
                <div data-ng-if="vm.buttonsConfiguration.showAccept || vm.buttonsConfiguration.showCancel || vm.buttonsConfiguration.showCloseButton" class="pop-in-buttons">
                    <button data-ng-if="vm.buttonsConfiguration.showCancel" class="pop-in-button cancel" data-ng-click="vm.cancel()"  data-i18n="{{vm.buttonsConfiguration.cancelButtonResource}}"></button>
                    <button data-ng-if="vm.buttonsConfiguration.showAccept" class="pop-in-button accept" data-ng-click="vm.accept()" data-i18n="{{vm.buttonsConfiguration.acceptButtonResource}}"></button>
                    <button data-ng-if="vm.buttonsConfiguration.showCloseButton" class="pop-in-button cancel" data-ng-click="vm.close(); $event.stopPropagation();" data-i18n="{{vm.buttonsConfiguration.closeButtonResource}}"></button>
                </div>
            </div>
        </div>
        `;

        static factory() {
            return () => {
                return new PopInDirective();
            };
        }
    }

    angular.module('core').directive('popIn', [PopInDirective.factory()]);
}