module Core {
    export enum JourOuvertureFlag {
        Lundi = 1 << 0,
        Mardi = 1 << 1,
        Mercredi = 1 << 2,
        Jeudi = 1 << 3,
        Vendredi = 1 << 4,
        Samedi = 1 << 5,
        Dimanche = 1 << 6,
    }
}