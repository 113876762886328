module Easypass {
    export class DinnerTakeawayConfigService {
        constructor(private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService) {
        }

        getConfig(restaurantId: number): ng.IPromise<Easypass.IDinnerTakeawayConfiguration> {
            var deferred = this.$q.defer<Easypass.IDinnerTakeawayConfiguration>();

            this.$http.get<Easypass.IDinnerTakeawayConfiguration>
                (`${this.configService.getConfigValue('url')}/${restaurantId}/DinnerTakeawayConfiguration`)
                .success((r) => deferred.resolve(r))
                .catch(e => deferred.reject(e));

            return deferred.promise;
        }
    }
    angular.module('easypass').service('dinnerTakeawayConfigService',
        ['$http', '$q', 'configService', DinnerTakeawayConfigService]);
}