module Core {
    export class EntrepriseAccesService {

        constructor(private $http: ng.IHttpService,
                   private configService: IS.Configuration.ConfigService) {
        }

        addAcces(entrepriseId: number) {
            return this.$http.post<number>(`${this.configService.getConfigValue('url')}/timechefpro/access/request`, entrepriseId);
        }

        getEntreprisesAccesAndNotAcces(): ng.IPromise<IAccesEntrepriseModel> {
            return this.$http.get(`${this.configService.getConfigValue('url')}/timechefpro/access/accesEntreprisesConvives`)
                .then(x => (<any>x).data);
        }

    }

    angular.module('core').service('entrepriseAccesService', ['$http', 'configService', EntrepriseAccesService]);
}