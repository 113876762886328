module Easypass {
    export class CarteServiceDTA {
        constructor(
            private carteService: Core.CarteService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService,
            private restaurantsService: Core.RestaurantsService) {
        }

        getCarteDTA(restaurantId: number, ISOdate: string): ng.IPromise<ICarteDTA> {
            var deferred = this.$q.defer<ICarteDTA>();
            this.carteService.getCarte(restaurantId, ISOdate, false).then((carte) => {
                this.restaurantsService.get(restaurantId).then(restaurant => {
                    var restaurantType;
                    if (restaurant.isDinnerTakeaway) {
                        restaurantType = "DT";
                    } else if (restaurant.isFoodles) {
                        restaurantType = "FOODLES";
                    }
                    var newCarte = this.createCarte(carte, restaurantType);
                    deferred.resolve(newCarte);
                }).catch(() => {
                    deferred.reject();
                });
            });
            return deferred.promise;
        }

        getArticles(restaurantId: number, ISOdate: string): ng.IPromise<IArticleDTA[]> {
            var deferred = this.$q.defer<IArticleDTA[]>();

            this.getCarteDTA(restaurantId, ISOdate).then((carte) => {
                if (carte.entrees && carte.plats && carte.desserts && carte.divers) {
                    let articlesEntrees: Easypass.IArticleDTA[] = _.flatten(carte.entrees);
                    let articlesPlats: Easypass.IArticleDTA[] = _.flatten(carte.plats);
                    let articlesDesserts: Easypass.IArticleDTA[] = _.flatten(carte.desserts);
                    let articlesDivers: Easypass.IArticleDTA[] = _.flatten(carte.divers);

                    let articles: Easypass.IArticleDTA[] = articlesEntrees.concat(articlesPlats, articlesDesserts, articlesDivers);

                    if (articles) deferred.resolve(articles);
                    else deferred.reject();
                }
                else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        getArticle(restaurantId: number, ISOdate: string, articleId: number): ng.IPromise<IArticleDTA> {
            var deferred = this.$q.defer<IArticleDTA>();

            this.getArticles(restaurantId, ISOdate).then((articles) => {
                if (articles) {
                    var article: Easypass.IArticleDTA = _.find(articles, (art) => { return art.id == articleId });

                    if (article) deferred.resolve(article);
                    else deferred.reject();
                }
                else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        private getPortions(articles: Core.IArticle[]): Core.IPortion[] {
            var portions: Core.IPortion[] = new Array();
            articles.forEach((article) => {

                portions.push({
                    portionType: article.typeDePortion,
                    price: article.prix,
                    articleId: article.id,
                    articleLibelle: article.libelle
                })
            });
            return portions;
        }

        private getCategoryArticlesGroupedByLibelle(articles: Core.IArticle[]): IArticleDTA[] {
            var groupedArticles = _.groupBy(articles, 'libelle');
            var newArticles: IArticleDTA[] = [];
            _.forOwn(groupedArticles, (value, key) => {
                var portions = this.getPortions(value);
                var article = <IArticleDTA>value[0];
                article.portions = portions;
                article.imageUrl = article.imageId ? `${this.configService.getConfigValue("url")}/documents/${article.imageId}` : '',

                    newArticles.push(article);
            });
            return newArticles;
        }

        getCarteSemaineDTA(restaurantId: number, take: number = 5): ng.IPromise<ICarteDTA[]> {
            var cartesDTA: ICarteDTA[] = new Array();
            var deferred = this.$q.defer<ICarteDTA[]>();
            
            this.carteService.getCartes(restaurantId, take).then((cartes) => {
                this.restaurantsService.get(restaurantId).then(restaurant => {
                    var restaurantType;
                    if (restaurant.isDinnerTakeaway) {
                        restaurantType = "DT";
                    } else if (restaurant.isFoodles) {
                        restaurantType = "FOODLES";
                    }
                    cartes.forEach((carte) => {
                        var newCarte = this.createCarte(carte, restaurantType);
                        cartesDTA.push(newCarte);
                    });
                    deferred.resolve(cartesDTA);
                }).catch(() => {
                    deferred.reject();
                });
            }, () => {
                deferred.reject();
            });
            
            return deferred.promise;
        }

        private createCarte(carte: Core.ICarte, restaurantType: string): ICarteDTA {
            var categorieEntrees = carte.categories.filter(c => c.categorieType.referenceExterne === `${restaurantType}_ENTREES`)[0];
            var categoriePlats = carte.categories.filter(c => c.categorieType.referenceExterne === `${restaurantType}_PLATS`)[0];
            var categorieDesserts = carte.categories.filter(c => c.categorieType.referenceExterne === `${restaurantType}_DESSERTS`)[0];
            var categorieDivers = carte.categories.filter(c => c.categorieType.referenceExterne === `${restaurantType}_DIVERS`)[0];

            var entrees = categorieEntrees == null ? [] : this.getCategoryArticlesGroupedByLibelle(categorieEntrees.sousArticles);
            var plats = categoriePlats == null ? [] : this.getCategoryArticlesGroupedByLibelle(categoriePlats.sousArticles);
            var desserts = categorieDesserts == null ? [] : this.getCategoryArticlesGroupedByLibelle(categorieDesserts.sousArticles);
            var divers = categorieDivers == null ? [] : this.getCategoryArticlesGroupedByLibelle(categorieDivers.sousArticles);
            var newCarte: ICarteDTA = {
                id: carte.id,
                dateDebut: carte.dateDebut,
                dateFin: carte.dateFin,
                entrees: <any>entrees,
                plats: <any>plats,
                desserts: <any>desserts,
                divers:<any> divers
            }
            return newCarte;
        }
    }
    angular.module('easypass')
        .service('carteServiceDTA',
        ['carteService', '$q', 'configService', 'restaurantsService', CarteServiceDTA]);
}