module Core {
    class EnqueteController {
        enquete: IEnquete;
        hasError: boolean = false;
        hasNetworkError: boolean = false;
        isLoading: boolean = false;
        isPosting: boolean = false;
        campagne: ICampagne;
        restaurant: IRestaurant;
        commentaireError: boolean = false;
        campagneNumber: number;
        changeStateUrl: string;
        fromRestaurant: boolean;
        restaurantId: number;
        private currentQuestionIndex = -1;
        avgNotes = 0;

        constructor(private $timeout: ng.ITimeoutService,
            private enqueteService: EnqueteService,
            private $stateParams: ng.ui.IStateParamsService,
            private configService: IS.Configuration.ConfigService,
            private restaurantService: RestaurantsService,
            private $state: ng.ui.IStateService,
            private routeService: RouteService,
            private $scope: ng.IScope,
            private helperService: Core.HelperService) {

            var id = $stateParams['id'];
            if (id) id = +id;
            // when coming from restaurant > enquete
            var campagneId = $stateParams['campagneId'];
            if (campagneId) campagneId = +campagneId;
            this.restaurantId = $stateParams['restaurantId'];
            if (this.restaurantId) this.restaurantId = +this.restaurantId;

            this.fromRestaurant = this.$state.current.name === 'campagnesWithRestaurantIdWithBack';
            this.changeStateUrl = 'enquete';

            if (!!this.restaurantId) {
                this.changeStateUrl = this.$state.current.name;
            }

            this.isLoading = true;

            (<any>window).DataAnalyticsSender.sendCustomEvent('Surveys_Screen', '');

            this.enqueteService.gets(false, this.restaurantId)
                .then(campagnes => {
                    this.campagneNumber = campagnes.length;

                    if (!!campagneId) {
                        this.campagne = _.find(campagnes, (camp) => camp.id == campagneId);
                    }
                    else if (!!id) {
                        this.campagne = _.find(campagnes, (camp) => camp.id == id);
                    }

                    if (!this.campagne) {
                        this.campagne = _.find(campagnes, (camp) => !camp.dejaRepondueAujourdhui);
                    }
                    if (!this.campagne) {
                        this.campagne = campagnes[0];
                    }

                    this.restaurantService.get(this.campagne.restaurantId)
                        .then((restaurant) => {
                            this.restaurant = restaurant;
                        })
                        .catch(error => {
                            // TODO: StatusCode=0
                        });

                    this.getEnquete(this.campagne.enqueteId);
                })
                .catch(error => {
                    // TODO: StatusCode=0
                    this.hasError = true;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }

        getEnquete(id: number) {
            this.isLoading = true;
            this.hasError = false;
            if (!this.campagne.dejaRepondueAujourdhui) {

                this.enqueteService.getContent(id)
                    .then(enquete => {
                        this.enquete = enquete;
                        this.enquete.questions.forEach((question) => question.note = 0);
                        this.enquete.commentaire = '';
                        this.currentQuestionIndex = 0;
                        let errorTimeout: ng.IPromise<void>;
                        this.$scope.$watch(() => this.enquete.commentaire, () => {
                            if (this.enquete.commentaire.length > 255) {
                                this.enquete.commentaire = this.enquete.commentaire.substr(0, 255);
                                this.commentaireError = true;
                                if (errorTimeout) {
                                    errorTimeout
                                    this.$timeout.cancel(errorTimeout);
                                }
                                errorTimeout = this.$timeout(5e3);
                                errorTimeout.then(() => this.commentaireError = false);
                            }
                        }, true);
                    })
                    .catch(error => {
                        // TODO: StatusCode=0
                        this.hasError = true;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else {
                this.currentQuestionIndex = 0;
                this.isLoading = false;
            }
        }

        getPreviousCampagne = () => {
            this.routeService.forceNavigationDirection(NavigationDirection.right);
            this.enqueteService.getPrevious(this.campagne.id, this.fromRestaurant ? this.restaurantId : null)
                .then((campagne) => {
                    if (this.fromRestaurant) {
                        this.$state.go(this.changeStateUrl, { campagneId: campagne.id, restaurantId: this.restaurantId });
                    }
                    else {
                        this.$state.go(this.changeStateUrl, { id: campagne.id });
                    }
                })
                .catch(error => {
                    // TODO: StatusCode=0
                });
        }

        goToHomePage = () => {
            if (this.helperService.isTcPro()) {
                this.$state.go('roomService.articles');
            } else {
                this.$state.go('restaurants');
            }
        }

        getNextCampagne = () => {
            this.routeService.forceNavigationDirection(NavigationDirection.left);
            this.enqueteService.getNext(this.campagne.id, this.fromRestaurant ? this.restaurantId : null)
                .then((campagne) => {
                    if (this.fromRestaurant) {
                        this.$state.go(this.changeStateUrl, { campagneId: campagne.id, restaurantId: this.restaurantId });
                    }
                    else {
                        this.$state.go(this.changeStateUrl, { id: campagne.id });
                    }
                })
                .catch(error => {
                    // TODO: StatusCode=0
                });
        }

        goToNext = (question: number) => {
            if (this.enquete.questions.length > 1) {
                this.$timeout(2000).then(() => {
                    if (this.currentQuestionIndex == question)
                        this.currentQuestionIndex++;
                });
            }
        }

        goBack() {
            if (this.$stateParams['fromCC']) {
                this.$state.go('clickAndCollect', { id: this.restaurantId });
            } else {
                this.$state.go('restaurant', { id: this.restaurantId });
            }
        }

        goToRestaurant() {
            if (this.restaurant.id != null) {
                this.restaurantService.getRouteById(this.restaurant.id).then(route => {
                    this.$state.go(route, { id: this.restaurant.id });
                });
            }
            else {
                this.restaurantService.getRouteById(this.restaurant.id).then(route => {
                    this.$state.go(route, { id: this.restaurant.id });
                });
            }
        }

        validate = () => {
            this.isPosting = true;
            this.hasError = false;
            this.hasNetworkError = false;

            var sumNotes = 0;
            this.enquete.questions.forEach((question : IQuestion) => sumNotes += question.note );
            this.avgNotes = Math.round(sumNotes / this.enquete.questions.length);

            if (!_.some(this.enquete.questions, (question) => !question.note)) {
                this.enqueteService.sendResponses(this.enquete.questions, this.enquete.commentaire, this.campagne.id, this.campagne.restaurantId)
                    .then(() => this.currentQuestionIndex++)
                    .catch(error => {
                        checkNetworkError(error, () => this.hasError = true, () => this.hasNetworkError = true);
                    })
                    .finally(() => this.isPosting = false);
            }
        }
    }

    angular.module('core').controller('enqueteController', ['$timeout', 'enqueteService', '$stateParams', 'configService', 'restaurantsService', '$state', 'routeService', '$scope', 'helperService', EnqueteController]);
}