module Easypass {
    angular.module('easypass').filter('articleRecherche', [() => {
        return (input: Core.IArticle[], search: string): Core.IArticle[] => {
            if (search == null || search == '') {
                return input;
            }
            search = search.toLocaleLowerCase();
            input = input.filter(function (el) {
                if (el.sousLibelle) {
                    return el.libelle.toLowerCase().indexOf(search.toLowerCase()) != -1 || el.sousLibelle.toLowerCase().indexOf(search) != -1;
                } else {
                    return el.libelle.toLowerCase().indexOf(search) != -1;
                }
            });
            return input;
        };
    }]);
}