module Core {
    class AccountInfosDirective implements ng.IDirective {
        restrict: string = 'E';
        templateUrl = "./accountInfosDirectiveTemplate.html";

        link(scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery) {
        }

        static factory() {
            return () => {
                return new AccountInfosDirective();
            };
        }
    }

    angular.module('core').directive('accountInfos', [AccountInfosDirective.factory()]);
}