module IS {
	export class SingleCallbackOrchestrator {
		constructor(private $q: ng.IQService,
			private requestCallback: () => ng.IPromise<any>,
			private successCallback?: (data: any) => any) {
		}

		private waitingDeferred: ng.IDeferred<any>[];

		execute(): ng.IPromise<any> {
            var deferred = this.$q.defer();

            if (!this.waitingDeferred || this.waitingDeferred.length === 0) {
                this.waitingDeferred = [];

				this.requestCallback().then(result => {
					if (this.successCallback) {
						result = this.successCallback(result);
					}

					for (var i = 0; i < this.waitingDeferred.length; i++) {
						this.waitingDeferred[i].resolve(result);
					}
				}).catch(error => {
					for (var i = 0; i < this.waitingDeferred.length; i++) {
						this.waitingDeferred[i].reject(error);
					}
				}).finally(() => {
					this.waitingDeferred = null;
				});
            }

            this.waitingDeferred.push(deferred);

            return deferred.promise;
		}
	}


    export class SingleRequestOrchestrator {
        constructor(private $http: ng.IHttpService,
            private $q: ng.IQService,
            private requestConfig: ng.IRequestConfig,
            private successCallback?: (data: any) => any) {
        }

        private waitingDeferred: ng.IDeferred<any>[];

        execute(): ng.IPromise<any> {
            var deferred = this.$q.defer();

            if (!this.waitingDeferred || this.waitingDeferred.length === 0) {
                this.waitingDeferred = [];
                this.$http(this.requestConfig).success((result) => {
					if (this.successCallback) {
						result = this.successCallback(result);
					}

					for (var i = 0; i < this.waitingDeferred.length; i++) {
						this.waitingDeferred[i].resolve(result);
					}
				}).error((error) => {
					for (var i = 0; i < this.waitingDeferred.length; i++) {
						this.waitingDeferred[i].reject(error);
					}
				}).finally(() => {
					this.waitingDeferred = null;
				});
            }

            this.waitingDeferred.push(deferred);

            return deferred.promise;
        }
    }
}