module Core {
    angular.module('core').filter('isErrorValidationState', [() => {
        return (state: Core.ValidationState): boolean => {
            switch (state) {
                case Core.ValidationState.warning:
                case Core.ValidationState.invalid:
                    return true;
                default:
                    return false;
            }
        };
    }]);
}