module Easypass.DinerTakeAway {
    @Component('easypass', 'dtBasket', {
        controllerAs: 'vm',
        bindings: {
            restaurantId: '<',
            currentDate: '<'
        },
        templateUrl: './dtBasket.html'
    })
    class BasketComponent {
        restaurant: Core.IRestaurant;
        restaurantId: number;
        currentDate: string;
        internalDate: Date;

        carte: ICarteDTA;
        basketOnlyContainArticlesFromDivers: boolean = false;

        isLoading: boolean = false;
        isSending: boolean = false;
        isSendingError: boolean = false;
        cgvAccepted: boolean;
        cgvLink: string;

        basket: BasketDTA;
        basketArticles: IBasketArticleDTA[] = [];
        basketIsExpired: boolean;

        basketCapacity: number;

        availableFrom: string;
        availableTo: string;

        total: number = 0;
        vat: number = 0;

        private basketCompletedObservableCanceller: Rx.Disposable;

        static $inject: string[] = ['restaurantsService', 'dtBasketService', 'carteServiceDTA', 'redirectionService', '$state'];

        constructor(
            private restaurantsService: Core.RestaurantsService,
            private basketService: DtBasketService,
            protected carteServiceDTA: CarteServiceDTA,
            private redirectionService: Core.IRedirectionService,
            protected $state: ng.ui.IStateService) {
            this.internalDate = new Date(this.currentDate);
            this.cgvLink = this.$state.href('dt-termsAndConditions', { restaurantId: this.restaurantId });
        }

        $onInit = () => {
            this.isLoading = true;
            this.cgvAccepted = false;

            // Chargement de la carte pour valider le panier
            var cartePromise = this.carteServiceDTA.getCarteDTA(this.restaurantId, this.currentDate);
            cartePromise.then(carte => {
                this.carte = carte;
            });

            this.restaurantsService.get(this.restaurantId).then(restaurant => {
                this.restaurant = restaurant;

                // TODO: Externaliser toute cette gestion de dates dans un service
                var openTime = this.restaurant.openAt.split(':');
                var closeTime = this.restaurant.closeAt.split(':');

                var openTimeDate = moment().hour(Number(openTime[0])).minute(Number(openTime[1]));
                var closeTimeDate = moment().hour(Number(closeTime[0])).minute(Number(closeTime[1]));

                this.availableFrom = openTimeDate.format("LT").replace(":", "H");
                this.availableTo = closeTimeDate.format("LT").replace(":", "H");
            });

            this.basketCompletedObservableCanceller = this.basketService.getBasketObserver(this.restaurantId, this.internalDate).subscribe(basket => {
                this.isLoading = false;

                this.basket = basket;
                if (basket) {
                    this.basketArticles = basket.getBasketArticles();
                    this.basketCapacity = basket.maxNumberOfArticles - basket.itemsNumber;

                    var amount = basket.amount;
                    this.total = amount.total;
                    this.vat = amount.vat;

                    // Il n'est pas possible de commander des articles qui appartiennent uniquement à la catégorie 'DIVERS'
                    cartePromise.then(() => {
                        var articleDiversQuantity = 0;
                        this.carte.divers.forEach((element: IArticleDTA) => {
                            element.portions.forEach((portion: any) => {
                                articleDiversQuantity += this.basket.getQuantityForPortion(portion.articleId);
                            });
                        });

                        this.basketOnlyContainArticlesFromDivers = (articleDiversQuantity > 0 && articleDiversQuantity === basket.itemsNumber);
                    });
                }
            }, () => { }, () => this.basketIsExpired = true);
        }

        $onDestroy = () => {
        }

        quantityChanged = (item: IBasketItemDTA) => {
            this.basketService.addOrUpdatePortion(this.restaurantId, this.internalDate, item.portion, item.quantity, item.quantity == 0);
        }

        removeItem = (item: IBasketItemDTA) => {
            this.basketService.removeItemFromBasket(this.restaurantId, this.internalDate, item.portion);
        }

        acceptCGV = () => {
            this.cgvAccepted = !this.cgvAccepted;
        }
        
        goToPayment = () => {
            this.isSending = true;

            this.basketService.gotoPayment(this.basket, this.restaurantId).then((creditRedirection) => {
                var commandeId = creditRedirection.commandeId;
                this.isSending = false;
                this.basketService.destroyBasket(this.restaurantId, this.internalDate);
                this.$state.go('dt-payment-success', { orderDate: this.currentDate, commandeId: commandeId });

            }, (reason) => {
                this.$state.go('dt-payment-error', { restaurantId: this.restaurantId });

                this.isSending = false;
                this.isSendingError = true;
            });
        }

        goToHome = () => {
            this.$state.go('dt-home', { restaurantId: this.restaurantId });
        }
    }
}