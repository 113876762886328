module Core {
    export class CarteService {
        constructor(private $rootScope: ng.IRootScopeService,
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private configService: IS.Configuration.ConfigService,
            private restaurantsService: RestaurantsService,
            private storageService: StorageService) {
            var now = new Date();
            this.restaurantsService.gets().then(restaurants => {
                restaurants.filter(x => x.isClickAndCollect).map((restaurant) => {
                    this.getCartesFromServer(restaurant.id);
                });
            });

            this.$rootScope.$on('clearUserInMemoryData', () => {
                this.cartes = {};
                this.getCarteDeferred = {};
            });
        }

        cartes: { [id: number]: ICarte[] } = {};

        getCartesId(restaurantId: number, dateISO: string = ''): ng.IPromise<number[]> {

            var deferred = this.$q.defer<number[]>();

            this.$http.get<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurantId}/cartesId/?date=${dateISO}`)
                .success((results: number[]) => {
                    deferred.resolve(results);
                })
                .catch(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        getCarte(restaurantId: number, dateISO: string = '', setDateToStartOfDay: boolean = false): ng.IPromise<ICarte> {
            var deferred = this.$q.defer<ICarte>();

            var restaurantCartes = this.cartes[restaurantId];
            if (restaurantCartes) {
                var filtered = restaurantCartes.filter(carte =>
                    (carte.dateDebut == null ? true : (Date.parse(dateISO) >= Date.parse(carte.dateDebut.toISOString())))
                    && (carte.dateFin == null ? true : (Date.parse(dateISO) <= Date.parse(carte.dateFin.toISOString())))
                );

                if (filtered.length > 0) {
                    var targetCarte = filtered[0];

                    if (targetCarte) {
                        deferred.resolve(targetCarte);
                        return deferred.promise;
                    }
                }
            }

            if (restaurantCartes && dateISO === '') {
                if (restaurantCartes.length === 1) {
                    deferred.resolve(restaurantCartes[0]);
                } else {
                    deferred.resolve(this.getActiveCarte(restaurantCartes, new Date(), setDateToStartOfDay));
                }
                return deferred.promise;
            }

            this.getCartesFromServer(restaurantId, dateISO).then((results: ICarte[]) => {
                // HACK fix à l'arrache pour réparer les bétises de DTA
                if (dateISO == null || dateISO === '') {
                    dateISO = new Date().toISOString();
                }
                deferred.resolve(this.getActiveCarte(results, new Date(dateISO), setDateToStartOfDay));
            }).catch((reason) => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        getCartes(restaurantId: number, atLeastNumber: number, fromDateISO: string = '', setDateToStartOfDay: boolean = false): ng.IPromise<ICarte[]> {
            var deferred = this.$q.defer<ICarte[]>();

            var restaurantCartes = this.cartes[restaurantId];
            var deferedDone = false;

            // something in cache   
            if (restaurantCartes && fromDateISO === '') {
                if (restaurantCartes.length >= atLeastNumber) {
                    deferred.resolve(restaurantCartes);
                    deferedDone = true;
                }

                if (!deferedDone) {
                    var actives = this.getActiveCartes(restaurantCartes, new Date(), setDateToStartOfDay);
                    if (actives.length >= atLeastNumber) {
                        deferred.resolve(actives);
                        deferedDone = true;
                    }
                }
            }

            if (!deferedDone) {
                this.getCartesFromServer(restaurantId, fromDateISO, atLeastNumber)
                    .then((results: ICarte[]) => {
                        deferred.resolve(results);
                    })
                    .catch((reason) => {
                        deferred.reject(reason);
                    });
            }

            return deferred.promise;
        }

        private getCarteDeferred: { [id: number]: ng.IDeferred<ICarte[]>[] } = {};

        private getCartesFromServer(restaurantId: number, dateISO: string = '', howMuch: number = 5): ng.IPromise<ICarte[]> {
            var deferred = this.$q.defer<ICarte[]>();

            if (!this.getCarteDeferred[restaurantId] || this.getCarteDeferred[restaurantId].length === 0) {
                this.getCarteDeferred[restaurantId] = [];


                this.$http.get<any>(`${this.configService.getConfigValue('url')}/restaurant/${restaurantId}/cartes/?date=${dateISO}&take=${howMuch}`)
                    .success((results: ICarte[]) => {
                        var today = new Date();
                        today.setHours(0, 0, 0, 0);

                        results.forEach((c) => {
                            c.dateDebut = !!c.dateDebut ? new Date(<any>c.dateDebut) : null;
                            c.dateFin = !!c.dateFin ? new Date(<any>c.dateFin) : null;
                        });

                        this.cartes[restaurantId] = results;
                        for (var i = 0; i < this.getCarteDeferred[restaurantId].length; i++) {
                            this.getCarteDeferred[restaurantId][i].resolve(results);
                        }
                    })
                    .catch(error => {
                        for (var i = 0; i < this.getCarteDeferred[restaurantId].length; i++) {
                            this.getCarteDeferred[restaurantId][i].reject(error);
                        }
                    })
                    .finally(() => {
                        this.getCarteDeferred[restaurantId] = null;
                    });
            }

            this.getCarteDeferred[restaurantId].push(deferred);

            return deferred.promise;
        }

        private getActiveCarte(cartes: ICarte[], currentDate = new Date(), setDateToStartOfDay: boolean): ICarte {
            currentDate.setHours(0, 0, 0, 0);

            return _.find(cartes, c => {
                var dateDebut: Date = c.dateDebut;
                if (!!dateDebut && setDateToStartOfDay) {
                    dateDebut.setHours(0, 0, 0, 0);
                }
                var dateFin: Date = c.dateFin;
                if (!!dateFin && setDateToStartOfDay) {
                    dateFin.setHours(0, 0, 0, 0);
                }

                return (!dateDebut || dateDebut <= currentDate) && (!dateFin || currentDate <= dateFin);
            });
        }


        private getActiveCartes(cartes: ICarte[], currentDate = new Date(), setDateToStartOfDay: boolean): ICarte[] {
            currentDate.setHours(0, 0, 0, 0);

            return _.filter(cartes, c => {
                var dateDebut: Date = c.dateDebut;
                if (!!dateDebut && setDateToStartOfDay) {
                    dateDebut.setHours(0, 0, 0, 0);
                }
                var dateFin: Date = c.dateFin;
                if (!!dateFin && setDateToStartOfDay) {
                    dateFin.setHours(0, 0, 0, 0);
                }

                return (!dateDebut || dateDebut <= currentDate) && (!dateFin || currentDate <= dateFin);
            });
        }
    }

    angular.module('core').service('carteService', ['$rootScope', '$http', '$q', 'configService', 'restaurantsService', 'storageService', CarteService]);
}