module Easypass {
  class PlanningController {
    // Dans le array presences il y a tout le mois
    // avec des presences non renseignées pour les jours vide
    nbrMaxMonth: number = 3;
    qrCodeImage: string;
    currentSiteHasQrCode: boolean;
    presences: IPresenceConvive[];
    presencesLieux: IPresenceLieu[];
    presenceEvents: IPresenceEventType[];
    selectedPresenceLieu?: IPresenceLieu;
    selectedPresenceLieuForm?: IPresenceLieu;
    selectedPresenceEventType?: IPresenceEventType;
    selectedPresenceEventId?: number;
    codeResa?: string;
    isBookingMode: boolean = false;
    monthPresences: IPresenceConvive[];
    presenceType: PresenceType;
    dateDebut: Date;
    dateFin: Date;
    dateDebutTrigger: string = "dateDebutTrigger";
    dateFinTrigger: string = "dateFinTrigger";
    maxDate: Date;
    maxReservationDate: Date;
    tmpDateDebut: Date;
    tmpDateFin: Date;
    isLoading: boolean = true;
    askPresent: boolean = false;
    askAbsent: boolean = false;
    askLunch: boolean = false;
    askHeureArrivee: boolean = false;
    askPresenceLieu: boolean = false;
    askPresenceEvent: boolean = true;
    nbJourMaxReservation: number;
    showPopInFormulairePresence: boolean = false;
    showPopInDisplayReservation: boolean = false;
    cameFromDisplayReservation: boolean = false;
    showPopInMessage: boolean = false;
    errorMessage: string = "";
    warningMessage: string = "";
    successMessage: string = "";
    isSuccess: boolean = false;
    isError: boolean = false;
    isWarning: boolean = false;
    isSubmitting: boolean = false;
    disableDate: boolean = false;
    hours: ICreneauHoraire[];
    selectedHour: ICreneauHoraire;
    weekdays: string[] = ["Monday_short", "Tuesday_short", "Wednesday_Short", "Thursday_Short", "Friday_short", "Saturday_Short", "Sunday_Short"];

    selectedMonth: number;
    selectedYear: number;
    today: Date;
    todayMonth: number;
    todayYear: number;
    public presenceForm: ng.IFormController;

    constructor(
      private presenceConviveService: PresenceConviveService,
      private storageService: StorageService,
      private userService: Core.UserService,
      private downloadFileService: DownloadFileService,
      private $filter: any,
      $stateParams: ng.ui.IStateParamsService,
      private $q: ng.IQService
    ) {
      this.initDefaultMonthAndYear();

      this.userService.getUserInformations().then(userInformations => {
        this.currentSiteHasQrCode = userInformations.currentSite.hasQrCode;

        this.askPresent = userInformations.currentSite.askPresenceOnSite;
        this.askAbsent = true;
        this.askLunch = userInformations.currentSite.askPresenceForLunch;
        this.askHeureArrivee = userInformations.currentSite.askPresenceOnSiteHeureArrivee;
        this.askPresenceLieu = userInformations.currentSite.askPresenceOnSiteLieu;
        this.askPresenceEvent = userInformations.currentSite.askPresenceEvent;
        this.nbJourMaxReservation = userInformations.currentSite.presenceOnSiteNbJourMaxReservation;

        if (this.askHeureArrivee && userInformations.currentSite.presenceOnSiteHeureArriveeDebut && userInformations.currentSite.presenceOnSiteHeureArriveeFin && userInformations.currentSite.presenceOnSiteHeureArriveeStep) {
          this.hours = this.presenceConviveService.initHourSteps(userInformations.currentSite.presenceOnSiteHeureArriveeDebut, userInformations.currentSite.presenceOnSiteHeureArriveeFin, userInformations.currentSite.presenceOnSiteHeureArriveeStep, userInformations.culture);
        }
        this.presenceEvents = userInformations.currentSite.presenceEvents;

        this.maxDate = new Date(this.todayYear, this.todayMonth + this.nbrMaxMonth);
        this.maxReservationDate = this.nbJourMaxReservation ? this.addDays(this.today, this.nbJourMaxReservation) : this.maxDate;

        // Initialize calendar
        const startDate = this.getFirstDate(this.selectedMonth, this.selectedYear);
        const endDate = this.getLastDate(this.selectedMonth, this.selectedYear);
        this.monthPresences = this.buildMonthPresences([], startDate, endDate);

        $q.all({
          eventTypes: this.presenceConviveService.getPresencesEventType(moment(this.today).format("YYYY-MM-DD"), moment(this.maxDate).format("YYYY-MM-DD")),
          presenceLieux: this.presenceConviveService.getPresencesLieu(this.askPresenceLieu)
        }).then(result => {
          console.log(result);
          this.presenceEvents = result.eventTypes;
          this.presencesLieux = result.presenceLieux;

          if (this.askPresenceLieu && this.presencesLieux.length > 0) {

            // On prend le Lieu sauvegardé par defaut si aucun n'est sélectionné
            if (!this.selectedPresenceLieu) {
              this.selectedPresenceLieu = this.storageService.getPresenceConviveLieu();
            }

            // On prend le premier Lieu par defaut si aucun n'est sélectionné
            if (!this.selectedPresenceLieu) {
              this.selectedPresenceLieu = this.presencesLieux[0];
            }
          }
        })
          .then(() => this.getPresences())
          .catch(
            (error) => {
              console.log(error);
            }
          );

        // Si on arrive sur le calendrier avec un event id
        if ($stateParams["eventId"]) {
          this.selectedPresenceEventId = $stateParams["eventId"];
          this.bookEvent(this.selectedPresenceEventId);
        }
      });

    }

    private initDefaultMonthAndYear() {
      this.today = this.getToday();

      this.todayMonth = this.today.getMonth();
      this.todayYear = this.today.getFullYear();
      this.selectedMonth = this.today.getMonth();
      this.selectedYear = this.today.getFullYear();
    }

    getPresences() {
      // je ne fais pas de get presence si pas de lieu sélectionné
      if (this.askPresenceLieu && !this.selectedPresenceLieu) {
        return;
      }

      const startDate = this.getFirstDate(this.selectedMonth, this.selectedYear);
      const endDate = this.getLastDate(this.selectedMonth, this.selectedYear);

      this.presenceConviveService.getPresencesConvive(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")).then(
        (data) => {
          this.presences = data.map((presence) => {
            presence.date = new Date(presence.date as any);
            return presence;
          });
          this.monthPresences = this.buildMonthPresences(this.presences, startDate, endDate);
        },
        (error) => {
          console.log(error);
        }
      );
    }

    // getPresencesAndLieux() {
    //   this.presenceConviveService.getPresencesLieu().then(
    //     (data) => {
    //       //console.log(data);
    //       this.presencesLieux = data;

    //       // On prend le Lieu sauvegardé par defaut si aucun n'est sélectionné
    //       if (!this.selectedPresenceLieu) {
    //         this.selectedPresenceLieu = this.storageService.getPresenceConviveLieu();
    //       }

    //       // On prend le premier Lieu par defaut si aucun n'est sélectionné
    //       if (!this.selectedPresenceLieu) {
    //         this.selectedPresenceLieu = this.presencesLieux[0];
    //       }

    //       this.getPresences();
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // }

    getPresencesEventType(start: string, end: string) {
      this.presenceConviveService.getPresencesEventType(start, end).then(
        (data) => {
          console.log(data);
          this.presenceEvents = data;
        },
        (error) => {
          console.log(error);
        }
      );
    }

    showPopinFormPresence() {
      this.initForm();
      this.disableDate = false;
      this.showPopInFormulairePresence = true;
      this.showPopInDisplayReservation = false;
    }

    gotoFormPresence() {
      this.showPopInFormulairePresence = true;
      this.showPopInDisplayReservation = false;
      this.cameFromDisplayReservation = true;
    }

    updateDay(presence: IPresenceConvive) {
      var presenceId = presence.id;
      var date = presence.date;
      var presenceType = presence.presence;
      var heure = presence.heureArriveeOnSite ? presence.heureArriveeOnSite.toString() : null;
      var lieuId = presence.lieuId;
      var eventId = presence.presenceEventTypeId;
      this.initForm();
      if (presenceType === PresenceType.disabled) {
        return;
      }
      this.disableDate = true;
      const tmpDate = this.$filter("date")(date, "dd/MM/yyyy");
      this.tmpDateDebut = tmpDate;
      this.tmpDateFin = tmpDate;
      this.dateFin = date;
      this.dateDebut = date;
      this.presenceType = presenceType;
      if (this.presencesLieux) {
        if (lieuId) {
          this.selectedPresenceLieuForm = this.presencesLieux.filter((x) => x.id == lieuId)[0];
        } else {
          this.selectedPresenceLieuForm = this.selectedPresenceLieu;
        }
      }

      this.selectedHour = null;
      if (heure) {
        var currentDate = new Date();
        const hourStrTab = heure.split(":");
        currentDate.setMinutes(+hourStrTab[1]);
        currentDate.setHours(+hourStrTab[0]);
        const hour = moment(currentDate);
        let hourValue = hour.toDate().toLocaleTimeString("fr", {
          hour: "2-digit",
          minute: "2-digit"
        });
        var hoursFiltered = this.hours.filter((x) => x.id == hourValue);
        if (hoursFiltered && hoursFiltered.length > 0) {
          this.selectedHour = hoursFiltered[0];
        }
      }

      // recuperer les creneaux horaires
      // TODO : Ne faire cela que si le quota est param sur le site
      const start = moment(date).format("YYYY-MM-DD");
      const end = start;
      const currentLieu = this.selectedPresenceLieuForm ? this.selectedPresenceLieuForm.id : null;
      this.presenceConviveService.getCreneauxPresencesDisponibles(start, end, currentLieu).then((creneaux) => {
        // TODO : Gérer le cas de le présence avec un creneau sélectionné
        this.hours = creneaux;
        if (this.selectedHour) {
          var isExist = this.hours.filter((x) => x.id == this.selectedHour.id).length > 0;
          if (!isExist) {
            this.hours.push(this.selectedHour);
            this.hours = this.hours.sort((a, b) => (a.id > b.id ? 1 : -1));
          }
        }
      });

      if (this.askPresenceEvent) {
        // Pour une édition
        if (this.presenceEvents && eventId) {
          this.selectedPresenceEventType = this.presenceEvents.filter((x) => x.id == eventId)[0];
        }
      }

      const curDate = moment(date).format("YYYY-MM-DD");
      const todayF = moment(this.today).format("YYYY-MM-DD");
      if (presenceId > 0 && curDate === todayF && presenceType == PresenceType.Lunch) {
        this.GetQrCode();
        this.codeResa = presence.codeReservation;
        this.showPopInDisplayReservation = true;
      } else {
        this.showPopInFormulairePresence = true;
      }
      this.cameFromDisplayReservation = false;
    }

    bookEvent(eventId: number) {
      // Init le formulaire
      this.initForm();
      // Préparer le formulaire
      if (this.askPresenceEvent && this.presenceEvents && eventId) {
        this.selectedPresenceEventType = this.presenceEvents.filter((x) => x.id == eventId)[0];
        if (this.selectedPresenceEventType) {
          this.isBookingMode = true;
          this.disableDate = true; // empécher qu'on puisse modifier les dates
          // Pour la sauvegarde
          this.dateDebut = this.selectedPresenceEventType.dateDebutEvent;
          this.dateFin = this.selectedPresenceEventType.dateFinEvent;
          // Pour l'affichage
          this.tmpDateDebut = this.$filter("date")(this.dateDebut, "dd/MM/yyyy");
          this.tmpDateFin = this.$filter("date")(this.dateFin, "dd/MM/yyyy");
          // On souhaite réserver ...
          this.presenceType = PresenceType.Lunch;
          // TODO :  Récuperer les lieux et les horaires
        }
      }
      // Afficher la popin
      this.showPopInFormulairePresence = true;
    }

    getCreneaux() {
      if (this.presenceType == PresenceType.Absent) {
        return;
      }
      const dateDebut = moment(this.dateDebut).format("YYYY-MM-DD");
      const dateFin = moment(this.dateFin).format("YYYY-MM-DD");
      const lieuId = this.selectedPresenceLieuForm ? this.selectedPresenceLieuForm.id : null;
      this.presenceConviveService.getCreneauxPresencesDisponibles(dateDebut, dateFin, lieuId).then((creneaux) => {
        this.hours = creneaux;
      });
    }

    savePresence() {
      if (this.presenceForm.$invalid) {
        return;
      }
      this.showPopInFormulairePresence = false;
      if (!this.isSubmitting) {
        this.isSubmitting = true;
        this.presenceConviveService.savePresence(this.dateDebut, this.dateFin, this.presenceType, this.selectedHour ? this.selectedHour.id : null, this.selectedPresenceLieuForm ? this.selectedPresenceLieuForm.id : null, this.selectedPresenceEventType ? this.selectedPresenceEventType.id : null).then(
          (data: any) => {
            this.isSubmitting = false;
            this.isSuccess = data.success;
            this.isError = data.error;
            this.isWarning = data.warning;
            this.warningMessage = data.warning;
            this.errorMessage = data.error;
            this.showPopInMessage = this.isError || this.isWarning;

            //console.log(data)
            // Afficher un resumé de la reservation
            // const today = this.getToday();
            // const curDate = moment(this.dateDebut).format("YYYY-MM-DD");
            // const todayF = moment(today).format("YYYY-MM-DD");
            // if (curDate === todayF
            //     && this.presenceType == PresenceType.Lunch
            //     && !this.cameFromDisplayReservation) {
            //   this.updateDay( {
            //     id :1,
            //     date: this.dateDebut,
            //     codeReservation : this.codeResa,
            //     heureArriveeOnSite: this.selectedHour ? moment(this.selectedHour.id).toDate() : null,
            //     presence:this.presenceType,
            //     lieuId: this.selectedPresenceLieuForm ? this.selectedPresenceLieuForm.id : null,
            //     presenceEventTypeId:  this.selectedPresenceEventType ? this.selectedPresenceEventType.id : null,
            //     conviveId: null,
            //     siteId: null
            //   } as IPresenceConvive);
            // } else
            // {
            //   this.initForm();
            // }
            
            this.initForm();
            if (this.selectedPresenceLieu) {
              this.storageService.savePresenceConviveLieu(this.selectedPresenceLieu);
            }
            this.getPresences();
          },
          (error) => {
            this.isSubmitting = false;
            this.isError = true;
            this.successMessage = null;
            this.warningMessage = null;
            this.errorMessage = error.message;
            this.showPopInMessage = true;
            console.log(error);
          }
        );
      }
    }

    onNextMonth() {
      this.selectedMonth = (this.selectedMonth + 1) % 12;
      if (this.selectedMonth === 0) {
        this.selectedYear++;
      }

      this.getPresences();
    }

    onPreviousMonth() {
      if (this.selectedMonth === this.todayMonth && this.selectedYear === this.todayYear) {
        return;
      }

      this.selectedMonth = (this.selectedMonth - 1 + 12) % 12;
      if (this.selectedMonth === 11) {
        this.selectedYear--;
      }

      this.getPresences();
    }

    export() {
      var selectorElem = "#print-zone";
      window.scroll(0, 0); // HACK :  pour corriger la capture d'image par html2canvas dansle desktop ce pb est liée au composant qui gére le progressive scroll.
      html2canvas(document.querySelector(selectorElem)).then((canvas) => {
        var data = canvas.toDataURL("image/jpeg", 1);
        this.downloadReservation(data);
      });
    }

    downloadReservation(data) {
      if (!window.cordova) {
        this.downloadFileService.createDownloadLink("ReservatioPresence.jpg", data).click();
      } else {
        this.downloadFileService.downloadCordova(data);
      }
    }

    private GetQrCode() {
      if (this.currentSiteHasQrCode) {
        this.userService
          .getQrCode()
          .then((qrcode) => {
            if (!!qrcode) {
              this.qrCodeImage = qrcode;
            }
          })
          .catch((error) => {
            console.log("error : " + error);
          });
      }
    }

    // Return all weeks days (from monday to sunday) contening the month in parameter
    private buildMonthPresences(presences: IPresenceConvive[], startDate: Date, endDate: Date): IPresenceConvive[] {
      console.log(`from ${startDate} to ${endDate}`);

      const monthPresences = [];

      let cursorDate = new Date(startDate.valueOf());
      while (cursorDate < endDate || this.isSameDate(cursorDate, endDate)) {
        let foundDayPresence = null;

        foundDayPresence = _.find(presences, (presence: IPresenceConvive) => this.isSameDate(presence.date, cursorDate));

        if (foundDayPresence) {
          foundDayPresence.presence = this.getPresenceType(cursorDate, foundDayPresence.presenceEventTypeId, foundDayPresence.presence);
          monthPresences.push(foundDayPresence);
        }
        else {
          let presenceEventType = this.getPresenceEventType(cursorDate);
          let presenceEventTypeId = presenceEventType ? presenceEventType.id : null;
          let monthPresence = <IPresenceConvive>{
                conviveId: presences[0] ? presences[0].conviveId : null,
            date: new Date(cursorDate.valueOf()),
                heureArriveeOnSite: null,
            presence: this.getPresenceType(cursorDate, presenceEventTypeId, null),
                siteId: presences[0] ? presences[0].siteId : null,
            presenceEventTypeId: presenceEventTypeId
          };
          monthPresences.push(monthPresence);
        }
        cursorDate = this.addDays(cursorDate, 1);
      }

      return monthPresences;
    }

    private getPresenceType(curDate: Date, presenceEventTypeId: number, defaultPresenceType: PresenceType): PresenceType {

      // Date dans le passé
      if (curDate.getTime() < this.today.getTime()) {
        return PresenceType.disabled;
      }

      // Le convive doit avoir saisi un lieu si c'est demandé
      if (this.askPresenceLieu && !this.selectedPresenceLieu) {
        return PresenceType.disabled;
      }

      // Desactiver tous les samedi et dimanche
      if (curDate.getDay() == 6 || curDate.getDay() == 0) {
        return PresenceType.disabled;
      }

      // Si un evênement existe il doit pouvoir être réservé même en dehors de la plage de réservation imposé par nbJourMaxReservation
      if (presenceEventTypeId) {
        return defaultPresenceType;
      }

      // Limitation du nombre de jours réservable
      if (this.nbJourMaxReservation && curDate > this.maxReservationDate) {
        return PresenceType.disabled;
      }

      return defaultPresenceType;
    }

    private getPresenceEventType(curDate: Date): IPresenceEventType {
      if (this.presenceEvents) {
        const curDateF = moment(moment(curDate).format("YYYY-MM-DD"));
        const todayF = moment(moment(this.today).format("YYYY-MM-DD"));
        return _.find(
          this.presenceEvents,
          (x) =>
            curDateF.isSameOrAfter(moment(x.dateDebutEvent).format("YYYY-MM-DD")) &&
            curDateF.isSameOrBefore(moment(x.dateFinEvent).format("YYYY-MM-DD")) &&
            todayF.isSameOrAfter(moment(x.dateDebutReservation).format("YYYY-MM-DD")) &&
            todayF.isSameOrBefore(moment(x.dateFinReservation).format("YYYY-MM-DD"))
        );
      } else {
        console.log('no presenceEventType defined');
      }
    }

    private getToday() {
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      return now;
    }

    // Return the first date of week wich contains the first day of the month in parameter 
    private getFirstDate(month: number, year: number): Date {
      const firstDayOfMonth = new Date(year, month, 1);
      return this.getFirstDateOfWeek(firstDayOfMonth);
    }

    // Return the last date of week wich contains the last day of the month in parameter 
    private getLastDate(month: number, year: number): Date {
      let lastDayOfMonth = new Date(year, month + 1, 1);
      lastDayOfMonth = this.addDays(lastDayOfMonth, -1);
      return this.getLastDateOfWeek(lastDayOfMonth);
    }

    // Add days to the date and return the new date
    addDays(date: Date, days: number): Date {
      if (!days) {
        days = 0;
      }
      var result = new Date(date.valueOf());
      result.setDate(result.getDate() + days);
      return result;
    }

    // Compare two date without time. Return true if they are the same else false
    private isSameDate(date1: Date, date2: Date): boolean {
      return date2 && date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
    }

    private getFirstDateOfWeek(date: Date): Date {
      return this.addDays(date, 1 - this.getWeekDay(date));
    }

    private getLastDateOfWeek(date: Date): Date {
      return this.addDays(date, 7 - this.getWeekDay(date));
    }

    // same as getDay but sunday = 7 instead of 0
    private getWeekDay = function (date: Date): number {
      return date.getDay() === 0 ? 7 : date.getDay();
    };

    private initForm() {
      this.dateDebut = null;
      this.dateFin = null;
      this.tmpDateDebut = null;
      this.tmpDateFin = null;
      this.presenceType = null;
      this.selectedHour = null;
      this.selectedPresenceEventType = null;
      this.selectedPresenceEventId = null;
      this.isBookingMode = false;
      this.codeResa = null;
    }
  }

  angular.module("easypass").controller("planningController", ["presenceConviveService", "storageService", "userService", "downloadFileService", "$filter", "$stateParams", "$q", PlanningController]);
}
