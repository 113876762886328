module Core {
    class AccesEntrepriseController {

        entreprisesHasAcces: IEntrepriseAccesModel[];
        entreprisesAcces: IEntrepriseAccesModel[];
        showPopinConfirmation: boolean;
        currentEntrepriseLabel: string;
        marque: string;
         
        constructor(private entrepriseAccesService: EntrepriseAccesService) {
            this.currentEntrepriseLabel = "";
            this.showPopinConfirmation = false;
            this.initEntrepriseList();
        }

        public addAcces(entreprise: IEntrepriseAccesModel) {
            this.currentEntrepriseLabel = "";
            this.showPopinConfirmation = false;
            this.entrepriseAccesService.addAcces(entreprise.id)
                .then((response) => {
                    this.initEntrepriseList();
                    this.currentEntrepriseLabel = entreprise.libelle;
                    this.showPopinConfirmation = true;
                });
        }

        private initEntrepriseList() {
            this.currentEntrepriseLabel = "";
            this.showPopinConfirmation = false;
            this.entrepriseAccesService.getEntreprisesAccesAndNotAcces().then((result) => {
                this.marque = result.marque;
                this.entreprisesHasAcces = result.demandes.filter((demande) => demande.accesStatus == AccesStatus.HasAcces)
                    .map((demande) => this.convertToEntrepriseAccesModel(demande));
                this.entreprisesAcces = result.demandes.filter((demande) => demande.accesStatus != AccesStatus.HasAcces)
                    .map((demande) => this.convertToEntrepriseAccesModel(demande));
            });
        }

        private convertToEntrepriseAccesModel(demande: IDemandeAccesRequest): IEntrepriseAccesModel {
            return <IEntrepriseAccesModel>{
                id: demande.entrepriseId,
                libelle: demande.libelle,
                checked: demande.accesStatus == AccesStatus.HasAcces,
                pendingStatus: demande.accesStatus == AccesStatus.Pending
            }
        }

    }

    interface IEntrepriseAccesModel {
        id: number;
        libelle: string;
        checked: boolean;
        pendingStatus: boolean;
    }

    angular.module('core').controller('accesEntrepriseController', ['entrepriseAccesService', AccesEntrepriseController]);
}