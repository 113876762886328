module Core {
    angular.module('core').filter('passwordToValidationState', [() => {
        return (password: string, userLastName: string, userFirstName: string): Core.ValidationState => {
            userLastName = userLastName || '';
            userFirstName = userFirstName || '';
            if (!password || password.length == 0) return Core.ValidationState.empty;
            if (!password || password.length < 8) return Core.ValidationState.invalid;
            const lowercasePassword: string = password.toLowerCase();
            if ((userFirstName != '' && lowercasePassword.indexOf(userFirstName.toLowerCase()) != -1) ||
                (userLastName != '' &&  lowercasePassword.indexOf(userLastName.toLowerCase()) != -1))
                return Core.ValidationState.invalid; 
            let score = 0;
            if (/[a-z]/.test(password)) score++;
            if (/[A-Z]/.test(password)) score++;
            if (/[0-9]/.test(password)) score++;
            if (/[^a-zA-Z\d]/.test(password)) score++;

            if (score > 2) return Core.ValidationState.valid;
            if (score > 1) return Core.ValidationState.warning;
            return Core.ValidationState.invalid;
        };
    }]);
}