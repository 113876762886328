module Easypass {
    @Component('easypass', 'tcCommandeArticleDta', {
        controllerAs: 'vm',
        bindings: {
            article: '<'
        },
        templateUrl: './commandeArticleDTA.html'
    })
    class CommandeArticleDTA {
        static $inject: string[] = [];
        article;

        constructor() {
        }

        $onInit = () => {
        }
	}
}