module Easypass {
    class RetraitController {

        retrait: ICommandeRetrait;
        restaurantId: number;
        takeout: string;
        takeoutEnd: string;
        takeoutDay: string;

        takeoutLieu: string;
        isClickAndServe: boolean;
        isServiceATable: boolean;

        constructor(private panierService: PanierService,
            private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService,
            private $rootScope: ng.IRootScopeService,
            private commandeService: CommandeService,
            private restaurantsService: RestaurantsService) {
            this.restaurantId = +this.$stateParams["restaurantId"];
            this.retrait = this.$stateParams["retrait"];
            var formatNumbers = (num: number): string => ('0' + num).slice(-2);
            this.$rootScope.$broadcast('soldeChanged');
            this.takeout = formatNumbers(this.retrait.dateRetrait.getHours()) + ":" + formatNumbers(this.retrait.dateRetrait.getMinutes());

            if (!moment(this.retrait.dateRetrait).isSame(Date.now(), 'day')) {
                this.takeoutDay = moment(this.retrait.dateRetrait).format('dddd');
            }
            if (!!this.retrait.dateFinRetrait) {
                var dateFinRetrait = new Date(this.retrait.dateFinRetrait.toString());
                this.takeoutEnd = formatNumbers(dateFinRetrait.getHours()) + ":" + formatNumbers(dateFinRetrait.getMinutes());
            }

            if (this.retrait.lieuRetraitLibelle) {
                this.takeoutLieu = this.retrait.lieuRetraitLibelle;
            }

            this.commandeService.getMesCommandes(true).then(commandes => {
            });
            this.restaurantsService.get(this.restaurantId).then((res) => {
                this.isClickAndServe = res.isClickAndServe;
                this.isServiceATable = res.isServiceATable;
            });
        }
    }
    angular.module('easypass').controller('retraitController', ["panierService", "$stateParams", "$state", "$rootScope", "commandeService", "restaurantsService", RetraitController]);
}