module Core {
    interface IDateDirectiveScope extends ng.IScope {
        format: string;
        date: Date;
    }

    class DateDirective implements ng.IDirective {
        constructor() { }

        restrict = 'A';

        scope = {
            date: '=',
            format: '@'
        }

        link = (scope: IDateDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
            scope.$watch(() => scope.date, (value: any) => {
                if (scope.format === "LLLLWithoutTime") {
                    var formattedValue = moment(value).format('LLLL').replace(moment(value).format('LT'), '').trim();
                    element[0].textContent = formattedValue;
                } else {
                    element[0].textContent = moment(value).format(scope.format);
                }
            });

            scope.$on('resourcesUpdated', () => {
                if (scope.format === "LLLLWithoutTime") {
                    var formattedValue = moment(scope.date).format('LLLL').replace(moment(scope.date).format('LT'), '').trim();
                    element[0].textContent = formattedValue;
                } else {
                    element[0].textContent = moment(scope.date).format(scope.format);
                }
            });
        }

        static factory() {
            return () => {
                return new DateDirective();
            };
        }
    }

    angular.module('core').directive('date', [DateDirective.factory()]);
}
