
module Core {
    angular.module('core').filter('zeroPad', [() => {
        return (value : number = 0, pad: number = 2): string => {
            var str = value.toString();
            var nbZeros = pad - str.length;
            for (var i = 0; i < nbZeros; i++) {
                str = "0" + str;
            }
            return str;
        };
    }]);
}