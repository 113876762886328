module IS.Localization {
    interface ILocalizationScope extends ng.IScope {
        replaceWith: any;
    }

    class I18NDirective implements ng.IDirective {
        constructor(private localizeService: LocalizeService) {
        }

        restrict: string = 'AE';

        scope = {
            replaceWith: "=?"
        };

        link = (scope: ILocalizationScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
			var updateLocalization = () => {
				var key = attrs['i18n'];
                var text = this.localizeService.getLocalizedResource(key);
                var replaceWith = scope.replaceWith;
                for (var i in replaceWith) {
                    if (replaceWith[i] != undefined)
                        text = text.replace(`{{${i}}}`, replaceWith[i]);
                    else
                        text = text.replace(`{{${i}}}`, '');
                }
                if (attrs.hasOwnProperty('i18nHtml')) {
                    element[0].innerHTML = text;
                } else {
                    element[0].textContent = text;
                }
            }

            attrs.$observe('i18n', (key: string) => {
                updateLocalization();
            });

            scope.$on('replaceWith', () => {
                updateLocalization();
            });
            scope.$on('resourcesUpdated', () => {
                updateLocalization();
            });
        }

        static factory() {
            return (localizeService: LocalizeService) => {
                return new I18NDirective(localizeService);
            };
        }
    }

    angular.module('localization').directive('i18n', ['localizeService', I18NDirective.factory()]);


    class I18NAttrDirective implements ng.IDirective {
        constructor(private localizeService: LocalizeService) {
        }

        restrict: string = 'AE';

        scope = {
            replaceWith: "=?"
        };

        link = (scope: ILocalizationScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
            var binds = attrs['i18nAttr'].split('/');

            var updateAttr = () => {
                for (var i in binds) {
                    var values = binds[i].split('|');
                    var key = values[0];
                    var attrName = values[1];
                    var resource = this.localizeService.getLocalizedResource(key);
                    if (!!scope.replaceWith) {
                        var replaceWith = JSON.parse(scope.replaceWith);
                        for (var i in replaceWith) {
                            resource = resource.replace(`{{${i}}}`, replaceWith[i]);
                        }
                    }
                    if (attrName === 'value') {
                        (<any>element[0]).value = resource;
                    } else if (attrName === 'placeholder') {
                        (<any>element[0]).placeholder = resource;
                    } else {
                        element[0].setAttribute(attrName, resource);
                    }
                }
            }
            attrs.$observe('i18nAttr', () => {
                updateAttr();
            });

            scope.$on('resourcesUpdated', () => {
                updateAttr();
            });
        }

        static factory() {
            return (localizeService: LocalizeService) => {
                return new I18NAttrDirective(localizeService);
            };
        }
    }

    angular.module('localization').directive('i18nAttr', ['localizeService', I18NAttrDirective.factory()]);
}