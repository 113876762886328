module Core {
    class NotificationsParametersController {

        isRegisteredInCaisse: boolean;
        parameters: INotificationParameters;
        paramType: string;
        isLoading: boolean;
        isOnError: boolean;
        isOnNetworkError: boolean;
        showNoPhoneNumberErrorMessage: boolean;

        showAnimationParam: boolean = true;
        showCommandeParam: boolean = false;
        showEnqueteParam: boolean = true;
        showDinerTakeAwayParam: boolean = false;

        constructor(private restaurantsService: Core.RestaurantsService, private userService: UserService, private $window: ng.IWindowService, private $stateParams: ng.ui.IStateParamsService) {
            this.showNoPhoneNumberErrorMessage = false;
            this.paramType = $stateParams['type'];

            if (userService.isAuthenticated()) {
                userService.getUserInformations(false).then((userInformations) => {
                    this.isRegisteredInCaisse = userInformations.currentSite.isRegisteredInCaisse;
                    if (userInformations.currentSite.restaurantsDisplayed) {
                        this.SetDinerTakeAwayParamVisibility();
                        this.SetCommandeParamVisibility();
                    } else {
                        this.manageTimechefSansRestaurants();
                    }
                }).catch(error => {
                    // TODO: StatusCode=0
                });
            }

            userService.getNotificationParameters(this.paramType)
                .then((parameters) => {
                    this.parameters = parameters;
                })
                .catch(error => {
                    // TODO: StatusCode=0
                });

        }

        private SetDinerTakeAwayParamVisibility(): void {
            this.restaurantsService.gets(false, false, false, false).then(restaurants => {
                for (var i = 0; i < restaurants.length; i++) {
                    if (restaurants[i].isDinnerTakeaway) {
                        this.showDinerTakeAwayParam = true;
                        return;
                    }
                }
            });
        }

        private SetCommandeParamVisibility(): void {
            this.restaurantsService.gets(false, false, false, false).then(restaurants => {
                for (var i = 0; i < restaurants.length; i++) {
                    if (restaurants[i].hasCommandeNotification) {
                        this.showCommandeParam = true;
                        return;
                    }
                }
            });
        }

        private manageTimechefSansRestaurants(): void {
            this.showAnimationParam = false;
            this.showEnqueteParam = false;
            this.showDinerTakeAwayParam = false;
        }

        updateParameter(flag: string, enable: boolean): void {
            if (this.paramType == 'sms' && enable && !this.userService.userInformations.phoneNumber) {
                this.showNoPhoneNumberErrorMessage = true;
                return;
            }

            this.showNoPhoneNumberErrorMessage = false;
            this.isLoading = true;
            this.isOnError = false;

            this.userService.updateNotificationParametersForProvider(flag, this.paramType, enable)
                .then((parameters) => {
                    this.isOnError = false;
                    this.isOnNetworkError = false;

                    if (this.paramType == 'sms') {
                        (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_notifications_SMS_BTN_Toggle', flag + '/' + enable);
                    }
                    else if (this.paramType == 'email') {
                        (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_notifications_Email_BTN_Toggle', flag + '/' + enable);
                    }
                    else if (this.paramType == 'push') {
                        (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_notifications_Push_BTN_Toggle', flag + '/' + enable);
                    }

                    this.parameters = parameters;
                })
                .catch(error => {
                    checkNetworkError(error,
                        () => {
                            this.isOnNetworkError = false;
                            this.isOnError = true;
                        },
                        () => {
                            this.isOnError = false;
                            this.isOnNetworkError = true;
                        });
                    // TODO: StatusCode=0
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
    angular.module('core').controller('notificationsParametersController', ['restaurantsService', 'userService', '$window', '$stateParams', 'userService', NotificationsParametersController]);
}