module Easypass.DinerTakeAway {
    @Component('easypass', 'moreLess', {
        controllerAs: 'vm',
        bindings: {
            value: '<',
            valueChanged: '&',
            disableQuantity: '<'
        },
        templateUrl: './dtMoreLess.html'
    })
    class MoreLessComponent {
        internalValue: number;
        valueChanged: (data: { value: number }) => void;
        disabledQuantity: boolean;

        set value(value: number) {
            this.internalValue = value | 0;
        }

        more = () => {
            this.internalValue++;
            this.valueChanged({ value: this.internalValue });
        };

        less = () => {
            if (this.internalValue > 0) {
                this.internalValue--;
                this.valueChanged({ value: this.internalValue });
            }
        };
    }
}