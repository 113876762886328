module Easypass {
    interface IAllergeneDirectiveScope extends ng.IScope {
        code: string;
        allergene: Core.IAllergene;
        isAllergic: boolean;
        enabledIsAllergic: boolean;
    }

    class AllergeneDirective implements ng.IDirective {
        constructor(private allergenesService: Core.AllergenesService) { }

        restrict = 'EA';

        scope = {
            code: '=?',
            allergene: '=?',
            enabledIsAllergic: '=?'
        };

        link = (scope: IAllergeneDirectiveScope): void => {
            if (!scope.allergene) {
                scope.allergene = this.allergenesService.getAllergeneByKey(scope.code);
            }
            //console.log('Allergene : ' + JSON.stringify(scope.allergene));

            if (scope.enabledIsAllergic) {
                scope.isAllergic = this.allergenesService.isAllergic(scope.allergene);
            }
        }

        template = `
            <div class='allergene-picto' data-ng-class='{ "allergic": enabledIsAllergic && isAllergic, "nonallergic": enabledIsAllergic && !isAllergic }' >
                <colored-svg source='allergene.asset' title='{{allergene.ressourceNameKey | i18n}}'/>
            </div>
        `;

        static factory() {
            return (allergenesService: Core.AllergenesService) => {
                return new AllergeneDirective(allergenesService);
            };
        }
    }

    angular.module('easypass').directive('allergene', ['allergenesService', AllergeneDirective.factory()]);
}