module Core {
    class AllergenesParametersController {

        allergenesList: IAllergene[];

        constructor(private allergenesService: AllergenesService, private $window: ng.IWindowService) {
            this.allergenesList = allergenesService.allergenes;
            (<any>window).DataAnalyticsSender.sendCustomEvent('Settings_Allergens_Screen', '');
        }

        isAllergic(allergene: IAllergene): boolean {
            return this.allergenesService.isAllergic(allergene);
        }

        toggleAllergene(allergene: IAllergene) {
            return this.allergenesService.toggleAllergene(allergene);
        }

        goBack() {
            this.$window.history.back();
        }

    }
    angular.module('core').controller('allergenesParametersController', ['allergenesService', '$window', AllergenesParametersController]);
    angular.module('core').filter('orderedAllergene', ['$filter', function ($filter: ng.IFilterService) {
        return function (allergenes: IAllergene[]) {
            var removeAccents = (input: string) => {
                return input.toLowerCase()
                    .replace(new RegExp("\\s", 'g'), "")
                    .replace(new RegExp("[àáâãäå]", 'g'), "a")
                    .replace(new RegExp("æ", 'g'), "ae")
                    .replace(new RegExp("ç", 'g'), "c")
                    .replace(new RegExp("[èéêë]", 'g'), "e")
                    .replace(new RegExp("[ìíîï]", 'g'), "i")
                    .replace(new RegExp("ñ", 'g'), "n")
                    .replace(new RegExp("[òóôõö]", 'g'), "o")
                    .replace(new RegExp("œ", 'g'), "oe")
                    .replace(new RegExp("[ùúûü]", 'g'), "u")
                    .replace(new RegExp("[ýÿ]", 'g'), "y")
                    .replace(new RegExp("\\W", 'g'), "");
            };
            return _.sortByOrder(allergenes, (allergene: IAllergene) => removeAccents($filter<(key: string) => string>('i18n')(allergene.ressourceNameKey)));
        };
    }]);
}