module Easypass {
    class PlatTypologiesController {
        typologies: Core.ITypologie[];
        title: string;
        subTitle: string;

        constructor(
            private configService: IS.Configuration.ConfigService,
            private typologiesService: Core.TypologiesService,
            private localizeService: IS.Localization.LocalizeService,
            private $stateParams: ng.ui.IStateParamsService,
            private $state: ng.ui.IStateService
        ) {
            (<any>window).DataAnalyticsSender.sendCustomEvent('Plat_Typologies_Screen', '');

            var typologieType = +$stateParams['typologieType'];
            var typologieCodes = $stateParams['typologieCodes'];

            typologiesService.getTypologies(typologieCodes).then(platTpologies => {
                this.typologies = _.filter(platTpologies, (typologie: Core.ITypologie) => typologie.type === typologieType);
                switch (typologieType) {
                    case Core.TypologiesTypes.Label:
                        this.title = this.localizeService.getLocalizedResource('Plat_Typologies_Labels_Title');
                        this.subTitle = this.localizeService.getLocalizedResource('Plat_Typologies_Labels_SubTitle');
                        break;
                    case Core.TypologiesTypes.Marqueur:
                        this.title = this.localizeService.getLocalizedResource('Plat_Typologies_Marqueurs_Title');
                        this.subTitle = this.localizeService.getLocalizedResource('Plat_Typologies_Marqueurs_SubTitle');
                        break;
                    case Core.TypologiesTypes.Autre:
                        this.title = this.localizeService.getLocalizedResource('Plat_Typologies_Autress_Title');
                        this.subTitle = this.localizeService.getLocalizedResource('Plat_Typologies_Autres_SubTitle');
                        break;
                    default:
                        this.title = 'ERREUR : Typologie inconnue';
                        break;
                }
            });
        }

        getPictogram(pictogrammeDocumentId: number): string {
            return pictogrammeDocumentId ? `${this.configService.getConfigValue("url")}/documents/${pictogrammeDocumentId}` : '';
        }
    }

    angular.module('easypass').controller('platTypologiesController', ['configService', 'typologiesService', 'localizeService', '$stateParams', '$state', PlatTypologiesController]);
}
