module Core {
    interface CurrencyDirectiveScope extends ng.IScope {
        currency: number;
        nbDigit: number;
        nospacing: boolean;
        nosymbol: boolean;
        prefix: string;
        suffix: string;
    }

    class CurrencyDirective implements ng.IDirective {
        constructor(private localizeService: IS.Localization.LocalizeService) { }

        restrict = 'A';

        scope = {
            currency: '=',
            nbDigit: '&?',
            nospacing: '=?',
            nosymbol: '=?',
            prefix: '=?',
            suffix: '@?'
        }

        link = (scope: CurrencyDirectiveScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
            var delimiter = this.localizeService.getLocalizedResource('Currency_Delimiter');
            var format = this.localizeService.getLocalizedResource('Currency_Format');

            scope.nbDigit = !!scope.nbDigit ? scope.nbDigit : 2;

            scope.$watch(() => scope.currency, (value: any) => {
                if (value || value == 0) {
                    if(typeof value === 'string') {
                        value = +value;
                    }
                    
                    value = value.toFixed(scope.nbDigit).toString().replace('.', delimiter);
                    var currencyValue = format.replace('{value}', value);
                    if (scope.nospacing === true) {
                        currencyValue = currencyValue.replace(' ', '');
                    }
                    if (scope.prefix != null) {
                        currencyValue = scope.prefix + currencyValue;
                    }
                    if (scope.suffix != null) {
                        currencyValue = currencyValue + " " + scope.suffix;
                    }
                    element[0].innerHTML = (scope.nosymbol === true) ? currencyValue.replace(/[$€£]/, '').trim() : currencyValue;
                }else {
                    element[0].textContent = '';
                }
            });

            scope.$on('resourcesUpdated', () => {
                delimiter = this.localizeService.getLocalizedResource('Currency_Delimiter');
                format = this.localizeService.getLocalizedResource('Currency_Format');
                if (scope.currency || scope.currency == 0) {
                    var value = scope.currency.toFixed(scope.nbDigit).toString().replace('.', delimiter);
                    var currencyValue = format.replace('{value}', value);
                    if (scope.nospacing === true) {
                        currencyValue = currencyValue.replace(' ', '');
                    }
                    if (scope.prefix != null) {
                        currencyValue = scope.prefix + currencyValue;
                    }
                    element[0].textContent = (scope.nosymbol === true) ? currencyValue.replace(/[$€£]/, '').trim() : currencyValue;
                }
                else {
                    element[0].textContent = '';
                }
            });
        }

        static factory() {
            return (localizeService: IS.Localization.LocalizeService) => {
                return new CurrencyDirective(localizeService);
            };
        }
    }

    angular.module('core').directive('currency', ['localizeService', CurrencyDirective.factory()]);
}