module Core {
    export class ToggleMenuButtonDirective implements ng.IDirective {
        restrict = 'E';
        replace = true;

        template = `
            <button class="hamburger hide" onclick="window.DataAnalyticsSender.sendCustomEvent('BTN_Menu', '');">
                <span></span>
                <span></span>
                <span></span>
            </button>
        `;

        link = (scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery) => {

            scope.$on('headerConfigurationHasChanged', (event: any, headerConfiguration: IHeaderConfiguration) => {
                if (!!headerConfiguration) {
                    toggleVisibility(headerConfiguration.showMenu);
                    toggleActive(headerConfiguration.activeMenu);
                }
            });

            var toggleVisibility = (visible: boolean) => {
                if (visible) {
                    element[0].classList.remove('hide');
                } else {
                    element[0].classList.add('hide');
                }
            };

            var toggleActive = (active: boolean) => {
                if (active) {
                    menu.classList.add('active');

                } else {
                    menu.classList.remove('active');
                }
            }

            var menu = document.getElementsByTagName('menu')[0];
            element[0].addEventListener('click', () => {
                if (menu.classList.contains('menu-open')) {
                    menu.classList.remove('menu-open');
                    element[0].classList.remove('active');
                } else {
                    menu.classList.add('menu-open');
                    element[0].classList.add('active');
                }
            }, true);

            if (!!scope.headerConfiguration) {
                toggleVisibility(scope.headerConfiguration.showMenu);
                toggleActive(scope.headerConfiguration.activeMenu);
            }

        };

        static factory() {
            return () => {
                return new ToggleMenuButtonDirective();
            };
        }
    }

    angular.module('core').directive('toggleMenuButton', [ToggleMenuButtonDirective.factory()]);

    export class BackButtonDirective implements ng.IDirective {
        constructor(private $window: ng.IWindowService, private $rootScope: IRootScopeWithHeaderConfiguration) { }

        restrict = 'E';
        replace = true;

        template = `
            <button class='back-button hide'></button>
        `;


        link = (scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery) => {
            scope.$on('headerConfigurationHasChanged', (event: any, headerConfiguration: IHeaderConfiguration) => {
                if (!!headerConfiguration) {
                    toggleVisibility(headerConfiguration.showBack);
                }

            });
            var toggleVisibility = (visible: boolean) => {
                if (visible) {
                    element[0].classList.remove('hide');
                } else {
                    element[0].classList.add('hide');
                }
            };

            element.on('click', () => {
                this.$window.history.back();
            });

            if (!!scope.headerConfiguration) {
                toggleVisibility(scope.headerConfiguration.showBack);
            }
        };

        static factory() {
            return ($window: ng.IWindowService, $rootScope: IRootScopeWithHeaderConfiguration) => {
                return new BackButtonDirective($window, $rootScope);
            };
        }
    }

    angular.module('core').directive('backButton', ['$window', '$rootScope', BackButtonDirective.factory()]);

    export class NotificationsButtonDirectiveController {
        unread: number;

        constructor($scope: ng.IScope, private $state: ng.ui.IStateService, private $window: ng.IWindowService, userService: UserService) {
            this.unread = _.size(_.filter(userService.notifications, n => !n.lue));
            $scope.$on('notificationsUpdated', () => {
                this.unread = _.size(_.filter(userService.notifications, n => !n.lue));
            });
        }

        onClick() {
            if (this.$state.is('notifications')) {
                this.$window.history.back();
            } else {
                this.$state.go('notifications');
            }
        }
    }

    export class NotificationsButtonDirective implements ng.IDirective {
        restrict = 'E';
        replace = true;

        template = `
            <button class="notifications hide" data-ng-click="vm.onClick()">
                <div class="number" data-ng-show="vm.unread">{{vm.unread}}</div>
            </button>
        `;

        controller = ['$scope', '$state', '$window', 'userService', NotificationsButtonDirectiveController];

        controllerAs = 'vm';

        link = (scope: IScopeWithHeaderConfiguration, element: ng.IAugmentedJQuery) => {

            scope.$on('headerConfigurationHasChanged', (event: any, headerConfiguration: IHeaderConfiguration) => {
                if (!!headerConfiguration) {
                    toggleVisibility(headerConfiguration.showNotifications);
                }
            });

            var toggleVisibility = (visible: boolean) => {
                if (visible) {
                    element[0].classList.remove('hide');
                } else {
                    element[0].classList.add('hide');
                }
            };


            if (!!scope.headerConfiguration) {
                toggleVisibility(scope.headerConfiguration.showNotifications);
            }
        };

        static factory() {
            return () => {
                return new NotificationsButtonDirective();
            };
        }
    }

    angular.module('core').directive('notificationsButton', [NotificationsButtonDirective.factory()]);
}